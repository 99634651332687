import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import ExpandedLocationHeader from 'Components/Shared/LocationDetails/Header'
import MainContent from './MainContent/MainContent'
import Category from 'Components/Shared/LocationDetails/Category'
import { StyledScrollerBox } from 'Components/LocalResources/LocationCard/style'
import { StyledChip } from 'Components/Shared/CommonElements'
import { fetchLocationDetails } from 'Actions/locations'
import {
  updateShowLocationList,
  updateLocationId,
  updateShowLocationDetail,
} from 'Reducers/uiSlice'
import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'
import { preferredStar } from 'Shared/Icons/LocationInfo/paths'
import CoverImage from 'Components/Shared/LocationDetails/CoverImage/CoverImage'
import cover from './Assets/cover.svg'
import Icon from 'Components/Shared/Icon/Icon'
import DisplayLogo from 'Components/LocalResources/DisplayLogo/DisplayLogo'
import { isDesktop } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'

const LocationDetail = ({
  id,
  setLocationId,
}: {
  id: string | number
  setLocationId?: Function
}) => {
  const dispatch = useDispatch()

  const boundUpdateShowLocationList = () =>
    dispatch(updateShowLocationList(false))

  const boundFetchLocationDetails = () => dispatch(fetchLocationDetails(id))

  const ui: any = useSelector<RootState>((state) => state.ui)

  const { searchResults, locationDetail }: any = useSelector<RootState>(
    (state) => state.locations
  )

  const location = id ? searchResults[id] : locationDetail

  useEffect(() => {
    boundFetchLocationDetails()
  }, [])

  useEffect(() => {
    boundUpdateShowLocationList()
  }, [])

  useEffect(() => {
    dispatch(updateLocationId(+id!))
  }, [id, dispatch])

  const navigate = useNavigate()

  // why return null if !location.quickFilters?
  if (!location || !location.quickFilters) return null

  const { quickFilters } = location

  const secondaryActionBarCallback = () => {
    if (setLocationId) {
      dispatch(updateShowLocationDetail(false))
      return setLocationId(null)
    }
    const returnToUrl = ui.showLocationList
      ? '/locations/map'
      : '/locations/list'
    navigate(returnToUrl)
  }

  const renderSingleStop = () => {
    const { singleStop } = location

    if (!singleStop) return

    return (
      //@ts-ignore
      <StyledChip actionType='HEADER'>
        <Icon
          fill={'auto'}
          scale={'1.5rem'}
          margin={'0 1rem 0 0'}
          viewBox={'0 0 22.527 22.527'}
        >
          {preferredStar}
        </Icon>
        Preferred Partner
      </StyledChip>
    )
  }

  const renderedCategories = Object.values(quickFilters).map(
    (category: any) => {
      return (
        <div key={category.id} id={category.id}>
          <MediaQuery query={DESKTOP_MEDIA_QUERY}>
            {(matches) => {
              const fill = matches ? 'rgb(255,255,255)' : 'rgb(155, 195, 103)'

              return (
                <Category
                  maxHeight={'100px'}
                  key={category.id}
                  {...category}
                  backgroundColor={'rgb(59,69,47)'}
                  fill={fill}
                  className='cypress-category-badge'
                />
              )
            }}
          </MediaQuery>
        </div>
      )
    }
  )

  return (
    <div className={'cypress-location-detail-page'}>
      <CoverImage
        alt={'this image contains of logo partner or image of place'}
        src={cover}
        callback={secondaryActionBarCallback}
      />
      <StyledScrollerBox>
        <ExpandedLocationHeader
          location={location}
          badges={renderedCategories}
          preferredPartner={renderSingleStop()}
        />
        <MainContent
          location={location}
          padding={'16rem 0 5.6rem'}
          className='cypress-location-main-content'
        />
      </StyledScrollerBox>
      {!isDesktop && <DisplayLogo isMobile={true} />}
    </div>
  )
}

export default LocationDetail
