import React from 'react'

const taxesIconPath = (
  <g id='a'>
    <g transform='translate(-5.389 1.725)'>
      <path
        d='M38.793.738c21.413-4.907,32.841,15.117,32.83,46.01s-6.289,69.563-27.7,74.464S-.011,96.443,0,65.553,17.378,5.645,38.793.738Z'
        transform='translate(32.737 137.493) rotate(-118.999)'
        fill='#ffdfdf'
        opacity='0.8'
      />
      <g transform='translate(24.143 1)' opacity='0.8'>
        <g transform='translate(17.308 32.433)'>
          <g transform='translate(0 0)'>
            <path
              d='M349.107,436.115l-2.609,2.609-6.259,6.259-7.571,7.571-6.546,6.546c-1.058,1.058-2.149,2.108-3.191,3.191l-.049.049a2.46,2.46,0,1,0,3.478,3.478l2.608-2.609,6.26-6.258,7.571-7.571,6.546-6.546c1.058-1.058,2.149-2.108,3.191-3.191l.049-.049a2.46,2.46,0,1,0-3.478-3.478Z'
              transform='translate(-322.162 -435.396)'
            />
          </g>
        </g>
        <g transform='translate(15.25 30.376)'>
          <g transform='translate(0 0)'>
            <path
              d='M306.728,419.651c-.114.114-.227.214-.352.319-.311.278.55-.377.033-.033a5.072,5.072,0,0,1-.8.435l.591-.247a5.567,5.567,0,0,1-1.4.385c.222-.033.435-.058.656-.09a6.072,6.072,0,0,1-1.337,0c.222.033.435.058.656.09a5.564,5.564,0,0,1-1.4-.385l.591.247a5.07,5.07,0,0,1-.8-.435c-.517-.341.341.311.033.033-.114-.107-.238-.2-.352-.319s-.214-.227-.319-.352c-.278-.311.377.549.033.033a5.066,5.066,0,0,1-.434-.8l.246.591a5.571,5.571,0,0,1-.385-1.4c.033.222.058.435.09.656a6.075,6.075,0,0,1,0-1.337c-.033.222-.058.435-.09.656a5.569,5.569,0,0,1,.385-1.4l-.246.591a5.062,5.062,0,0,1,.434-.8c.341-.517-.311.341-.033.033.107-.114.2-.238.319-.352s.227-.214.352-.319c.311-.278-.55.377-.033.033a5.075,5.075,0,0,1,.8-.434l-.591.245a5.565,5.565,0,0,1,1.4-.385c-.222.033-.435.058-.656.09a6.076,6.076,0,0,1,1.337,0c-.222-.033-.434-.057-.656-.09a5.57,5.57,0,0,1,1.4.385l-.591-.245a5.068,5.068,0,0,1,.8.434c.517.341-.341-.311-.033-.033.114.107.238.2.352.319s.214.227.319.352c.278.311-.377-.55-.033-.033a5.072,5.072,0,0,1,.434.8l-.247-.591a5.56,5.56,0,0,1,.385,1.4c-.033-.222-.058-.434-.09-.656a6.068,6.068,0,0,1,0,1.337c.033-.222.058-.435.09-.656a5.56,5.56,0,0,1-.385,1.4l.247-.591a5.085,5.085,0,0,1-.434.8c-.341.517.311-.341.033-.033a4.3,4.3,0,0,1-.319.352,2.46,2.46,0,1,0,3.478,3.478,7.543,7.543,0,0,0,2.1-3.953,8.153,8.153,0,0,0-.656-4.906,7.672,7.672,0,0,0-7.44-4.241,8.058,8.058,0,0,0-4.528,1.9,7.478,7.478,0,0,0-2.362,3.83,7.692,7.692,0,0,0,12.887,7.359,2.457,2.457,0,1,0-3.478-3.47Z'
              transform='translate(-297.076 -410.008)'
            />
          </g>
        </g>
        <g transform='translate(35.142 50.242)'>
          <g transform='translate(0 0)'>
            <path
              d='M549.228,661.851c-.114.114-.227.214-.352.319-.311.279.55-.377.033-.033a5.067,5.067,0,0,1-.8.434l.591-.246a5.556,5.556,0,0,1-1.4.385c.222-.033.434-.057.656-.09a6.088,6.088,0,0,1-1.337,0c.222.033.435.057.656.09a5.561,5.561,0,0,1-1.4-.385l.591.246a5.064,5.064,0,0,1-.8-.434c-.517-.341.341.311.033.033-.114-.107-.238-.2-.352-.319s-.214-.227-.319-.352c-.278-.311.377.549.033.033a5.078,5.078,0,0,1-.434-.8l.247.591a5.558,5.558,0,0,1-.385-1.4c.033.222.058.434.091.656a6.05,6.05,0,0,1,0-1.337c-.033.222-.058.435-.091.656a5.573,5.573,0,0,1,.385-1.4l-.247.591a5.072,5.072,0,0,1,.434-.8c.341-.517-.311.341-.033.033.107-.114.2-.237.319-.352s.227-.214.352-.319c.311-.278-.549.377-.033.033a5.077,5.077,0,0,1,.8-.435l-.591.247a5.553,5.553,0,0,1,1.4-.385c-.222.033-.435.057-.656.09a6.073,6.073,0,0,1,1.337,0c-.222-.033-.435-.058-.656-.09a5.554,5.554,0,0,1,1.4.385l-.591-.247a5.087,5.087,0,0,1,.8.435c.517.341-.341-.311-.033-.033.114.107.237.2.352.319s.214.227.319.352c.278.311-.377-.55-.033-.033a5.052,5.052,0,0,1,.435.8l-.245-.591a5.564,5.564,0,0,1,.385,1.4c-.033-.222-.057-.435-.09-.656a6.065,6.065,0,0,1,0,1.337c.033-.222.058-.435.09-.656a5.558,5.558,0,0,1-.385,1.4l.245-.591a5.045,5.045,0,0,1-.435.8c-.341.517.311-.341.033-.033-.107.123-.214.238-.319.352a2.46,2.46,0,1,0,3.478,3.478,7.543,7.543,0,0,0,2.1-3.955,8.153,8.153,0,0,0-.656-4.906,7.672,7.672,0,0,0-7.44-4.241,8.058,8.058,0,0,0-4.528,1.9,7.478,7.478,0,0,0-2.362,3.83,7.692,7.692,0,0,0,12.887,7.359,2.457,2.457,0,0,0-3.478-3.47Z'
              transform='translate(-539.576 -652.207)'
            />
          </g>
        </g>
        <path
          d='M176.839,111.092V65.532c0-.877.025-1.764,0-2.642a2.728,2.728,0,0,0-.73-1.813,2.616,2.616,0,0,0-.214-.214c-.41-.426-.821-.845-1.239-1.272l-4.786-4.9q-3.039-3.113-6.079-6.235-2.609-2.682-5.229-5.357c-.739-.755-1.444-1.558-2.215-2.273a3.045,3.045,0,0,0-2.338-.722H117.769a6.95,6.95,0,0,0-3.429.829,6.514,6.514,0,0,0-3.281,5.628c-.008.607,0,1.214,0,1.819v62.548a7.09,7.09,0,0,0,.73,3.142,6.479,6.479,0,0,0,5.725,3.478h52.614a7.345,7.345,0,0,0,1.985-.227,6.562,6.562,0,0,0,4.725-6.227,2.461,2.461,0,1,0-4.922,0,4.382,4.382,0,0,1-.041.568c.033-.222.058-.434.09-.656a4.382,4.382,0,0,1-.263.959l.247-.591a3.956,3.956,0,0,1-.352.665c-.14.214-.329.288.131-.156-.09.082-.164.189-.255.278a1.959,1.959,0,0,1-.278.255c.016.008.591-.418.156-.131a4.306,4.306,0,0,1-.665.352l.591-.246a4.262,4.262,0,0,1-.959.263c.222-.033.435-.058.656-.09a14.362,14.362,0,0,1-1.879.041H123.837c-2.091,0-4.184.008-6.283,0a5.3,5.3,0,0,1-.623-.041c.222.033.435.058.656.09a4.383,4.383,0,0,1-.959-.263l.591.246a3.958,3.958,0,0,1-.665-.352c-.214-.14-.288-.328.156.131-.082-.09-.189-.164-.278-.255a1.961,1.961,0,0,1-.255-.278c-.008.016.418.591.131.156a4.3,4.3,0,0,1-.352-.665l.247.591a4.261,4.261,0,0,1-.263-.959c.033.222.058.434.09.656a17.622,17.622,0,0,1-.041-2.182V46.608a5.579,5.579,0,0,1,.041-.64c-.033.222-.058.434-.09.656a4.384,4.384,0,0,1,.263-.959l-.247.591a3.959,3.959,0,0,1,.352-.665c.14-.214.328-.288-.131.156.09-.082.164-.189.255-.278a1.962,1.962,0,0,1,.278-.255c-.016-.008-.591.418-.156.131a4.306,4.306,0,0,1,.665-.352l-.591.247a4.261,4.261,0,0,1,.959-.263c-.222.033-.434.058-.656.09a12.077,12.077,0,0,1,1.444-.041h31.617c1.517,0,3.052.041,4.569,0h.066c-.582-.238-1.157-.484-1.739-.722l1.961,2.01,4.709,4.832,5.7,5.849q2.473,2.547,4.955,5.085c.8.821,1.575,1.665,2.395,2.461l.033.033c-.238-.582-.484-1.157-.722-1.739v42.335c0,1.953-.033,3.9,0,5.857v.082a2.489,2.489,0,0,0,2.461,2.461A2.527,2.527,0,0,0,176.839,111.092Z'
          transform='translate(-111.055 -40.082)'
        />
        <g transform='translate(41.094 0.024)'>
          <path
            d='M635.97,61.121l-1.961-2.01L629.3,54.28l-5.7-5.849q-2.473-2.547-4.955-5.085c-.8-.821-1.575-1.665-2.395-2.461l-.033-.033A2.486,2.486,0,0,0,612,42.594V58.8a6.839,6.839,0,0,0,.771,3.142,6.534,6.534,0,0,0,5.873,3.379h7.851c2.527,0,5.061.025,7.588,0h.14a2.461,2.461,0,0,0,0-4.922h-14.06a17.862,17.862,0,0,1-2.3-.041c.222.033.435.058.656.09a4.384,4.384,0,0,1-.959-.263l.591.246a3.968,3.968,0,0,1-.665-.352c-.214-.14-.288-.328.156.131-.082-.09-.189-.164-.278-.255a1.949,1.949,0,0,1-.255-.278c-.008.016.418.591.131.156a4.287,4.287,0,0,1-.352-.665l.245.591a4.268,4.268,0,0,1-.263-.959c.033.222.058.434.09.656a15.93,15.93,0,0,1-.041-1.977V42.608c-1.4.582-2.8,1.157-4.206,1.739l1.961,2.01,4.709,4.832,5.7,5.849q2.473,2.547,4.955,5.085c.8.821,1.583,1.657,2.395,2.461l.033.033a2.484,2.484,0,0,0,3.478,0,2.511,2.511,0,0,0,.023-3.5Z'
            transform='translate(-612 -40.149)'
          />
        </g>
      </g>
    </g>
  </g>
)

export default taxesIconPath
