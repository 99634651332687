import React from 'react'
import { StyledLoadingAnimationWrapper } from './style'

const LoadingIndicator = ({ show }) => {
  return show ? (
    <StyledLoadingAnimationWrapper data-testid={'loading-indicator'}>
      <span className='k-loading-text'>Loading</span>
      <svg viewBox='0 0 270.09 277.43' className='loading-indicator'>
        <path
          mask='url(#subtraction)'
          transform='translate(4 7)'
          d='M 84.07254028320312 17 L 17 84.072509765625 L 17 178.9274597167969 L 84.072509765625 246 L 178.9274597167969 246 L 246 178.927490234375 L 246 84.07255554199219 L 178.9274597167969 17 L 84.07254028320312 17 M 77.03094482421875 0 L 185.9690551757812 0 L 263 77.03094482421875 L 263 185.9691009521484 L 185.9690551757812 263 L 77.03089904785156 263 L 0 185.9690551757812 L 0 77.03089904785156 L 77.03094482421875 0 Z'
          stroke='none'
          fill='#fff'
        />
        <g id='dot_group' data-name='Dot' className='loading-indicator__dot'>
          <circle
            id='Ellipse_234'
            data-name='Ellipse 234'
            cx='16'
            cy='16'
            r='16'
            transform='translate(119 -1)'
            fill='#0d8762'
          />
          <rect
            id='Rectangle_1'
            data-name='Rectangle 1'
            width='263'
            height='271'
            transform='translate(4 -1)'
            fill='none'
          />
        </g>
        <mask id='subtraction'>
          <path
            id='Subtraction_7'
            data-name='Subtraction 7'
            d='M311,319H-41V-42H311V319ZM77-10.266,92,43h87l15-53.267Z'
            fill='#fff'
            className='loading-indicator__mask'
          />
        </mask>
      </svg>
    </StyledLoadingAnimationWrapper>
  ) : null
}

export default LoadingIndicator
