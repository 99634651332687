import { useDispatch } from 'react-redux'

const useClearReduxStore = () => {
  const dispatch = useDispatch()

  const handleClearReduxStore = () =>
    dispatch({
      type: 'LOGOUT_CLIENT',
    })

  return {
    handleClearReduxStore,
  }
}

export default useClearReduxStore
