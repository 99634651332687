import styled, { css } from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export const StyledLocationRequest = styled.div`
  background-color: ${(props) => props.theme.background.alt};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 3.2rem;
  font-size: 2.32rem;
  font-weight: 500;
  padding: 1.6rem 2.4rem;
`

export const StyledLocationRequestSecondary = styled.div`
  text-align: center;
`

export const StyledLocationTitle = styled.div`
  font-size: 1.5625rem;
  font-weight: 700;
  color: #29283e;
  width: 100%;
  text-align: center;
  margin: 1rem 0;
`

interface StyledButtonI extends CSSProperties {
  mylocation?: boolean
}

export const StyledButton = styled.button<StyledButtonI>`
  background-color: ${theme.palette.primary.dark};
  text-shadow: 0 0 5px ${theme.palette.color.black};
  border-radius: 0.4rem;
  border: none;
  box-shadow: ${(props) => props.theme.surfaceElevation(props.elevation)};
  color: ${theme.palette.color.white};
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 1.6rem auto 0.6rem;
  min-height: 3.8rem;
  outline: none;
  padding: 1.2rem 1.6rem;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: ${(props) => props.theme.surfaceElevation(2)};
  }

  &:focus {
    box-shadow: 0 3px 5px -1px #2e2e2e, 0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }

  ${({ mylocation }) =>
    mylocation &&
    css`
      position: absolute;
      bottom: 7.5rem;
      right: 0.6rem;
      width: auto;
      background-color: ${theme.palette.color.white};
      margin: auto;
      min-height: auto;
      padding: 0.5rem;
      border-radius: 0.7rem;

      &:focus {
        box-shadow: none;
      }

      ${media.smaller`
        bottom: 9.5rem;
        right: 0.8125rem;
        padding: 0.875rem;
      `}
      ${media.tablet`
        bottom: 8.5rem;
        right: 0.7rem;
        padding: 0.875rem;
      `}
      ${media.desktop`
        bottom: 7.5rem;
        right: 0.6rem;
        padding: 0.5rem;
      `}
    `}
`

export const StyledOutlinedButton = styled(StyledButton)`
  background-color: ${theme.palette.color.white};
  border: 0.08rem solid #70707087;
  color: ${(props) => props.theme.text.alt};
  margin: ${(props) => (props.margin ? props.margin : '0.6rem auto 1.6rem')};
  text-shadow: none;
  &:focus {
    box-shadow: 0 3px 5px -1px #2e2e2e, 0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }
`

export const StyledParagraph = styled.p`
  align-self: center;
  margin: 0.4rem;
`

StyledLocationRequest.displayName = 'StyledLocationRequest'
StyledButton.displayName = 'StyledButton'
StyledOutlinedButton.displayName = 'StyledOutlinedButton'
StyledParagraph.displayName = 'StyledParagraph'
