import React from 'react'
import NextStepsBenefits from 'Pages/NextSteps/NextStepsBenefits'
import NextStepsApplyOnline from 'Pages/NextSteps/NextStepsApplyOnline'
import NextStepsAppointment from 'Pages/NextSteps/NextStepsAppointment'
import NextStepsPrepareVisit from 'Pages/NextSteps/NextStepsPrepareVisit'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'

import { StyledWrapper } from './style'
import theme from 'Shared/Theme/ssTheme'

const NextSteps = (props) => {
  const { section } = props

  return (
    <HeaderWithContainer section={section}>
      <StyledWrapper
        minHeight={'calc(100vh - ' + theme.layout.headerHeight + ')'}
        padding='0'
        background='#f1f1f1'>
        {section === 'benefits' && <NextStepsBenefits />}
        {section === 'preferred-partners' && <NextStepsAppointment />}
        {section === 'documentation' && <NextStepsPrepareVisit />}
        {section === 'apply-online' && <NextStepsApplyOnline />}
      </StyledWrapper>
    </HeaderWithContainer>
  )
}

export default NextSteps
