import React from 'react'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import {
  StyledWrapper,
  StyledNavColumn,
  StyledTitle,
  StyledNavList,
  StyledNavItem,
  StyledNavLink,
  StyledNavRouterLink,
} from '../Styles/FooterNav'
import Icon from 'Components/Shared/Icon/Icon'
import { ISOLogoIcon } from 'Components/Shared/PageFooter/Components/IconsPath'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'

const FooterNav = () => {
  const registrationUrl = useDynamicRegistrationUrl()
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const FooterLinks = [
    {
      title: 'Services',
      links: [
        {
          label: 'Find My Eligibility',
          url: registrationUrl,
        },
        {
          label: 'Browse Resources',
          url: '/locations/list',
        },
      ],
    },
    {
      title: 'About',
      links: [
        {
          label: 'Our People',
          url: 'https://singlestop.org/leadership/',
          external: true,
        },
        {
          label: 'Board of Directors',
          url: 'https://singlestop.org/about/board-of-directors/',
          external: true,
        },
        {
          label: 'Careers',
          url: 'https://singlestop.org/about/careers/',
          external: true,
        },
        {
          label: 'Contact Us',
          url: 'https://singlestop.org/contact-us/get-help/',
          external: true,
        },
      ],
    },
  ]

  return (
    <StyledWrapper>
      {FooterLinks.map(({ title, links }, index) => {
        return (
          <StyledNavColumn key={index}>
            <StyledTitle>{title}</StyledTitle>
            <StyledNavList>
              {links.map(({ url, label, external }, index) => {
                return (
                  <StyledNavItem key={index}>
                    {title === 'Services' ? (
                      <StyledNavRouterLink to={url}>
                        {label}
                      </StyledNavRouterLink>
                    ) : (
                      <StyledNavLink
                        href={url}
                        target={external ? '_blank' : '_self'}
                      >
                        {label}
                      </StyledNavLink>
                    )}
                  </StyledNavItem>
                )
              })}
            </StyledNavList>
          </StyledNavColumn>
        )
      })}
      {!isMobile && (
        <StyledNavColumn>
          <Icon
            viewBox={'0 0 174.151 88.436'}
            scale={'auto'}
            width={'9rem'}
            screenReaderLabel={
              'BSI. ISO/IEC 27001. Information Security Management Certified.'
            }
            aria-label='BSI. ISO/IEC 27001. Information Security Management Certified.'
          >
            {ISOLogoIcon}
          </Icon>
        </StyledNavColumn>
      )}
    </StyledWrapper>
  )
}

export default FooterNav
