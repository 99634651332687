export const formattedReferralNumber = (referralNumber) => {
  const pairChunks = referralNumber.match(/.{1,2}/g)
  return pairChunks.join(' ')
}

export const calculateTotalBenefitAmount = (data) => {
  if (!data) return 0
  const cases = data?.calculateScreeningResults?.screening?.serviceCases ?? []
  return cases.map((c) => c.eligibleAmount).reduce((acc, curr) => acc + curr, 0)
}

export const calculateRecurringBenefitAmount = (serviceCase) => {
  const eligibleAmount = serviceCase?.eligibleAmount
  const frequency = serviceCase?.service?.frequency

  if (!eligibleAmount) return eligibleAmount
  switch (frequency) {
    case 'WEEKLY':
      return eligibleAmount / 52

    case 'MONTHLY':
      return eligibleAmount / 12

    default:
      return eligibleAmount
  }
}
