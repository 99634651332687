import React from 'react'
import { StyledParagraph } from 'Components/Screener/style'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'

const CheckEmail = () => (
  <HeaderWithContainerAndCard>
    <StyledParagraph>Check your email</StyledParagraph>
  </HeaderWithContainerAndCard>
)

export default CheckEmail
