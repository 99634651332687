import React from 'react'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import { useLocation } from 'react-router-dom'

const About = () => {
  const { location: pathname } = useLocation()

  return (
    <div>
      <Header />
      <Menu pathname={pathname} />
      <h3> About us </h3>
    </div>
  )
}

export default About
