import React from 'react'
import ScreenerBackButton from './ScreenerBackButton'
import {
  ReverseCol,
  StyledParentButtonsActionsInMobile,
  StyledCallsToActionParent,
} from 'Components/Screener/style'
import CallsToAction from './CallsToAction'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY, DESKTOP_MEDIA_QUERY } from 'Shared/constants'
import { Row } from 'styled-bootstrap-grid'
import { FIRST_STEP } from 'Shared/constants'

const ScreenerActions = (props) => {
  const {
    smallerWidth,
    tabletMargin,
    currentStep: stepNumber,
    screenerCompleted,
  } = props
  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  return (
    <StyledParentButtonsActionsInMobile>
      <Row style={isMobile ? { margin: '0' } : {}}>
        <ReverseCol
          smallerWidth={smallerWidth}
          smallerMargin={'auto'}
          tabletWidth={
            stepNumber === FIRST_STEP && !screenerCompleted ? '100%' : '70%'
          }
          tabletMargin={tabletMargin}>
          {!isDesktop && (
            <>
              <ScreenerBackButton margin={isMobile ? '0' : '0 1rem 0 0'} />
              <CallsToAction
                {...props}
                hideExtraButton={props.hideExtraButton}
              />
            </>
          )}

          {isDesktop && (
            <>
              <ScreenerBackButton
                margin={'0 0 0 0.9375rem '}
                width={'auto'}
                padding='0 3rem'
              />
              <StyledCallsToActionParent>
                <CallsToAction
                  {...props}
                  width='auto'
                  hideExtraButton={props.hideExtraButton}
                />
              </StyledCallsToActionParent>
            </>
          )}
        </ReverseCol>
      </Row>
    </StyledParentButtonsActionsInMobile>
  )
}
export default ScreenerActions
