import React, { useCallback } from 'react'
import { Field } from 'redux-form'
import InputRenderField from './InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { useFieldName } from './hooks'

const NameField = ({ label, fieldName, validate }) => (
  <Field
    name={fieldName}
    type='text'
    component={InputRenderField}
    label={label}
    validate={validate}
  />
)

const FirstAndLastNameRenderField = ({ fields, meta: { error } }) => {
  const fieldName = useFieldName(fields)

  const validateFirstName = useCallback(() => error && error.first_name, [
    error,
  ])

  return (
    <>
      <StyledHouseholdMembersListItem margin='0'>
        <MuiThemeProvider theme={ssTheme}>
          <StyledHouseholdMembersList>
            <Row>
              <Col lg='6'>
                <StyledMuiTextFieldWrapper>
                  <NameField
                    label={'First Name'}
                    fieldName={`${fieldName}.first_name`}
                    validate={validateFirstName}
                  />
                </StyledMuiTextFieldWrapper>
              </Col>
              <Col lg='6'>
                <StyledMuiTextFieldWrapper>
                  <NameField
                    label={'Last Name'}
                    fieldName={`${fieldName}.last_name`}
                  />
                </StyledMuiTextFieldWrapper>
              </Col>
            </Row>
          </StyledHouseholdMembersList>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
    </>
  )
}

export default React.memo(FirstAndLastNameRenderField)
