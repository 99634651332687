import React from 'react'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'
import { FieldArray } from 'redux-form'
import ScreenerActions from 'Components/Screener/screenerActions'

const FieldArrayContainer = (props) => {
  const {
    questionText,
    questionDirectionText,
    label,
    name,
    validate,
    fieldArrayComponent,
  } = props

  return (
    <>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <FieldArray
        name={name}
        label={label}
        component={fieldArrayComponent}
        validate={validate}
      />

      <ScreenerActions {...props} />
    </>
  )
}

export default FieldArrayContainer
