import React from 'react'

import { StyledTab, StyledTabs } from 'Components/Screener/style'

type NextStepsBenefitsTabsProps = {
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
  value: number
}

const NextStepsBenefitsTabs = ({
  onChange,
  value,
}: NextStepsBenefitsTabsProps) => {
  return (
    <StyledTabs className='styledAppBar' onChange={onChange} value={value}>
      <StyledTab
        data-testid='tab_0'
        id='tab1'
        label='Likely Eligible'
        tabIndex={0}
      />
      <StyledTab
        data-testid='tab_1'
        id='tab2'
        label='Unlikely Eligible'
        tabIndex={0}
      />
      <StyledTab
        data-testid='tab_2'
        id='tab3'
        label='All Available Benefits'
        tabIndex={0}
      />
    </StyledTabs>
  )
}

export default NextStepsBenefitsTabs
