import {
  UPDATE_COORDINATES,
  UPDATE_ZIPCODE,
  GEOLOCATION_ERROR,
  CLEAR_GEOLOCATION,
} from 'Actions/geolocation'
import { geolocation } from './initialState'
import { REMEMBER_REHYDRATED, REMEMBER_PERSISTED } from 'redux-remember'

export default (state = geolocation, action) => {
  switch (action.type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.geolocation,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_COORDINATES:
      const { coordinates } = action
      return { ...state, coordinates }

    case UPDATE_ZIPCODE:
      const { zipCode } = action
      return { ...state, zipCode, geolocationError: false }

    case GEOLOCATION_ERROR:
      return { ...state, zipCode: null, geolocationError: true }

    case CLEAR_GEOLOCATION:
      return geolocation

    default:
      return state
  }
}
