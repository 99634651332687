import styled from 'styled-components/macro'

export const StyledChip = styled.div`
  display: flex;
  color: ${(props) =>
    props.actionType === 'HEADER' ? '#ababab' : props.theme.text.default};
  padding: 0;
  align-items: left;
  font-size: 0.8125rem;
  font-weight: 500;
  align-items: center;
  margin: 1rem 0;
`

export const StyledConditionallyHiddenWrapper = styled.div`
  margin: 2rem auto 0;
  width: 70%;
  ${(props) => (props.hidden === true ? `display: none` : ``)}
  > div {
    width: 100%;
    label {
      background: #fff;
    }
    svg {
      top: calc(50% - 0.75rem);
    }
    select {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 480px) {
    margin: 2.25rem 1rem 1.25rem;
    width: 90%;
  }
`

StyledChip.displayName = 'StyledChip'
StyledConditionallyHiddenWrapper.distance = 'StyledConditionallyHiddenWrapper'
