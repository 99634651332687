import styled from 'styled-components/macro'
import { withStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'

export const StyledCard = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgb(255, 255, 255)'};
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`

export const StyledLocationServices = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  font-size: 1rem;
`

export const StyledLocationService = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`

export const ContainerButton = styled.div`
  border-bottom: 1px solid #00000017;
  padding: 1rem;
`

export const StyledBullet = styled.span`
  font-size: 1.5rem;
`

export const StyledButtonReferral = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 3px 5px -1px #2e2e2e',
    },
    '&:disabled': {
      color: 'white',
    },
  },
})(Button)

StyledCard.displayName = 'StyledCard'
