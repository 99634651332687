import React, { useLayoutEffect, useRef } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

import { toggleSingleStopFilter } from 'Actions/filters'
import { StyledFilterRow } from 'Components/LocalResources/Filtering/FilterMenu/style'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import IOSSwitch from './IOSSwitch'

type SingleStopFilterProps = {
  callback: Function
}

const SingleStopFilter = (props: SingleStopFilterProps) => {
  const { callback } = props

  const dispatch = useAppDispatch()
  const singleStopFilterSelected = useAppSelector(
    (state) => state.filters.attributes.singleStop.selected
  )

  const isInitialRender = useRef(true)

  useLayoutEffect(() => {
    if (isInitialRender) {
      isInitialRender.current = false
      return
    }
    callback()
  }, [callback, isInitialRender, singleStopFilterSelected])

  return (
    <MuiThemeProvider theme={ssTheme}>
      <StyledFilterRow>
        <FormGroup row>
          <FormControlLabel
            control={
              <IOSSwitch
                className={'cypress-singlestop-filter'}
                checked={singleStopFilterSelected}
                onChange={() => dispatch(toggleSingleStopFilter())}
                color='default'
              />
            }
            label='Show Preferred Partners Only'
          />
        </FormGroup>
      </StyledFilterRow>
    </MuiThemeProvider>
  )
}

export default SingleStopFilter
