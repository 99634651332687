import React, { useState, useRef, useEffect } from 'react'
import Icon from '../Icon/Icon'
import searchIconPath from 'Shared/Icons/searchIconPath'
import closeIconPath from 'Shared/Icons/closeIconPath'
import {
  StyledInput,
  StyledInputCaseManagement,
  SearchButton as StyledSearchButton,
  StyledLabel,
} from './style'
import { selectText } from 'Shared/keyboardHelpers'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from 'Shared/constants'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { isCaseManagementPage } from 'Shared/helpers'

export type SearchInputProps = {
  className?: string
  handleOnBlur?: Function
  handleOnFocus?: Function
  handleSearch: Function
  iconFill: string
  id?: string
  initialValue?: string
  isCaseManagement?: boolean
  placeholder: string
  screenReaderLabel?: string
}

const SearchInput = ({
  className,
  handleOnBlur,
  handleOnFocus,
  handleSearch,
  id,
  initialValue = '',
  placeholder,
  isCaseManagement,
  iconFill,
}: SearchInputProps) => {
  const [focus, setFocus] = useState(false)
  const [searchTerm, setSearchTerm] = useState(initialValue)

  const StyledInputField = isCaseManagement
    ? StyledInputCaseManagement
    : StyledInput

  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm, handleSearch])

  const inputElement = useRef<HTMLInputElement | null>()

  const handleClearText = () => {
    if (inputElement?.current?.value !== '') {
      inputElement?.current?.focus()
      setSearchTerm('')
    }
  }

  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const isTablet = useMediaQuery({ query: TABLET_MEDIA_QUERY })

  const renderButton = () => {
    if ((focus && searchTerm) || searchTerm) {
      return (
        <StyledSearchButton
          isCaseManagement={isCaseManagement}
          clear
          data-testid={'clearSearchText'}
          aria-label={'clear search box'}
          id={id}
          type={'button'}
          role='button'
          onClick={handleClearText}
        >
          <Icon
            scale={
              isCaseManagement
                ? '1.2rem'
                : isMobile || isTablet
                ? '2.4rem'
                : '1.5rem'
            }
            fill={isCaseManagement ? '#333333a3' : iconFill}
            cursorOnHover={'pointer'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={'Close Search Icon'}
          >
            {closeIconPath}
          </Icon>
        </StyledSearchButton>
      )
    } else {
      return (
        <StyledSearchButton
          isCaseManagement={isCaseManagement}
          aria-label={'search'}
          type={'button'}
          role='button'
        >
          <Icon
            scale={
              isCaseManagement
                ? '1.2rem'
                : isMobile || isTablet
                ? '2.4rem'
                : '1.5rem'
            }
            fill={isCaseManagement ? '#333333a3' : iconFill}
            cursorOnHover={'default'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={'Search Icon'}
          >
            {searchIconPath}
          </Icon>
        </StyledSearchButton>
      )
    }
  }

  return (
    <>
      <SrOnly>
        <label htmlFor='SearchInput'>Search</label>
      </SrOnly>
      {!isCaseManagementPage() &&
        ((focus && searchTerm) || searchTerm ? (
          <StyledLabel htmlFor='SearchInput'>{placeholder}</StyledLabel>
        ) : (
          ''
        ))}
      <StyledInputField
        id='SearchInput'
        value={searchTerm}
        className={className}
        tabIndex={0}
        onDrag={(evt: React.DragEvent<HTMLInputElement>) =>
          selectText(evt.target)
        }
        // @ts-ignore
        ref={inputElement}
        placeholder={placeholder}
        onFocus={() => {
          setFocus(true)
          handleOnFocus && handleOnFocus()
        }}
        onBlur={() => {
          setFocus(false)
          handleOnBlur && handleOnBlur()
        }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(evt.target.value)
        }
        aria-label='The following text field filters the results as you type.'
      />
      {renderButton()}
    </>
  )
}

export default SearchInput
