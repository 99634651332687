import { Link } from 'react-router-dom'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components/macro'

import theme from 'Shared/Theme/ssTheme'

const headerHeight = `${theme.layout.headerHeight}`

export const StyledScrollerBox = styled.div`
  height: calc(100vh - (${headerHeight} + 11rem));
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #babac0;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const StyledATag = styled.a`
  display: flex;
  color: ${(props) => props.theme.text.default};
`

interface StyledCardContentProps extends CSSProperties {
  radius?: string
  shadow?: string
}

export const StyledCardContent = styled.div<StyledCardContentProps>`
  fill: ${(props) => props.theme.icon.white};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '8rem')};
  padding: 1rem 1.6rem;
  border-bottom: ${(props) =>
    props.withBorder ? '1px solid  #ececec' : 'none'};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  background-color: ${(props) => props.background};
  ${({ radius }) => (radius ? `border-radius: ${radius}` : '')};
  ${({ shadow }) => (shadow ? `box-shadow: ${shadow}` : '')}
  position: relative;
`

export const StyledExpandedCardContent = styled(StyledCardContent)`
  padding: ${(props) => (props.padding ? props.padding : '1.6rem')};
  font-size: 0.875rem;
  font-weight: 500;
`

export const StyledH1 = styled.h1`
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
  line-height: 1.5;
  text-align: left;
`

export const StyledH3 = styled(StyledH1)`
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1rem;
`

export const StyledBorderBottom = styled.div`
  border-bottom: 1px solid #00000017;
`

interface StyledCategoryProps extends CSSProperties {
  paddBottom?: string
}

export const StyledCategory = styled.div<StyledCategoryProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  color: ${(props) => props.color || props.theme.text.default};
  fill: ${(props) => props.theme.icon.default};
  max-height: ${(props) => props.maxHeight};
  overflow: hidden;
  position: relative;
  padding-bottom: ${(props) => (props.paddBottom ? props.paddBottom : '0')};
`
export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.3rem;
`

export const StyledShowMore = styled.div`
  position: absolute;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  bottom: -12px;
  font-size: 0.8rem;
  font-weight: 500;
  border: 1px solid #00000017;
  border-radius: 30px;
  padding: 0.6rem 1rem;
  margin: 0;
  cursor: pointer;
  ${media.smaller`
    width: 40%;
    bottom: -5px;
    font-size: .6rem;
    padding: .4rem .8rem;
  `}
`

export const StyledSubsection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.text.default};
`

export const StyledSubsectionTitle = styled.div`
  font-size: 1.92rem;
`

export const StyledSubsectionContent = styled.div`
  margin: 0 1.6rem;
`
export const StyledCardTwoColumnLayoutSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.8125rem;
  font-weight: 500;
`

export const StyledParentDivLastChild = styled.div`
  display: flex;
  width: 100%;
`

export const StyledDivLastChild = styled.div`
  width: 100%;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

StyledATag.displayName = 'StyledATag'
StyledCardContent.displayName = 'StyledCardContent'
StyledH1.displayName = 'StyledH1'
StyledCategory.displayName = 'StyledCategory'
StyledShowMore.displayName = 'StyledShowMore'
StyledSubsection.displayName = 'StyledSubsection'
StyledSubsectionTitle.displayName = 'StyledSubsectionTitle'
StyledSubsectionContent.displayName = 'StyledSubsectionContent'
StyledCardTwoColumnLayoutSection.displayName =
  'StyledCardTwoColumnLayoutSection'
