import React, { useRef } from 'react'
import {
  StyledFooterWrapper,
  StyledFooterTop,
  // StyledPolyShape, // gets in the way of the About sections links
} from './Footer.style'
import { Container } from 'styled-bootstrap-grid'
import FooterMission from './Components/FooterMission'
import FooterNav from './Components/FooterNav'
import CopyWrites from './Components/copyrights'
import { TimelineMax } from 'gsap'
import { useInView } from 'react-hook-inview'

const LandingFooter = () => {
  let ElemRef = useRef([])
  let ResourcesTL = null
  let entered = false
  const TweenResources = () => {
    ResourcesTL = new TimelineMax({
      defaults: {
        duration: 0.6,
      },
    })
    ResourcesTL.staggerTo(ElemRef.current, 1, { y: 0, opacity: 1 }, 0.5)
  }
  const [resourcesRef] = useInView({
    threshold: 0.5,
    onEnter: () => {
      if (!entered) {
        TweenResources()
        entered = true
      }
    },
  })
  return (
    <StyledFooterWrapper ref={resourcesRef}>
      {/* <StyledPolyShape /> */}
      <Container>
        <StyledFooterTop
          ref={(element) => {
            ElemRef.current[0] = element
          }}>
          <FooterMission />
          <FooterNav />
        </StyledFooterTop>
      </Container>
      <CopyWrites
        forwardRef={(element) => {
          ElemRef.current[1] = element
        }}
      />
    </StyledFooterWrapper>
  )
}

export default LandingFooter
