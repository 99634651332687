import React, { useEffect, useRef } from 'react'
import * as Sentry from '@sentry/react'
import {
  useInitialRender,
  usePhoneIosHack,
  useNewRelic,
} from 'Components/App/Hooks'
import { isCaseManagementPage, isReportingPage } from 'Shared/helpers'
import { isWindows } from 'react-device-detect'
import PageRouter from 'Components/PageRouter/PageRouter'
import { StyledApp, GlobalStyle } from './style'
import { BaseCSS } from 'styled-bootstrap-grid'
// https://greensock.com/forums/topic/22339-gsap-3-registerplugin-problems-with-react/
import { gsap, CSSPlugin } from 'gsap'
import { getCredentials, isExpiredCredentials } from 'ApolloClient/helpers'
import { MUI_SELECT_OPEN_EVENT } from 'Shared/constants'

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

const App = () => {
  const appRef = useRef(null)
  usePhoneIosHack()
  useInitialRender()
  useNewRelic()

  useEffect(() => {
    if (
      isExpiredCredentials(getCredentials({ browserStorage: localStorage }))
    ) {
      localStorage.removeItem('credentials')
    }
  }, [window.location.pathname])

  useEffect(() => {
    appRef.current.addEventListener(MUI_SELECT_OPEN_EVENT, handleMUISelectOpen)
  }, [])

  const handleMUISelectOpen = () => {
    setTimeout(function () {
      const listElement = document.querySelectorAll(
        "ul[class^='MuiList-root-']"
      )
      listElement.forEach((list) => {
        list.setAttribute('aria-role', 'listbox')
      })
    }, 0)
  }

  return (
    <StyledApp ref={appRef}>
      <BaseCSS />
      <GlobalStyle
        isReporting={isReportingPage()}
        isCaseManagementPage={isCaseManagementPage()}
        isWindows={isWindows}
      />
      <PageRouter />
    </StyledApp>
  )
}

export default Sentry.withProfiler(App)
