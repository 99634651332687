import styled from 'styled-components/macro'
import { StyledContainer } from 'Components/LocalResources/LocationsList/style'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

const headerHeight = `${theme.layout.headerHeight}`

interface StyledTabletAndDesktopLeftColumnI extends CSSProperties {
  zIndex?: number
  top?: string
  minHeight?: string
}

export const StyledTabletAndDesktopLeftColumn = styled.aside<StyledTabletAndDesktopLeftColumnI>`
  width: ${theme.layout.asideWidth};
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1)};
  position: absolute;
  top: ${(props) => (props.top ? props.top : '0')};
  left: 0;
  background-color: #fff;
  overflow: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  box-shadow: ${theme.layout.asideShadow};
  height: ${(props) => props.minHeight || `calc(100vh - ${headerHeight})`};
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTabletContainer = styled(StyledContainer)`
  position: relative;
  padding-top: 0;

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledLocationListContainer = styled.div<CSSProperties>`
  height: ${(props) =>
    props.height || `calc(100vh - ${headerHeight} - 6.7rem)`};
  overflow-y: auto;
  ${media.tablet`
    padding-bottom: 5rem;
  `}
  ${media.desktop`
    padding-bottom: 0;
    padding-top: 0;
  `}
  scrollbar-width: thin;
  scrollbar-color: #c8c8c8 #f5f5f5;
  &::-webkit-scrollbar {
    display: block;
    background-color: #fff;
    width: 0.4rem;
    height: 0.3rem;
    border: 1px solid #ececec;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 1.6rem;
    height: 7rem;
  }
`
StyledLocationListContainer.displayName = 'StyledLocationListContainer'
StyledTabletAndDesktopLeftColumn.displayName =
  'StyledTabletAndDesktopLeftColumn'
StyledTabletContainer.displayName = 'StyledTabletContainer'
