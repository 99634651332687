import styled from 'styled-components/macro'
import { StyledParagraph as paragraph } from 'Components/CaseManagement/Modals/style.tsx'

export const StyledParagraph = styled(paragraph)`
  width: 25.625rem;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
`
StyledParagraph.displayName = 'StyledParagraph'
