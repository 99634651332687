import React from 'react'
import { withTheme } from 'styled-components/macro'

import Icon from '../Icon/Icon'
import { StyledActionButton } from './style'

const ActionButton = (props) => {
  const { icon, label, theme, callback, key, marginRight, disabled } = props
  const { alt: fill } = theme.text

  return (
    <StyledActionButton
      disabled={disabled}
      onClick={() => callback && callback()}
      key={key}
      marginRight={marginRight}
    >
      <Icon
        viewBox={'0 0 24 24'}
        radius='80rem'
        scale='2.75rem'
        padding='0.25rem'
        fill={fill}
        margin='0 0 7px 0'
        border='1px solid #00AE7A'
        cursorOnHover={disabled ? 'not-allowed' : 'pointer'} // shouldn't be pointer if disabled...
      >
        {icon}
      </Icon>
      {label}
    </StyledActionButton>
  )
}

export default withTheme(ActionButton)
