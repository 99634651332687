import styled from 'styled-components/macro'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

export const styles = (theme) => ({
  root: {
    margin: 0,
    padding: `${MOBILE_MEDIA_QUERY ? '1rem' : theme.spacing(2)}`,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      backgroundColor: '#5E5E5E',
    },
    '&:focus': {
      backgroundColor: '#5E5E5E !important',
    },
  },
})

export const ArrowSpan = styled.span`
  position: absolute;
  width: 30px;
  height: 30px;
  padding: 0;
  border: 0;
  right: ${(props) => (props.right ? props.right : '0')};
  &:after {
    position: absolute;
    top: ${(props) => (props.afterTop ? props.afterTop : '40%')};
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #858585;
    border-width: 6px;
    transform: ${(props) =>
      props.toggleRotate ? 'rotate(90deg)' : 'rotate(-90deg)'};
    left: ${(props) => (props.afterLeft ? props.afterLeft : '3px')};
  }
`
