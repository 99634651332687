import React from 'react'

const educationIconPath = (
  <g id='a'>
    <path
      d='M29.971,122.782c21.846,0,45.947-26.993,45.947-61.391S51.817,0,29.971,0,0,39.364,0,67.576,8.123,122.782,29.971,122.782Z'
      transform='matrix(0.719, 0.695, -0.695, 0.719, 86.333, -3)'
      fill='#d7e2ff'
      opacity='0.8'
    />
    <path
      d='M85.629,97V72.555l1.014-.3c3.9-1.171,4.719-3.152,4.719-4.609s-.819-3.438-4.719-4.608L53.267,53.025a27.472,27.472,0,0,0-7.586-.956,27.478,27.478,0,0,0-7.587.956L4.719,63.034C.819,64.2,0,66.185,0,67.642s.819,3.438,4.719,4.6L17.2,75.991V89.136c0,2.506,1.629,6.03,9.387,8.621a62.723,62.723,0,0,0,19.092,2.653,62.724,62.724,0,0,0,19.092-2.653c7.759-2.587,9.387-6.115,9.387-8.621V75.991l6.115-1.834V97a8.424,8.424,0,0,0-5.734,7.971v11.468a8.41,8.41,0,1,0,16.821,0V104.968A8.424,8.424,0,0,0,85.629,97Zm-46-38.848a22.4,22.4,0,0,1,6.048-.736,22.408,22.408,0,0,1,6.048.731l31.647,9.495L70.7,71.445l-.022.006L51.73,77.137a22.407,22.407,0,0,1-6.048.731,22.41,22.41,0,0,1-6.048-.731L20.683,71.452l-.022-.006-12.673-3.8ZM68.809,89.142c0,.345-.972,1.957-5.727,3.543a57.322,57.322,0,0,1-17.4,2.384,57.32,57.32,0,0,1-17.4-2.382c-4.755-1.586-5.731-3.2-5.731-3.543V77.6L38.1,82.265a27.474,27.474,0,0,0,7.586.956,27.475,27.475,0,0,0,7.586-.956L68.807,77.6Zm17.2,27.291a3.057,3.057,0,1,1-6.114,0V104.968a3.057,3.057,0,0,1,6.114,0Z'
      transform='translate(1 -34.564)'
    />
  </g>
)

export default educationIconPath
