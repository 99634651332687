import { change } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'

export const UPDATE_PRE_SCREENER = 'UPDATE_PRESCREENER'
export const CLEAR_PRE_SCREENER = 'CLEAR_PRE_SCREENER'

export const updatePreScreener = (preScreener) => {
  return {
    type: UPDATE_PRE_SCREENER,
    preScreener,
  }
}

export const updateStep = (step) => (dispatch) => {
  dispatch(updatePreScreener({ step }))
}

export const toggleIsOnCategories = () => (dispatch, getState) => {
  const { preScreener } = getState()
  const { isOnCategories } = preScreener
  dispatch(updatePreScreener({ isOnCategories: !isOnCategories }))
}

export const toggleAllCategories =
  (allCategoriesSelected, selectedCategories, categories) => (dispatch) => {
    if (!allCategoriesSelected) {
      const allSelected = categories.reduce((acc, category) => {
        acc[`key-${category.id}`] = true
        return acc
      }, {})

      dispatch(change(PRE_SCREENER_FORM_NAME, 'category', allSelected))
    } else {
      const allDeselected = Object.keys(selectedCategories).reduce(
        (acc, key) => {
          acc[key] = false
          return acc
        },
        { ...selectedCategories }
      )

      dispatch(change(PRE_SCREENER_FORM_NAME, 'category', allDeselected))
    }
  }

export const clearPreScreener = () => ({
  type: CLEAR_PRE_SCREENER,
})
