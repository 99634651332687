/**
 * Used for Pie Charts
 *
 * The 12 positions that MUI Toolip supports, starting with 'right'
 * and going clock-wise in order.
 */
export const tooltipPlacementsPie = [
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start',
  'top-start',
  'top',
  'top-end',
  'right-start',
]

/**
 * Tooltip Placements used for Victory Stack
 */
export const tooltipPlacementsStack = ['right', 'bottom', 'left', 'top']

export const READ_ONLY_ROLES = [
  'GLOBAL_READ_ONLY',
  'READ_ONLY',
  'REPORT_MANAGER',
]
