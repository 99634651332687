import styled from 'styled-components/macro'

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  left: -0.4rem;
  color: #000;
  font-size: 0.9rem;
  font-weight: 500;
`

export const StyledRadioButton = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

export const StyledFieldset = styled.fieldset`
  padding: 0;
`

StyledLabel.displayName = 'StyledLabel'
StyledRadioButton.displayName = 'StyledRadioButton'
