import React, { useCallback } from 'react'
import { Field } from 'redux-form'
import SelectMenuMuiRenderField from './SelectMenuMuiRenderField'
import InputRenderField from './InputRenderField'
import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'

import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'

import { StyledParagraph } from 'Components/Screener/style'
import { Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { useFieldName } from './hooks'

const createStyledHouseholdMemberListItem = (
  fieldName,
  label,
  validate,
  component,
  children
) => (
  <StyledMuiTextFieldWrapper>
    <StyledHouseholdMembersListItem>
      <Field
        name={fieldName}
        component={component}
        label={label}
        type='text'
        validate={validate}>
        {children}
      </Field>
    </StyledHouseholdMembersListItem>
  </StyledMuiTextFieldWrapper>
)

const BirthDateAndAgeRenderField = ({ fields, meta: { error }, text }) => {
  const fieldName = useFieldName(fields)

  const validateBirthMonth = useCallback(() => error && error.birthMonth, [
    error,
  ])
  const validateBirthDay = useCallback(() => error && error.birthDay, [error])
  const validateBirthYear = useCallback(() => error && error.birthYear, [error])
  const validateAge = useCallback(() => error && error.age, [error])

  return (
    <StyledHouseholdMembersList>
      <StyledHouseholdMembersListItem margin='0 0 2.5rem'>
        <MuiThemeProvider theme={ssTheme}>
          <Row>
            <Col md='4'>
              <StyledMuiTextFieldWrapper>
                {createStyledHouseholdMemberListItem(
                  `${fieldName}.birth_month`,
                  'Month',
                  validateBirthMonth,
                  SelectMenuMuiRenderField,
                  CalendarMonths()
                )}
              </StyledMuiTextFieldWrapper>
            </Col>
            <Col md='4'>
              <StyledMuiTextFieldWrapper>
                {createStyledHouseholdMemberListItem(
                  `${fieldName}.birth_day`,
                  'Day',
                  validateBirthDay,
                  SelectMenuMuiRenderField,
                  CalendarDays()
                )}
              </StyledMuiTextFieldWrapper>
            </Col>
            <Col md='4'>
              <StyledMuiTextFieldWrapper>
                {createStyledHouseholdMemberListItem(
                  `${fieldName}.birth_year`,
                  'Year',
                  validateBirthYear,
                  SelectMenuMuiRenderField,
                  CalendarYears()
                )}
              </StyledMuiTextFieldWrapper>
            </Col>
            <Col lg='12'>
              <StyledParagraph marginBottom='.625rem'>{text}</StyledParagraph>
            </Col>
            <Col lg='12'>
              <StyledMuiTextFieldWrapper mbottom='0' fullWid>
                <Field
                  name={`${fieldName}.age`}
                  type='text'
                  component={InputRenderField}
                  label='Age'
                  props={{
                    numberFormat: 'integer',
                  }}
                  normalize={formatters.ageToNumber}
                  validate={validateAge}
                />
              </StyledMuiTextFieldWrapper>
            </Col>
          </Row>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
    </StyledHouseholdMembersList>
  )
}

export default React.memo(BirthDateAndAgeRenderField)
