import { FIRST_STEP } from 'Shared/constants'

export const screener = {
  initialValues: null,
  categoryIndex: 0,
  categoryOffset: 0,
  currentStep: FIRST_STEP,
  filteredHouseholdMembers: null,
  progressStatus: null,
  householdMembersIndex: 0,
  hasCustomQuestions: false,
  steps: {
    101: {
      stepNumber: 101,
      progressPercentage: 1,
      category: {
        id: 1,
        name: 'Basic Demographics',
      },
      mainText: 'Okay, provide us little more information about you.',
      descriptionText: ['What gender do you identify with?'],
      question: {
        name: 'client.gender',
        component: 'SelectMenu',
        label: 'Gender',
        options: [
          {
            label: 'Woman',
            value: 'female',
          },
          {
            label: 'Man',
            value: 'male',
          },
          {
            label: 'Transgender woman',
            value: 'transgenderWoman',
          },
          {
            label: 'Transgender man',
            value: 'transgenderMan',
          },
          {
            label: 'Non-Binary/Genderqueer/Gender Fluid',
            value: 'nonBinary',
          },
          {
            label: 'not listed',
            value: 'notListed',
          },
          {
            label: 'Prefer not to Answer',
            value: 'preferNotToAnswer',
          },
        ],
      },
      navigation: {
        nextButton: {
          updateCurrentStep: {
            always: 102,
          },
        },
        backButton: {
          updateCurrentStep: { always: null, ifElse: null, callback: null },
        },
      },
    },
  },
  rehydrated: false,
  persisted: false,
}
