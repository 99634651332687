import React from 'react'
import { connect } from 'react-redux'
import { updateStep } from 'Actions/preScreener'
import { StyledScreenerBackButton } from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import backIconPath from 'Shared/Icons/backIconPath'
import { singleStopBlack } from 'Shared/Theme/index'

const PreScreenerBackButton = (props) => {
  const { step, updateStep } = props

  const prevPage = () => {
    updateStep(step - 1)
  }

  return (
    <StyledScreenerBackButton
      prescreener={'true'}
      width={props.width}
      height={props.height}
      position={props.position}
      bottom={props.bottom}
      left={props.left}
      padding={props.padding}
      hasExtraButton={props.hasExtraButton}
      outlined={props.outlined}
      margin={props.margin}
      smallermargin={props.smallerMargin}
      type={'button'}
      aria-label={'Go Back To Previous Question'}
      disabled={step === 1}
      onClick={prevPage}>
      <Icon
        position={'absolute'}
        left={'1.375rem'}
        fill={singleStopBlack}
        margin={'0'}
        scale={'1rem'}
        style={props.style}
        viewBox={'0 0 24 24'}
        cursorOnHover={'pointer'}>
        {backIconPath}
      </Icon>
      Back
    </StyledScreenerBackButton>
  )
}

const mapStateToProps = (state) => {
  const { step } = state.preScreener
  return {
    step,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateStep: (step) => dispatch(updateStep(step)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreScreenerBackButton)
