import React from 'react'
import FSQ from 'Components/LocalResources/Assets/PbFSQ.png'
import { StyledParentSponsorLog, StyledSponsorLog } from './style'
import type { StyledParentSponsorLogProps } from './style'

const DisplayLogo = (props: StyledParentSponsorLogProps) => (
  <StyledParentSponsorLog
    isSideOpen={props.isSideOpen}
    isMobile={props.isMobile}
  >
    <StyledSponsorLog src={FSQ} alt='Powered by FourSquare' />
  </StyledParentSponsorLog>
)

export default DisplayLogo
