import React from 'react'
import {
  FooterBottom,
  Wrapper,
  Paragraph,
  Strong,
  NavList,
  NavItem,
  NavLink,
  ExternalLink,
} from '../Styles/copyrights'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { Container } from 'styled-bootstrap-grid'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import Icon from 'Components/Shared/Icon/Icon'
import { ISOLogoIcon } from 'Components/Shared/PageFooter/Components/IconsPath'

const CopyWrites = (props) => {
  const { forwardRef } = props
  const theYear = new Date().getFullYear()
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const CopyWrites = () => (
    <Paragraph>
      © {theYear} <Strong> Single Stop.</Strong> All rights reserved
    </Paragraph>
  )

  return (
    <FooterBottom ref={forwardRef}>
      <Container>
        {isMobile && (
          <Icon
            viewBox={'0 0 174.151 88.436'}
            scale={'auto'}
            width={'9rem'}
            margin={'1rem auto'}
            screenReaderLabel={'Certified ISO'}>
            {ISOLogoIcon}
          </Icon>
        )}
        <Wrapper>
          <SrOnly>Fotter bottom Navigation</SrOnly>
          {!isMobile && <CopyWrites />}
          <NavList>
            <NavItem>
              <ExternalLink
                href='https://singlestop.org/privacy-policy/'
                target='_blank'>
                Privacy Policy
              </ExternalLink>
            </NavItem>
            <NavItem>
              <ExternalLink
                href='https://singlestop.org/terms-and-conditions/'
                target='_blank'>
                Terms and Conditions
              </ExternalLink>
            </NavItem>
            <NavItem>
              <NavLink
                href='https://singlestop.org/single-stop-usa-statement-on-programmatic-integrity/'
                target='_blank'>
                Program Integrity Policy
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                href='https://singlestop.org/client-privacy-statement/'
                target='_blank'>
                Client Privacy Statement
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                href='https://singlestop.org/covid-19-resources/'
                target='_blank'>
                COVID-19 Resources
              </NavLink>
            </NavItem>
          </NavList>
          {isMobile && <CopyWrites />}
        </Wrapper>
      </Container>
    </FooterBottom>
  )
}

export default CopyWrites
