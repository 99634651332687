import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

type UnstyledSwitchProps = {
  checked: boolean
  classes: {
    root: string
    switchBase: string
    thumb: string
    track: string
    checked: string
    focusVisible: string
  }
  className: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  color: 'primary' | 'secondary' | 'default'
}

const UnstyledSwitch = ({ classes, ...props }: UnstyledSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    margin: theme.spacing(1.5),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(23px)',
      border: 'none',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.dark,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    transform: 'translate(2px, 1px)',
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#ececec',
    opacity: 1,
    transition: theme.transitions.create([
      'background-color',
      'border',
      'transform',
    ]),
  },
  checked: {},
  focusVisible: {},
}))(UnstyledSwitch)

export default IOSSwitch
