import {
  StyledFormControl,
  StyledSelect,
} from 'Components/Shared/ReduxForm/SelectMenu/style'
import FormError from 'Components/Shared/FormError/FormError'

const SelectMenuMuiRenderField = (props) => {
  const {
    input: inputProps,
    label,
    children,
    meta: { touched, error },
  } = props
  return (
    <StyledFormControl variant='outlined' fullWidth>
      <StyledSelect
        {...inputProps}
        variant='outlined'
        label={label}
        error={touched && error}
      >
        {children}
      </StyledSelect>
      <FormError touched={touched} error={error} id={label} />
    </StyledFormControl>
  )
}

export default SelectMenuMuiRenderField
