import { useQuery } from '@apollo/client'
import { CUSTOM_FIELDS_QUERY } from './gql'
import { mapCustomFields, reduceResponses } from './helpers'

/**
 *
 * @returns {{hasCustomFields: boolean, customFields: Array, hasResponses: boolean, responses, error: ApolloError, loading: boolean}}
 */
const useCustomScreenerQuestions = () => {
  const clientId = sessionStorage.getItem('clientId')
  const skip =
    (!sessionStorage.getItem('credentials') &&
      !localStorage.getItem('credentials')) ||
    !clientId

  const { data, error, loading } = useQuery(CUSTOM_FIELDS_QUERY, {
    variables: { id: clientId },
    skip,
  })

  error && console.error(error)

  // Get the raw data of the custom fields
  const rawCustomFields = data?.client?.screening?.location?.customFields ?? []

  // Get the raw data of the responses
  const rawResponses = data?.client?.customFieldResponses ?? []

  return {
    customFields: mapCustomFields(rawCustomFields),
    error,
    hasCustomFields: !!rawCustomFields.length,
    hasResponses: !!rawResponses.length,
    loading,
    responses: reduceResponses(rawResponses),
  }
}

export default useCustomScreenerQuestions
