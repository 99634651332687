import { TableRow } from '@material-ui/core'
import React from 'react'
import {
  StyledTableHead,
  StyledTableCell,
} from 'Components/Review/ReviewSection/style'

const ReviewSectionTableHead = ({ labels }) => (
  <StyledTableHead>
    <TableRow>
      {labels?.map((label, i) => (
        <StyledTableCell key={i}>{label}</StyledTableCell>
      ))}
    </TableRow>
  </StyledTableHead>
)

export default ReviewSectionTableHead
