import React from 'react'
import { connect } from 'react-redux'
import { FieldArray } from 'redux-form'
import InputFieldArrayRenderField from './InputFieldArrayRenderField'
import { screenerSelector } from 'Selectors'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'

import ScreenerActions from 'Components/Screener/screenerActions'
import { useFields } from './hooks'

const InputFieldArray = (props) => {
  const {
    label,
    name,
    numberFormat,
    options,
    questionDirectionText,
    questionText,
    validate,
    disableValidation,
  } = props

  useFields(props)

  return (
    <>
      {questionText && (
        <StyledHeadingMain data-testid={'questionText'}>
          {questionText}
        </StyledHeadingMain>
      )}

      {questionDirectionText && (
        <StyledParagraph data-testid={'questionDirectionText'}>
          {questionDirectionText}
        </StyledParagraph>
      )}

      <FieldArray
        name={name}
        label={label}
        options={options}
        component={InputFieldArrayRenderField}
        props={{
          numberFormat: numberFormat,
          validate: disableValidation ? undefined : validate,
        }}
      />
      <ScreenerActions {...props} />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  backButtonClicked: state.screener.returnStep,
  fieldValues: screenerSelector(state, ownProps.name),
})

export default connect(mapStateToProps)(InputFieldArray)
