import React, { createRef, useEffect } from 'react'
import MediaQuery from 'react-responsive'

import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { StyledLocationListContainer } from 'Pages/Locations/style'
import { useAppDispatch } from 'Store/hooks'
import LocationCard from 'Components/LocalResources/LocationCard/LocationCard'
import type { AlgoliaLocation } from 'Pages/Locations/AlgoliaLocation'

import { StyledContainer, StyledLocationCardContainer } from './style'
import 'Components/Uielements/styles/globalStyle.css'

export type LocationsListProps = {
  locations?: AlgoliaLocation[]
  locationID?: number
  setLocationId?: (locationId: number) => void
  updateLocationId?: (locationId: number) => void
  modalLayout?: boolean
}

const LocationsList = ({
  locationID,
  locations: locationsObj,
  updateLocationId,
  setLocationId,
  modalLayout,
}: LocationsListProps) => {
  const dispatch = useAppDispatch()
  const locations = locationsObj
    ? Object.values(locationsObj).sort((a, b) => a.index - b.index)
    : []

  const locationRefs = locations.reduce((acc: Record<string, any>, value) => {
    acc[value.id] = createRef()
    return acc
  }, {})

  useEffect(() => {
    const activeLocationRef = locationID && locationRefs[locationID]
    const current = activeLocationRef && activeLocationRef.current.children[0]

    if (current) {
      current.focus()
      current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [locationID, locationRefs])

  const renderedLocations = locations.map((location) => {
    const { id } = location
    const ref = locationRefs[id]
    const active = id === locationID
    return (
      <StyledLocationCardContainer
        key={id}
        ref={ref}
        className={'cypress-list-location-card'}
        onClick={() =>
          updateLocationId ? dispatch(updateLocationId(id)) : null
        }
        onKeyDown={() =>
          updateLocationId ? dispatch(updateLocationId(id)) : null
        }
      >
        <LocationCard
          withBorder
          active={active}
          location={location}
          margin={'1.2rem'}
          setLocationId={setLocationId}
          modalLayout={modalLayout}
        />
      </StyledLocationCardContainer>
    )
  })

  const hiddenContainer = (
    <SrOnly aria-live='assertive'>
      {locations.length > 0
        ? `${locations.length} results available`
        : `no results available`}
    </SrOnly>
  )

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledContainer className={'cypress-locations-list'}>
          {hiddenContainer}
          {renderedLocations}
        </StyledContainer>
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <MediaQuery query={TABLET_MEDIA_QUERY}>
          {(matches) => {
            const device = matches ? 'tablet' : 'desktop'
            return (
              <StyledLocationListContainer
                padding={'0'}
                device={device}
                className={'cypress-locations-list'}
              >
                {hiddenContainer}
                {renderedLocations}
              </StyledLocationListContainer>
            )
          }}
        </MediaQuery>
      </MediaQuery>
    </>
  )
}

export default LocationsList
