import React from 'react'
import { useSelector } from 'react-redux'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import useLogOut from 'Components/Auth/Hooks/useLogOut'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import {
  StyledHeadingMain,
  StyledSubHeading,
  StyledParagraph,
} from 'Components/Screener/style'
import { StyledHouseholdMembersList } from 'Components/Shared/ReduxForm/style'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import theme from 'Shared/Theme/ssTheme'
import { useLocation, useNavigate } from 'react-router-dom'

const Account = () => {
  const client = useSelector((state) => state.client)
  const { id: clientId } = client
  const { location: pathname } = useLocation()
  const { handleLogOut } = useLogOut()
  const navigate = useNavigate()

  const logout = () => {
    return handleLogOut().then(() => {
      navigate('/')
    })
  }

  return (
    <>
      <Header />
      <Menu pathname={pathname} />
      <Container>
        <Row>
          <StyledHeadingMain width='100%'> My Account </StyledHeadingMain>
          <StyledSubHeading width='100%'>Client Info</StyledSubHeading>
        </Row>
        <Row>
          <Col md='4'>
            <StyledParagraph>{`firstName: ${client.firstName}`}</StyledParagraph>
            <StyledParagraph>{`lastName: ${client.lastName}`}</StyledParagraph>
          </Col>
          <Col md='4'>
            <StyledParagraph>{`email: ${client.email}`}</StyledParagraph>
            <StyledParagraph>{`id: ${clientId}`}</StyledParagraph>
          </Col>
          <Col md='6'>
            <StyledParagraph>{`nickName: ${client.nickName}`}</StyledParagraph>
          </Col>
        </Row>
        <StyledHouseholdMembersList textalign='center'>
          <Row>
            <Col lg='12'>
              <Button
                minHeight='70px'
                width='100%'
                margin='2rem auto 0'
                background={theme.palette.secondary.default}
                data-testid='cypress-logout-button'
                onClick={logout}
              >
                Log Out
              </Button>
            </Col>
          </Row>
        </StyledHouseholdMembersList>
      </Container>
    </>
  )
}

export default Account
