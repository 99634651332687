import { withStyles } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import theme from 'Shared/Theme/ssTheme'

export const StyledRadioGroup = withStyles({
  root: {
    flexDirection: 'row',
  },
})(RadioGroup)

export const StyledFormControlLabel = withStyles({
  root: {
    padding: '0 2rem 0 0',
    '& > span': {
      fontSize: '1rem',
      fontWeight: '600',
    },
    '&&:hover': {
      borderColor: 'rgb(0, 0, 0)',
    },
    '& span.Mui-checked': {
      color: `${theme.palette.primary.dark}`,
    },
  },
})(FormControlLabel)
