import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import SearchInput from 'Components/Shared/SearchInput/SearchInput'
import MediaQuery from 'react-responsive'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'

import { StyledSearchBox, StyledSurface, StyledLabelSearchBox } from './style'

import { parseQuery } from 'Actions/filters'
import { updateShowLocationCard, updateLocationId } from 'Reducers/uiSlice'
import { useMediaQuery } from 'react-responsive'
import theme from 'Shared/Theme/ssTheme'
import { isLocalResourcesList } from 'Shared/helpers'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { AppDispatch, RootState } from 'Store'

export type SearchBoxProps = {
  handleSearch: (inputText: string) => void
  hideLocationCard: () => void
  initialValue?: string
  updateLocationId: () => void
}

const SearchBox = (props: SearchBoxProps) => {
  const { handleSearch, hideLocationCard, initialValue, updateLocationId } =
    props
  const isDesktop = useMediaQuery({ query: DESKTOP_MEDIA_QUERY })
  const debouncedHandleSearchFunction = useDebouncedCallback((inputText) => {
    handleSearch(inputText)
  }, 500)

  const [elevation, setElevation] = useState(0)

  const handleOnFocus = () => {
    updateLocationId()
    hideLocationCard()
    setElevation(3)
  }

  const handleOnBlur = () => {
    setElevation(0)
  }

  const searchBox = () => (
    <StyledSearchBox>
      <StyledLabelSearchBox>
        <SrOnly>Search for local resources</SrOnly>
        <SearchInput
          className={'cypress-search-box'}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
          handleSearch={debouncedHandleSearchFunction}
          iconFill={isDesktop ? '#fff' : theme.palette.primary.dark}
          id={'cypress-clear-text-input'}
          initialValue={initialValue}
          placeholder={'Zip code, keyword, or service'}
          screenReaderLabel={'Search for local resources'}
        />
      </StyledLabelSearchBox>
    </StyledSearchBox>
  )

  const tabletAndMobileWrappedSearchBox = () => (
    <StyledSurface
      background={'none'}
      minHeight={'0'}
      boxShadow={'none'}
      elevation={elevation}
      margin={'0'}
      padding={'0'}
      radius={'0.4rem'}
      width={'100%'}
      flex='none'
    >
      {searchBox()}
    </StyledSurface>
  )

  const DesktopWrappedSearchBox = () => (
    <StyledSurface
      boxShadow={'none'}
      elevation={elevation}
      margin={'0'}
      padding={'0'}
      radius={'0'}
      minHeight={'10px'}
      width={isLocalResourcesList() ? '80%' : '100%'}
    >
      {searchBox()}
    </StyledSurface>
  )

  const renderTabletAndMobile = () => {
    return tabletAndMobileWrappedSearchBox()
  }

  return (
    <>
      <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
        {renderTabletAndMobile()}
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        {DesktopWrappedSearchBox()}
      </MediaQuery>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { ui } = state
  return {
    ui,
  }
}

const dispatchToProps = (dispatch: AppDispatch) => ({
  handleSearch: (text: string) => {
    //@ts-ignore
    dispatch(parseQuery(text))
  },
  updateLocationId: () => dispatch(updateLocationId(null)),
  hideLocationCard: () => dispatch(updateShowLocationCard(false)),
  //@ts-ignore
  parseQuery: (query: string) => dispatch(parseQuery(query)),
})

const connected = connect(mapStateToProps, dispatchToProps)(SearchBox)

export default connected
