import { reducer } from 'redux-form'
import { SCREENER_FORM_NAME, PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { REMEMBER_REHYDRATED, REMEMBER_PERSISTED } from 'redux-remember'

/**
 * Form reducer function.
 *
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
const formReducer = (name, state, action) => {
  switch (action.type) {
    case REMEMBER_REHYDRATED:
      const formState = action.payload.form?.[name]

      if (formState) {
        delete formState.registeredFields
        delete formState.syncErrors
      }

      return {
        ...state,
        ...formState,
        rehydrated: true,
      }
    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }
    default:
      return state
  }
}

/**
 */
export default reducer.plugin({
  [PRE_SCREENER_FORM_NAME]: (state, action) =>
    formReducer(PRE_SCREENER_FORM_NAME, state, action),
  [SCREENER_FORM_NAME]: (state, action) =>
    formReducer(SCREENER_FORM_NAME, state, action),
})
