import React, { useEffect } from 'react'
import useLogOut from 'Components/Auth/Hooks/useLogOut'
import { getHomeUrl, isUserLoggedIn } from 'Pages/Logout/helpers'
import { getUserType, isJestTest } from 'Shared/helpers'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const { handleLogOut } = useLogOut()
  const userType = getUserType()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate('/')
      return
    }

    handleLogOut().then(({ data, graphQLErrors }) => {
      if (data) {
        const location =
          data.userLogout?.authenticatable?.userable?.screening?.location
        const locationSlug = location?.slug
        const orgSlug = location?.organization?.slug
        navigate(
          userType === 'Case Manager'
            ? '/case-management/login'
            : getHomeUrl({ orgSlug, locationSlug })
        )
      } else {
        if (!isJestTest()) console.error(graphQLErrors)
        navigate(userType === 'Case Manager' ? '/case-management/login' : '/')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <h1>Logging out</h1>
}

export default Logout
