import React from 'react'
import { useQuery } from '@apollo/client'
import { ME } from 'Components/Auth/Hooks/gql'
import { StyledAuthHeader } from './style'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import useMenuItemsData from 'Components/Shared/Hooks/useMenuItemsData'
import { Link } from 'react-router-dom'
import { noop } from 'Shared/constants'
import { connect } from 'react-redux'

const ClientAuthHeader = ({ client }) => {
  const { accountData, logoutData } = useMenuItemsData()

  const { data: userHasInfo } = useQuery(ME, { fetchPolicy: 'network-only' })

  const items = client?.authenticated || userHasInfo ? logoutData : accountData

  return (
    <StyledAuthHeader>
      {items.map(({ onClick, path, primary, text }, index) => (
        <Button
          as={Link}
          key={index}
          margin='0 0 0 0.625rem'
          onClick={() => (onClick ? onClick() : noop())}
          primary={primary}
          to={path}
        >
          {text}
        </Button>
      ))}
    </StyledAuthHeader>
  )
}

const mapStateToProps = (state) => {
  const { client } = state
  return { client }
}

export default connect(mapStateToProps)(ClientAuthHeader)
