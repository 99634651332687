import 'intersection-observer'

import React from 'react'
import { createRoot } from 'react-dom/client'
import App from 'Components/App/App'
import { IS_DEVELOPMENT } from 'Shared/constants'
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import styledTheme from 'Shared/Theme'
import { mainMuiTheme as muiTheme } from 'Shared/Theme/muiTheme'
import * as serviceWorker from 'serviceWorker'
import 'normalize.css'
import * as Sentry from '@sentry/browser'
import store from 'Store'
import caseManagementClient from 'ApolloClient/caseManagementClient.js'
import withTracker from './withTracker'
import { Integrations } from '@sentry/tracing'
import { StylesProvider } from '@material-ui/core'
import packageJson from '../package.json'
import MaintenanceRouter from './Components/MaintenanceRouter/Components/MaintenanceRouter'
import FeatureToggleProvider from 'Components/FeatureToggleProvider/FeatureToggleProvider'

/**
 * traceSampleRate
 *
 * The type is a float and expected values are in the range [0.0, 1.0].
 * The default value is 0.0.
 *
 * A tracesSampleRate of 0.0 means no transactions should be sent to Sentry.
 * Conversely, 1.0 means all transactions should be sent. Anything in between
 * means the fraction of uniformly random samples that should be sent.
 * For example, 0.25 means send ~25% of all transactions.
 *
 * The default value being 0.0 is such that tracing is an opt-in feature.
 */
if (!IS_DEVELOPMENT) {
  const subDomain = window.location.hostname.split('.')[0]
  Sentry.init({
    dsn: 'https://9e477dc683c9459cb40330a1fcdd9913@o268344.ingest.sentry.io/5220585',
    release: 'single-stop-3@' + packageJson.version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment: subDomain !== 'app' ? subDomain : 'production',
  })

  const user = JSON.parse(localStorage.getItem('user'))
  if (user) Sentry.setUser(user)
}

const TrackerWrappedApp = withTracker(App)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <FeatureToggleProvider>
    <ApolloProvider client={caseManagementClient}>
      <ReduxProvider store={store}>
        <StylesProvider injectFirst>
          <StyledThemeProvider theme={styledTheme.mode['light']}>
            <MuiThemeProvider theme={muiTheme}>
              <BrowserRouter>
                <MaintenanceRouter />
                <TrackerWrappedApp />
              </BrowserRouter>
            </MuiThemeProvider>
          </StyledThemeProvider>
        </StylesProvider>
      </ReduxProvider>
    </ApolloProvider>
  </FeatureToggleProvider>
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
