import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadScreener } from 'Actions/screener'
import MediaQuery from 'react-responsive'
import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from '../../Shared/constants'
import MobileScreener from './MobileScreener'
import TabletAndDesktopScreener from './TabletAndDesktopScreener'
import { useNavigate } from 'react-router-dom'
import { getOnboardingSection } from 'Actions/client'
import { useRoutes } from 'Components/Client/ClientHooks/useRouteToOnboarding'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks'

const Screener = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { householdMembersIndex } = useSelector((state) => state.screener)

  const { screenerCompleted, editing } = useSelector((state) => state.client)

  const { steps: stepsObj } = useSelector((state) => state.screener)

  const steps = Object.values(stepsObj)

  const { handleRouteToPreScreener } = useRoutes()

  const registrationUrl = useDynamicRegistrationUrl()

  useEffect(() => {
    if (!editing) {
      dispatch(getOnboardingSection()).then((section) => {
        if (section === 'PRESCREENER') {
          handleRouteToPreScreener(registrationUrl)
        } else {
          dispatch(loadScreener())
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (screenerCompleted && !editing) {
      navigate('/completed-screener')
    }
  }, [navigate, screenerCompleted, editing])

  if (!steps.length) return null

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <MobileScreener
          steps={steps}
          householdMembersIndex={householdMembersIndex}
        />
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <MediaQuery query={TABLET_MEDIA_QUERY}>
          {(matches) => {
            const device = matches ? 'tablet' : 'desktop'
            return (
              <TabletAndDesktopScreener
                device={device}
                steps={steps}
                householdMembersIndex={householdMembersIndex}
              />
            )
          }}
        </MediaQuery>
      </MediaQuery>
    </>
  )
}

export default Screener
