const locations = {
  searchResults: {
    null: {
      name: null,
      id: 0,
      description: null,
      phoneNumber: null,
      singleStop: true,
      emailAddress: null,
      url: null,
      distance: null,
      numbers: {
        primary: {
          number: null,
          extension: null,
        },
      },
      address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
      },
      coordinates: {
        lat: null,
        lng: null,
      },
      languageAccommodation: null,
      quickFilters: {
        null: {
          id: null,
          name: null,
          selected: null,
        },
      },
      businessHours: null,
      algoliaQuickFilters: null,
      _geoloc: null,
      algoliaLanguageAccommodation: null,
      objectID: null,
      _highlightResult: null,
    },
  },

  locationDetail: {
    null: {
      name: null,
      id: 0,
      description: null,
      phoneNumber: null,
      singleStop: true,
      emailAddress: null,
      url: null,
      distance: null,
      numbers: {
        primary: {
          number: null,
          extension: null,
        },
      },
      address: {
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
      },
      coordinates: {
        lat: null,
        lng: null,
      },
      languageAccommodation: null,
      quickFilters: {
        null: {
          id: null,
          name: null,
          selected: null,
        },
      },
      businessHours: null,
      algoliaQuickFilters: null,
      _geoloc: null,
      algoliaLanguageAccommodation: null,
      objectID: null,
      _highlightResult: null,
    },
  },
  rehydrated: false,
  persisted: false,
}

export { locations }
