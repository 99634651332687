import styled, { css } from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'

export const MobileHeader = styled.header`
  padding: 1rem;
  ${u.dFlex};
  ${u.justifyContentCenter};
  ${u.pRelative};
  @media only screen and (min-width: 1025px) {
    ${u.dNone}
  } ;
`
export const BurgerIcon = styled.img`
  ${u.dFlex}
  ${u.pAbsolute}
  top: 30px;
  left: 15px;
  width: 24px;
  @media only screen and (min-width: 1025px) {
    ${u.dNone}
  }
  ${({ close }) =>
    close &&
    css`
      width: 34px;
    `};
`

export const HeaderWrapper = styled.header`
  padding: 1em 0;
  z-index: 100;
  ${u.pFixed};
  top: 0;
  ${u.hi100};
  background-color: ${theme.palette.secondary.default};
  ${u.wd100};
  ${u.dNone};
  ${u.justifyContentCenter};
  ${u.alignItemsCenter};
  @media only screen and (min-width: 1025px) {
    ${u.pRelative};
    ${u.dFlex};
    height: auto;
    background-color: unset;
  }
  ${({ ResponsiveOpen }) =>
    ResponsiveOpen &&
    css`
      display: flex;
    `};
`

export const HeaderContainer = styled.div`
  ${u.dFlex};
  ${u.justifyContentSB};
  ${u.alignItemsCenter};
  ${u.wd100};
  ${u.flexCol};
  @media only screen and (min-width: 481px) {
    margin: 2rem 0 0;
  }
  @media only screen and (min-width: 1025px) {
    ${u.flexRow};
    margin: 0;
    padding: ${(props) => props.padding};
  }
  @media only screen and (min-width: 800px) and (max-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`

export const HeaderFlex = styled.div`
  ${(props) => (props.display ? `display: ${props.display}` : u.dFlex)};
  ${(props) => (props.noJust ? '' : u.justifyContentSB)}
  ${u.alignItemsCenter}
  ${u.flexRow}
  align-items:${(props) => props.alignitems || 'flex-start'};
  ${(props) => (props.width ? props.width : u.wd100)}
  align-items: flex-start;
  ${(props) => (props.width ? `width: ${props.width}` : u.wd100)};
  position: ${(props) => props.position};
  padding: ${(props) => props.padding};
  @media only screen and (min-width: 481px) {
    ${u.flexRow}
    ${u.alignItemsCenter}
    ${u.flexRow}
    width: auto;
    margin: 0;
  }
  @media only screen and (min-width: 1025px) {
    width: ${(props) => (props.width ? props.width : 'auto')};
    margin: ${(props) => (props.margin ? props.margin : '0 !important')};
  }
  @media only screen and (max-width: 1024px) {
    width: ${(props) => props.customWidth};
    padding: ${(props) => props.custompadding};
  }
  ${({ Responsive }) =>
    Responsive &&
    css`
      @media only screen and (max-width: 480px) {
        margin: ${(props) =>
          props.mobilemargin ? props.mobilemargin : '0 0 1rem 0'};
      } ;
    `};
`
