import styled from 'styled-components/macro'
import { withStyles, TextField, FormControl } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'

export const StyledTextField = withStyles({
  root: {
    width: '100%',
    '& input, textarea': {
      width: '100%',
      color: '#29283E',
      fontSize: '.875rem',
      fontFamily: 'Noto Sans HK,sans-serif',
      fontWeight: '400',
      padding: '1.15625rem 0.875rem',
      borderRadius: '0.25rem',
    },
    '& textarea': {
      padding: '0.5rem 0.625rem',
      borderRadius: '0.3125rem',
    },
  },
})(TextField)

export const StyledFormControl = withStyles({
  root: {
    border: '0.0625rem solid rgba(0, 0, 0, 0.23)',
    borderRadius: '0.375rem',
    width: '100%',
    padding: '3px 10px',
    background: ({ disabled }) => disabled && theme.palette.secondary.lightGrey,
    '&&:hover': {
      borderColor: ({ disabled }) =>
        disabled ? 'rgba(0, 0, 0, 0.23)' : 'rgb(0, 0, 0)',
    },
  },
})(FormControl)

export const StyledCharacterCountWarning = styled.p`
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
`

StyledCharacterCountWarning.displayName = 'StyledCharacterCountWarning'
