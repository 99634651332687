import React from 'react'

import { StyledButtonsContainer } from 'Components/CaseManagement/Modals/style'
import Card from 'Components/Shared/Card/Card'
import TodaysClosingTime from 'Components/Shared/LocationDetails/BusinessHours/TodaysClosingTime'
import Category from 'Components/Shared/LocationDetails/Category'
import Distance from 'Components/Shared/LocationDetails/Distance/Distance'
import PhoneNumber from 'Components/Shared/LocationDetails/PhoneNumber'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import type { AlgoliaLocation } from 'Pages/Locations/AlgoliaLocation'
import {
  updateShowLocationCard,
  updateShowLocationDetail,
} from 'Reducers/uiSlice'
import { useAppDispatch } from 'Store/hooks'

import {
  CategoryContainer,
  StyledCardContent,
  StyledCardTwoColumnLayoutSection,
  StyledDivLastChild,
  StyledH1,
  StyledLink,
  StyledParentDivLastChild,
} from './style'

type LocationCardProps = {
  active?: boolean
  bottom?: number
  childBackground?: string
  childMargin?: string
  childMinHeight?: string
  childRadius?: string
  childShadow?: string
  childWidth?: string
  className?: string
  location?: AlgoliaLocation
  margin?: string | number
  parentBackground?: string
  parentBottom?: string
  parentLeft?: string
  parentRight?: string
  position?: string
  width?: string
  withBorder?: boolean
  withShadow?: boolean
  modalLayout?: boolean
  setLocationId?: Function
}

export const LocationCard = (props: LocationCardProps) => {
  const { active, location, width, withShadow, modalLayout, setLocationId } =
    props
  const dispatch = useAppDispatch()

  if (!location) {
    return null
  }

  const elevation = active ? 3 : 1

  const {
    businessHours,
    distance,
    name,
    numbers,
    quickFilters: quickFiltersObject,
  } = location

  if (!quickFiltersObject) {
    return null
  }

  const quickFilters = Object.values(quickFiltersObject)

  const renderDesktopPrimaryContact = () => {
    return (
      <>
        {numbers && (
          <PhoneNumber
            alignitems={'center'}
            numbers={numbers}
            isPrimaryNumberOnly={true}
          />
        )}
      </>
    )
  }

  const renderCategories = () => {
    const renderedCategories = quickFilters.map((category) => {
      const className = category.name
        ? `category-${category.name.toLowerCase()}`
        : ''
      return (
        <Category
          width={'auto'}
          key={category.id}
          isIconOnly
          className={className}
          {...category}
        />
      )
    })

    return <CategoryContainer>{renderedCategories}</CategoryContainer>
  }

  return (
    <Card
      {...props}
      customSurface={true}
      elevation={elevation}
      margin={0}
      boxShadow={withShadow ? null : 'none'}
      focusBackground
      background={props.parentBackground}
      bottom={props.parentBottom}
      left={props.parentLeft}
      width={width}
      right={props.parentRight}
      role='group'
      aria-label='locations'
    >
      <StyledCardContent
        withBorder={props.withBorder}
        minHeight={props.childMinHeight}
        width={props.childWidth}
        margin={props.childMargin}
        background={props.childBackground}
        radius={props.childRadius}
        shadow={props.childShadow}
      >
        {!modalLayout && (
          <StyledLink
            aria-label={name}
            className={'cypress-show-more-link'}
            to={{
              pathname: `/locations/${location.id}`,
            }}
            onClick={() => dispatch(updateShowLocationCard(false))}
          />
        )}
        <StyledH1>{name}</StyledH1>
        <StyledCardTwoColumnLayoutSection>
          <Distance alignitems={'center'} distance={distance} />
          <StyledParentDivLastChild>
            <StyledDivLastChild>
              {renderDesktopPrimaryContact()}
            </StyledDivLastChild>
          </StyledParentDivLastChild>
          {businessHours && (
            <TodaysClosingTime
              alignitems={'center'}
              businessHours={businessHours}
            />
          )}
        </StyledCardTwoColumnLayoutSection>
        {renderCategories()}
        {modalLayout && (
          <StyledButtonsContainer margintop='1rem'>
            <Button
              mdFontSize='0.8125rem'
              mdMinWidth='10.25rem'
              mdMinHeight='3rem'
              fontWeight='500'
              marginSmall='1rem'
              primary
              md
              onClick={() => {
                dispatch(updateShowLocationDetail(true))
                if (setLocationId) setLocationId(location.id)
              }}
              data-testid='rtl-confirm-button'
            >
              Select
            </Button>
          </StyledButtonsContainer>
        )}
      </StyledCardContent>
    </Card>
  )
}

export default LocationCard
