import React, { useState, useEffect } from 'react'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import { StyledBenefitListItemToggleButton, StyledBenefitsSpan } from './style'
import Icon from 'Components/Shared/Icon/Icon'
import LikeIconPath from 'Shared/Icons/LikeIconPath'
import UnLikeIconPath from 'Shared/Icons/UnLikeIconPath'
import theme from 'Shared/Theme/ssTheme'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import Typography from '@material-ui/core/Typography'
import 'Components/Uielements/styles/globalStyle.css'
import { StyledButtonFilled } from 'Shared/Buttons/style'
import 'Components/Uielements/styles/globalStyle.css'
import BenefitAmount from './BenefitAmount'
import EligibilityStatus from './EligibilityStatus'
import RecurringBenefitAmount from 'Pages/NextSteps/RecurringBenefitAmount'
import { toCapitalized } from 'Shared/helpers'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 6),
    textAlign: 'center',
    margin: '0 0 3rem 0',
  },
}))(MuiDialogContent)

const NextStepsBenefitListItem = (props) => {
  const {
    amountLabel,
    description,
    eligible,
    eligibleAmount,
    findOutMore,
    frequency,
    name,
    toolkitUrl,
    recurringAmount,
  } = props

  const { people, category, categoryIcon } = { ...props }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  // Accessibility audit fix: Added Aria Attr in Right Div of Modal and Remove Role Presentation
  useEffect(() => {
    setTimeout(() => {
      const ModalContainer = document.querySelector('div[role="dialog"]')
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
        ModalContainer.setAttribute('aria-label', name)
      }
      const ModalRolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      const ModalRoleNone = document.querySelector(
        'div[role="none presentation"]'
      )
      if (ModalRolePresentation) {
        ModalRolePresentation.removeAttribute('role')
      }
      if (ModalRoleNone) {
        ModalRoleNone.removeAttribute('role')
      }
    })
  })

  return (
    <>
      <StyledMuiTextFieldWrapper
        data-testid='benefit-program'
        cursor='pointer'
        onClick={handleClickOpen}
        mbottom='0'
        padding='1rem 0 1rem 1.5rem'
        display='flex'
      >
        <Icon
          position={'absolute'}
          top={'1rem'}
          fill={'unset'}
          scale={'3rem'}
          margin={isMobile ? '0 1rem 0 0.5rem' : '0 1rem 0 0'}
          viewBox={'0 0 32.043 32.043'}
          backgroundColor={
            eligible ? theme.palette.primary.lighten10 : '#DEDEDE'
          }
          padding={'.2rem .5rem'}
          radius={'5rem'}
          screenReaderLabel={
            eligible ? 'Likely Eligible Program' : 'Unlikely Eligible Program'
          }
        >
          {eligible ? LikeIconPath : UnLikeIconPath}
        </Icon>
        <StyledMuiTextFieldWrapper
          padding='0 0 0 3.5rem'
          mobilePadd='0rem 0rem 0rem 4rem'
          mbottom='0'
        >
          <div style={{ display: 'inline-flex', alignItems: 'flex-end' }}>
            {name}
            <div style={{ display: 'flex' }} role={'button'}>
              <Icon
                fill={'#000'}
                scale={'1.25rem'}
                margin={'0 0 0 .4rem'}
                viewBox={'0 0 24 24'}
                padding={'0'}
                screenReaderLabel={'More Info'}
              >
                <path d='M0 0h24v24H0V0z' fill='none' />
                <path d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
              </Icon>
            </div>
          </div>
          <StyledBenefitListItemToggleButton
            eligible={eligible}
            findOutMore={findOutMore}
            flex={'0'}
            background={'none'}
            fontSize={'0.75rem'}
            fontWeight={'500'}
            padding={'0'}
            paddLeft={'0'}
            radius={'0'}
            margin={'.5rem 0 0 0'}
            border={'0'}
          >
            <EligibilityStatus
              people={people}
              findOutMore={findOutMore}
              eligible={eligible}
            />
          </StyledBenefitListItemToggleButton>
          <div>
            <StyledBenefitsSpan>
              {toCapitalized(amountLabel)} Yearly Value:
            </StyledBenefitsSpan>{' '}
            <BenefitAmount eligibleAmount={eligibleAmount} />
          </div>
          <RecurringBenefitAmount
            frequency={frequency}
            recurringAmount={recurringAmount}
          />
        </StyledMuiTextFieldWrapper>
      </StyledMuiTextFieldWrapper>

      <Dialog
        fullScreen={isTabletAndMobile}
        style={{
          top: isTabletAndMobile ? 'auto' : 0,
          bottom: isTabletAndMobile ? '-1.5rem' : 0,
        }}
        className='dialogInDesktop'
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id={name}
          onClose={handleClose}
          typostyle={{
            fontSize: '1.6875rem',
            color: '#29283E',
            fontFamily: 'inherit',
            letterSpacing: 'normal',
            padding: '2rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'inline-flex',
            }}
          >
            {categoryIcon}
          </div>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <StyledMuiTextFieldWrapper
              style={{ fontSize: '0.875rem', fontWeight: '500', opacity: '.5' }}
              mbottom='0'
            >
              {category}
            </StyledMuiTextFieldWrapper>
            <StyledMuiTextFieldWrapper
              style={{ fontSize: '1.5rem', fontWeight: '500' }}
              mbottom='0'
            >
              {name}
            </StyledMuiTextFieldWrapper>
          </div>
        </DialogTitle>
        {(description || toolkitUrl) && (
          <DialogContent>
            <Typography
              style={{
                fontSize: '0.9375rem',
                fontWeight: '400',
                color: '#81818D',
                fontFamily: 'inherit',
                marginBottom: '1rem',
                lineHeight: '2',
                textAlign: 'left',
              }}
            >
              {description}
            </Typography>

            {toolkitUrl && (
              <StyledButtonFilled
                href={toolkitUrl}
                target='_blank'
                style={{
                  color: theme.palette.primary.dark,
                  fontSize: '0.875rem',
                  fontWeight: '400 !important',
                  border: 'unset',
                  textDecoration: 'underline',
                }}
              >
                Download Toolkit (PDF)
              </StyledButtonFilled>
            )}
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}

export default NextStepsBenefitListItem
