export const client = {
  email: null,
  firstName: null,
  id: window.sessionStorage.getItem('clientId'),
  lastName: null,
  nickName: null,
  screenings: 1,
  rehydrated: false,
  persisted: false,
  editing: false,
  authenticated: false,
}
