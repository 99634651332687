import React from 'react'
import CreateIcon from '../Icon/CreateIcon'
import {
  StyledMenuItem,
  StyledMenuItemLabel,
  StyledMenuItemExternalLink,
  StyledMenuItemExternalLinkLabel,
} from './style'
import { useMediaQuery } from 'react-responsive'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import theme from 'Shared/Theme/ssTheme'

const MenuItemIcon = ({ iconPath, viewBox }) => {
  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <CreateIcon
      fill={'#000'}
      scale={isMobile ? '1.675rem' : '1.5rem'}
      margin={isMobile ? '0 1rem 0 0' : '0 .25rem 0 0'}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      iconPath={iconPath}
    />
  )
}

const MenuItem = ({
  activelink,
  externalUrl,
  handleClick,
  iconPath,
  path,
  text,
  withoutIcon,
  viewBox,
  greenColor,
}) => {
  const styledBool = activelink ? 'active' : 'inactive'

  if (externalUrl) {
    return (
      <StyledMenuItemExternalLink
        href={externalUrl}
        onClick={handleClick}
        target='_blank'
      >
        <MenuItemIcon iconPath={iconPath} viewBox={viewBox} />
        <StyledMenuItemExternalLinkLabel>
          {text}
        </StyledMenuItemExternalLinkLabel>
      </StyledMenuItemExternalLink>
    )
  }

  if (withoutIcon) {
    return (
      <StyledMenuItem
        withoutstyle='true'
        activelink={styledBool}
        to={path}
        onClick={handleClick}
      >
        <StyledMenuItemLabel withOUtStyle activelink={styledBool}>
          {text}
        </StyledMenuItemLabel>
      </StyledMenuItem>
    )
  }

  return (
    <StyledMenuItem
      activelink={styledBool}
      to={path}
      onClick={handleClick}
      fontSize={'1rem'}
    >
      <MenuItemIcon iconPath={iconPath} viewBox={viewBox} />
      <StyledMenuItemLabel
        activelink={styledBool}
        color={greenColor && theme.palette.primary.dark}
      >
        {text}
      </StyledMenuItemLabel>
    </StyledMenuItem>
  )
}

export default MenuItem
