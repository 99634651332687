import React from 'react'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import FilterMenu from 'Components/LocalResources/Filtering/FilterMenu/FilterMenu'
import SecondaryActionBar from 'Components/Shared/SecondaryActionBar/SecondaryActionBar'
import LocationsList from './LocationsList/LocationsList'
import LocationsMap from './LocationsMap/LocationsMap'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import SearchBox from '../../Components/LocalResources/SearchBox/SearchBox'
import SearchBar from '../../Components/Shared/SearchBar/SearchBar'
import filterIconPath from 'Shared/Icons/filterIconPath'
import closeIconPath from 'Shared/Icons/closeIconPath'
import DisplayLogo from 'Components/LocalResources/DisplayLogo/DisplayLogo'
import { StyledMain } from 'Pages/NextSteps/style'
import { AlgoliaLocation } from './AlgoliaLocation'
import { useParams } from 'react-router-dom'

type TabletMobileLocationsProps = {
  filterMenuClickHandler: Function
  locationId?: number
  locations: AlgoliaLocation[]
  pageName: string
  secondaryActionBarCallback: Function
  showFilterMenu: boolean
  showList: boolean
  zipCode: string
}

const TabletMobileLocations = ({
  filterMenuClickHandler,
  locations,
  locationId,
  pageName,
  secondaryActionBarCallback,
  showFilterMenu,
  showList,
  zipCode,
}: TabletMobileLocationsProps) => {
  const params = useParams<{ id: string }>()

  let id: number

  if (locationId) {
    id = locationId
  } else if (params.id && window.location.pathname.includes('locations')) {
    id = Number(params.id)
  }

  const renderMobileMainContent = () => {
    if (showFilterMenu) {
      return null
    } else if (showList) {
      return <LocationsList locationID={id} locations={locations} />
    } else {
      return <LocationsMap />
    }
  }

  return (
    <>
      <Header />
      <Menu />
      <StyledMain>
        <SearchBar>
          <SearchBox initialValue={zipCode} />
          <ActionToggle
            filterinmobile
            className={'cypress-filter-menu-toggle'}
            activeIconPath={closeIconPath}
            activeLabel={'filters'}
            handleOnClick={filterMenuClickHandler}
            inactiveIconPath={filterIconPath}
            inactiveLabel={'filters'}
            fill={'rgba(0,0,0,0.65)'}
            showActive={showFilterMenu}
            rightMob={'20%'}
            rightTab={'9%'}
          />
        </SearchBar>
        <FilterMenu onClick={filterMenuClickHandler} />
        {renderMobileMainContent()}
        <SecondaryActionBar
          position={'fixed'}
          callback={secondaryActionBarCallback}
          pageName={pageName}
          opacity={'1'}
          fillIcon={'#fff'}
          color={'#fff'}
          justContAction={'space-between'}
          width='30%'
          tabletWidth='16%'
          margin='auto'
          left='0'
          right='0'
          bottom='6rem'
          background='unset'
          boxShadow='none'
          backgroundBtn='#000'
          actionPadding='0.425rem 1rem'
          opacityToggle={1}
          minWidthToggle='auto'
          borderRadiusToggle={'50px'}
          backgroundAction={'unset'}
        />
        <DisplayLogo isMobile={true} />
      </StyledMain>
    </>
  )
}

export default TabletMobileLocations
