import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

type StyledLogoLinkProps = {
  display?: string
  justifyContent?: string
  marginLeft?: string
}

export const StyledLogoLink = styled.div<StyledLogoLinkProps>`
  @media only screen and (max-width: 1025px) {
    display: ${(props) => props.display};
    justify-content: ${(props) => props.justifyContent};
    margin-left: ${(props) => props.marginLeft};
    width: 100%;
  } ;
`

export const StyledLogoLinkCaseManagement = styled.div``

export const StyledLink = styled(Link)`
  cursor: pointer;
`

StyledLogoLink.displayName = 'StyledLogoLink'
