import React from 'react'

const careIconPath = (
  <g id='a'>
    <g transform='translate(-38.749 -52.434)'>
      <path
        d='M1.406,100.291c9.516,21.506,70.017,17.57,88.633-10.349S87.379,0,68.308,0,49.236,41.877,41.373,54.176-8.11,78.785,1.406,100.291Z'
        transform='matrix(0.799, 0.602, -0.602, 0.799, 98.632, 37.582)'
        fill='#ffe49e'
        opacity='0.8'
      />
      <g transform='translate(61.371 59.96)' opacity='0.8'>
        <path
          d='M89.2,33.27a6.144,6.144,0,0,0-8.339,1.755L74.72,44.241a5.323,5.323,0,0,0-4.214,1.317L57.866,56.531a11.115,11.115,0,0,0-3.511,6.32L52.6,74.437V85.41A2.173,2.173,0,0,0,54.794,87.6H70.067a2.173,2.173,0,0,0,2.194-2.194V76.018L81.215,69a17.637,17.637,0,0,0,6.32-10.007l4.214-19.311A5.846,5.846,0,0,0,89.2,33.27ZM57.077,83.213V77.159H67.962v6.057Zm26.249-25.1a12.421,12.421,0,0,1-4.74,7.461l-9.216,7.2H57.34l1.4-9.216a6.188,6.188,0,0,1,2.019-3.687L73.315,48.9a1.066,1.066,0,0,1,1.4,0,.99.99,0,0,1,.181,1.4l-2.194,3.511a17.121,17.121,0,0,1-3.072,3.6l-3.511,3.16a2.178,2.178,0,1,0,2.9,3.252l3.511-3.16A24.175,24.175,0,0,0,76.393,56.1l2.194-3.511a5.575,5.575,0,0,0-.175-6.144l5.969-8.954a1.709,1.709,0,0,1,2.282-.526,1.858,1.858,0,0,1,.782,1.837Z'
          transform='translate(-8.641 -7.986)'
        />
        <path
          d='M40.069,62.843a9.978,9.978,0,0,0-3.511-6.32L24.006,45.546a5.423,5.423,0,0,0-4.214-1.317l-6.145-9.213a5.985,5.985,0,0,0-8.339-1.755,6.1,6.1,0,0,0-2.633,6.5L6.889,59.068a17.472,17.472,0,0,0,6.32,10.007l8.954,7.018v9.4a2.173,2.173,0,0,0,2.194,2.194H39.629a2.173,2.173,0,0,0,2.2-2.194V74.166Zm-24.227,2.72A13.275,13.275,0,0,1,11.1,58.1L6.889,38.791a1.77,1.77,0,0,1,.79-1.843,1.6,1.6,0,0,1,2.282.526l5.969,8.954a5.417,5.417,0,0,0-.175,6.144l2.191,3.511a21.315,21.315,0,0,0,3.862,4.565l3.511,3.16a2.178,2.178,0,0,0,2.9-3.252L24.7,57.4a20.173,20.173,0,0,1-3.072-3.6l-2.194-3.511a1.193,1.193,0,0,1,.175-1.4,1.066,1.066,0,0,1,1.4,0L33.569,59.852a6.686,6.686,0,0,1,2.023,3.7l1.58,9.216H25.059ZM26.551,83.207V77.15H37.435v6.057Z'
          transform='translate(-2.518 -7.977)'
        />
        <path
          d='M62.841,38.132l-.878-7.2a11,11,0,0,0-6.32-8.69,10.327,10.327,0,0,0,2.808-7.111,10.533,10.533,0,1,0-21.067,0,10.327,10.327,0,0,0,2.808,7.111,11,11,0,0,0-6.32,8.69L33,38.132a3.907,3.907,0,0,0,3.16,4.389A57.482,57.482,0,0,0,47.918,43.75,57.483,57.483,0,0,0,59.68,42.521,4.113,4.113,0,0,0,62.841,38.132ZM47.918,9.077a6.057,6.057,0,1,1-6.057,6.057A6.057,6.057,0,0,1,47.918,9.077ZM37.473,38.308l.79-6.847a6.631,6.631,0,0,1,6.583-5.793H50.99a6.631,6.631,0,0,1,6.583,5.793l.79,6.847a52.32,52.32,0,0,1-20.89,0Z'
          transform='translate(-6.243 -4.597)'
        />
      </g>
    </g>
  </g>
)

export default careIconPath
