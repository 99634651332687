export const TRANSLATION_OPTIONS = [
  {
    value: 'ar',
    label: 'العربية',
    inputProps: {
      ignore: '',
    },
  },
  {
    value: 'en',
    label: 'English',
    inputProps: {
      ignore: '',
    },
  },
  {
    value: 'es',
    label: 'Español',
    inputProps: {
      ignore: '',
    },
  },
  {
    value: 'ko',
    label: '한국어',
    inputProps: {
      ignore: '',
    },
  },
  {
    value: 'ru',
    label: 'Русский',
    inputProps: {
      ignore: '',
    },
  },
  {
    value: 'zh',
    label: '中文(简体)',
    inputProps: {
      ignore: '',
    },
  },
]
