import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  MouseEvent,
  Children,
  cloneElement,
  ReactElement,
} from 'react'
import { createIdFromLabel } from 'Shared/helpers'
import { Menu } from '@material-ui/core'
import {
  StyledButtonSelect,
  InputLabel,
  StyledSpan,
  ContainerSelect,
} from './Styles'
import { MUI_SELECT_OPEN_EVENT } from 'Shared/constants'

interface DropdownObject {
  onChange?: Function
  value: string
  placeholder?: string | null
  labelId?: string
  label?: string
  name: string
  className?: string
  children: Array<React.ReactElement>
  variant?: 'standard' | 'outlined'
  error?: boolean
  input?: any
  disabled?: boolean
  multiple?: boolean
  dataTestId?: string
  renderValue?: Function
}

const CustomDropdown = ({
  onChange,
  value: defaultValue,
  label,
  labelId,
  className,
  name, // we need to get a name attribute as this is how a form will read the value
  children,
  variant = 'outlined',
  error,
  input,
  disabled,
  multiple = false,
  dataTestId,
  renderValue,
  ...rest
}: DropdownObject) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const buttonEl = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectLabel, setSelectLabel] = useState('')
  const [ariaActiveDescendant, setAriaActiveDescendant] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const createdLabelId = useMemo(() => createIdFromLabel(name), [name])
  const open = Boolean(anchorEl)

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget)
    let event = new Event(MUI_SELECT_OPEN_EVENT, { bubbles: true })
    evt.target.dispatchEvent(event)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectItem = (child: ReactElement) => (event: MouseEvent) => {
    // set value of input
    let newValue
    if (multiple) {
      newValue = Array.isArray(defaultValue) ? [...defaultValue] : []
      const itemIndex = newValue.indexOf(child.props.value)

      if (itemIndex === -1) {
        newValue.push(child.props.value)
      } else {
        newValue.splice(itemIndex, 1)
      }
      inputEl?.current?.setAttribute('value', newValue.join(','))
    } else {
      newValue = child.props.value
      inputEl?.current?.setAttribute('value', child.props.value)
      handleClose()
    }
    if (newValue !== defaultValue) {
      setAriaActiveDescendant(child.props.value)
      if (onChange) {
        const nativeEvent: any = event.nativeEvent || event
        const clonedEvent = new nativeEvent.constructor(
          nativeEvent.type,
          nativeEvent
        )

        Object.defineProperty(clonedEvent, 'target', {
          writable: true,
          value: { value: newValue, name },
        })
        onChange(clonedEvent, child)
      }
    }
  }

  //every time value changes
  useEffect(() => {
    if (defaultValue !== undefined) {
      inputEl?.current?.setAttribute(
        'value',
        Array.isArray(defaultValue) ? defaultValue.join(',') : defaultValue
      )
    }
  }, [defaultValue])

  // after every render
  useEffect(() => {
    if (inputEl?.current?.value !== '' && renderValue === undefined) {
      const selected = children
        .flat()
        .find(({ props }: any) => props.value === inputEl?.current?.value)!
      setSelectLabel(selected?.props?.children)
    } else {
      setSelectLabel('')
    }
  })
  //remove and add aria attributes to the list
  useEffect(() => {
    setTimeout(() => {
      const rolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      if (rolePresentation) {
        rolePresentation?.removeAttribute('role')
      }
      const popoverElem = document.querySelector('ul[role="menu"]')
      if (popoverElem) {
        popoverElem?.setAttribute('role', 'listbox')
        popoverElem?.setAttribute(
          'aria-labelledby',
          labelId ? labelId : `label-${createdLabelId}`
        )
        multiple && popoverElem?.setAttribute('aria-multiselectable', 'true')
      }
    })
  })
  const isSelected = (childValue: string) => {
    if (Array.isArray(defaultValue)) {
      return defaultValue.some((el: string) => el === childValue)
    } else {
      return childValue === inputEl?.current?.value
    }
  }
  return (
    <ContainerSelect disabled={disabled} aria-live='assertive'>
      {/* MUI uses aria-hidden="true" tabIndex="-1" and opacity: 0 instead of type="hidden" might be some limitation with type="hidden"?*/}
      <input
        ref={inputEl}
        type='text'
        name={name}
        aria-hidden='true'
        tabIndex={-1}
        style={{ display: 'none' }}
        aria-describedby={error ? label : undefined}
      />
      {label && (
        <InputLabel
          id={labelId ? labelId : `label-${createdLabelId}`}
          isActive={Boolean(inputEl?.current?.value)}
          isFocused={isFocused || anchorEl !== null}
          label={label}
          disabled={disabled}
        />
      )}
      <StyledButtonSelect
        elemRef={buttonEl}
        id={`mui-component-select-${createdLabelId}`}
        aria-activedescendant={
          ariaActiveDescendant ? ariaActiveDescendant : undefined
        }
        error={error}
        aria-labelledby={labelId ? labelId : `label-${createdLabelId}`}
        aria-expanded={open}
        aria-owns={`menu-item-${createdLabelId}`}
        onClick={handleClick}
        className={className}
        variant={variant}
        disabled={disabled}
        data-testid={dataTestId}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
      >
        <StyledSpan>
          {renderValue ? renderValue(defaultValue) : selectLabel}
        </StyledSpan>
      </StyledButtonSelect>
      <Menu
        id={`menu-item-${createdLabelId}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {Children.map(children, (child, i) =>
          cloneElement(child, {
            role: 'option',
            onClick: handleSelectItem(child),
            'aria-selected': isSelected(child.props.value),
            selected: isSelected(child.props.value),
            style: {
              minWidth: `${buttonEl.current?.offsetWidth}px`,
              minHeight: '30px',
            },
            value: undefined, // li elements does not recognize value as an attribute, that's why it's provided data-value instead
            'data-value': child.props.value,
          })
        )}
      </Menu>
    </ContainerSelect>
  )
}

export default CustomDropdown
