import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'

export const StyledLink = styled(Link)`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.8rem')};
  color: ${(props) => props.color || props.theme.text.caseManagerLink};
  ${({ loginpage }) =>
    loginpage &&
    css`
      color: ${theme.palette.primary.dark};
    `}
`
