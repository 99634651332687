import React, { useState } from 'react'
import { Marker, OverlayView } from '@react-google-maps/api'

import { LocationCard } from 'Components/LocalResources/LocationCard/LocationCard'
import { useAppSelector } from 'Store/hooks'
import type {
  AlgoliaLocation,
  Coordinates,
} from 'Pages/Locations/AlgoliaLocation'

type MarkerWithInfoWindowProps = {
  coordinates: Coordinates
  device?: string
  icon: string
  location: AlgoliaLocation
  onClick: (event: google.maps.MapMouseEvent) => void
}

const MarkerWithInfoWindow = (props: MarkerWithInfoWindowProps) => {
  const { device, onClick, coordinates, icon, location } = props
  const [isInfoWindowOpen, setInfoWindowOpen] = useState(false)

  const { showLeftColumn } = useAppSelector((state) => state.ui)

  const [position, setPosition] = useState<google.maps.Point | null>(null)
  const [mapSize, setMapSize] = useState<{ height: number; width: number }>()

  const toggleInfoWindowOpen = () => {
    setInfoWindowOpen(!isInfoWindowOpen)
  }

  const onOverlayLoad = (overlay: google.maps.OverlayView) => {
    const Projection = overlay.getProjection()
    const map = overlay.getMap()! as google.maps.Map
    const { google } = window
    setPosition(
      Projection.fromLatLngToContainerPixel(
        new google.maps.LatLng({ ...coordinates })
      )
    )

    setMapSize({
      width: map.getDiv().offsetWidth,
      height: map.getDiv().offsetHeight,
    })
  }

  const getPixelPositionOffset = (width: number, height: number) => {
    const markerWidth = 30
    const markerHeight = 50
    const leftColumnWidth = 420

    const topCenter = { x: -width / 2, y: -(height + markerHeight) }
    const topLeft = { x: 0, y: -(height + markerHeight) }
    const topRight = { x: -(width - markerWidth), y: -(height + markerHeight) }

    const bottomCenter = { x: -width / 2, y: markerWidth / 2 }
    const bottomLeft = { x: 0, y: markerWidth / 2 }
    const bottomRight = { x: -(width - markerWidth), y: markerWidth / 2 }

    // case marker position top
    if (position!.y <= mapSize!.height / 2) {
      if (
        position!.x < width / 2 ||
        (showLeftColumn && position!.x < leftColumnWidth + width / 2)
      ) {
        // case marker x position left OR left column open
        return bottomLeft
      } else if (mapSize!.width - position!.x < width / 2) {
        // case marker x position right
        return bottomRight
      } else {
        return bottomCenter
      }
    }
    // case marker position bottom
    else {
      if (
        position!.x < width / 2 ||
        (showLeftColumn && position!.x < leftColumnWidth + width / 2)
      ) {
        // case marker x position left OR left column open
        return topLeft
      } else if (mapSize!.width - position!.x < width / 2) {
        // case marker x position right
        return topRight
      }
    }

    // ToDo: this position is only showing when moving the map
    return topCenter
  }

  return (
    <Marker
      position={coordinates}
      icon={
        isInfoWindowOpen ? `${window.location.origin}/map/Active.svg` : icon
      }
      onClick={onClick}
      onMouseOver={toggleInfoWindowOpen}
      onMouseOut={toggleInfoWindowOpen}
      title='map marker'
    >
      {isInfoWindowOpen && device === 'desktop' && (
        <OverlayView
          getPixelPositionOffset={getPixelPositionOffset}
          onLoad={onOverlayLoad}
          position={coordinates}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <LocationCard withShadow width={'300px'} location={location} />
        </OverlayView>
      )}
    </Marker>
  )
}

export default MarkerWithInfoWindow
