import React from 'react'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Form } from 'redux-form'
import styled, { css } from 'styled-components/macro'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { singleStopDarkerGray } from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { Link } from 'react-router-dom'
import 'Components/Uielements/styles/globalStyle.css'
import { makeStyles } from '@material-ui/core/styles'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { media } from 'styled-bootstrap-grid'
import { singleStopGreen } from 'Shared/Theme'

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`

interface styledFormProps extends CSSProperties {
  scrollx?: string
  scrolly?: string
  textalign?: string
  alignitems?: string
  justifycontent?: string
  boxshadow?: string
  borderradius?: string
}

export const StyledForm = styled(Form)<styledFormProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${(props) => (props.height ? props.height : '100%')};
  overflow-x: ${(props) => (props.scrollx ? props.scrollx : '')};
  overflow-y: ${(props) => (props.scrolly ? props.scrolly : '')};
  text-align: ${(props) => props.textalign};
  align-items: ${(props) => props.alignitems};
  justify-content: ${(props) => props.justifycontent};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.palette.color.white};
  @media only screen and (max-width: 480px) {
    padding-bottom: 6rem;
  }
  @media only screen and (min-width: 1025px) {
    padding: ${(props) => (props.padding ? props.padding : '2.5rem')};
    margin: ${(props) => props.margin};
    box-shadow: ${(props) => props.boxshadow};
    border-radius: ${(props) => props.borderradius};
  }
`

export const StyledSectionIntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

interface styledLocationFormProps extends CSSProperties {
  boxshadow?: string
  borderradius?: string
}

/**
 * [StyledLocationForm Part of hack to overcome
 * {@link https://github.com/redux-form/redux-form/issues/4026|Issue 4026}
 * otherwise <code>StyledForm</code> would be used. Comment containing
 * <code>handleSubmit</code> hack:
 * {@link https://github.com/redux-form/redux-form/issues/4026#issuecomment-445159076|Issue 4026 Comment}</p>]
 * @type {StyledLocationForm}
 * @see  PreScreenerLocation
 */
export const StyledLocationForm = styled.form<styledLocationFormProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: ${(props) => props.padding};
  overflow: auto;
  ${media.desktop`
    height: ${(props: any) => (props.height ? props.height : '100%')};
    background-color: ${(props: any) => props.background};
    margin: ${(props: any) => props.margin};
    box-shadow: ${(props: any) => props.boxshadow};
    border-radius: ${(props: any) => props.borderradius};
  `}
`

interface styledHeadingMainProps extends CSSProperties {
  textalign?: string
  deskWidth?: string
}

export const StyledHeadingMain = styled.h2<styledHeadingMainProps>`
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  color: ${theme.palette.text.darken};
  width: ${(props) => props.width};
  ${(props) => props.border};
  padding: ${(props) => props.padding};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '2.5rem')};
  line-height: 3.3rem;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1.25rem'} !important;
  margin-top: ${(props) =>
    props.marginTop ? props.marginTop : '0'} !important;
  font-weight: 700;
  &:focus {
    outline: 0;
  }
  @media only screen and (min-width: 1025px) {
    width: ${(props) => props.deskWidth};
  }
`

interface styledSubHeadingProps extends CSSProperties {
  textalign?: string
  black?: boolean
  borderBottomMobile?: string
  paddingBottomMobile?: string
  paddingTopMobile?: string
  smallerPadd?: string
  reviewInfoCard?: boolean
}

export const StyledSubHeading = styled.h2<styledSubHeadingProps>`
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  color: ${(props) =>
    props.black ? theme.palette.color.black : theme.palette.text.darken};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.75rem')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1.375rem'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-top: ${(props) => props.paddingTop};
  border-bottom: ${(props) => props.borderBottom};
  opacity: ${(props) => props.opacity};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '300')};
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: 480px) {
    border-bottom: ${(props) => props.borderBottomMobile};
    padding-bottom: ${(props) => props.paddingBottomMobile};
    padding-top: ${(props) => props.paddingTopMobile};
    padding: ${(props) => props.smallerPadd};
  }
  @media only screen and (min-width: 481px) {
    padding: ${(props) => props.smallerPadd};
  }
  ${({ reviewInfoCard }) =>
    reviewInfoCard &&
    css`
      width: 80%;
      overflow-wrap: anywhere;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
    `}
`

export const StyledHeadingSecondary = styled.h3`
  color: ${(props) => props.theme.text.default};
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`

export const StyledTabPanelDiv = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${theme.palette.color.black};
  font-family: Noto Sans HK, sans-serif, 'sans-serif';
`

interface styledParagraphProps extends CSSProperties {
  textalign?: string
  lineheight?: string
  income?: boolean
  houseHold?: boolean
  introduction?: boolean
  dialogdesktop?: boolean
  privacyTerms?: boolean
  overivewFooter?: boolean
}

export const StyledParagraph = styled.p<styledParagraphProps>`
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  color: ${(props) => (props.color ? props.color : theme.palette.text.default)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1.25rem'};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '300')};
  text-decoration: ${(props) => props.textDecoration};
  opacity: ${(props) => props.opacity};
  text-transform: ${(props) => props.textTransform};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2rem')};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  font-family: ${(props) => props.fontFamily};
  line-height: initial;
  letter-spacing: ${(props) => props.letterSpacing};
  line-height: ${(props) => (props.lineheight ? props.lineheight : 'initial')};
  ${({ income }) =>
    income &&
    css`
      font-size: 0.6875rem;
      font-weight: 500;
      margin: 0.4375rem 0;
      width: 50%;
    `};
  ${({ houseHold }) =>
    houseHold &&
    css`
      font-size: 0.6875rem;
      font-weight: 500;
      margin: 0.4375rem 0;
      width: 25%;
    `};

  ${({ introduction }) =>
    introduction &&
    css`
      @media only screen and (max-width: 480px) {
        width: 100%;
      }
      @media only screen and (min-width: 481px) {
        width: 80%;
      }
      @media only screen and (min-width: 1025px) {
        width: 52%;
      }
    `};
  ${({ dialogdesktop }) =>
    dialogdesktop &&
    css`
      text-align: center;
      @media only screen and (max-width: 480px) {
        font-size: 1.125rem;
        line-height: 1.8;
        padding: 0 2rem 2rem;
      }
      @media only screen and (min-width: 481px) {
        margin-bottom: 3rem;
        font-size: 1.125rem;
        line-height: 1.5;
        padding: 0 4rem;
      }
      @media only screen and (min-width: 1025px) {
        margin-bottom: 3rem;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: 0 4rem;
      }
    `}

  ${({ privacyTerms }) =>
    privacyTerms &&
    css`
        font-family 'Roboto';
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: -.022em;
        margin-top: 2rem;
        line-height: 1.5rem;
        color: ${theme.palette.text.Lighten};
        a{
          color: ${theme.palette.primary.dark};
        }
      `};

  ${({ overivewFooter }) =>
    overivewFooter &&
    css`
      font-size: 0.875rem;
      font-weight: 400;
      text-align: right;
      margin-top: 0.25rem;
      margin-bottom: 0;
      color: ${theme.palette.text.Lighten};
      font-style: italic;
    `};
`

export const StyledPrivacyTermsLoginPage = styled(StyledParagraph)`
font-family 'Roboto';
font-size: .875rem;
letter-spacing: -.022em;
line-height: 1.5rem;
font-weight: 400;
margin: 3rem auto 0;
width: 100%;
text-align: center;
color: ${theme.palette.text.default};
a{
  color: ${theme.palette.primary.dark};
  font-family inherit;
  font-size: inherit;
  font-weight: inherit;
}
`

export const StyledBoldText = styled(StyledParagraph)`
  font-weight: bold;
`

export const StyledScreeningPairSvg = styled.img`
  display: block;
  width: 7.5rem;
  margin: 0 auto 1.375rem;
`

export const StyledScreeningCompleteSvg = styled.img<CSSProperties>`
  display: flex;
  width: ${(props) => (props.width ? props.width : '7.5rem')};
  margin: ${(props) => (props.margin ? props.margin : 'auto')};
`

interface styledLinkProps extends CSSProperties {
  justCont?: string
  alignitems?: string
}

export const StyledLink = styled.a<styledLinkProps>`
  width: ${(props) => props.width};
  color: ${(props) => props.theme.text.default};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justCont};
  align-items: ${(props) => props.alignitems};
`

export const StyledRouterLink = styled(Link)`
  color: ${(props) => props.theme.text.default};
  margin: 0 auto;
`

export const StyledParentButtonsActionsInMobile = styled.div`
  @media only screen and (max-width: 480px) {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 1rem;
    background: #fff;
    boxshadow: 0px 3px 6px #1d283117;
    left: 0;
    z-index: 8;
  }
`

export const StyledCallsToActionParent = styled.div`
  margin: 0 0.9375rem;
`

type reverseColProps = {
  smallerWidth?: string
  smallermargin?: string
  tabletWidth?: string
  tabletMargin?: string
}

export const ReverseCol = styled.div<reverseColProps>`
  display: flex;
  @media only screen and (max-width: 480px) {
    width: ${(props) => (props.smallerWidth ? props.smallerWidth : '100%')};
    margin: ${(props) => (props.smallermargin ? props.smallermargin : 'auto')};
  }
  @media only screen and (min-width: 481px) {
    width: ${(props) => (props.tabletWidth ? props.tabletWidth : '80%')};
    margin: ${(props) => (props.tabletMargin ? props.tabletMargin : 'auto')};
  }
  @media only screen and (min-width: 1025px) {
    width: 100%;
    margin: 0;
  }
`

interface StyledParentPreScreenerButtonProps extends CSSProperties {
  width?: string
  $isOnlyContinue?: boolean
}

export const StyledParentPreScreenerButton = styled.div<StyledParentPreScreenerButtonProps>`
  width: ${(props) => props.width};
  @media only screen and (max-width: 1024px) {
    margin: 0 0 0 auto;
  }
  @media only screen and (min-width: 1025px) {
    margin: 0 0.9375rem 1rem;
    ${({ $isOnlyContinue }) =>
      $isOnlyContinue &&
      css`
        margin: 0 0.9375rem 1rem 0;
      `}
  }
`

export const useStyles = makeStyles({
  root: {
    marginBottom: '2rem',
    textAlign: 'center',
    position: 'relative',
  },
  media: {
    height: 140,
  },
})

export const StyledCardButton = withStyles({
  root: {
    fontWeight: 300,
    padding: '0.5rem',
    width: '100%',
    lineHeight: '1.3',
    '&:focus': {
      background: '#ddd',
    },
  },
})(Button)

export const StyledCardActions = withStyles({
  root: {
    '@media only screen and (min-width : 481px)': {
      position: 'absolute',
      bottom: '0',
      padding: '0',
      width: '100%',
      zIndex: '1',
    },
  },
})(CardActions)

export const StyledTypography = withStyles({
  root: {
    '@media only screen and (max-width : 480px)': {
      margin: '0',
    },
    '@media only screen and (min-width : 481px)': {
      margin: '0 0 0 1rem',
    },
  },
})(Typography)

export const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 6, 6, 6),
  },
}))(MuiDialogContent)

export const StyledRadioButtonUncheckedIcon = withStyles({
  root: {
    color: '#ddd',
  },
})(RadioButtonUncheckedIcon)

export const StyledCheckCircleIcon = withStyles({
  root: {
    color: theme.palette.primary.dark,
  },
})(CheckCircleIcon)

export const StyledButton = withStyles({
  root: {
    background: `${singleStopDarkerGray}`,
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 38,
    padding: '0 1.875rem',
    width: '100%',
    marginTop: 'auto',
  },
  label: {
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: '.1rem',
  },
})(Button)

export const StyledTabs = withStyles({
  root: {
    width: '100%',
    justifyContent: 'space-between',
  },
})(Tabs)

export const StyledTab = withStyles({
  root: {
    padding: '1.5rem 0',
    textTransform: 'capitalize',
    '@media (max-width: 700px)': {
      padding: '0',
    },
  },
})(Tab)

export const StyledAppBar = styled.div`
  background: ${theme.palette.color.white};
  border: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 0.125rem 0.3125rem #0000000a;
  @media (min-width: 700px) {
    border-radius: 0 0 0.625rem 0.625rem;
  }
`

export const StyledScreenerBackButton = styled(
  ({ hasExtraButton, ...props }) => (
    <Button {...props} disableFocusRipple={true} />
  )
)`
  display: ${(props) =>
    props.displaynone ? 'none' : props.display ? props.display : 'flex'};
  position: ${(props) => (props.position ? props.position : 'relative')};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1.125rem;
  background: ${(props) =>
    props.hasExtraButton
      ? 'none'
      : props.outlined
      ? 'none'
      : theme.palette.secondary.default};
  color: ${theme.palette.color.black};
  margin: ${(props) =>
    props.hasExtraButton
      ? props.margin || '2rem'
      : props.margin || '0 0 0 1rem'};
  border-radius: 0.3rem;
  height: ${(props) => (props.height ? props.height : '3.8rem')};
  padding: ${(props) => (props.padding ? props.padding : '0 3rem')};
  width: ${(props) => (props.width ? props.width : '50%')};
  border: ${(props) => (props.outlined ? '1px solid #70707087' : 'none')};
  letter-spacing: 0.1rem;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    background: ${theme.palette.secondary.default};
    filter: brightness(97.5%);
  }
  &:focus {
    box-shadow: 0 3px 5px -1px #2e2e2e, 0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
    flex: 1;
    max-width: ${(props) => (props.maxwidth ? props.maxwidth : '50%')};
    margin: ${(props) =>
      props.smallermargin ? props.smallermargin : '0rem 1rem 0 0'};
    ${({ prescreener }) =>
      prescreener &&
      css`
        max-width: 100%;
        width: 100%;
      `}
  }
`

type StyledDynamicCtaButtonProps = {
  $hasExtraButton: boolean
  $nextSteps: boolean
  $isYesNoQuestion: boolean
}

export const StyledDynamicCtaButton = styled((props) => {
  const { $hasExtraButton, $nextSteps, $isYesNoQuestion, ...rest } = props
  return <Button {...rest} disableFocusRipple={true} />
})<StyledDynamicCtaButtonProps>`
  &&& {
    background: ${(props) =>
      props.$hasExtraButton
        ? 'none'
        : props.background || theme.palette.primary.dark};
    text-shadow: ${(props) =>
      props.$hasExtraButton
        ? 'none'
        : props.textshadow
        ? props.textshadow
        : `0 0 5px ${theme.palette.color.black}`};
    border: ${(props) =>
      props.$hasExtraButton
        ? props.border || `.1rem solid ${props.theme.background.buttonDefault}`
        : props.border
        ? props.border
        : '0'};
    color: ${(props) =>
      props.$hasExtraButton
        ? `${props.theme.text.default}`
        : props.color || `${props.theme.text.buttonDefault}`};
    margin: ${(props) =>
      props.$hasExtraButton ? props.margin || '2rem' : '0'};
    border-radius: 0.3rem;
    height: ${(props) => (props.height ? props.height : '3.8rem')};
    padding: ${(props) => (props.padding ? props.padding : '0 3rem')};
    width: ${(props) => (props.width ? props.width : '100%')};
    text-transform: capitalize;
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1.125rem')};
    letter-spacing: 0.1rem;
    justify-content: ${(props) => props.justcont};
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin: ${(props) =>
        props.margin
          ? props.margin
          : props.$hasExtraButton
          ? 'auto 1rem 2rem'
          : props.smallermargin
          ? props.smallermargin
          : '0rem 0 1rem 0'};
      text-align: center;
      font-size: 1rem;
    }
    @media only screen and (min-width: 1025px) {
      width: ${(props) => props.lgwidth};
    }
  }

  &&&:hover {
    color: ${(props) =>
      props.$hasExtraButton
        ? props.colorhover || theme.palette.color.white
        : null};
    background: ${(props) =>
      props.$hasExtraButton
        ? props.bghover || `${props.theme.text.default}`
        : `${theme.palette.primary.darken5}`};
    border-color: ${(props) =>
      props.borderhover ? props.borderhover : theme.palette.color.white};
    filter: brightness(97.5%);
    ${({ $nextSteps }) =>
      $nextSteps &&
      css`
        background: unset;
        color: ${theme.palette.primary.default};
      `}
  }
  &&&:focus {
    box-shadow: 0 3px 5px -1px #2e2e2e, 0 6px 10px 0 #2e2e2e,
      0 1px 18px 0 #2e2e2e;
    ${({ $isYesNoQuestion }) =>
      $isYesNoQuestion &&
      css`
        box-shadow: 0 3px 5px -1px #c9c9c9, 0 6px 10px 0 #c9c9c9,
          0 1px 18px 0 #c9c9c9;
      `};
  }
`

export const StyledList = styled.ul<CSSProperties>`
  color: ${(props) => props.theme.text.default};
  font-size: 1rem;
  font-weight: 300;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  margin-left: 1.2rem;
  padding: 0;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '5rem')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: ${(props) => (props.width ? props.width : '50%')};
  @media only screen and (max-width: 480px) {
    width: 100%;
    max-height: unset;
  }
  li {
    margin-bottom: 0.25rem;
  }
`

export const StyledListItem = styled.li`
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5rem;
  margin-right: 3rem;
  &:before {
    content: '•';
    font-size: 2rem;
    position: absolute;
    left: -1.6rem;
    top: -0.6rem;
    color: ${singleStopGreen};
  }
`

export const StyledDialog = withStyles({
  root: {
    '@media (max-width : 1024px)': {
      top: 'auto !important',
      bottom: '-1.5rem !important',
    },
  },
})(Dialog)

export const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '2rem 0 0',
  },
})(Typography)

export const StyledCardActionArea = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    '@media (max-width: 574px)': {
      justifyContent: 'start',
    },
  },
})(CardActionArea)

export const StyledCardContent = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
  },
})(CardContent)

export const StyledTestButton = styled.button<CSSProperties>`
  display: ${(props) => (props.display ? props.display : 'block')};
`

export const StyledFieldset = styled.fieldset`
  padding: 0;
  border: unset;
  legend {
    text-align: left;
    color: ${theme.palette.text.darken};
    font-size: 1.75rem;
    margin-bottom: 1.375rem;
    margin-top: 0;
    font-weight: 300;
    &:focus {
      outline: 0;
    }
  }
`

StyledScreenerBackButton.displayName = 'StyledScreenerBackButton'
StyledContainer.displayName = 'StyledContainer'
StyledForm.displayName = 'StyledForm'
StyledHeadingMain.displayName = 'StyledHeadingMain'
StyledHeadingSecondary.displayName = 'StyledHeadingSecondary'
StyledParagraph.displayName = 'StyledParagraph'
StyledSubHeading.displayName = 'StyledSubHeading'
StyledLink.displayName = 'StyledLink'
StyledButton.displayName = 'StyledButton'
StyledDynamicCtaButton.displayName = 'StyledDynamicCtaButton'
StyledList.displayName = 'StyledList'
StyledParentPreScreenerButton.displayName = 'StyledParentPreScreenerButton'
