import React from 'react'
import { Container, Col } from 'styled-bootstrap-grid'
import { FeaturesText } from '../Components/FeaturesText'
import { LandingRow } from '../Styles/LandingSection'
import { LandingSection } from '../Styles/LandingSection'
import { StyledFigure } from '../Styles/FeaturesImage'
import { SrOnly } from 'Components/Uielements/styles/helpers.styles'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import 'Components/Uielements/styles/globalStyle.css'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import { StyledMessageElem } from 'Components/LandingPage/Styles/FeaturesText'
import useBranding from 'Components/LandingPage/Hooks/useBranding'
import { StyledParentImg, StyledElmImg } from '../Styles//HeroSection'
import SoliderElem from 'Components/LandingPage/Assets/SoliderElem.svg'
import OldLadyElem from 'Components/LandingPage/Assets/OldLadyElem.svg'
import HandicapElem from 'Components/LandingPage/Assets/HandicapElem.svg'
import StudentElem from 'Components/LandingPage/Assets/StudentElem.svg'

const randomImages = [SoliderElem, OldLadyElem, HandicapElem, StudentElem]

const randomImage = Math.floor(Math.random() * 4)

const HeroSection = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const registrationUrl = useDynamicRegistrationUrl()
  const { content: brandedContent, header: brandedHeader } = useBranding()
  const brandedCopy = brandedContent ?? brandedHeader
  return (
    <LandingSection hero id='mainContent'>
      <Container>
        <LandingRow>
          <Col lg='7'>
            {brandedContent ? (
              <StyledMessageElem
                startingOpacity={'1'}
                multilineText={true}
                dangerouslySetInnerHTML={{ __html: brandedCopy }}
              />
            ) : (
              <FeaturesText
                widthTitle={'75%'}
                lineHeightTitle={'1.3'}
                marginSmallerMessage={'0'}
                title={[
                  `<span class='custom-heading'>Welcome to Single Stop!</span>
                `,
                ]}
                message={
                  'With a few quick questions, we’ll help you get access to public benefits and community resources across our vast network.'
                }
              ></FeaturesText>
            )}
          </Col>
          {!isMobile && (
            <Col lg='5' className='col-no-padding'>
              <StyledFigure>
                <StyledParentImg>
                  <img src={randomImages[randomImage]} alt='Hero' />
                </StyledParentImg>
                <SrOnly as='figcaption'>A lady holds a book</SrOnly>
              </StyledFigure>
            </Col>
          )}
        </LandingRow>
        <LandingRow>
          <Col>
            <FeaturesText
              moveBtn={'true'}
              footerMarginSmaller={'0'}
              action1="Let's Get Started!"
              target1={registrationUrl}
              action2='About us'
              target2='https://singlestop.org'
              target2External={true}
            ></FeaturesText>
          </Col>
        </LandingRow>
        <LandingRow>
          {isMobile && (
            <Col className='col-no-padding'>
              <StyledFigure>
                <StyledParentImg>
                  <StyledElmImg src={randomImages[randomImage]} alt='Hero' />
                </StyledParentImg>
                <SrOnly as='figcaption'>A lady holds a book</SrOnly>
              </StyledFigure>
            </Col>
          )}
        </LandingRow>
      </Container>
    </LandingSection>
  )
}

export default HeroSection
