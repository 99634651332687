export const phoneNumberRegExp = /\d{10}$/

export const parseEmailOrPhone = ({ emailOrPhone }) => ({
  primaryPhone: phoneNumberRegExp.test(emailOrPhone) ? emailOrPhone : null,
  email: !phoneNumberRegExp.test(emailOrPhone) ? emailOrPhone : null,
})

export const parseDateOfBirth = ({
  birthYear: birth_year,
  birthMonth: birth_month,
  birthDay: birth_day,
  age,
  date_of_birth,
}) => ({
  date_of_birth: date_of_birth ?? {
    birth_month,
    birth_day,
    birth_year,
    age,
  },
})

export const parseClientData = ({ otp = null, ...formData }) => {
  const {
    birthYear,
    birthMonth,
    birthDay,
    emailOrPhone,
    age,
    date_of_birth,
    ...remainingFieldValues
  } = formData
  return {
    ...remainingFieldValues,
    ...parseDateOfBirth({
      birthYear,
      birthMonth,
      birthDay,
      age,
      date_of_birth,
    }),
    emailOrPhone,
    ...parseEmailOrPhone({ emailOrPhone }),
  }
}

export const parseScreeningData = ({ formData, orgAndLoc }) => ({
  client: { ...parseClientData(formData) },
  organization: {
    slug: orgAndLoc.organization,
  },
  location: {
    slug: orgAndLoc.location,
  },
})

export const validName = (values) => {
  const errors = {}

  const { first_name, last_name, nick_name } = values

  if (!first_name && last_name) {
    errors.first_name = 'Enter first name'
  }

  if (!last_name && first_name) {
    errors.last_name = 'Enter last name'
  }

  if (!first_name && !last_name && !nick_name) {
    errors.nick_name =
      'Please provide a Preferred Name if not sharing full name'
  }

  return errors
}
