import styled, { css } from 'styled-components/macro'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import theme, { ssTheme } from 'Shared/Theme/ssTheme'
import { StyledHeadingMain } from 'Components/Screener/style'
import CloseIcon from '@material-ui/icons/Close'
import {
  CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'

export const StyledCloseIcon = styled(CloseIcon)``

interface sharedStyledFilterColumnProps extends CSSProperties {
  mdposition?: string
  widthTablet?: string
  mdheight?: string
  lgheight?: string
}

const SharedStyledFilterColumn = css<sharedStyledFilterColumnProps>`
  min-height: calc(100vh - 5.5rem);
  position: ${(props) => (props.mdposition ? props.mdposition : 'relative')};
  flex: unset;
  overflow: hidden;
  padding: 0 1.5rem 0 0;
  width: ${(props) => props.widthTablet};
  height: ${(props) => (props.mdheight ? props.mdheight : 'auto')};
  z-index: 3;
  transition: 0.1s ease-in-out;
`
export const StyledFilterColumn = styled.div<sharedStyledFilterColumnProps>`
  height: auto;
  @media only screen and (max-width: 800px) {
    ${SharedStyledFilterColumn};
    top: 0;
    left: 0;
    padding: 0;
    min-height: auto;
    height: auto;
    position: fixed;
    z-index: 9;
  }
  @media only screen and (min-width: 1024px) {
    width: ${(props) => props.width};
    position: relative;
    height: ${(props) => (props.lgheight ? props.lgheight : 'auto')};
    padding-right: 1.5rem;
  }
  ${StyledHeadingMain} {
    margin-bottom: 0.8125rem;
    line-height: inherit;
    margin-top: 0.75rem;
    font-weight: 500;
  }
`

type SharedStyledFilterToggleProps = {
  topToggle?: string
  transformButton?: string
  rightIcon?: string
}

const SharedStyledFilterToggle = css<SharedStyledFilterToggleProps>`
  position: absolute;
  top: ${(props) => (props.topToggle ? props.topToggle : '1.25rem')};
  right: 0.5rem;
  width: 1.0625rem;
  height: 1.625rem;
  background: #fff;
  border: 0;
  border-radius: 0 7px 7px 0;
  box-shadow: 4px 0px 7px #00000024;
  &:after {
    position: absolute;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #858585;
    border-width: 6px;
    transform: ${(props) => props.transformButton};
    right: ${(props) => props.rightIcon};
    top: 24%;
  }
  :hover {
    cursor: pointer;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: 0;
    background: #f1f1f1;
  }
`

export const StyledFilterToggle = styled.button`
  @media only screen and (max-width: 800px) {
    display: none;
  }
  @media only screen and (min-width: 481px) {
    ${SharedStyledFilterToggle}
  }
`

const SharedStyledFilterContent = css<CSSProperties>`
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.surfaceElevation(props.elevation)};
  padding: ${(props) => props.padding};
  background: #fff;
  height: 100%;
  min-height: calc(100vh - 5.5rem);
  overflow: hidden;
`
export const StyledFilterContent = styled.div`
  ${SharedStyledFilterContent};
  @media (max-width: 800px) {
    height: 100vh;
    min-height: auto;
  }
`

const SharedStyledFilterContentScroll = css`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`
export const StyledFilterContentScroll = styled.div`
  ${SharedStyledFilterContentScroll};
  @media (max-width: 800px) {
    height: 85vh;
  }
`
type StyledFilterColumnCardProps = {
  smmargin?: string
  checkbox?: boolean
}

export const StyledFilterColumnCard = styled.div<StyledFilterColumnCardProps>`
  padding: 0 1px 33px 1px;
  text-transform: capitalize;
  > div:last-child > label {
    margin-bottom: 0px;
  }
  @media (max-width: 800px) {
    padding: 0;
    margin-bottom: ${(props) => (props.smmargin ? props.smmargin : '1.5rem')};
  }
  ${({ checkbox }) =>
    checkbox &&
    css`
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      button {
        position: absolute;
        right: 0;
        top: 3px;
      }
    `}
`

export const StyledSiteFilterColumnCard = styled(StyledFilterColumnCard)`
  padding: 0 0 1rem 0;
`

const filterColumnHeading = css`
  color: #000;
  font-weight: 500;
  margin-top: 0;
  font-size: 0.875rem;
  margin-bottom: 0;
`
export const StyledClearButton = styled.button`
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 0.9em;
`

export const StyledFilterColumnCardHeading = styled.h3`
  ${filterColumnHeading};
  @media (max-width: 800px) {
    margin-bottom: 0.75rem;
  }
`
interface HeadingDropDownProps extends CSSProperties {
  $marginBottom?: string
  $isCaseManagementFiltersRedesign?: boolean
}
export const StyledFilterColumnCardHeadingDropdown = styled.label<HeadingDropDownProps>`
  ${filterColumnHeading};
  margin-bottom: ${(props) =>
    props.$marginBottom ? props.$marginBottom : '0.25rem'};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    margin-bottom: 0.25rem;
  }
  ${({ $isCaseManagementFiltersRedesign }) =>
    $isCaseManagementFiltersRedesign &&
    css`
      font-size: 0.75rem;
      color: #000000;
      opacity: 0.7;
      margin-right: 0.5rem;
      margin-bottom: 0;
    `}
`

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
    background: '#fff',
  },
})(FormControl)

export const StyledFeatureFormControl = withStyles({
  root: {
    width: '100%',
    background: '#fff',
    display: 'flex',
    flexDirection: 'row',
    outline: '1px solid #6363633B',
    borderRadius: '6px',
    alignItems: 'center',
    padding: '0.56rem 0.75rem',
    '& button': {
      outline: 'unset',
      borderRadius: 'unset',
      padding: 'unset',
      minWidth: 'auto',
      width: '6rem',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'end',
      '& span span': {
        fontSize: '0.775rem',
        color: '#000000',
        fontWeight: '700',
      },
      '&:hover, &:focus': {
        outline: 'unset',
        backgroundColor: 'unset',
      },
    },
    '&:hover, &:focus, &:focus-within': {
      outline: '1px solid #ffe9b1',
    },
  },
})(FormControl)

export const StyledFormControlLabel = withStyles({
  root: {
    marginLeft: '0',
    marginBottom: '0.6875rem',
  },
  label: {
    color: '#444356',
    fontWeight: 500,
    fontSize: '0.75rem',
    fontFamily: 'Noto Sans HK,sans-serif !important',
  },
})(FormControlLabel)

export const StyledMenuItem = withStyles({
  root: {
    fontSize: '0.6875rem',
    fontWeight: 500,
    fontFamily: 'Noto Sans HK,sans-serif !important',
    textTransform: 'capitalize',
  },
})(MenuItem)

export const StyledFeaturedMenuItem = withStyles({
  root: {
    fontSize: '0.6875rem',
    color: theme.palette.color.black,
    fontWeight: 500,
    fontFamily: 'Noto Sans HK,sans-serif !important',
    textTransform: 'capitalize',
  },
})(MenuItem)

export const StyledMenuItemRecentActivity = withStyles({
  root: {
    fontSize: '0.6875rem',
    fontWeight: 400,
    fontFamily: 'Noto Sans HK,sans-serif !important',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: '15rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
})(MenuItem)

export const StyledFilterButton = styled.button`
  color: ${theme.palette.text.darken};
  font-size: 0.625rem;
  font-weight: 500;
  text-transform: capitalize;
  border: none;
  text-decoration: underline;
  padding: 0;
  background: none;
  &:hover {
    cursor: pointer;
  }
`

export const StyledClearAllButton = withStyles({
  root: {
    background: `${theme.palette.primary.dark}`,
    width: '100%',
    '&:hover': {
      background: `${theme.palette.primary.lighten5}`,
    },
  },
  label: {
    textTransform: 'capitalize',
    color: 'white',
    fontSize: '0.75rem',
    fontWeight: 500,
    fontFamily: 'Noto Sans HK,sans-serif !important',
  },
})(Button)

export const StyledFilterMobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0 1.25rem;
`
export const StyledParentReportMenu = styled.div`
  margin-left: 1rem;
`

export const StyledFieldset = styled.fieldset`
  padding: 0;
  border: unset;
  legend {
    width: 100%;
    ${filterColumnHeading};
    margin-bottom: 0.5rem;
    @media (max-width: 800px) {
      margin-bottom: 0.75rem;
    }
  }
`

export const FilterContainer = styled.div`
  padding-left: 0.5rem;
  max-width: 6rem;

  @media ${CUSTOM_MOBILE_TABLET_MEDIA_QUERY} {
    max-width: initial;
  }

  @media ${MOBILE_MEDIA_QUERY} {
    max-width: 12rem;
  }

  @media only screen and (min-width: ${ssTheme.breakpoints.values.md}px) {
    max-width: 10rem;
  }

  @media only screen and (min-width: ${ssTheme.breakpoints.values.lg}px) {
    max-width: 12rem;
  }
`

StyledFeatureFormControl.displayName = 'StyledFeatureFormControl'
StyledFeaturedMenuItem.displayName = 'StyledFeaturedMenuItem'
