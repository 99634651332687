import React from 'react'
import {
  ProgressSideBoxStyled,
  BorderLinearProgress,
  StyledInfoBox,
  StyledBarBox,
  StyledBarCompleted,
} from 'Components/Shared/ProgressSideBar/style'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledParagraph } from 'Components/Screener/style'
import theme from 'Shared/Theme/ssTheme'
import { CompletedIcon } from 'Components/Shared/ProgressSideBar/Icons/PathIcons'

const exceptionSteps = [
  { stepNumber: 271, stoppingStep: 204 },
  { stepNumber: 272, stoppingStep: 204 },
  { stepNumber: 163, stoppingStep: 113 },
  { stepNumber: 275, stoppingStep: 215 },
]
const ProgressSideBarBox = ({
  category,
  selectedCategory,
  preProgressPercentage,
  stepsArray,
  currentStep,
}) => {
  let percentage = 0
  const filteredStepsArray = stepsArray.filter(
    (step) => step.category.id === category.id
  )
  const isCompleted = selectedCategory.id > category.id
  const targetStep =
    exceptionSteps.find(({ stepNumber }) => stepNumber === currentStep)
      ?.stoppingStep || currentStep
  const indexOfCurrentStep = filteredStepsArray.findIndex(
    (step) => step.stepNumber === targetStep
  )
  if (indexOfCurrentStep > -1 && selectedCategory.id === category.id)
    percentage = (indexOfCurrentStep / (filteredStepsArray.length + 1)) * 100

  if (selectedCategory.id === category.id && preProgressPercentage)
    percentage = preProgressPercentage

  return (
    <ProgressSideBoxStyled
      opacity={category.id > selectedCategory.id ? '.5' : '1'}
    >
      <StyledInfoBox>
        <Icon
          scale={'2rem'}
          viewBox={category.viewBox}
          margin='auto'
          fill={
            category.id === selectedCategory.id
              ? theme.palette.primary.dark
              : ''
          }
        >
          {category.icon}
        </Icon>
        <StyledParagraph
          fontSize='0.75rem'
          fontWeight='500'
          textAlign='center'
          marginBottom='0'
          opacity='.5'
        >
          {category.name}
        </StyledParagraph>
      </StyledInfoBox>
      <StyledBarBox>
        {!isCompleted && category.progressBar && (
          <BorderLinearProgress
            variant='determinate'
            value={percentage}
            aria-label='Progressbar'
          />
        )}
        {isCompleted && category.progressBar && (
          <StyledBarCompleted>
            <BorderLinearProgress
              variant='determinate'
              value={100}
              aria-label='Progressbar'
            />
            <CompletedIcon />
          </StyledBarCompleted>
        )}
      </StyledBarBox>
    </ProgressSideBoxStyled>
  )
}

export default ProgressSideBarBox
