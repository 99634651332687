import React from 'react'
import SelectMenuField from './SelectMenuField'
import SelectMenuFieldArray from './SelectMenuFieldArray'

const SelectMenu = (props) => {
  return props.iterable ? (
    <SelectMenuFieldArray {...props} />
  ) : (
    <SelectMenuField {...props} />
  )
}

export default SelectMenu
