import styled from 'styled-components/macro'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
    '& div:first-of-type > div': {
      textTransform: 'capitalize',
    },
    '& select': {
      color: '#29283E',
      fontSize: '.875rem',
      fontWeight: '400',
      fontFamily: 'Noto Sans HK,sans-serif',
    },
    '& svg': {
      top: 'calc(38% - .75rem)',
    },
    '&&:hover': {
      borderColor: 'rgb(0, 0, 0)',
    },
  },
})(FormControl)

export const StyledSelect = styled(CustomDropdown)`
  padding: 14px;
  font-size: 0.96rem;
`

export const StyledSelectMenuWrapper = styled.div`
  margin-top: 0rem;
`

export const StyledPlaceholderSpan = styled.span`
  color: ${(props) => props.theme.text.default};
`

export const StyledMenuItem = MenuItem

export const StyledMenuItemBlank = withStyles({
  root: {
    height: 37,
  },
})(MenuItem)
