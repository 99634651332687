import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import * as u from 'Shared/Theme/utilities.styles'

import Polygon1 from 'Components/LandingPage/Assets/Polygon1.svg'
import KindsDots from 'Components/LandingPage/Assets/KindsDots.svg'

export const StyledPolyShape = styled.div`
  ${u.alignShapeImage}
  background-image: url(${Polygon1});
  background-repeat: no-repeat;
  background-position: 92% 123%;
  top: 0;
  right: 0;
`

export const StyledParentWithBG = styled.div`
  ${u.wd100}
  ${u.hi100}
  background-image: url(${KindsDots});
  background-repeat: no-repeat;
  background-position: 50% 20%;
  ${media.smaller`
    background-position: 100% 15%;
    background-image: unset;
  `}
`

export const StyledParentElemImg = styled.div`
  ${u.wd100}
  ${u.hi100}
  ${u.dFlex}
  ${u.justifyContentCenter}
  ${u.alignItemsCenter}
  ${u.flexCol}
`

StyledPolyShape.displayName = 'StyledPolyShape'
StyledParentWithBG.displayName = 'StyledParentWithBG'
StyledParentElemImg.displayName = 'StyledParentElemImg'
