import React from 'react'
import { StyledCard, StyledCardTitle, StyledImage } from './ResourcesCard.style'

export const ResourceCard = (props) => {
  const { title, imageSrc, imageAlt, transform, forwardRef } = props

  return (
    <StyledCard ref={forwardRef} style={{ transform }}>
      <StyledCardTitle>{title}</StyledCardTitle>
      <StyledImage src={imageSrc} alt={imageAlt ? imageAlt : title} />
    </StyledCard>
  )
}
