import algoliasearch from 'algoliasearch'
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  ALGOLIA_INDEX_NAME,
} from 'Shared/constants'
import { loadingStarted, loadingStopped } from 'Reducers/uiSlice'
import { isJestTest } from 'Shared/helpers'

/**
 * If algoliasearch is used to perform a search outside this method,
 * the loading indicator won't show.
 *
 * @param {String} query - the text to search
 * @param {Object} params - facets, geolocation, etc
 * @param {String} indexName - the index to search
 * @returns {function(*, *): *}
 */
export const fetchAlgolia =
  (query, params, indexName = ALGOLIA_INDEX_NAME) =>
  (dispatch) => {
    const success = (response) => {
      dispatch(loadingStopped())
      return response?.hits
    }
    const error = (error) => {
      dispatch(loadingStopped())
      if (!isJestTest()) console.error(error)
    }

    dispatch(loadingStarted())

    return algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)
      .initIndex(indexName)
      .search(query, params)
      .then(success)
      .catch(error)
  }
