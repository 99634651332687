import { useSelector } from 'react-redux'
import {
  ALTERNATE_CONTACT_MUTATION,
  QUERY_GET_RESPONSES,
} from 'Pages/Review/gql'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { getReviewSections } from '../helpers'
import useHandlePatchClient from 'Components/Shared/Hooks/useHandlePatchClient'
import { CUSTOM_FIELDS_MUTATION } from 'Components/Screener/Hooks/gql'
import { isJestTest } from 'Shared/helpers'

/**
 * Provides formatted review section data
 */
export const useReviewSections = () => {
  const { data, refetch, loading, error } = useQuery(QUERY_GET_RESPONSES, {
    variables: { id: useSelector((state) => state.client.id) },
    // we sould use no-cache option when testing graphQL as it has errors while mocking with cache options
    fetchPolicy: isJestTest() ? 'no-cache' : 'cache-and-network',
  })
  const handlePatchClient = useHandlePatchClient()
  const [saveCustomFields] = useMutation(CUSTOM_FIELDS_MUTATION)
  const [saveAlternateContactInfo] = useMutation(ALTERNATE_CONTACT_MUTATION)
  return {
    sections: getReviewSections(
      data,
      handlePatchClient,
      saveCustomFields,
      saveAlternateContactInfo
    ),
    refetch,
    legacy: data?.client?.screening?.legacy,
    loading,
    error,
  }
}

/**
 * Given the section index, scrolls the sections grid to bring the section
 * to the top of the page.
 *
 * @param {Number} sectionIndex
 */
export const useReviewSectionNavigation = (sectionIndex) => {
  const container = useRef(null)
  const grid = useRef(null)

  useEffect(() => {
    if (sectionIndex !== -1 && grid.current && container.current) {
      container.current.scrollTop =
        grid.current.children[sectionIndex].offsetTop - grid.current.offsetTop
    }
  }, [container, grid, sectionIndex])

  return [container, grid]
}
