import styled from 'styled-components/macro'

type StyledHeaderProps = {
  backgroundColor?: string
  padding?: string
  actionType?: string
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  text-align: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.background.alt};
  color: ${(props) => props.theme.text.default};
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 400;
  padding: ${(props) =>
    props.actionType === 'HEADER' ? props.padding || '1.6rem' : '0 1rem'};
  z-index: 1;
  min-height: fit-content;
`

type BrandStyleProps = {
  alignItems?: string
  justifyContent?: string
}

export const BrandStyle = styled.h1<BrandStyleProps>`
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
`
export const StyledTabletHeader = styled(StyledHeader)`
  padding: 3.2rem 0 0;
`

export const StyledSubheader = styled.div`
  background-color: ${(props) => props.theme.background.secondary};
  display: flex;
  flex-direction: row-reverse;
  flex: 1 0 auto;
  align-items: center;
  padding: 0rem 0.8rem;
`

export const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.brand.default};
  font-size: 2.8rem;
  font-weight: 500;
  margin-top: 1.6rem;
`

StyledHeader.displayName = 'StyledHeader'
StyledTabletHeader.displayName = 'StyledTabletHeader'
StyledSubheader.displayName = 'StyledSubheader'
StyledLogo.displayName = 'StyledLogo'
