import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { emailOrPhone as selectEmailOrPhone } from 'Selectors'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import {
  StyledDynamicCtaButton,
  StyledForm,
  StyledHeadingMain,
  StyledSubHeading,
  StyledParagraph,
} from 'Components/Screener/style'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import { setFocusOnFirstError } from 'Shared/helpers'
import { parseEmailOrPhone } from '../PreScreener/helpers'
import useOtp from 'Components/Auth/Hooks/useOtp'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import PreScreenerBackButton from 'Components/PreScreener/PreScreenerBackButton'
import { useQuery } from '@apollo/client'
import { ME } from './Hooks/gql'
import { PreScreenerContext } from 'Pages/PreScreener/PreScreenerProvider'
import { StyledSkipVerificationButton } from './style'

const ClientAuth = ({ error, handleSubmit }) => {
  const { data } = useQuery(ME)

  const focusRef = useRef(null)

  const context = useContext(PreScreenerContext)

  const setPreScreenerState = context?.setPreScreenerState

  const { handleRequestOtp } = useOtp()

  const isACaseManager = data && data.me.isACaseManager

  useEffect(() => {
    setFocusToMainHeading()
    setTimeout(() => {
      document.getElementById('otp').focus()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const emailOrPhone = selectEmailOrPhone(useSelector((state) => state))

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const handleResendOtp = () => {
    handleRequestOtp(parseEmailOrPhone({ emailOrPhone }))
  }

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm
            padding={'2rem 0 0'}
            onSubmit={handleSubmit}
            noValidate
            className='cypress-otp-form'
          >
            <StyledHeadingMain id='mainContent' tabIndex='-1' ref={focusRef}>
              Verify Your Account
            </StyledHeadingMain>
            <StyledParagraph>
              Please take a moment to verify your contact information. This
              helps us to confirm your identity and secure your account.
            </StyledParagraph>
            <StyledSubHeading>
              Please enter the verification code sent to:
            </StyledSubHeading>
            <StyledSubHeading>{emailOrPhone}</StyledSubHeading>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg='12'>
                      <StyledMuiTextFieldWrapper
                        className='cypress-client-auth'
                        width='100%'
                        marginRight='auto'
                        marginLeft='auto'
                        mbottom='0'
                      >
                        <Field
                          name='otp'
                          type='text'
                          component={TextField}
                          label='Verification Code'
                        />
                        <FormSubmissionError error={error} />
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>
            <StyledHouseholdMembersList textalign='center'>
              <Row>
                <Col lg='12'>
                  <ColDynamicCtaButton />
                  <PreScreenerBackButton width='100%' margin='1rem auto 0' />
                  <StyledDynamicCtaButton
                    width='100%'
                    $hasExtraButton
                    type='button'
                    onClick={handleResendOtp}
                    margin='1rem 0'
                  >
                    Resend Code
                  </StyledDynamicCtaButton>
                </Col>
              </Row>
            </StyledHouseholdMembersList>
          </StyledForm>
          {/* ToDo: Style this **/}
          {isACaseManager && (
            <p>
              Verify Later
              <StyledSkipVerificationButton
                onClick={() => {
                  setPreScreenerState({ modal: 'SKIP_VERIFY' })
                }}
              >
                Skip Verification
              </StyledSkipVerificationButton>
            </p>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validators.otp,
  onSubmitFail: setFocusOnFirstError,
})(ClientAuth)
