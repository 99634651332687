import { createTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

export const mainMuiTheme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: grey,
  },
})

export const caseManagementMuiTheme = createTheme({
  typography: {
    htmlFontSize: 18,
  },
  palette: {
    primary: {
      main: '#0d8762',
    },
  },
})
