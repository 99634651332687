import styled from 'styled-components/macro'

export const StyledGrid = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
`
export const StyledLeftPanel = styled.div`
  margin: auto;
  text-align: center;
  vertical-align: middle;
  grid-row: 1 / 3;
  padding-right: 0.725rem;
`

export const StyledValue = styled.div`
  margin-top: 0.85rem;
  font: normal normal bold 1.4rem/1.0625rem Roboto;
  letter-spacing: 0;
  color: #252529;
  display: flex;
  align-items: center;
`

export const StyledLabel = styled.div`
  margin-top: 0.35rem;
  font: normal normal normal 0.825rem/1.0625rem Roboto;
  letter-spacing: 0;
  color: #252529;
  opacity: 0.7;
`
