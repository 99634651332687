import styled from 'styled-components/macro'

export const StyledActionBar = styled.div`
  align-items: center;
  background-color: ${(props) => {
    if (props.backgroundColor) return props.backgroundColor
    const { actionType, theme } = props
    const { alt, default: _default, secondary } = theme.background

    switch (actionType) {
      case 'ACTIONS':
      case 'HEADER':
        return secondary
      case 'MENU':
        return alt
      default:
        return _default
    }
  }};
  display: flex;
  flex: 0 0;
  flex-direction: ${(props) => {
    const { actionType } = props

    switch (actionType) {
      case 'HEADER':
        return 'row-reverse'
      default:
        return 'row'
    }
  }};
  justify-content: ${(props) => {
    const { actionType } = props

    switch (actionType) {
      case 'ACTIONS':
        return 'space-between'
      default:
        return 'flex-start'
    }
  }};
  padding: ${(props) => (props.padding ? props.padding : '0 1.6rem')};
  text-transform: ${(props) => {
    const { actionType } = props

    switch (actionType) {
      case 'HEADER':
      case 'MENU':
        return 'uppercase'
      default:
        return 'capitalize'
    }
  }};
  margin-bottom: ${(props) => props.marginBottom || 0};
  border-radius: ${(props) => props.borderRadius};
  &:hover {
    cursor: pointer;
  }
`

StyledActionBar.displayName = 'StyledActionBar'
