import React, { useEffect, useRef } from 'react'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'

import {
  StyledForm,
  StyledHeadingMain,
  StyledLink,
  StyledParagraph,
  StyledScreeningPairSvg,
} from 'Components/Screener/style'

import { StyledTermsAndButtonToContinueWrapper } from './style'
import ScreeningPairSvg from 'Pages/PreScreener/Assets/stopwatch.svg'

import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import PreScreenerBackButton from 'Components/PreScreener/PreScreenerBackButton'
import CheckBoxInput from '../Shared/CheckboxInput/CheckboxInput'

import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import { connect } from 'react-redux'
import { setFocusOnFirstError } from 'Shared/helpers'

const validate = (values) => {
  const errors = {}
  if (!values.agreeToTerms) {
    errors.agreeToTerms = 'You must check the box above to proceed'
  }
  return errors
}

const PreScreenerSectionIntroduction = (props) => {
  const { handleSubmit, outlined, error } = props

  const focusRef = useRef(null)

  useEffect(() => {
    setFocusToMainHeading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }
  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='10'>
          <StyledForm
            padding={'2rem 0 0'}
            textalign={'center'}
            alignitems={'center'}
            justifycontent={'center'}
            onSubmit={handleSubmit}
            className='cypress-prescreen-intro-form'
          >
            <StyledParagraph
              introduction
              textalign='center'
              marginBottom='0.6875rem'
              lineheight={'1.5'}
            >
              To better assist you, we're going to ask you some quick questions.
              This typically takes
            </StyledParagraph>

            <StyledHeadingMain
              id='mainContent'
              tabIndex='-1'
              textalign={'center'}
              ref={focusRef}
            >
              10 to 15 minutes to complete.
            </StyledHeadingMain>

            <StyledScreeningPairSvg
              margin='auto auto 4rem'
              alt='single stop case workers'
              src={ScreeningPairSvg}
            />

            <StyledParagraph>
              Don't worry, we'll keep it confidential.
            </StyledParagraph>

            <StyledTermsAndButtonToContinueWrapper>
              <StyledParagraph
                textalign='center'
                fontSize='1rem'
                marginBottom='0'
              >
                By proceeding, you agree to the{' '}
                <StyledLink target='_blank' href='/privacy-policy'>
                  Privacy Policy
                </StyledLink>{' '}
                and{' '}
                <StyledLink target='_blank' href='/terms-and-conditions'>
                  Terms and Conditions.
                </StyledLink>
              </StyledParagraph>
              <Field
                name='agreeToTerms'
                component={CheckBoxInput}
                label='I have read and agree to the Privacy Policy and Terms and Conditions.'
                type='checkbox'
              />
              <FormSubmissionError error={error} />
              <ColDynamicCtaButton />
              <PreScreenerBackButton
                outlined={outlined}
                width='100%'
                margin='1rem 0'
              />
            </StyledTermsAndButtonToContinueWrapper>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

const SectionIntroduction = reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerSectionIntroduction)

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues(PRE_SCREENER_FORM_NAME)(state) ?? {},
  }
}

export default connect(mapStateToProps)(SectionIntroduction)
