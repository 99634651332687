import React from 'react'
import MediaQuery from 'react-responsive'

import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'
import LocationList, {
  LocationsListProps,
} from 'Components/LocalResources/LocationsList/LocationsList'

const LocationsList = (props: LocationsListProps) => {
  const { showFilterMenu, showMainMenu } = useAppSelector((state) => state.ui)

  const hideMobileContent = showFilterMenu || showMainMenu

  const renderMobile = () => {
    if (hideMobileContent) {
      return null
    } else {
      return <LocationList {...props} />
    }
  }

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>{renderMobile()}</MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <LocationList {...props} />
      </MediaQuery>
    </>
  )
}

export default LocationsList
