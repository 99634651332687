import React from 'react'
import { Button } from '@material-ui/core'
import { SaveIcon } from 'Components/Review/assets'

const SaveButton = ({ handleSubmit, handleCancel }) => (
  <>
    <Button className='cancel-button' onClick={handleCancel}>
      Cancel
    </Button>
    <Button
      data-testid='saveButton'
      startIcon={SaveIcon}
      onClick={handleSubmit}
    >
      Save
    </Button>
  </>
)

export default SaveButton
