import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeoutModal from 'Components/Shared/IdleTimeoutModal/IdleTimeoutModal'
import { useQuery } from '@apollo/client'
import { ME } from 'Components/Auth/Hooks/gql'
import { ProtectedRouteContext } from './ProtectedRouteProvider'

const ProtectedRoute = ({ conditional, redirectUrl, timeout, children }) => {
  const [showModal, setShowModal] = useState(false)
  const { data } = useQuery(ME)

  const handleIdle = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const { reset } = useIdleTimer({
    timeout: timeout,
    onIdle: handleIdle,
    events: ['apiRequest'],
  })

  return (
    <>
      {conditional() ? (
        <>
          <IdleTimeoutModal
            open={showModal}
            handleClose={handleClose}
            redirectUrl={redirectUrl}
            reset={reset}
          />
          <ProtectedRouteContext.Provider value={data}>
            {children}
          </ProtectedRouteContext.Provider>
        </>
      ) : (
        <Navigate to={redirectUrl} replace='false' />
      )}
    </>
  )
}

export default ProtectedRoute
