import React from 'react'

import { updateQuickFilterSelected } from 'Actions/filters'

import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import Icon from 'Components/Shared/Icon/Icon'
import Checkbox from './Checkbox'

import {
  StyledCategoryFilterItem,
  StyledCategoryFilterItemLabel,
} from './style'
import { useAppDispatch } from 'Store/hooks'

type CategoryFilterItemProps = {
  category: {
    name: string
    selected: boolean
  }
  id: string
}

const CategoryFilterItem = (props: CategoryFilterItemProps) => {
  const { category, id } = props
  const { name, selected } = category
  const dispatch = useAppDispatch()

  const iconPath =
    categoryIconPathsMap[+id as keyof typeof categoryIconPathsMap]

  return (
    <StyledCategoryFilterItem
      margin={'0 0 0.625rem'}
      padding='0 16px'
      onClick={() => dispatch(updateQuickFilterSelected(+id))}
    >
      <Checkbox checked={selected} />
      <Icon
        key={id}
        id={id}
        radius={'0'}
        scale={'2.8rem'}
        padding={'0'}
        border={'0'}
        margin={'0 0.625rem 0 0'}
        fill={'auto'}
        screenReaderLabel={name}
      >
        {iconPath}
      </Icon>
      <StyledCategoryFilterItemLabel htmlFor={name}>
        {name}
      </StyledCategoryFilterItemLabel>
    </StyledCategoryFilterItem>
  )
}

export default CategoryFilterItem
