import styled from 'styled-components/macro'

export const SelectBtn = styled.button`
  background: unset;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  border: 1px solid #dddddd;
  padding: 0 15px;
  border-radius: 0.4375rem;
  min-height: 3.375rem;
  font-weight: 500;
`

export const SelectDropDown = styled.div`
  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 7px;
    border: 0.4rem solid #dddddd;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #dbdbdb;
  }
`

SelectBtn.displayName = 'SelectBtn'
SelectDropDown.displayName = 'SelectDropDown'
