import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'

const navLinkStyling = css`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex-direction: ${(props) =>
    props.flexdirectton ? props.flexdirectton : 'column'};
  align-items: ${(props) => props.alignitems};
  background: inherit;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  color: ${(props) => (props.color ? props.color : theme.palette.text.default)};
  font-size: ${(props) => props.fontSize};
  padding: ${(props) => props.padding || 0};
  position: ${(props) => props.position};
`

export const StyledDesktopAndTabletTopNavMenuItem = styled(NavLink)`
  ${navLinkStyling}
  ${(props) =>
    props.activelink === 'active'
      ? `&::after{
        content: '';
        width: 78%;
        height: 3px;
        background: ${theme.palette.primary.dark};
        display: flex;
        position: absolute;
        bottom: 0;
      }`
      : ''};
  &:hover {
    color: ${theme.palette.primary.dark};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`

export const StyledDesktopAndTabletTopNavMenuItemLabel = styled.div`
  font-weight: ${(props) => props.fontWeight};
  margin: 0;
  padding: ${(props) => (props.padding ? props.padding : '0 1rem')};
  ${({ addClient }) =>
    addClient &&
    css`
      padding: 0;
      color: #fff;
      font-weight: 400;
      &:hover {
        cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
      }
    `}
`
