import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, getFormValues } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import SelectMenuMuiRenderField from 'Components/Shared/ReduxForm/SelectMenuMuiRenderField'
import InputRenderField from 'Components/Shared/ReduxForm/InputRenderField'

import CalendarDays from 'Components/Shared/CalendarDays/CalendarDays'
import CalendarMonths from 'Components/Shared/CalendarMonths/CalendarMonths'
import CalendarYears from 'Components/Shared/CalendarYears/CalendarYears'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'

import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'

import {
  StyledForm,
  StyledHeadingMain,
  StyledParagraph,
  StyledFieldset,
} from 'Components/Screener/style'

import { setFocusOnFirstError } from 'Shared/helpers'
import {
  AGE_LIMIT_ERROR,
  getBirthdayErrors,
  userBirthdayUnderage,
  userUnderage,
} from 'Components/Shared/ReduxForm/helpers'
import PreScreenerActions from 'Components/PreScreener/PreScreenerActions'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'

const validate = (values) => {
  let errors = getBirthdayErrors(values)

  if (userUnderage(values)) {
    errors.age = AGE_LIMIT_ERROR
  } else if (userBirthdayUnderage(values)) {
    errors.birthYear = AGE_LIMIT_ERROR
  }

  return errors
}

const BirthDayField = ({ name, label, options, className }) => {
  return (
    <Col lg='4'>
      <StyledMuiTextFieldWrapper className={className}>
        <Field
          name={name}
          component={SelectMenuMuiRenderField}
          label={label}
          type='text'
        >
          {options()}
        </Field>
      </StyledMuiTextFieldWrapper>
    </Col>
  )
}

const PreScreenerBirthday = (props) => {
  const { handleSubmit, fieldValues, error } = props

  const { first_name, last_name, nick_name } = fieldValues

  const name = nick_name ? nick_name : `${first_name} ${last_name}`

  const focusRef = useRef(null)

  useEffect(() => {
    setFocusToMainHeading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm
            padding={'2rem 0 0'}
            onSubmit={handleSubmit}
            className='cypress-prescreen-birthday-form'
          >
            <StyledHeadingMain id='mainContent' tabIndex='-1' ref={focusRef}>
              {`Perfect! It's nice to meet you, ${name}.`}
            </StyledHeadingMain>
            <StyledFieldset>
              <legend>When is your birthday?</legend>
              <StyledHouseholdMembersListItem margin='0'>
                <MuiThemeProvider theme={ssTheme}>
                  <StyledHouseholdMembersList>
                    <Row>
                      <BirthDayField
                        name={`birthMonth`}
                        label={'Month'}
                        options={CalendarMonths}
                        className='cypress-birth-month'
                      />
                      <BirthDayField
                        name={`birthDay`}
                        label={'Day'}
                        options={CalendarDays}
                        className='cypress-birth-day'
                      />
                      <BirthDayField
                        name={`birthYear`}
                        label={`Year`}
                        options={CalendarYears}
                        className='cypress-birth-year'
                      />
                    </Row>
                  </StyledHouseholdMembersList>
                </MuiThemeProvider>
              </StyledHouseholdMembersListItem>
            </StyledFieldset>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg='12'>
                      <StyledParagraph marginBottom='1.375rem'>
                        Not comfortable sharing your full date of birth?
                      </StyledParagraph>
                      <StyledParagraph marginBottom='.625rem'>
                        Let us know how old you are.
                      </StyledParagraph>
                    </Col>
                    <Col lg='12'>
                      <StyledMuiTextFieldWrapper
                        mbottom='0'
                        fullWid
                        className='cypress-age'
                      >
                        <Field
                          name='age'
                          type='text'
                          component={InputRenderField}
                          label='Age'
                          props={{
                            numberFormat: 'integer',
                          }}
                          normalize={formatters.ageToNumber}
                        />
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
              <StyledHouseholdMembersListItem margin='0'>
                <FormSubmissionError error={error} />
              </StyledHouseholdMembersListItem>
            </StyledHouseholdMembersListItem>
            <PreScreenerActions
              continueButtonWidth={'31%'}
              backButtonWidth={'31%'}
            />
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

const PreScreenerBirthdayForm = reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerBirthday)

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues(PRE_SCREENER_FORM_NAME)(state) ?? {},
  }
}

export default connect(mapStateToProps)(PreScreenerBirthdayForm)
