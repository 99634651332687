import React, { useEffect } from 'react'
import { filesVar } from 'ApolloClient/caseManagementCache'
import { showFileUploadModalVar } from 'ApolloClient/caseManagementCache'
import { Dashboard } from '@uppy/react'

// We used uppy on the frontend with S3 preuploading
// and shrinerb on the backend
// See the Janko blog for S3 uploading
// https://uppy.io/examples/dashboard/
// https://janko.io/better-file-uploads-with-shrine-direct-uploads/
const DashboardWrapper = ({ uppy }) => {
  useEffect(() => {
    uppy.on('complete', handleCompleteUpload)

    return () => {
      uppy.off('complete', handleCompleteUpload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCompleteUpload = (result) => {
    const uploadedFiles = result.successful

    uploadedFiles.forEach((file) => {
      const [storage, key] = file.meta.key.split('/')
      const uppyId = file.id
      const size = file.size
      const fileName = file.meta.name
      const mimeType = file.type
      const etag = file.meta['x-amz-signature']

      const filesArray = [...filesVar()]

      filesVar([
        ...filesArray,
        {
          uppyId,
          key,
          storage,
          fileName,
          size,
          mimeType,
          etag,
        },
      ])
    })

    showFileUploadModalVar(false)
  }

  return (
    <Dashboard
      uppy={uppy}
      metaFields={[{ id: 'name', name: 'Name', placeholder: 'file name' }]}
    />
  )
}

export default DashboardWrapper
