import React from 'react'
import { StyledFormControl } from 'Components/Shared/ReduxForm/SelectMenu/style'
import FormError from 'Components/Shared/FormError/FormError'
import { StyledSelect, StyledOption } from './style'
import { stringToKebabCase } from 'Shared/helpers'

const ListInput = ({
  id,
  input: { onChange, value, ...rest },
  meta: { touched, error, submitError },
  name,
  options,
  dataTestId,
  inputLabelText = 'Select an Option',
  placeholder = '',
}) => {
  return (
    <StyledFormControl
      variant='standard'
      fullWidth
      error={touched && (!!error || !!submitError)}
    >
      <StyledSelect
        {...rest}
        id={name}
        labelId={`${name}-label`}
        label={value ? '' : inputLabelText}
        dataTestId={dataTestId}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={error && touched}
        name={name}
      >
        {/* prepend the options with an empty string so that the first one does not appear as if it is selected */}
        {[placeholder, ...options].map((option, i) => {
          const optionType = typeof option
          const value = optionType === 'string' ? option : option?.value
          const label = optionType === 'string' ? option : option?.label
          return (
            <StyledOption
              key={i}
              value={value}
              id={stringToKebabCase(`${id}-${label}`)}
            >
              {label}
            </StyledOption>
          )
        })}
      </StyledSelect>
      <FormError touched={touched} error={error ?? submitError} />
    </StyledFormControl>
  )
}

export default ListInput
