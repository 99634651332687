import React, { useCallback, useEffect, useState } from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { GoogleMap, LoadScriptNext } from '@react-google-maps/api'

import {
  DESKTOP_MEDIA_QUERY,
  GOOGLE_API_KEY,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'

import { updateLocationId, updateShowLocationCard } from 'Reducers/uiSlice'

import LocationCard from 'Components/LocalResources/LocationCard/LocationCard'
import { StyledContainer } from './style'
import mapStyle from './mapStyle'
import DesktopSecondaryActionButton from 'Components/Shared/DesktopSecondaryActionButton/DesktopSecondaryActionButton'
import { updateCoordinates, updateZipCode } from 'Actions/geolocation'
import { updateQuery } from 'Actions/filters'
import theme from 'Shared/Theme/ssTheme'
import { StyledButton } from 'Pages/LocationRequest/style'
import Icon from 'Components/Shared/Icon/Icon'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import MarkerWithInfoWindow from './MarkerWithInfoWindow'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'Store/hooks'
import type {
  AlgoliaLocation,
  Coordinates,
} from 'Pages/Locations/AlgoliaLocation'

const mapOptions = {
  styles: mapStyle,
  disableDefaultUI: true,
  zoomControl: true,
}

type LocationsMapProps = {
  locationID?: string | number | undefined
  setLocationId?: (locationId: number) => void
  locations?: AlgoliaLocation[]
  showLocationCard?: boolean
  userLocation?: Coordinates
  zeroSearchResults?: boolean
  zipCode?: string
  modalLayout?: boolean
}

const LocationsMap = ({ modalLayout, setLocationId }: LocationsMapProps) => {
  const isMobileOrTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const [map, setMap] = useState<google.maps.Map>()
  const [isDragging, setIsDragging] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const {
    showLocationCard,
    showZeroSearchResultsMessage: zeroSearchResults,
    map: { locationID },
  } = useAppSelector((state) => state.ui)
  const locationsArray: AlgoliaLocation[] = useAppSelector((state) =>
    Object.values(state.locations.searchResults)
  )
  const { coordinates: userLocation, zipCode } = useAppSelector(
    (state) => state.geolocation
  )
  const query = useAppSelector((state) => state.filters.query)

  useEffect(() => setIsDragging(false), [zipCode])

  const locationsWithCoordinates = useCallback(() => {
    return locationsArray.filter((location) => {
      const { coordinates } = location
      return coordinates.lat && coordinates.lng
    })
  }, [locationsArray])

  const updateBounds = useCallback(
    (map: google.maps.Map) => {
      if (!isDragging) {
        const { google } = window
        const bounds = new google.maps.LatLngBounds()
        const locations = locationsWithCoordinates()

        if (zeroSearchResults && userLocation) {
          const { lat, lng } = userLocation
          if (lat && lng) {
            bounds.extend(userLocation)
            map.fitBounds(bounds)
            map.setZoom((map.getZoom() || 0) - 4)
          }
        } else {
          locations.forEach((location) => {
            const { coordinates } = location
            bounds.extend(coordinates)
          })
          map.fitBounds(bounds)
        }
      }
    },
    [locationsWithCoordinates, userLocation, zeroSearchResults, isDragging]
  )

  const handleOnLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map)
      map.setOptions({ styles: mapStyle, disableDefaultUI: true })
      updateBounds(map)
    },
    [updateBounds]
  )

  const updateLocation = (id: number | null) => {
    dispatch(updateLocationId(id))
  }

  useEffect(() => {
    if (map) {
      updateBounds(map)
    }
  }, [updateBounds, map])

  const handleDesktopMarkerClick = (
    _event: google.maps.MapMouseEvent,
    coordinates: Coordinates,
    id: number
  ) => {
    map!.panTo(coordinates)
    updateLocation(id)
    if (setLocationId) setLocationId(id)
    navigate(`/locations/${id}`, { replace: true })
  }

  const handleMobileAndTabletMarkerClick = (
    _event: google.maps.MapMouseEvent,
    coordinates: Coordinates,
    id: number
  ) => {
    map!.panTo(coordinates)
    updateLocation(id)
    if (updateShowLocationCard) {
      dispatch(updateShowLocationCard(true))
    }
  }

  const renderMarkers = (device?: string) => {
    const locations = locationsWithCoordinates()

    const locationMarkers = locations.map((location) => {
      const { coordinates, id, singleStop } = location
      const currentSingleStopLocationIcon =
        id === locationID
          ? 'preferredPartnerSelected.svg'
          : 'starPreferredPartner.svg'
      const currentLocationIcon =
        id === locationID ? 'Active.svg' : 'Normal.svg'

      const icon = singleStop
        ? `${window.location.origin}/map/${currentSingleStopLocationIcon}`
        : `${window.location.origin}/map/${currentLocationIcon}`

      const clickHandler =
        device === 'desktop'
          ? handleDesktopMarkerClick
          : handleMobileAndTabletMarkerClick
      return (
        <MarkerWithInfoWindow
          key={id}
          device={device}
          coordinates={coordinates}
          icon={icon}
          onClick={(event) => clickHandler(event, coordinates, id)}
          location={location}
        />
      )
    })

    return locationMarkers
  }

  const findLocation = () => {
    return locationsArray.find((location) => location.id === locationID)
  }

  const renderLocationCard = () => {
    if (showLocationCard && locationID) {
      const location = findLocation()
      return (
        <LocationCard
          withBorder
          className={'cypress-map-location-card'}
          bottom={0}
          location={location}
          margin={0}
          position={'absolute'}
          width={'100%'}
          parentBackground={'unset'}
          parentBottom={'10rem'}
          parentLeft={'0'}
          parentRight={'0'}
          childMinHeight={'auto'}
          childWidth={'80%'}
          childMargin={'auto'}
          childBackground={'#fff'}
          childRadius={'13px'}
          childShadow={'2px 6px 12px #00000024'}
          modalLayout={modalLayout}
        />
      )
    } else {
      return null
    }
  }

  const clearZipcodeFromSearch = () => {
    const zipCodeRegex = /(\d{5})/g
    const match = query.match(zipCodeRegex)
    if (match) {
      let zipcodeLessQuery = query.replace(match[0], '')
      dispatch(updateZipCode(''))
      dispatch(updateQuery(zipcodeLessQuery))
    }
  }

  const panToCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        map!.setZoom(18)
        map!.setCenter(pos)
      })
    }
  }

  const CurrentLocate = () => {
    return (
      <StyledButton
        mylocation
        className={'cypress-share-my-location'}
        type='button'
        onClick={panToCurrentLocation}
      >
        <Icon
          viewBox={'0 0 24 24'}
          scale={'1.7rem'}
          padding={'0'}
          margin={'0'}
          cursorOnHover={'pointer'}
        >
          <path className='a' fill='none' d='M0,0H24V24H0Z' />
          <path
            className='b'
            fill='#848484'
            d='M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm8.94,3A8.994,8.994,0,0,0,13,3.06V1H11V3.06A8.994,8.994,0,0,0,3.06,11H1v2H3.06A8.994,8.994,0,0,0,11,20.94V23h2V20.94A8.994,8.994,0,0,0,20.94,13H23V11H20.94ZM12,19a7,7,0,1,1,7-7A6.995,6.995,0,0,1,12,19Z'
          />
        </Icon>
        <SrOnly>Find My location</SrOnly>
      </StyledButton>
    )
  }

  const renderMap = (device?: string) => {
    return (
      <LoadScriptNext id='script-loader' googleMapsApiKey={GOOGLE_API_KEY!}>
        <>
          <GoogleMap
            id='locations-map'
            options={mapOptions}
            mapContainerStyle={{
              height: isMobileOrTablet
                ? `calc(100vh - ${theme.layout.headerHeight} - 8rem)`
                : `calc(100vh - ${theme.layout.headerHeight})`,
            }}
            onClick={() => {
              updateLocation(null)
              dispatch(updateShowLocationCard(false))
            }}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => {
              const c = map!.getCenter()
              const lat = c?.lat()
              const lng = c?.lng()
              const coordinates = { lat, lng }
              clearZipcodeFromSearch()
              dispatch(updateCoordinates(coordinates as Coordinates))
            }}
            onLoad={handleOnLoad}
          >
            {renderMarkers(device)}
          </GoogleMap>
          <CurrentLocate />
        </>
      </LoadScriptNext>
    )
  }

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledContainer className={'cypress-locations-map'}>
          {renderMap()}
          {renderLocationCard()}
        </StyledContainer>
      </MediaQuery>

      <MediaQuery query={TABLET_MEDIA_QUERY}>
        <StyledContainer className={'cypress-locations-map'}>
          {renderMap('tablet')}
          {renderLocationCard()}
        </StyledContainer>
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        <StyledContainer className={'cypress-locations-map'}>
          {renderMap('desktop')}
          <DesktopSecondaryActionButton />
        </StyledContainer>
      </MediaQuery>
    </>
  )
}

export default LocationsMap
