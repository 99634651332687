import {
  defaultCheckboxMap,
  householdMembersExcludingClientMap,
  householdMembersSelectorMap,
} from 'Components/Screener/helpers'
import {
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
} from './constants/checkboxes'

/**
 * [AGE_LIMIT The age limit]
 * @type {Number}
 */
export const AGE_LIMIT = 13

/**
 * [AGE_LIMIT_ERROR Error displayed when under the age limit]
 * @type {String}
 */
export const AGE_LIMIT_ERROR = `You must be over ${AGE_LIMIT} years old to proceed`

/**
 * [AGE_ERROR_NO_AGE_OR_DATE_OF_BIRTH
 *   Error shown when no age or date of birth is entered]
 * @type {String}
 */
export const AGE_ERROR_NO_AGE_OR_DATE_OF_BIRTH =
  'Please provide an age if you are not sharing the full date of birth.'

/**
 * [AGE_ERROR_NO_BIRTH_MONTH Error shown when the month is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_MONTH = 'Enter birth month'

/**
 * [AGE_ERROR_NO_BIRTH_DAY Error shown when the day is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_DAY = 'Enter birth day'

/**
 * [AGE_ERROR_NO_BIRTH_YEAR Error shown when the year is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_YEAR = 'Enter birth year'

/**
 * [AGE_BIRTHDAY_IN_FUTURE Error shown when a DOB is set in the future]
 * @type {String}
 */
export const AGE_ERROR_BIRTHDAY_IN_FUTURE =
  'Date of birth cannot be in the future'

/**
 * [AGE_ERROR_AGE_AND_DATE_OF_BIRTH Error shown if a user tries to enter both
 * a date of birth and an age]
 * @type {String}
 */
export const AGE_ERROR_AGE_AND_DATE_OF_BIRTH =
  'Please enter date of birth or age, not both'

/**
 * [FIELD_FIRST_NAME First and Last Name - First Name]
 * @type {String}
 */
export const FIELD_FIRST_NAME = 'first_name'

/**
 * [FIELD_AGE Birth Date and Age - Age]
 * @type {String}
 */
export const FIELD_AGE = 'age'

/**
 * [FIELD_BIRTH_MONTH Birth Date and Age - Month]
 * @type {String}
 */
export const FIELD_BIRTH_MONTH = 'birth_month'

/**
 * [FIELD_BIRTH_DAY Birth Date and Age - Day]
 * @type {String}
 */
export const FIELD_BIRTH_DAY = 'birth_day'

/**
 * [FIELD_BIRTH_YEAR Birth Date and Age - Year]
 * @type {String}
 */
export const FIELD_BIRTH_YEAR = 'birth_year'

/**
 * [REQUIRED Error shown for empty forms that are required]
 * @type {String}
 */
export const REQUIRED = 'Required'
export const CHECKBOXREQUIRED = 'A selection is required.'

/**
 * Calculates age based on Birthday
 *
 * @param   {Number} options.birthMonth  Birth Month
 * @param   {Number} options.birthDay    Birth Day
 * @param   {Number} options.birthYear   Birth Year
 * @return  {Number} options.age         Age
 */
export const getAge = ({ birthYear, birthMonth, birthDay }) => {
  const date_of_birth = new Date(`${birthYear}-${birthMonth}-${birthDay}`)
  var diffMs = Date.now() - date_of_birth.getTime()
  var ageDifference = new Date(diffMs)
  return ageDifference.getUTCFullYear() - 1970
}

/**
 * Detects lack of month, day, and year, and age
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @param  {Number} options.age         Age
 * @return {Boolean}                    True if Birthday and Age is missing
 */
const missingDateOfBirth = ({ birthMonth, birthDay, birthYear, age }) =>
  !birthMonth && !birthDay && !birthYear && !age

/**
 * Detects if birthdate and age are both present
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @param  {Number} options.age         Age
 * @return {Boolean}                    True if DOB and Age are present
 */
const hasAgeAndDateOfBirth = ({ birthMonth, birthDay, birthYear, age }) =>
  birthMonth && birthDay && birthYear && age

/**
 * Checks for missing birth month among month, day, year
 * @type {Boolean}
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @return {Boolean}                    True if Month is missing
 */
const missingBirthMonth = ({ birthMonth, birthDay, birthYear }) =>
  !birthMonth && (birthDay || birthYear)

/**
 * Checks for missing birth day among month, day, year
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @return {Boolean}                    True if Day is missing
 */
const missingBirthDay = ({ birthMonth, birthDay, birthYear }) =>
  !birthDay && (birthMonth || birthYear)

/**
 * Checks for missing birth year among month, day, year
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @return {Boolean}                    True if Year is missing
 */
const missingBirthYear = ({ birthMonth, birthDay, birthYear }) =>
  !birthYear && (birthDay || birthMonth)

/**
 * Checks if user is underage, by date of birth
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @param  {Number} options.age         Age
 * @return {Boolean}                    True if underage
 */
export const userBirthdayUnderage = ({
  birthMonth,
  birthDay,
  birthYear,
  age,
}) => userUnderage({ age: getAge({ birthYear, birthMonth, birthDay }) })

/**
 * Checks if user is underage, by age
 *
 * @param  {Number}   options.age         Age
 * @return {Boolean}                      True if underage
 */
export const userUnderage = ({ age }) => age <= AGE_LIMIT

/**
 * Returns the common errors with birthday and age input that are shared
 * across Prescreener and Screener
 *
 * @param  {Object} values Object containing birthday or age
 * @return {Object}        Object containing errors
 */
export const getBirthdayErrors = (values) => {
  let errors = {}

  if (missingBirthMonth(values)) {
    errors.birthMonth = AGE_ERROR_NO_BIRTH_MONTH
  }

  if (missingBirthDay(values)) {
    errors.birthDay = AGE_ERROR_NO_BIRTH_DAY
  }

  if (missingBirthYear(values)) {
    errors.birthYear = AGE_ERROR_NO_BIRTH_YEAR
  }

  if (missingDateOfBirth(values)) {
    errors.age = AGE_ERROR_NO_AGE_OR_DATE_OF_BIRTH
  }

  if (hasAgeAndDateOfBirth(values)) {
    errors.age = AGE_ERROR_AGE_AND_DATE_OF_BIRTH
  }

  return errors
}

export const checkboxProps = ({
  clientName,
  householdMembers,
  householdMembersIndex,
  checkboxType,
  name: fieldName,
  options,
}) => {
  switch (checkboxType) {
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR:
      return householdMembersSelectorMap(
        clientName,
        householdMembers,
        fieldName
      )
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT:
      return householdMembersExcludingClientMap(householdMembers, fieldName)
    default:
      return defaultCheckboxMap(householdMembersIndex, options)
  }
}
