import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import {
  API_BASE,
  GOOGLE_API_KEY,
  GOOGLE_API_GEOCODE_URL,
} from 'Shared/constants'
import { getLocationsFailure, loadInitialLocations } from 'Actions/locations'
import { fetchAlgolia } from 'Actions/algolia'
import {
  updateCoordinates,
  getDistanceBetween,
  updateZipCode,
} from 'Actions/geolocation'
import { updateShowZeroSearchResults } from 'Reducers/uiSlice'
import { isJestTest } from 'Shared/helpers'
export const LOAD_INITIAL_FILTERS = 'LOAD_INITIAL_FILTERS'
export const UPDATE_QUICK_FILTER_SELECTED = 'UPDATE_QUICK_FILTER_SELECTED'
export const UPDATE_DISTANCE_FILTER_UI = 'UPDATE_DISTANCE_FILTER_UI'
export const UPDATE_DISTANCE_AROUND = 'UPDATE_DISTANCE_AROUND'
export const TOGGLE_SINGLE_STOP_FILTER = 'TOGGLE_SINGLE_STOP_FILTER'
export const UPDATE_FILTERED_LOCATIONS = 'UPDATE_FILTERED_LOCATIONS'
export const UPDATE_LANGUAGE_SELECTED = 'UPDATE_LANGUAGE_SELECTED'
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const CLEAR_FILTER = 'CLEAR_FILTER'

/**
 *
 * @param {Object} payload
 * @returns {{payload: {Object}, type: string}}
 */
export const loadInitialFilters = (payload) => ({
  type: LOAD_INITIAL_FILTERS,
  payload,
})

/**
 *
 * @param {Number} payload
 * @returns {{payload: Number, type: string}}
 */
export const updateQuickFilterSelected = (payload) => ({
  type: UPDATE_QUICK_FILTER_SELECTED,
  payload,
})

export const updateFilteredLocations = (filteredLocations) => ({
  type: UPDATE_FILTERED_LOCATIONS,
  filteredLocations,
})

export const updateDistanceAround = (distance) => ({
  type: UPDATE_DISTANCE_AROUND,
  distance,
})

/**
 *
 * @param {Object} payload
 * @returns {{payload: {Object}, type: string}}
 */
export const updateDistanceUI = (payload) => ({
  type: UPDATE_DISTANCE_FILTER_UI,
  payload,
})

/**
 *
 * @returns {{type: string}}
 */
export const toggleSingleStopFilter = () => {
  return { type: TOGGLE_SINGLE_STOP_FILTER }
}

export const updateLanguageSelected = (payload) => ({
  type: UPDATE_LANGUAGE_SELECTED,
  payload,
})

export const updateQuery = (query) => {
  return {
    type: UPDATE_QUERY,
    query,
  }
}

export const catchAxios = (thrown) => {
  if (axiosWithoutAuthHeaders.isCancel(thrown)) {
    console.log('Request canceled', thrown.message)
  } else {
    console.log(thrown)
  }
}

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})
/**
 *
 * @returns {Function | null}
 */
export const fetchFilters = () => (dispatch, getState) => {
  const { filters } = getState()
  if (filters.initialState) {
    return axiosWithoutAuthHeaders
      .get(`${API_BASE}/filters`)
      .then((result) => {
        const { data } = result
        return dispatch(loadInitialFilters(data))
      })
      .catch((thrown) => {
        if (!isJestTest()) catchAxios(thrown)
      })
  } else {
    return null
  }
}

/**
 *
 * @param {Object} filters
 * @param {String} name
 * @returns {string[]}
 */
export const getActiveFilters = (filters, name) => {
  return Object.keys(filters)
    .map((id) => ({ ...filters[id], id: id }))
    .filter((obj) => obj.selected === true)
    .map((obj) => `${name}:${obj.id}`)
}

/**
 *
 * @param {Object} filters
 * @returns {Object} params
 */
export const getAlgoliaParams = (filters) => {
  const { quickFilters, languageAccommodation, distance, attributes } = filters
  const { meters } = distance
  const facetFilters = []

  const { singleStop } = attributes

  const activeQuickFilters = getActiveFilters(
    quickFilters,
    'algoliaQuickFilters'
  )

  const activeLanguageFilters = getActiveFilters(
    languageAccommodation,
    'algoliaLanguageAccommodation'
  )

  if (activeQuickFilters.length) {
    facetFilters.push(activeQuickFilters)
  }

  if (activeLanguageFilters.length) {
    facetFilters.push(activeLanguageFilters)
  }

  if (singleStop.selected) {
    facetFilters.push(['singleStop:true'])
  }

  const params = {
    aroundRadius: meters,
  }

  if (facetFilters.length) {
    params.facetFilters = facetFilters
  }
  return params
}

export const processSearchResults = (results) => {
  const locationsObj = {}
  results.forEach((result) => {
    const { id } = result
    locationsObj[id] = result
  })
  return locationsObj
}

/**
 *
 * @param {String} zipCode
 * @returns {Function}
 */
export const parseQuery = (query) => (dispatch) => {
  if (!query) return
  const zipCodeRegex = /(\d{5})/g
  const zipCodeMatch = query?.match(zipCodeRegex)

  if (zipCodeMatch) {
    const zipCode = zipCodeMatch[0]
    const searchTerm = query.replace(zipCode, '')
    const url = `${GOOGLE_API_GEOCODE_URL}?address=${zipCode},US|country:us&key=${GOOGLE_API_KEY}`
    axiosWithoutAuthHeaders
      .get(url)
      .then((response) => {
        dispatch(updateZipCode(zipCode))
        dispatch(updateQuery(searchTerm))

        const { results } = response.data
        if (results.length) {
          const { location } = results[0].geometry
          const { lat, lng } = location
          const coordinates = { lat, lng }
          dispatch(updateCoordinates(coordinates))
        }
      })
      .catch((thrown) => {
        if (!isJestTest()) catchAxios(thrown)
        dispatch(getLocationsFailure(thrown))
      })
  } else {
    dispatch(updateQuery(query))
  }
}

export const searchAroundIp =
  (query = '') =>
  (dispatch, getState) => {
    const { filters } = getState()
    const { query } = filters
    const params = getAlgoliaParams(filters)
    params.aroundLatLngViaIP = true

    dispatch(handleFetchAlgolia(params, query))
  }

export const searchAroundLatLng = (query) => (dispatch, getState) => {
  const { filters, geolocation } = getState()
  const { lat, lng } = geolocation.coordinates
  const { query } = filters
  const params = getAlgoliaParams(filters)

  params.aroundLatLng = `${lat}, ${lng}`

  dispatch(handleFetchAlgolia(params, query))
}

export const handleFetchAlgolia =
  (params, query = '') =>
  (dispatch, getState) => {
    const { locations } = getState()
    const isInitialState =
      Object.values(locations).length === 1 && locations[null]

    return dispatch(fetchAlgolia(query, params))
      .then((results) => {
        const zeroSearchResultsFlag = !results.length
        const locationsArray = results.map((result, i) => {
          const { coordinates } = result
          const distance = dispatch(getDistanceBetween(coordinates))
          return { ...result, distance: distance, index: i }
        })

        const locations = processSearchResults(locationsArray)
        if (isInitialState) {
          dispatch(loadInitialLocations(locations))
        } else {
          dispatch(updateFilteredLocations(locations))
        }
        dispatch(updateShowZeroSearchResults(zeroSearchResultsFlag))
      })
      .catch((err) => {
        dispatch(getLocationsFailure(err))
      })
  }

export const filterLocations = () => (dispatch, getState) => {
  const { filters, geolocation } = getState()
  const { lat, lng } = geolocation.coordinates
  const { query } = filters

  const hasSharedLocation = lat && lng
  if (hasSharedLocation) {
    dispatch(searchAroundLatLng(query))
  } else {
    dispatch(searchAroundIp(query))
  }
}
