import React, { useContext, useEffect } from 'react'
import { ThemeContext } from 'styled-components/macro'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import {
  handleShareLocation,
  promptGeolocationPermission,
} from 'Actions/geolocation'
import { updateShowLocationRequest } from 'Reducers/uiSlice'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import Icon from 'Components/Shared/Icon/Icon'
import pinDropIconPath from 'Shared/Icons/pinDropIconPath'
import { useAppDispatch } from 'Store/hooks'
import {
  StyledLocationRequestSecondary,
  StyledLocationTitle,
  StyledButton,
  StyledOutlinedButton,
  StyledParagraph,
} from './style'

const StyledDialog = withStyles({
  root: {
    '& .MuiBackdrop-root': {
      backgroundColor: '#1E1E1E29',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '23px',
      maxWidth: '538px !important',
    },
  },
})(Dialog)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 6),
  },
}))(MuiDialogContent)

const LocationRequest = () => {
  const [open, setOpen] = React.useState(true)
  const dispatch = useAppDispatch()
  const themeContext = useContext(ThemeContext)

  const handleClick = () => {
    dispatch(updateShowLocationRequest())
  }

  const handleClose = () => {
    setOpen(false)
    handleClick()
  }

  // Accessibility audit fix: Added Aria Attr in Right Div of Modal
  useEffect(() => {
    setTimeout(() => {
      const ModalContainer: HTMLDivElement =
        document.querySelector('[role="dialog"]')!
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
        ModalContainer.setAttribute('tabindex', '-1')
      }
    })
  })

  useEffect(() => {
    dispatch(promptGeolocationPermission())
  }, [dispatch])

  return (
    <StyledLocationRequestSecondary
      onTouchStartCapture={(e) => {
        e.nativeEvent.stopPropagation()
      }}
    >
      <StyledDialog
        onClose={handleClose}
        //@ts-ignore
        TransitionProps={{ role: 'presentation' }}
        aria-labelledby='Sharing-your-location'
        open={open}
      >
        <DialogTitle id='Sharing-your-location' onClose={handleClose}>
          <StyledLocationTitle>Sharing your location</StyledLocationTitle>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Icon
            viewBox={'0 0 93.335 93.107'}
            fill={themeContext.icon.dark}
            scale={'5.875rem'}
            style={{ margin: '0 auto 2rem' }}
            screenReaderLabel={'Location Pin Icon'}
          >
            {pinDropIconPath}
          </Icon>

          <StyledParagraph style={{ fontSize: '1rem' }}>
            lets us better help you locate nearby benefits and resources.
          </StyledParagraph>
          <StyledButton
            className={'cypress-share-my-location'}
            type='button'
            onClickCapture={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.nativeEvent.stopImmediatePropagation()
              dispatch(handleShareLocation())
            }}
          >
            Share My Location
          </StyledButton>
          <StyledOutlinedButton
            margin={'0.6rem auto 2rem'}
            className={'cypress-continue-with-zip-code'}
            elevation={0}
            onClickCapture={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              handleClick()
            }}
            type='button'
          >
            Continue With My Zip Code
          </StyledOutlinedButton>
        </DialogContent>
      </StyledDialog>
    </StyledLocationRequestSecondary>
  )
}

export default LocationRequest
