import React from 'react'

const goodsIconPath = (
  <g id='a'>
    <g transform='translate(-56.852 -55.394)'>
      <path
        d='M31.419,128.715c22.9,0,48.168-28.3,48.168-64.357S54.321,0,31.419,0,0,41.266,0,70.842,8.516,128.715,31.419,128.715Z'
        transform='matrix(0.719, 0.695, -0.695, 0.719, 141.413, 49.387)'
        fill='#d7e2ff'
        opacity='0.8'
      />
      <g transform='translate(60.48 61.544)'>
        <g transform='translate(0 0)'>
          <g transform='translate(0 0)'>
            <path
              d='M5.809,10h73.87a5.837,5.837,0,0,1,5.809,5.832v3.88a5.823,5.823,0,0,1-5.809,5.832H5.809A5.837,5.837,0,0,1,0,19.712v-3.88A5.823,5.823,0,0,1,5.809,10ZM3.886,19.712a1.951,1.951,0,0,0,1.923,1.946h73.87A1.938,1.938,0,0,0,81.6,19.712v-3.88a1.951,1.951,0,0,0-1.923-1.946H5.809a1.938,1.938,0,0,0-1.923,1.946Z'
              transform='translate(0.037 25.682)'
              fillRule='evenodd'
            />
            <path
              d='M7.518,43.655a9.506,9.506,0,0,0,8.965,7.219H63.165a9.608,9.608,0,0,0,9.019-7.145l6.924-29.5-3.783-.885L68.4,42.84a5.737,5.737,0,0,1-5.235,4.148H16.483a5.632,5.632,0,0,1-5.171-4.172L4.8,13.541l-3.793.843Z'
              transform='translate(2.957 35.323)'
              fillRule='evenodd'
            />
            <path
              d='M22.961,16.083A1.943,1.943,0,1,0,19.8,13.825L.369,41.025A1.943,1.943,0,1,0,3.53,43.283Z'
              transform='translate(0.03 34.328)'
              fillRule='evenodd'
            />
            <path
              d='M19.517,13.8a1.943,1.943,0,0,0-3.161,2.258l19.426,27.2A1.943,1.943,0,1,0,38.943,41Z'
              transform='translate(46.218 34.349)'
              fillRule='evenodd'
            />
            <path
              d='M10.886,12.816a1.944,1.944,0,0,1,1.929-1.925l15.752.049a1.946,1.946,0,0,1,1.947,1.991v9.608H34.4V12.931a5.831,5.831,0,0,0-5.816-5.876L12.833,7A5.829,5.829,0,0,0,7,12.816v9.718h3.886Z'
              transform='translate(20.238 17.032)'
              fillRule='evenodd'
            />
            <path
              d='M27.515,9.8a5.791,5.791,0,0,0-5.837-5.8L9.8,4.1A5.86,5.86,0,0,0,4,9.946V31.179H7.886V9.946A1.975,1.975,0,0,1,9.837,7.983l11.876-.09A1.906,1.906,0,0,1,23.635,9.8v9.718h3.88Z'
              transform='translate(11.581 8.388)'
              fillRule='evenodd'
            />
            <path
              d='M22.8,6.134a1.914,1.914,0,0,1,2.353-1.363L36.6,7.932a1.99,1.99,0,0,1,1.365,2.441l-6.993,26.1a1.944,1.944,0,1,0,3.754,1.011L41.72,11.379a5.876,5.876,0,0,0-4.085-7.192L26.186,1.026a5.8,5.8,0,0,0-7.142,4.1l-6.03,22.51a1.944,1.944,0,1,0,3.754,1.011Z'
              transform='translate(37.4 -0.819)'
              fillRule='evenodd'
            />
          </g>
        </g>
      </g>
    </g>
  </g>
)

export default goodsIconPath
