import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import {
  StyledForm,
  StyledHeadingMain,
  StyledParentPreScreenerButton,
  StyledSubHeading,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'

import { validName } from './helpers'
import { ResetFieldset } from './style'

const PreScreenerName = (props) => {
  const { handleSubmit } = props

  const inputStyle = {
    width: '50%',
  }

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm
            padding={'2rem 0 0'}
            onSubmit={handleSubmit}
            className='cypress-prescreen-name-form'
          >
            <StyledHeadingMain id='mainContent' tabIndex='-1'>
              It's great to meet you!
            </StyledHeadingMain>
            <ResetFieldset>
              <legend>
                <StyledSubHeading>How should we address you?</StyledSubHeading>
              </legend>
              <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
                <MuiThemeProvider theme={ssTheme}>
                  <StyledHouseholdMembersList>
                    <Row>
                      <Col lg='6'>
                        <StyledMuiTextFieldWrapper className='cypress-first-name'>
                          <Field
                            style={inputStyle}
                            name='first_name'
                            type='text'
                            component={TextField}
                            label='First Name'
                            autoComplete='given-name'
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                      <Col lg='6'>
                        <StyledMuiTextFieldWrapper className='cypress-last-name'>
                          <Field
                            name='last_name'
                            type='text'
                            component={TextField}
                            label='Last Name'
                            autoComplete='family-name'
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                      <Col lg='12'>
                        <StyledMuiTextFieldWrapper
                          mbottom='0'
                          fullWid
                          className='cypress-nickname'
                        >
                          <Field
                            name='nick_name'
                            type='text'
                            component={TextField}
                            label='Preferred Name'
                            autoComplete
                            charLimit={250}
                          />
                        </StyledMuiTextFieldWrapper>
                      </Col>
                    </Row>
                  </StyledHouseholdMembersList>
                </MuiThemeProvider>
              </StyledHouseholdMembersListItem>
            </ResetFieldset>
            <Row>
              <Col lg='8'>
                <StyledParentPreScreenerButton $isOnlyContinue={true}>
                  <ColDynamicCtaButton
                    lgWidth='90%'
                    type='submit'
                    className='cypress-continue-button'
                  ></ColDynamicCtaButton>
                </StyledParentPreScreenerButton>
              </Col>
            </Row>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validName,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerName)
