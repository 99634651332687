import gql from 'graphql-tag'

export const CUSTOM_FIELD_RESPONSE = gql`
  fragment CustomFieldResponse on CustomFieldResponse {
    id
    value
    customField {
      id
    }
  }
`

export const CUSTOM_FIELDS = gql`
  fragment CustomFields on Client {
    customFieldResponses {
      ...CustomFieldResponse
    }
    screening {
      id
      location {
        id
        customFields {
          id
          label
          dataType
          options
          required
        }
      }
    }
  }
  ${CUSTOM_FIELD_RESPONSE}
`

export const CUSTOM_FIELDS_QUERY = gql`
  query customFields($id: ID!) {
    client(id: $id) {
      ...CustomFields
    }
  }
  ${CUSTOM_FIELDS}
`

export const CUSTOM_FIELDS_MUTATION = gql`
  mutation saveCustomFields(
    $clientId: ID!
    $responses: [CustomFieldResponseInput!]!
  ) {
    saveCustomFieldResponses(clientId: $clientId, responses: $responses) {
      customFieldResponses {
        ...CustomFieldResponse
      }
    }
  }
  ${CUSTOM_FIELD_RESPONSE}
`
