import styled from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

export const ProgressSideParent = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  background-color: ${theme.palette.color.white};
  overflow: auto;
  display: flex;
  box-shadow: 0px 2px 5px #0000000a;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  ::-webkit-scrollbar-thumb {
    display: none;
  }
`

export const ProgressSideBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  opacity: ${(props) => props.opacity};
`

export const StyledInfoBox = styled.div`
  margin: 0.5rem 0.5rem;
`

export const StyledBarBox = styled.div``

export const StyledBarCompleted = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledBackgroundDoneIcon = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  background-color: ${theme.palette.primary.dark};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 7,
    borderRadius: 5,
    width: 120,
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.lightGrey,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.dark,
  },
}))(LinearProgress)

BorderLinearProgress.displayName = 'BorderLinearProgress'
ProgressSideBoxStyled.displayName = 'ProgressSideBoxStyled'
