import moment from 'moment'
import { loadingStarted } from 'Reducers/uiSlice'
import { dispatchEvent, isFiltersRedesign } from 'Shared/helpers'
import store from 'Store'
import { NON_BLOCKING_OPERATION_NAMES } from 'Shared/constants'
import {
  clientStatusFilterVar,
  demographicsDashboardStateVar,
  drawdownByCaseServiceFilterVar,
  enableClientStatusFilterVar,
  enableDemographicsDashboardStateVar,
  enableDrawdownByCaseServiceFilterVar,
  enableKpiDashboardStateVar,
  enableReportingActivitiesAddedByFilterVar,
  enableReportingActivitiesCaseFilterVar,
  enableReportingActivitiesTypeFilterVar,
  enableReportingCasesAssignedToFilterVar,
  enableReportingCasesCaseFilterVar,
  enableReportingCasesCaseStatusFilterVar,
  enableReportingCasesCategoriesFilterVar,
  enableReportingCasesClosedVar,
  enableReportingCasesOutcomeFilterVar,
  enableReportingIndividualTypeFilterVar,
  enableReportsSiteFilterVar,
  enableScreeningStatusFilterVar,
  enableScreeningTypeFilterVar,
  kpiDashboardStateVar,
  reportingActivitiesAddedByFilterVar,
  reportingActivitiesCaseFilterVar,
  reportingActivitiesTypeFilterVar,
  reportingCasesAssignedToFilterVar,
  reportingCasesCaseFilterVar,
  reportingCasesCaseStatusFilterVar,
  reportingCasesCategoriesFilterVar,
  reportingCasesClosedVar,
  reportingCasesOutcomeFilterVar,
  reportingDateRangeEndVar,
  reportingDateRangeStartVar,
  reportingHouseholdSearchTermVar,
  reportingIndividualTypeFilterVar,
  reportsSiteFilterVar,
  screeningStatusFilterVar,
  screeningTypeFilterVar,
  reportingNotesAddedByVar,
  enableReportingNotesAddedByVar,
  notesSiteFilterVar,
  enableNotesSiteFilterVar,
} from './caseManagementCache'
import {
  getEndOfPreviousMonth,
  getFirstOfPreviousMonth,
} from 'Components/CaseManagement/helpers'

/**
 *
 * @param body
 * @returns {boolean}
 */
export const shouldDispatchLoadingStarted = ({ body }) => {
  const { operationName } = JSON.parse(body)
  return false === NON_BLOCKING_OPERATION_NAMES.includes(operationName)
}

const parseLocations = (locations) => {
  return locations
    .filter((val) => val !== 'ALL')
    .map((location) => location.value)
}
/**
 *
 * @param uri
 * @param options
 * @returns {*}
 */
export const customFetch = (uri, options) => {
  if (shouldDispatchLoadingStarted(options)) {
    store.dispatch(loadingStarted())
  }
  // dispatch a custom event that the react-idle-timer can bind do
  // this enables us to track idle time based on network requests (as opposed to DOM events)
  dispatchEvent({ eventName: 'apiRequest', bubbles: true })
  return fetch(uri, options)
}

/**
 *
 * @returns {{createdAt: {min: string, max: string}, screeningType: string, screeningStatus: string, searchTerm: string, status: string}}
 */
export const getHouseholdFilterSettings = () =>
  isFiltersRedesign()
    ? {
        screeningStatus: {
          value: screeningStatusFilterVar(),
          enabled: enableScreeningStatusFilterVar(),
        },
        status: {
          value: clientStatusFilterVar(),
          enabled: enableClientStatusFilterVar(),
        },
        screeningType: {
          value: screeningTypeFilterVar(),
          enabled: enableScreeningTypeFilterVar(),
        },
        searchTerm: reportingHouseholdSearchTermVar(),
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }
    : {
        screeningStatus: screeningStatusFilterVar(),
        status: clientStatusFilterVar(),
        screeningType: screeningTypeFilterVar(),
        searchTerm: reportingHouseholdSearchTermVar(),
        locationIds: reportsSiteFilterVar(),
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }

export const getIndividualFilterSettings = () =>
  isFiltersRedesign()
    ? {
        memberType: {
          value: reportingIndividualTypeFilterVar(),
          enabled: enableReportingIndividualTypeFilterVar(),
        },
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }
    : {
        memberType: reportingIndividualTypeFilterVar(),
        locationIds: reportsSiteFilterVar(),
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }

export const getCasesFilterSettings = () =>
  isFiltersRedesign()
    ? {
        activeStatus: {
          value: reportingCasesCaseStatusFilterVar(),
          enabled: enableReportingCasesCaseStatusFilterVar(),
        },
        categories: {
          value: reportingCasesCategoriesFilterVar(),
          enabled: enableReportingCasesCategoriesFilterVar(),
        },
        caseManagerId: {
          value: reportingCasesAssignedToFilterVar(),
          enabled: enableReportingCasesAssignedToFilterVar(),
        },
        serviceId: {
          value: reportingCasesCaseFilterVar(),
          enabled: enableReportingCasesCaseFilterVar(),
        },
        caseStatus: {
          value: reportingCasesOutcomeFilterVar(),
          enabled: enableReportingCasesOutcomeFilterVar(),
        },
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        closed: {
          value: reportingCasesClosedVar(),
          enabled: enableReportingCasesClosedVar(),
        },
        createdAt: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }
    : {
        activeStatus: reportingCasesCaseStatusFilterVar(),
        categories: reportingCasesCategoriesFilterVar(),
        caseManagerId: reportingCasesAssignedToFilterVar(),
        serviceId: reportingCasesCaseFilterVar(),
        caseStatus: reportingCasesOutcomeFilterVar(),
        locationIds: reportsSiteFilterVar(),
        closed: reportingCasesClosedVar(),
        createdAt: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }

export const getActivitiesFilterSettings = () =>
  isFiltersRedesign()
    ? {
        activityType: {
          value: reportingActivitiesTypeFilterVar(),
          enabled: enableReportingActivitiesTypeFilterVar(),
        },
        serviceId: {
          value: reportingActivitiesCaseFilterVar(),
          enabled: enableReportingActivitiesCaseFilterVar(),
        },
        caseManagerId: {
          value: reportingActivitiesAddedByFilterVar(),
          enabled: enableReportingActivitiesAddedByFilterVar(),
        },
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        createdAt: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }
    : {
        activityType: reportingActivitiesTypeFilterVar(),
        serviceId: reportingActivitiesCaseFilterVar(),
        caseManagerId: reportingActivitiesAddedByFilterVar(),
        locationIds: reportsSiteFilterVar(),
        createdAt: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }

export const getDrawdownFilterSettings = () =>
  isFiltersRedesign()
    ? {
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        serviceIds: {
          value: drawdownByCaseServiceFilterVar(),
          enabled: enableDrawdownByCaseServiceFilterVar(),
        },
        dateRange: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }
    : {
        locationIds: reportsSiteFilterVar(),
        serviceIds: drawdownByCaseServiceFilterVar(),
        dateRange: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
      }

export const getNotesFilterSettings = () =>
  isFiltersRedesign()
    ? {
        caseManagerId: {
          value: reportingNotesAddedByVar(),
          enabled: enableReportingNotesAddedByVar(),
        },
        locationIds: {
          value: notesSiteFilterVar(),
          enabled: enableNotesSiteFilterVar(),
        },
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }
    : {
        caseManagerId: reportingNotesAddedByVar(),
        locationIds: notesSiteFilterVar(),
        createdAt: {
          min: reportingDateRangeStartVar(),
          max: reportingDateRangeEndVar(),
        },
      }
/**
 *
 * @returns {{min: string, max: string}}
 */
export const getDemographicsDashboardDateRangeFilterSettings = () => ({
  min: moment(reportingDateRangeStartVar()).toISOString(),
  max: moment(reportingDateRangeEndVar()).toISOString(),
})

/**
 *
 * @returns {{min: string, max: string}}
 */
export const getAnalyticsDashboardDateRangeFilterSettings = () => ({
  min: moment(reportingDateRangeStartVar()).toISOString(),
  max: moment(reportingDateRangeEndVar()).toISOString(),
})

export const getDemographicDashboardFilterSettings = () =>
  isFiltersRedesign()
    ? {
        dateRange: getDemographicsDashboardDateRangeFilterSettings(),
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        //ToDo: should this be an object with enabled & value props
        state: {
          value: demographicsDashboardStateVar(),
          enabled: enableDemographicsDashboardStateVar(),
        },
      }
    : {
        dateRange: getDemographicsDashboardDateRangeFilterSettings(),
        locationIds: reportsSiteFilterVar(),
        state: demographicsDashboardStateVar(),
      }

export const getDemographicHouseholdFilterSettings = () => ({
  createdAt: getDemographicsDashboardDateRangeFilterSettings(),
  // ToDo: not sure why this one can't have shape {value: string[], enabled: boolean}. Investigate this
  locationIds: parseLocations(reportsSiteFilterVar()),
})
/**
 *
 * @return {{dateRange: {min: string, max: string}, locationIds: string[]}}
 */
export const getAnalyticsDashboardFilterSettings = () =>
  isFiltersRedesign()
    ? {
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        dateRange: getAnalyticsDashboardDateRangeFilterSettings(),
      }
    : {
        locationIds: reportsSiteFilterVar(),
        dateRange: getAnalyticsDashboardDateRangeFilterSettings(),
      }

export const getKpiDashboardFilterSettings = () =>
  isFiltersRedesign()
    ? {
        dateRange: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
        locationIds: {
          value: reportsSiteFilterVar(),
          enabled: enableReportsSiteFilterVar(),
        },
        //ToDo: should this be an object with enabled & value props
        state: {
          value: kpiDashboardStateVar(),
          enabled: enableKpiDashboardStateVar(),
        },
      }
    : {
        dateRange: {
          min: moment(reportingDateRangeStartVar()).toISOString(),
          max: moment(reportingDateRangeEndVar()).toISOString(),
        },
        locationIds: reportsSiteFilterVar(),
        state: kpiDashboardStateVar(),
      }

/**
 *
 * @returns {Date} - The date of the oldest possible Single Stop record
 */
export const getDefaultStartDate = () =>
  moment(getFirstOfPreviousMonth()).toDate()

/**
 *
 * @returns {Date} - Today's date
 */
export const getDefaultEndDate = () => moment(getEndOfPreviousMonth()).toDate()

/**
 *
 * @param {string} pathname - window.location.pathname
 * @returns {boolean}
 *
 * Indicates if the user is on the Screener
 */
export const isScreener = (pathname) =>
  pathname === '/screening' || pathname === '/next-steps'

/**
 *
 * @param {string} pathname - window.location.pathname
 * @returns {boolean}
 *
 * Indicates if user is on Case Management
 */
export const isCaseManagement = (pathname) =>
  pathname.includes('case-management')

/**
 *
 * @param {string} pathname - window.location.pathname
 * @returns {boolean}
 *
 * Indicates if a redirect should be performed
 */
export const shouldRedirect = (pathname) => {
  const uriSegments = [
    'screening',
    'questions',
    'completed-screener',
    'next-steps',
    'account',
    'review',
    'case-management',
    'locations',
  ]

  let redirectFlag = false
  for (let i = 0; i < uriSegments.length; i++) {
    if (
      pathname.includes(uriSegments[i]) &&
      !pathname.includes('login') &&
      !pathname.includes('reset')
    ) {
      redirectFlag = true
      break
    }
  }
  return redirectFlag
}

/**
 * @returns void
 *
 * Redirects to Case Management Login Page and removes browser storage credentials
 */

export const redirectToLoginPath = (pathname, operation) => {
  // we exclude query 'me' from redirect as it is executed every pageload.
  if (shouldRedirect(pathname) && operation.operationName !== 'me') {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = `${window.location.origin}${
      isCaseManagement(pathname) ? '/case-management' : ''
    }/login`
  }
}

export const getCredentials = ({ browserStorage }) => {
  const serialized = browserStorage.getItem('credentials')
  return serialized ? JSON.parse(serialized) : {}
}

export const isExpiredCredentials = ({ expiry }) => {
  return expiry ? expiry < Math.floor(Date.now() / 1000) : false
}

export const toDollarsWithoutDecimals = (num) =>
  num
    ? Math.round(num).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      })
    : ''
