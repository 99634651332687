import { useState } from 'react'
import FormError from 'Components/Shared/FormError/FormError'
import {
  StyledTextField,
  StyledFormControl,
  StyledCharacterCountWarning,
} from './style'

/**
 * String Input. Rendered automatically by dataType from within CustomQuestionField.
 *
 * @param {{input:object, meta:{touched:boolean, error:string}}} props The Form props received from final form
 * @returns {StringInput} A text input form component for use inside a final form field
 *
 * See https://stackoverflow.com/questions/57914368/how-can-i-remove-the-underline-of-textfield-from-material-ui/61185770#61185770
 * for removing underline style. Specifically, see the comment from  jstnno Jan 12 at 23:36 re: use of `InputProps` pascal case (as opposed to camel case)
 */
const StringInput = ({
  input,
  meta: { touched, error, submitError, active },
  disabled = false,
  multiline = false,
  placeholder = '',
  rows = 1,
  maxLength,
  inputTypePhone,
}) => {
  const [count, setCount] = useState(0)
  return (
    <>
      {count >= 980 && (
        <StyledCharacterCountWarning>
          {1000 - count} characters remaining
        </StyledCharacterCountWarning>
      )}
      <StyledFormControl
        error={touched && (!!error || !!submitError) && !active}
        fullWidth
      >
        <StyledTextField
          {...input}
          inputProps={{
            maxLength: maxLength || '1000',
          }}
          onKeyDown={(e) => {
            setCount(input.value.length)
          }}
          onChange={(e) => {
            if (inputTypePhone) {
              // strip anything other than numbers from the input
              if (e.target.value.match(/[^0-9]/g)) {
                e.target.value = e.target.value.replace(/[^0-9]/g, '')
              }
            }
            input.onChange(e)
          }}
          InputProps={{
            multiline,
            placeholder,
            rows,
            'data-testid': input.name,
            disableUnderline: true,
            disabled,
          }}
        />
      </StyledFormControl>
      <FormError
        error={error && !active ? error : submitError}
        touched={touched}
      />
    </>
  )
}

export default StringInput
