import React from 'react'

const housingIconPath = (
  <g id='a'>
    <g transform='translate(-13.332 -13.634)'>
      <path
        d='M1.393,99.364c9.428,21.307,69.37,17.407,87.814-10.253S86.572,0,67.677,0s-18.9,41.49-26.686,53.675S-8.035,78.057,1.393,99.364Z'
        transform='matrix(0.799, 0.602, -0.602, 0.799, 73.016, 3)'
        fill='#ffe49e'
        opacity='0.8'
      />
      <g transform='translate(37.221 21.412)' opacity='0.8'>
        <path
          d='M173.637,206c-4.435,0-7.631,3.711-7.631,8.216,0,4.948,3.811,8.2,9.581,13.118,1.006.856,2.045,1.742,3.15,2.709a1.526,1.526,0,0,0,2.009,0c1.1-.965,2.144-1.852,3.15-2.709,5.77-4.915,9.581-8.162,9.581-13.118,0-4.506-3.2-8.216-7.631-8.216a7.249,7.249,0,0,0-6.1,3.4,7.25,7.25,0,0,0-6.1-3.4Zm7.576,7.271c.012-.043,1.2-4.219,4.633-4.219,2.61,0,4.578,2.22,4.578,5.165,0,3.543-3.2,6.272-8.507,10.792-.7.6-1.425,1.214-2.175,1.862-.75-.648-1.471-1.266-2.175-1.862-5.306-4.52-8.507-7.25-8.507-10.792,0-2.944,1.968-5.165,4.578-5.165,3.391,0,4.58,4.033,4.634,4.223a1.526,1.526,0,0,0,2.942,0Z'
          transform='translate(-140.667 -174.563)'
        />
        <ellipse
          cx='1.151'
          cy='1.726'
          rx='1.151'
          ry='1.726'
          transform='translate(17.854 14.505)'
        />
        <path
          d='M68.336,36.3a6.106,6.106,0,1,0,7.4-9.716l-7.669-5.894V1.529A1.526,1.526,0,0,0,66.537,0H57.381a1.526,1.526,0,0,0-1.526,1.526v9.781L42.765,1.25a6.1,6.1,0,0,0-7.4,0l-11.622,8.93a1.526,1.526,0,1,0,1.86,2.417L37.222,3.682a3.053,3.053,0,0,1,3.682,0L73.878,29.01A3.052,3.052,0,0,1,70.2,33.874L40,10.655a1.526,1.526,0,0,0-1.808-.039c-.069.048-30.237,23.253-30.239,23.255a3.053,3.053,0,0,1-2.156.605,2.675,2.675,0,0,1-2.658-2.3,3.075,3.075,0,0,1,1.128-3.166l10.458-8.037a1.526,1.526,0,1,0-1.86-2.417L2.41,26.582A6.1,6.1,0,0,0,.16,32.846,5.693,5.693,0,0,0,5.5,37.514,6.092,6.092,0,0,0,9.8,36.3h0l.274-.206V57.026A9.124,9.124,0,0,0,3.24,64.3,7.609,7.609,0,0,0,7.632,78.135H70.505A7.609,7.609,0,0,0,74.9,64.3a9.123,9.123,0,0,0-6.837-7.276V36.089ZM58.908,3.055h6.1V18.346l-6.1-4.692ZM24.419,75.086H7.632A4.559,4.559,0,0,1,5.4,66.54a1.527,1.527,0,0,0,.77-1.184,6.067,6.067,0,0,1,11.013-2.941,1.526,1.526,0,0,0,1.6.6A4.525,4.525,0,0,1,24.422,67.4a1.541,1.541,0,0,0,1,1.766,3.04,3.04,0,0,1-1,5.917ZM50.7,66.743a6.062,6.062,0,0,0-2.262,2.235H29.7a6.063,6.063,0,0,0-2.262-2.235,7.74,7.74,0,0,0-.121-.818h23.5a7.772,7.772,0,0,0-.121.818Zm-21,8.343a6.067,6.067,0,0,0,.819-3.052H47.615a6.069,6.069,0,0,0,.819,3.052Zm42.263-9.731a1.526,1.526,0,0,0,.77,1.184,4.559,4.559,0,0,1-2.23,8.545H53.719a3.04,3.04,0,0,1-1-5.917,1.541,1.541,0,0,0,1-1.766,4.524,4.524,0,0,1,5.639-4.385,1.527,1.527,0,0,0,1.6-.6,6.067,6.067,0,0,1,11.013,2.941Zm-6.949-8.534a9.177,9.177,0,0,0-5.931,3.049,6.87,6.87,0,0,0-.784-.046,7.659,7.659,0,0,0-6.1,3.052H25.942a7.659,7.659,0,0,0-6.1-3.052,6.865,6.865,0,0,0-.784.046,9.176,9.176,0,0,0-5.931-3.049V33.742l25.94-19.953L65.017,33.742Z'
          transform='translate(0.004 0)'
        />
      </g>
    </g>
  </g>
)

export default housingIconPath
