import React from 'react'

const LikeIconPath = (
  <>
    <path className='a' fill='none' d='M0,0H32.043V32.043H0Z' />
    <path
      className='b'
      fill='#fff'
      d='M15.351,2A13.351,13.351,0,1,0,28.7,15.351,13.356,13.356,0,0,0,15.351,2Zm0,24.032A10.681,10.681,0,1,1,26.032,15.351,10.7,10.7,0,0,1,15.351,26.032ZM21.479,9.45l-8.8,8.8L9.223,14.8,7.34,16.686l5.34,5.34L23.362,11.346Z'
      transform='translate(0.67 0.67)'
    />
  </>
)

export default LikeIconPath
