import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MediaQuery from 'react-responsive'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import MenuItem from 'Components/Shared/MenuItem/MenuItem'
import { StyledMenu, StyledMenuParent } from './style'
import { toggleMainMenu } from 'Reducers/uiSlice'
import useActiveLink from 'Components/Shared/Hooks/useActiveLink'
import useMenuItemsData from 'Components/Shared/Hooks/useMenuItemsData'
import { getClientId } from 'Shared/helpers'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'
import { isCaseManagementPage } from 'Shared/helpers'
import MainMenuButton from 'Components/CaseManagement/Reporting/Sidebar/MainMenuButton/MainMenuButton'
import sidebarMenuArray from 'Components/CaseManagement/Reporting/Sidebar/sidebarMenuArray'
import { StyledParentReportMenu } from 'Components/CaseManagement/ClientRecords/FilterColumn/style'

const Menu = () => {
  const dispatch = useDispatch()
  const { isActiveLink } = useActiveLink()
  const { accountData, logoutData, fullMenuData } = useMenuItemsData()
  const { caseManagementTopNavMenuData } = useMenuItemsData()

  const { showMainMenu } = useSelector((state) => {
    state.ui.showMainMenu
      ? document.body.classList.add('menu-open')
      : document.body.classList.remove('menu-open')
    return state.ui
  })

  const handleToggleMainMenu = () => dispatch(toggleMainMenu())

  const menuItems = (items) => {
    let menuOptions = items
    if (isCaseManagementPage()) {
      menuOptions = [...menuOptions]
    } else if (!getClientId()) {
      menuOptions = [...menuOptions, ...accountData]
    } else {
      menuOptions = [...menuOptions, ...logoutData]
    }

    return menuOptions.map((props, index) => {
      return (
        <MenuItem
          className='main-menu__item'
          {...props}
          key={index}
          activelink={isActiveLink(props)}
          handleClick={handleToggleMainMenu}
        />
      )
    })
  }

  const render = () => {
    if (showMainMenu) {
      return (
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledMenuParent>
            <StyledMenu className={isCaseManagementPage() ? '' : 'main-menu'}>
              {isCaseManagementPage() ? (
                <>
                  {menuItems(caseManagementTopNavMenuData)}
                  <StyledParentReportMenu>
                    {sidebarMenuArray.map((button, i) => (
                      <MainMenuButton
                        {...button}
                        key={i}
                        handleClick={handleToggleMainMenu}
                      />
                    ))}
                    <TranslationWidget insideMenu={true} />
                  </StyledParentReportMenu>
                </>
              ) : (
                <>
                  {menuItems(fullMenuData)}
                  <TranslationWidget insideMenu={true} />
                </>
              )}
            </StyledMenu>
          </StyledMenuParent>
        </MediaQuery>
      )
    } else {
      return null
    }
  }

  return render()
}

export default Menu
