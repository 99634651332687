import React from 'react'
import MediaQuery from 'react-responsive'
import { filterLocations, clearFilter } from 'Actions/filters'
import SingleStopFilter from '../SingleStopFilter/SingleStopFilter'
import CategoryFilter from '../CategoryFilter/CategoryFilter'
import {
  StyledFilterMenu,
  StyledFilterTitle,
  StyledHeaderFilter,
  StyledParentFilter,
  StyledFilterSpan,
  StyledFilterBtn,
  StyledFilterClearButton,
} from './style'

import { StyledSubHeading } from 'Components/Screener/style'

import DistanceFilter from '../DistanceFilter/container'
import LanguageFilter from '../LanguageFilter/LanguageFilter'
import {
  DESKTOP_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'

import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import filterIconPath from 'Shared/Icons/filterIconPath'
import closeIconPath from 'Shared/Icons/closeIconPath'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

type FilterMenuProps = {
  onClick: Function
}

const FilterMenu = ({ onClick }: FilterMenuProps) => {
  const dispatch = useAppDispatch()
  const { showFilterMenu } = useAppSelector((state) => state.ui)

  const handleFilterChange = () => {
    dispatch(filterLocations())
  }

  if (showFilterMenu) {
    return (
      <>
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledParentFilter>
            <StyledHeaderFilter>
              <StyledFilterSpan>
                <ActionToggle
                  filterinmobile
                  topMob={'0'}
                  rightMob={'18px'}
                  topTab={'0'}
                  rightTab={'18px'}
                  className={'cypress-filter-menu-toggle'}
                  activeIconPath={closeIconPath}
                  activeLabel={'filters'}
                  handleOnClick={() => onClick()}
                  inactiveIconPath={filterIconPath}
                  inactiveLabel={'filters'}
                  fill={'rgba(0,0,0,0.65)'}
                  showActive={showFilterMenu}
                />
              </StyledFilterSpan>
              <StyledSubHeading marginBottom='0' textalign='center'>
                Filters
              </StyledSubHeading>
              <StyledFilterClearButton onClick={() => dispatch(clearFilter())}>
                clear
              </StyledFilterClearButton>
            </StyledHeaderFilter>
            <StyledFilterMenu className={'cypress-filter-menu'}>
              <DistanceFilter callback={handleFilterChange} />
              <SingleStopFilter callback={handleFilterChange} />
              <CategoryFilter
                minHeightMob='4rem'
                minHeightTab='4rem'
                callback={handleFilterChange}
              />
              <LanguageFilter callback={handleFilterChange} />
              <StyledFilterBtn onClick={() => onClick()}>
                Show Results
              </StyledFilterBtn>
            </StyledFilterMenu>
          </StyledParentFilter>
        </MediaQuery>

        <MediaQuery query={DESKTOP_MEDIA_QUERY}>
          <StyledFilterMenu className={'cypress-filter-menu'}>
            <StyledFilterTitle>Search Filters</StyledFilterTitle>
            <SingleStopFilter callback={handleFilterChange} />
            <DistanceFilter callback={handleFilterChange} />
            <CategoryFilter callback={handleFilterChange} />
            <LanguageFilter callback={handleFilterChange} />
            <StyledFilterBtn onClick={() => onClick()}>
              Show Results
            </StyledFilterBtn>
          </StyledFilterMenu>
        </MediaQuery>
      </>
    )
  } else {
    return null
  }
}

export default FilterMenu
