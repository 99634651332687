import moment from 'moment'
import _set from 'lodash.set'
import _mapValues from 'lodash.mapvalues'
import { isEmpty } from 'Shared/helpers'

/**
 *
 * @param {string} dateRangeKey
 * @param {string} locationIdPath
 * @return {Function}
 */
export const parseReportingFilters =
  ({ dateRangeKey, locationIdPath }) =>
  (filters = {}) => {
    if (!Object.keys(filters).length) return {}

    const {
      [dateRangeKey]: dateRangeFilter,
      locationIds: rawLocationIds,
      categories: rawCategories,
      caseStatus: rawOutcomeType,
      ...rest
    } = filters

    const locationIds = parseLocationIds({
      ids: rawLocationIds,
      path: locationIdPath,
    })
    const categories = parseOptions('categories', rawCategories)
    const caseStatus = parseOptions('caseStatus', rawOutcomeType)
    const dateFilter = parseDateFilter({ dateRangeFilter, dateRangeKey })
    const activeValues = removeInactiveFilters({
      ...locationIds,
      ...dateFilter,
      ...categories,
      ...caseStatus,
      ...rest,
    })
    return _mapValues(activeValues, (value) => {
      const booleanStrings = ['true', 'false']
      return booleanStrings.includes(value) ? value === 'true' : value
    })
  }

/**
 *
 * @param {Object} dateRangeFilter
 * @param {string} dateRangeKey
 * @return {{}}
 */
export const parseDateFilter = ({ dateRangeFilter, dateRangeKey }) => {
  // At least one chart query does not use a date range variable (overallConfirmationRate)
  // therefore, we may or may not have date range variables to work with
  const dateMin = dateRangeFilter?.min
  const dateMax = dateRangeFilter?.max

  // If we do have date range variable to work with, we need to format them and them to the source of our return value
  if (dateMin && dateMax) {
    return {
      [dateRangeKey]: {
        min: moment(dateMin).toISOString(),
        max: moment(dateMax).toISOString(),
      },
    }
  } else {
    return {}
  }
}

// ToDo: when toggle is removed will options ever NOT have the object shape?
export const parseLocationIds = ({ ids = [], path }) => {
  const value = ids.hasOwnProperty('value') ? ids.value : ids
  const parsedValues = value
    .filter((element) => element !== 'ALL')
    .map((element) => element.value)
  return parsedValues.length ? _set({}, path, parsedValues) : {}
}

export const isValueSetToAll = (value) => {
  // Is this a filter that can be shown or hidden in the UI?
  // If so, the type is `{enabled: boolean, value: string | number | boolean}`
  // If not, the type is `string | number | boolean`
  const parsedValue = value.hasOwnProperty('value') ? value.value : value
  return (
    parsedValue === 'ALL' ||
    (Array.isArray(parsedValue) && parsedValue.length === 0)
  )
}

/**
 *
 * @param {Object} filters
 * @return {*}
 */
export const removeInactiveFilters = (filters) => {
  if (typeof filters === 'undefined') return undefined
  return (
    Object.entries(filters)
      .filter(([filterKey, filterValue]) => {
        // Handle filters that are disabled in the UI
        if (filterValue.hasOwnProperty('enabled') && !filterValue.enabled) {
          return false
        } else {
          // Handle everything else
          return !isValueSetToAll(filterValue)
        }
      })
      // Map the entries back into an array of objects
      .map(([key, value]) => ({
        // Handle both filter value shapes (filters that can be disabled and those that cannot)
        [key]: value.hasOwnProperty('value') ? value.value : value,
      }))
      // Reduce the array of objects into a single object
      .reduce(
        (acc, curr) => ({
          ...acc,
          ...curr,
        }),
        {}
      )
  )
}

export const getLocationId = (locations) =>
  locations.length ? locations[0].id : undefined

export const shouldSkipQuery = (...args) => {
  let bool = false
  for (let i = 0; i < args.length; i++) {
    if (args[i] === undefined) {
      bool = true
      break
    }
  }
  return bool
}

export const setDateRange =
  ({ startSetter, endSetter }) =>
  ({ start, end }) => {
    if (start) {
      startSetter(start)
    }
    if (end) {
      endSetter(end)
    }
  }

export const addOptionAll = (options) => [
  {
    value: 'ALL',
    label: 'All',
  },
  ...options,
]

export const getDynamicOptions = (data = [], label) => [
  ...new Map(
    data.map((datum) => [datum.id, { value: datum.id, label: datum[label] }])
  ).values(),
]

export const parseGridFilters = parseReportingFilters({
  dateRangeKey: 'createdAt',
  locationIdPath: 'locationIds',
})

export const parseDashboardFilters = parseReportingFilters({
  dateRangeKey: 'dateRange',
  locationIdPath: 'filter.locationIds',
})

export const removeEmptyProperties = (sourceObject) =>
  Object.fromEntries(
    Object.entries(sourceObject).filter(
      ([_key, value]) => !isEmpty(value) && value !== ''
    )
  )

// ToDo: when toggle is removed will options ever NOT have the object shape?
export const parseOptions = (key, options = []) => {
  const value = options.hasOwnProperty('value') ? options.value : options
  const isArray = Array.isArray(value)

  if (!isArray) {
    return {
      [key]: value,
    }
  }

  const parsedValues = value
    .filter((element) => element !== 'ALL')
    .map((element) => element.value)

  return parsedValues.length ? { [key]: parsedValues } : {}
}
