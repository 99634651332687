import { createContext } from 'react'

export const ProtectedRouteContext = createContext({})

export const ProtectedRouteProvider = ({ children, data }) => {
  return (
    <ProtectedRouteContext.Provider value={data}>
      {children}
    </ProtectedRouteContext.Provider>
  )
}
