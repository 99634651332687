import styled, { css } from 'styled-components/macro'
import { makeStyles } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '0.0625rem solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}))

export const StyledCardInfoContainer = styled.div`
  display: inline-flex;
`

export const StyledIconButton = styled.button`
  background-color: unset;
  border: unset;
  &:hover {
    cursor: pointer;
  }
`
interface StyledCardBodyProps extends CSSProperties {
  quickDetails?: boolean
  rateDisplay?: boolean
}

export const StyledCardBody = styled.div<StyledCardBodyProps>`
  width: 12rem;
  border: unset !important;
  border-radius: 0.3125rem;
  padding: 0.875rem 0.75rem !important;
  word-break: break-word;
  height: max-content;
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
  ${({ quickDetails }) =>
    quickDetails &&
    css`
      right: 0 !important;
      left: -5rem !important;
      bottom: 172% !important;
      top: auto !important;
      box-shadow: 0 0.75rem 0.875rem #dad9d991;
      margin: auto;
      &::before {
        content: '';
        right: 0;
        left: 0;
        bottom: -0.75rem;
        border-top: 0.75rem solid #fff;
        border-right: 0.625rem solid transparent;
        border-left: 0.625rem solid transparent;
        margin: auto;
      }
    `};
  ${({ rateDisplay }) =>
    rateDisplay &&
    css`
      right: 0 !important;
      left: -5rem !important;
      bottom: auto !important;
      top: 172% !important;
      box-shadow: -0.125rem 0.1875rem 1.6875rem #dad9d991;
      margin: auto;
      &::before {
        content: '';
        right: 0;
        left: 0;
        top: -1rem;
        border-bottom: 0.75rem solid #fff;
        border-right: 0.625rem solid transparent;
        border-left: 0.625rem solid transparent;
        margin: auto;
      }
    `}
`
export const StyledCardTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0 0 0.75rem;
`
export const StyledCardDescr = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0;
`
