import React from 'react'
import PreScreenerBackButton from 'Components/PreScreener/PreScreenerBackButton'
import {
  ReverseCol,
  StyledParentPreScreenerButton,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import { Row } from 'styled-bootstrap-grid'
import MediaQuery from 'react-responsive'
import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
} from 'Shared/constants'

const PreScreenerActions = (props) => {
  const { smallerWidth, smallerMargin } = props
  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledParentButtonsActionsInMobile>
          <ReverseCol smallerWidth={smallerWidth} smallerMargin={smallerMargin}>
            <StyledParentPreScreenerButton width='46%'>
              <PreScreenerBackButton
                smallerMargin='0'
                margin='0'
                width='100%'
                type='submit'
                className='cypress-continue-button'
              >
                Back
              </PreScreenerBackButton>
            </StyledParentPreScreenerButton>
            <StyledParentPreScreenerButton width='46%'>
              <ColDynamicCtaButton smallerMargin='0' />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </StyledParentButtonsActionsInMobile>
      </MediaQuery>

      <MediaQuery query={TABLET_MEDIA_QUERY}>
        <Row>
          <ReverseCol>
            <StyledParentPreScreenerButton>
              <PreScreenerBackButton
                smallerMargin='0'
                margin='0'
                width='100%'
                type='submit'
                className='cypress-continue-button'
              >
                Back
              </PreScreenerBackButton>
            </StyledParentPreScreenerButton>

            <StyledParentPreScreenerButton>
              <ColDynamicCtaButton />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </Row>
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        <Row>
          <ReverseCol>
            <StyledParentPreScreenerButton
              width={props.backButtonWidth ? props.backButtonWidth : '30%'}
            >
              <PreScreenerBackButton
                margin={props.backMargin || '0'}
                width={props.backWidth || '100%'}
                padding='1rem 5rem 1rem 5.3rem'
                type='submit'
                className='cypress-continue-button'
              >
                Back
              </PreScreenerBackButton>
            </StyledParentPreScreenerButton>
            <StyledParentPreScreenerButton
              width={
                props.continueButtonWidth ? props.continueButtonWidth : '37%'
              }
            >
              <ColDynamicCtaButton width={props.continueWidth} />
            </StyledParentPreScreenerButton>
          </ReverseCol>
        </Row>
      </MediaQuery>
    </>
  )
}

export default PreScreenerActions
