import React from 'react'
import {
  StyledTabletAndDesktopSearchBar,
  StyledTabletAndDesktopSearchBarInner,
} from './style'

const SearchBar = ({ children }) => (
  <StyledTabletAndDesktopSearchBar>
    <StyledTabletAndDesktopSearchBarInner>
      {children}
    </StyledTabletAndDesktopSearchBarInner>
  </StyledTabletAndDesktopSearchBar>
)

export default SearchBar
