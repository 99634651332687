import {
  handleApiError,
  getClientId,
  addToCounters,
  isJestTest,
} from 'Shared/helpers'
import {
  arrayPush as reduxFormArrayPush,
  arraySplice as reduxFormArraySplice,
  change as reduxFormChange,
  arrayRemove as reduxFormArrayRemove,
  arrayRemoveAll as reduxFormArrayRemoveAll,
  SubmissionError,
} from 'redux-form'
import {
  API_BASE,
  FIRST_STEP,
  SCREENER_FORM_NAME,
  HOUSEHOLD_QUESTION_STEP,
  ERROR_GENERIC_MESSAGE,
} from '../Shared/constants'
import {
  axiosWithAuthHeaders,
  axiosWithoutAuthHeaders,
} from '../Shared/axiosInstances'
import { questions } from '../Mocks/ResponsePayloads/screener-questions.js'
import { frontEndState } from '../Mocks/ResponsePayloads/screener-front-end-state'
import {
  getClientName,
  screenerSelector,
  screenerFormValuesSelector,
} from '../Selectors/screener'
import { startEditing, updateClient } from 'Actions/client'

export const BACK_BUTTON_CLICKED = 'BACK_BUTTON_CLICKED'
export const CLEAR_FILTERED_HOUSEHOLD_MEMBERS =
  'CLEAR_FILTERED_HOUSEHOLD_MEMBERS'
export const DECREMENT_HOUSEHOLD_MEMBERS_INDEX =
  'DECREMENT_HOUSEHOLD_MEMBERS_INDEX'
export const INCREMENT_CATEGORY_INDEX = 'INCREMENT_CATEGORY_INDEX'
export const INCREMENT_HOUSEHOLD_MEMBERS_INDEX =
  'INCREMENT_HOUSEHOLD_MEMBERS_INDEX'
export const RESET_CATEGORY_INDEX = 'RESET_CATEGORY_INDEX'
export const RESET_CATEGORY_OFFSET = 'RESET_CATEGORY_OFFSET'
export const RESET_HOUSEHOLD_MEMBERS_INDEX = 'RESET_HOUSEHOLD_MEMBERS_INDEX'
export const RESET_RETURN_STEP = 'RESET_RETURN_STEP'
export const UPDATE_CATEGORY_INDEX = 'UPDATE_CATEGORY_INDEX'
export const UPDATE_CATEGORY_OFFSET = 'UPDATE_CATEGORY_OFFSET'
export const UPDATE_SCREENING_ORGANIZATION = 'UPDATE_SCREENING_ORGANIZATION'
export const UPDATE_SCREENING_LOCATION = 'UPDATE_SCREENING_LOCATION'
export const UPDATE_SCREENER_QUESTIONS = 'UPDATE_SCREENER_QUESTIONS'
export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP'
export const UPDATE_FILTERED_HOUSEHOLD_MEMBERS =
  'UPDATE_FILTERED_HOUSEHOLD_MEMBERS'
export const UPDATE_HOUSEHOLD_MEMBERS_INDEX = 'UPDATE_HOUSEHOLD_MEMBERS_INDEX'
export const UPDATE_INITIAL_VALUES = 'UPDATE_INITIAL_VALUES'
export const UPDATE_RETURN_STEP = 'UPDATE_RETURN_STEP'
export const CLEAR_SCREENER = 'CLEAR_SCREENER'
export const START_EDITING_ON_STEP = 'START_EDITING_ON_STEP'
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
export const UPDATE_HAS_CUSTOM_QUESTIONS = 'SET_HAS_CUSTOM_QUESTIONS'
export const startEditingOnStep = (step) => (dispatch, getState) => {
  const { progressStatus, returnStep } = getState().screener
  dispatch(updateHouseholdMembersIndex(0))
  dispatch(updateCategoryIndex(0))
  dispatch(updateFilteredHouseholdMembers(null))
  dispatch(updateCurrentStep(step))
  dispatch(startEditing(step))
  dispatch(updateProgress(step, progressStatus, returnStep))
}

export const addHouseholdMemberIndex = () => (dispatch, getState) => {
  const householdMembers = screenerSelector(
    getState(),
    'household.household_members'
  )

  if (householdMembers) {
    householdMembers.forEach((_member, index) => {
      dispatch(
        reduxFormChange(
          SCREENER_FORM_NAME,
          `household.household_members[${index}].index`,
          index
        )
      )
    })
  }
}

export const assignToAllPersons =
  ({ fromFieldName, toValueName }) =>
  (dispatch, getState) => {
    const state = getState()
    const fromFieldValue = screenerSelector(state, fromFieldName)
    const householdMembers = screenerSelector(
      state,
      'household.household_members'
    )

    dispatch(
      reduxFormChange(
        SCREENER_FORM_NAME,
        `client.${toValueName}`,
        fromFieldValue
      )
    )

    if (householdMembers) {
      householdMembers.forEach((_member, index) => {
        dispatch(
          reduxFormChange(
            SCREENER_FORM_NAME,
            `household.household_members[${index}].${toValueName}`,
            fromFieldValue
          )
        )
      })
    }
  }

/**
 *
 * @param fieldName
 * @returns {Function}
 */
export const assignToFilteredHouseholdMembers =
  ({ fieldName, categoryIterator }) =>
  (dispatch, getState) => {
    const { filteredHouseholdMembers, householdMembersIndex, categoryIndex } =
      getState().screener
    const data = screenerSelector(getState(), fieldName)
    const baseFieldName = fieldName.split('.')[1]

    if (data) {
      // this can probably always use the first case?
      const datum = categoryIterator
        ? data?.[householdMembersIndex]?.[categoryIndex]
        : data?.[householdMembersIndex]

      const filteredHouseholdMember =
        filteredHouseholdMembers[householdMembersIndex]

      const { index: filteredHouseholdMemberIndex } = filteredHouseholdMember

      const isClient = filteredHouseholdMemberIndex === null

      const field = isClient
        ? categoryIterator
          ? `client.${baseFieldName}[${categoryIndex}]`
          : `client.${baseFieldName}`
        : categoryIterator
        ? `household.household_members[${filteredHouseholdMemberIndex}][${baseFieldName}][${categoryIndex}]`
        : `household.household_members[${filteredHouseholdMemberIndex}][${baseFieldName}]`

      dispatch(reduxFormChange(SCREENER_FORM_NAME, field, datum))
    }
  }

/**
 *
 * @param fieldName
 * @returns {Function}
 */
export const assignToHouseholdMembers =
  ({ fieldName }) =>
  (dispatch, getState) => {
    const { householdMembersIndex } = getState().screener
    const hasHouseholdMembers = screenerSelector(
      getState(),
      'client.has_household_members'
    )
    const data = screenerSelector(getState(), fieldName)
    const name = fieldName.split('.')[1]
    if (data && hasHouseholdMembers) {
      const datum = data[householdMembersIndex]
      dispatch(
        reduxFormChange(
          SCREENER_FORM_NAME,
          `household.household_members[${householdMembersIndex}][${name}]`,
          datum
        )
      )
    }
  }

export const removeFromFieldArray =
  ({ fieldName, index }) =>
  (dispatch) => {
    dispatch(reduxFormArrayRemove(SCREENER_FORM_NAME, fieldName, index))
  }

export const removeHouseholdMember =
  ({ index }) =>
  (dispatch, getState) => {
    const householdMemberCount = screenerSelector(
      getState(),
      'household.household_members'
    ).length
    if (householdMemberCount === 1) {
      dispatch(clearHousehold())
      dispatch(updateCurrentStep(HOUSEHOLD_QUESTION_STEP))
    } else {
      const householdMemberFields = [
        'household.household_members',
        'household.relationship_to_client',
        'household.date_of_birth',
        'household.gender',
        'household.education',
        'household.has_health_insurance',
        'household.health_insurance',
      ]

      householdMemberFields.forEach((fieldName) =>
        dispatch(
          removeFromFieldArray({
            fieldName,
            index,
          })
        )
      )

      dispatch(addHouseholdMemberIndex())
      dispatch(decrementHouseholdMembersIndex())
    }
  }

/**
 *
 * @returns {{type: string}}
 */
export const clearFilteredHouseholdMembers = () => ({
  type: CLEAR_FILTERED_HOUSEHOLD_MEMBERS,
})

/**
 *
 * @param fieldName
 * @param value
 * @returns {Function}
 */
export const filterHouseholdMembers =
  ({ fieldName, value }) =>
  (dispatch, getState) => {
    const householdMembers = screenerSelector(
      getState(),
      'household.household_members'
    )
    const name = fieldName.split('.')[1]
    if (householdMembers) {
      const filtered = householdMembers.filter(
        (member) => member[name] === value
      )
      const clientValue = screenerSelector(getState(), `client.${name}`)
      if (clientValue === value) {
        const clientName = getClientName(getState())
        filtered.unshift({ first_name: `${clientName}`, index: null })
      }
      dispatch(updateFilteredHouseholdMembers(filtered))
    }
  }

/**
 *
 * @param id
 * @param buttonName
 * @returns {Function}
 */
export const getNextStep = (id, buttonName) => (dispatch, getState) => {
  const { [id]: current } = getState().screener.steps
  const { navigation } = current
  const { always, callback, ifElse } = navigation[buttonName].updateCurrentStep

  if (always) {
    return always
  } else if (callback) {
    const { name, args } = callback
    const fn = navigationCallbacks[name]
    return dispatch(fn(args))
  } else if (ifElse) {
    const { fieldName, comparator, ifStep, elseStep } = ifElse
    const value = screenerSelector(getState(), fieldName)
    if (value === comparator) {
      return ifStep
    } else {
      return elseStep
    }
  }
}

export const getQuestions = () =>
  axiosWithoutAuthHeaders
    .get(`${API_BASE}/screenings/new`)
    .then((response) => response.data)
    .catch(console.error)

/**
 * Copies any checkbox data into an array representing household
 * members.
 *
 * @param  {Array}      data        Data from checkboxes
 * @param  {Array}      memberArray Final array
 * @return {undefined}
 */
export const mapHouseholdData = (data, memberCount) => {
  const arr = new Array(memberCount).fill(false)

  if (data) {
    data.forEach((datum, index) => {
      if (datum !== null) {
        arr[index] = datum
      }
    })
  }

  return arr
}

/**
 *
 * @param form
 * @param fieldName
 * @param filterComparator
 * @returns {Function}
 */
export const handleHouseholdMemberCheckboxes =
  ({ form, fieldName, filterComparator }) =>
  (dispatch, getState) => {
    const householdMembers = screenerSelector(
      getState(),
      'household.household_members'
    )
    const name = fieldName.split('.')[1]

    const arr = mapHouseholdData(
      screenerSelector(getState(), fieldName),
      householdMembers.length + 1
    )

    const clientValue = arr.shift()

    dispatch(reduxFormChange(form, `client.${name}`, clientValue))

    arr.forEach((value, index) => {
      dispatch(
        reduxFormChange(
          form,
          `household.household_members[${index}][${name}]`,
          value
        )
      )
    })

    dispatch(
      filterHouseholdMembers({ fieldName: fieldName, value: filterComparator })
    )
    dispatch(resetHouseholdMembersIndex())
  }

/**
 * Clears the entire household. Setting to undefined, and ultimately removing
 * the household object from future patch requests deletes the household on
 * the backend.
 *
 * @return {Object}
 */
export const clearHousehold = () => (dispatch) => {
  dispatch(updateFilteredHouseholdMembers(null))
  dispatch(reduxFormChange(SCREENER_FORM_NAME, 'household', null))
  dispatch(
    reduxFormChange(SCREENER_FORM_NAME, 'client.has_household_members', false)
  )
}

/**
 * Returns the field name without 'client' or 'household'
 *
 * @param  {String} fieldName Entire field name
 * @return {String}
 */
export const getBaseFieldName = (fieldName) => fieldName.split('.')[1]

/**
 * Gets the fieldnames associated with a household member
 *
 * @param  {Array}  fieldNames            Fields to clear
 * @param  {Number} householdMembersIndex Household member's fields to select
 * @return {Array}                        All fields for the household member
 */
export const getHouseholdMemberFieldNames = (
  fieldNames,
  householdMembersIndex,
  filteredHouseholdMembers,
  includeSourceFields
) => {
  let originFieldNames = []
  let destinationFieldNames

  const filteredHouseholdMemberIndex =
    filteredHouseholdMembers?.[householdMembersIndex]?.index
  const isClient = filteredHouseholdMemberIndex === null

  if (includeSourceFields) {
    originFieldNames = fieldNames.map(
      (fieldName) =>
        `household.${getBaseFieldName(fieldName)}[${householdMembersIndex}]`
    )
  }

  destinationFieldNames = fieldNames.map((fieldName) =>
    isClient
      ? `client.${getBaseFieldName(fieldName)}`
      : `household.household_members[${
          typeof filteredHouseholdMemberIndex === 'undefined'
            ? householdMembersIndex
            : filteredHouseholdMemberIndex
        }][${getBaseFieldName(fieldName)}]`
  )

  return destinationFieldNames.concat(originFieldNames)
}

/**
 * Get field names to clear across the entire household.
 *
 * @param  {Array}  options.fieldNames                Fields to clear
 * @param  {[type]} options.excludeClient             Flag to ignore client fields
 * @param  {[type]} options.householdMembers          The household members
 * @param  {[type]} options.filteredHouseholdMembers  Filtered (possibly including the client) household members
 * @return {Array}                                    All fields that need to be cleared
 */
export const getHouseholdFieldNames = ({
  includeSourceFields,
  fieldNames,
  excludeClient,
  householdMembers,
  filteredHouseholdMembers,
}) => {
  let destinationFieldNames = []
  let baseFieldName

  fieldNames.forEach((name) => {
    baseFieldName = getBaseFieldName(name)

    destinationFieldNames = destinationFieldNames.concat(
      householdMembers.map(
        (_member, i) => `household.household_members[${i}].${baseFieldName}`
      )
    )

    if (includeSourceFields) {
      destinationFieldNames = destinationFieldNames.concat(
        householdMembers.map(
          (_member, i) =>
            `household.${baseFieldName}[${excludeClient ? i : i + 1}]`
        )
      )
    }

    if (!excludeClient) {
      destinationFieldNames.push(`client.${baseFieldName}`)
      if (!filteredHouseholdMembers) {
        destinationFieldNames.push(`household.${baseFieldName}[0]`)
      }
    }

    if (filteredHouseholdMembers) {
      destinationFieldNames = destinationFieldNames.concat(
        filteredHouseholdMembers.map(
          (_member, i) => `household.${baseFieldName}[${i}]`
        )
      )
    }
  })

  return destinationFieldNames
}

/**
 * Retrieves the names of the fields that need to be cleared, and dispatches
 * the clear field action for all of them. Depending on the parameters used in
 * the callback, there will be different sets of fields targeted for clearing.
 *
 * @param  {Array}    fieldNames                  Fields to clear
 * @param  {Boolean}  currentHouseholdMemberOnly  Only clear fields for the current household member
 * @param  {Boolean}  sourceFieldsOnly            Only clear the original input fields
 * @param  {Boolean}  excludeClient               Don't clear the associated client fields
 * @param  {Number}   householdMembersIndex       Current household member
 * @param  {Array}    filteredHouseholdMembers    Filtered (possibly including client) household members
 * @param  {Array}    householdMembers            The household members
 *
 * @return {undefined}
 */
export const setHouseholdFieldsTo =
  ({
    includeSourceFields,
    fieldNames,
    currentHouseholdMemberOnly,
    sourceFieldsOnly,
    excludeClient,
    householdMembersIndex,
    filteredHouseholdMembers,
    householdMembers,
    value,
  }) =>
  (dispatch) => {
    let names

    if (currentHouseholdMemberOnly) {
      names = getHouseholdMemberFieldNames(
        fieldNames,
        householdMembersIndex,
        filteredHouseholdMembers,
        includeSourceFields
      )
    } else if (sourceFieldsOnly) {
      names = fieldNames
    } else {
      names = getHouseholdFieldNames({
        includeSourceFields,
        fieldNames,
        excludeClient,
        householdMembers,
        filteredHouseholdMembers,
      })
    }

    dispatch(reduxFormSetFieldsTo(names, value))
  }

/**
 *
 * @param {*} fieldName
 */
const reduxFormSetFieldsTo = (fieldNames, value) => (dispatch) => {
  for (let name of fieldNames) {
    dispatch(reduxFormChange(SCREENER_FORM_NAME, name, value))
  }
}

/**
 * Screener question callback to clear one or more fields.
 *
 * Dispatches setFieldsTo with value = undefined to effectively remove
 * the field value from redux-form
 */
export const clearFields = (args) => (dispatch) =>
  dispatch(setFieldsTo({ ...args, value: undefined }))

/**
 *
 * @param {*} param0
 */
export const setFieldsTo =
  ({
    includeSourceFields,
    fieldNames,
    currentHouseholdMemberOnly,
    sourceFieldsOnly,
    excludeClient,
    value,
  }) =>
  (dispatch, getState) => {
    const state = getState()

    const { householdMembersIndex, filteredHouseholdMembers } = state.screener

    const householdMembers = screenerSelector(
      state,
      'household.household_members'
    )

    if (!filteredHouseholdMembers && !householdMembers) {
      dispatch(reduxFormSetFieldsTo(fieldNames, value))
    } else {
      dispatch(
        setHouseholdFieldsTo({
          includeSourceFields,
          fieldNames,
          currentHouseholdMemberOnly,
          sourceFieldsOnly,
          excludeClient,
          householdMembersIndex,
          filteredHouseholdMembers,
          householdMembers,
          value,
        })
      )
    }
  }

export const handleHouseholdMemberExcludingClientCheckboxes =
  ({ form, fieldName, filterComparator }) =>
  (dispatch, getState) => {
    const householdMembers = screenerSelector(
      getState(),
      'household.household_members'
    )
    const name = fieldName.split('.')[1]

    const arr = mapHouseholdData(
      screenerSelector(getState(), fieldName),
      householdMembers.length
    )

    arr.forEach((value, index) => {
      dispatch(
        reduxFormChange(
          form,
          `household.household_members[${index}][${name}]`,
          value
        )
      )
    })

    dispatch(
      filterHouseholdMembers({ fieldName: fieldName, value: filterComparator })
    )
    dispatch(resetHouseholdMembersIndex())
  }

export const updateCategoryIndex = (categoryIndex) => ({
  type: UPDATE_CATEGORY_INDEX,
  categoryIndex,
})

export const updateCategoryOffset = (categoryOffset) => ({
  type: UPDATE_CATEGORY_OFFSET,
  categoryOffset,
})

export const updateHouseholdMembersIndex = (householdMembersIndex) => ({
  type: UPDATE_HOUSEHOLD_MEMBERS_INDEX,
  householdMembersIndex,
})

export const handleCategorySelectors = (props) => (dispatch, getState) => {
  const { fieldName } = props
  const rawData = screenerSelector(getState(), fieldName)
  const hasHouseholdMembers = screenerSelector(
    getState(),
    'client.has_household_members'
  )
  const filtered = rawData
    ? Object.keys(rawData).filter((key) => rawData[key][0] === true)
    : []

  const destinationFieldName = hasHouseholdMembers
    ? `household.${fieldName}`
    : `client.${fieldName}`

  dispatch(reduxFormArrayRemoveAll(SCREENER_FORM_NAME, destinationFieldName))
  filtered.forEach((category) => {
    dispatch(
      reduxFormArrayPush(SCREENER_FORM_NAME, destinationFieldName, category)
    )
  })
}

export const handleHouseholdMemberCategorySelectors =
  ({ fieldName }) =>
  (dispatch, getState) => {
    const { categoryOffset, filteredHouseholdMembers, householdMembersIndex } =
      getState().screener
    const currentHouseholdMember =
      filteredHouseholdMembers[householdMembersIndex]
    const { index: currentHouseholdMemberIndex } = currentHouseholdMember
    const isClient = currentHouseholdMemberIndex === null
    const baseFieldName = fieldName.split('.')[1]

    // get the raw data from the field array
    const categories = screenerSelector(getState(), fieldName)

    // filter the raw data to weed out checkboxes that are NOT currently checked
    const filteredCategoryData = Object.keys(categories).filter(
      (key) => categories[key][householdMembersIndex] === true
    )

    // slice the filtered data array to get the data for the
    // specific member that we're currently iterating on
    const currentMemberFilteredCategories =
      filteredCategoryData.slice(categoryOffset)

    // calculate the correct the target field name based on whether this
    // is the client or a household member
    const destinationFieldName = isClient
      ? `client.${baseFieldName}`
      : `household.household_members[${currentHouseholdMemberIndex}][${baseFieldName}]`

    // manually set the data for the target member
    dispatch(reduxFormArrayRemoveAll(SCREENER_FORM_NAME, destinationFieldName))
    currentMemberFilteredCategories.forEach((category) => {
      dispatch(
        reduxFormArrayPush(SCREENER_FORM_NAME, destinationFieldName, category)
      )
    })

    // update the category index so that we can accurately slice the next
    // member's data from the filtered data
    dispatch(updateCategoryOffset(categories.length))
  }

/**
 *
 * @param form
 * @param fieldName
 * @param value
 * @param filtered
 * @returns {Function}
 */
export const handleHouseholdMemberYesOrNoQuestions =
  ({ form, fieldName, value, filtered }) =>
  (dispatch, getState) => {
    // need to know when we're changing an existing answer verses adding a new one

    const { returnStep: backButtonClicked } = getState().screener
    if (backButtonClicked) {
      const values = screenerSelector(getState(), fieldName)
      if (values) {
        const { householdMembersIndex } = getState().screener
        dispatch(
          reduxFormChange(form, `${fieldName}[${householdMembersIndex}]`, value)
        )
      }
    } else {
      dispatch(
        reduxFormChange(
          form,
          `${fieldName}[${getState().screener.householdMembersIndex}]`,
          value
        )
      )
    }
    if (filtered) {
      dispatch(assignToFilteredHouseholdMembers({ fieldName }))
    } else {
      dispatch(assignToHouseholdMembers({ fieldName }))
    }
  }

/**
 *
 * @param fieldName
 * @param value
 * @returns {Function}
 */
export const triggerArrayPush =
  ({ fieldName, value, useUpperBound = true }) =>
  (dispatch, getState) => {
    const { filteredHouseholdMembers, householdMembersIndex } =
      getState().screener
    if (useUpperBound) {
      const upperBound = filteredHouseholdMembers.length - 1
      if (householdMembersIndex < upperBound) {
        dispatch(reduxFormArrayPush(SCREENER_FORM_NAME, fieldName, value))
      }
    } else {
      dispatch(reduxFormArrayPush(SCREENER_FORM_NAME, fieldName, value))
    }
  }

/**
 * Removes the last household member. This facilitates backward
 * navigation while in the middle of creating a household member.
 */
export const removeLastHouseHoldMember =
  ({ always }) =>
  (dispatch, getState) => {
    dispatch(
      reduxFormArrayRemove(
        SCREENER_FORM_NAME,
        'household.household_members',
        getState().screener.householdMembersIndex
      )
    )
    return always
  }

/**
 *
 * @param loopStep
 * @param breakStep
 * @returns {Function}
 */
export const handleLoopingQuestionNavigation =
  ({ loopStep, breakStep }) =>
  (dispatch, getState) => {
    const { filteredHouseholdMembers, householdMembersIndex } =
      getState().screener
    const upperBound = filteredHouseholdMembers
      ? filteredHouseholdMembers.length - 1
      : 0
    if (householdMembersIndex === upperBound) {
      dispatch(resetHouseholdMembersIndex())
      const { always, callback: breakStepCallback } = breakStep
      if (always) {
        return always
      } else if (breakStepCallback) {
        const { name: breakStepCallbackName, args: breakStepCallbackArgs } =
          breakStepCallback
        const breakStepFn = navigationCallbacks[breakStepCallbackName]
        return dispatch(breakStepFn(breakStepCallbackArgs))
      }
    } else {
      dispatch(incrementHouseholdMembersIndex())
      const { always, callback: loopStepCallback, onLoopStep } = loopStep
      if (onLoopStep) {
        const { name: onLoopStepName, args: onLoopStepArgs } = onLoopStep
        const onLoopStepFn = navigationCallbacks[onLoopStepName]
        dispatch(onLoopStepFn(onLoopStepArgs))
      }
      if (always) {
        return always
      } else if (loopStepCallback) {
        let { name: loopStepCallbackName, args: loopStepCallbackArgs } =
          loopStepCallback
        let loopStepCallbackFn = navigationCallbacks[loopStepCallbackName]
        return dispatch(loopStepCallbackFn(loopStepCallbackArgs))
      }
    }
  }

export const handleClientCategoryIterator =
  ({
    categoryFieldName,
    targetFieldName,
    loopStep,
    breakStep,
    repeatingStep,
  }) =>
  (dispatch, getState) => {
    const { categoryIndex } = getState().screener

    const categories = screenerSelector(getState(), categoryFieldName)

    if (categoryIndex < Object.keys(categories).length - 1) {
      dispatch(incrementCategoryIndex())
      const { always, onRepeatingStep } = repeatingStep
      if (onRepeatingStep) {
        const { name, args } = onRepeatingStep
        const fn = navigationCallbacks[name]
        dispatch(fn(args))
      }
      return always
    } else {
      dispatch(resetCategoryIndex())
      dispatch(
        reduxFormArraySplice(
          SCREENER_FORM_NAME,
          targetFieldName,
          categoryIndex + 1,
          Infinity
        )
      )
      return dispatch(handleLoopingQuestionNavigation({ loopStep, breakStep }))
    }
  }

export const handleHouseholdMemberCategoryIterator =
  ({
    categoryFieldName,
    targetFieldName,
    loopStep,
    breakStep,
    repeatingStep,
  }) =>
  (dispatch, getState) => {
    const { categoryIndex, filteredHouseholdMembers, householdMembersIndex } =
      getState().screener
    const { index: householdMemberIndex } =
      filteredHouseholdMembers[householdMembersIndex]
    const isClient = householdMemberIndex === null
    const baseCategoryFieldName = categoryFieldName.split('.')[1]
    const name = isClient
      ? `client.${baseCategoryFieldName}`
      : `household.household_members[${householdMemberIndex}].${baseCategoryFieldName}`
    const categories = screenerSelector(getState(), name)

    if (categoryIndex < Object.keys(categories).length - 1) {
      dispatch(incrementCategoryIndex())
      const { always, onRepeatingStep } = repeatingStep
      if (onRepeatingStep) {
        const { name, args } = onRepeatingStep
        const fn = navigationCallbacks[name]
        dispatch(fn(args))
      }
      return always
    } else {
      dispatch(resetCategoryIndex())
      dispatch(
        reduxFormArraySplice(
          SCREENER_FORM_NAME,
          targetFieldName,
          categoryIndex + 1,
          Infinity
        )
      )
      return dispatch(handleLoopingQuestionNavigation({ loopStep, breakStep }))
    }
  }

/**
 *
 * @param currentStep
 * @param buttonName
 * @returns {Function}
 */
export const handleNavigationButtonClick =
  (currentStep, buttonName) => (dispatch, getState) => {
    const { returnStep, progressStatus } = getState().screener
    const next = dispatch(getNextStep(currentStep, buttonName))
    if (next) {
      if (returnStep && returnStep < next) {
        dispatch(resetReturnStep())
      }
      dispatch(updateCurrentStep(next))
      dispatch(updateProgress(next, progressStatus, returnStep))
    }
  }

/**
 * Handles errors when submitting any of the forms in the screener wizard
 *
 * @param  {[type]} error    The error
 * @param  {[type]} navigate) Navigation history
 * @return {[type]}          Action
 */
export const handlePatchScreenerError = (error, navigate) => {
  if (!isJestTest()) console.error(error) // so we can see what the original erro was

  if (error?.response?.status === 401) {
    navigate('/login')
  } else {
    throw new SubmissionError({ _error: ERROR_GENERIC_MESSAGE })
  }
}

/**
 *
 * @param fieldName
 * @param comparator
 * @param ifStep
 * @param elseStep
 * @returns {Function}
 */
export const ifAnyHouseholdMember =
  ({ fieldName, comparator, ifStep, elseStep }) =>
  (dispatch, getState) => {
    dispatch(resetHouseholdMembersIndex())
    dispatch(
      filterHouseholdMembers({ fieldName: fieldName, value: comparator })
    )
    const { filteredHouseholdMembers } = getState().screener
    if (filteredHouseholdMembers.length) {
      return ifStep
    } else {
      return elseStep
    }
  }

/**
 *
 * @param fieldName
 * @param comparator
 * @param ifStep
 * @param elseStep
 * @returns {Function}
 */
export const ifCurrentFilteredHouseholdMember =
  ({ fieldName, comparator, ifStep, elseStep }) =>
  (dispatch, getState) => {
    const { filteredHouseholdMembers, householdMembersIndex } =
      getState().screener
    const member = filteredHouseholdMembers[householdMembersIndex]
    if (member[fieldName] === comparator) {
      return ifStep
    } else {
      return elseStep
    }
  }

/**
 *
 * @param fieldName
 * @param comparator
 * @param ifStep
 * @param elseStep
 * @returns {Function}
 */
export const ifCurrentHouseholdMember =
  ({ fieldName, comparator, ifStep, elseStep }) =>
  (dispatch, getState) => {
    const householdMembers = screenerSelector(
      getState(),
      'household.household_members'
    )
    const { householdMembersIndex } = getState().screener
    const member = householdMembers[householdMembersIndex]
    if (member[fieldName] === comparator) {
      return ifStep
    } else {
      return elseStep
    }
  }

export const incrementCategoryIndex = () => ({
  type: INCREMENT_CATEGORY_INDEX,
})

export const incrementHouseholdMembersIndex = () => ({
  type: INCREMENT_HOUSEHOLD_MEMBERS_INDEX,
})

export const decrementHouseholdMembersIndex = () => ({
  type: DECREMENT_HOUSEHOLD_MEMBERS_INDEX,
})

export const updateMetaDataFromServer = (metaData) => (dispatch) => {
  const {
    categoryIndex,
    categoryOffset,
    filteredHouseholdMembers,
    householdMembersIndex,
  } = metaData
  dispatch(updateCategoryIndex(categoryIndex))
  dispatch(updateCategoryOffset(categoryOffset))
  dispatch(updateFilteredHouseholdMembers(filteredHouseholdMembers))
  dispatch(updateHouseholdMembersIndex(householdMembersIndex))
}

export const handleUpdateScreeningOrgAndLoc = (orgAndLoc) => (dispatch) => {
  const { organization, location } = orgAndLoc
  if (organization) {
    sessionStorage.setItem('organization', organization)
    dispatch(updateScreeningOrganization(organization))
  }
  if (location) {
    sessionStorage.setItem('location', location)
    dispatch(updateScreeningLocation(location))
  }
}

export const loadScreener = () => (dispatch) => {
  dispatch(populateScreener())
  dispatch(loadQuestions())
}

export const loadQuestions = () => (dispatch) => {
  mockGetQuestions().then((response) => {
    const { data } = response
    const { steps: questions } = data
    dispatch(updateScreenerQuestions(questions))
  })
}

export const populateScreener = () => (dispatch) =>
  new Promise((resolve) => {
    // ToDo: should the 1 in 1.json be a variable?
    const url = `${API_BASE}/clients/${getClientId()}/screenings/1.json`

    return axiosWithAuthHeaders
      .get(url)
      .then(({ data }) => {
        const {
          currentStep,
          returnStep,
          frontEndState: { formValues, metadata, progressStatus },
        } = data

        // ToDo: We should always get the currentStep, not just when it's a returning user
        const step = currentStep || FIRST_STEP
        dispatch(updateCurrentStep(step))
        dispatch(updateProgress(step, progressStatus, returnStep))

        if (metadata) {
          dispatch(updateMetaDataFromServer(metadata))
        }
        if (formValues) {
          dispatch(updateInitialValues(formValues))
        }
        dispatch(loadQuestions())
        resolve({ currentStep })
      })
      .catch((error) => resolve(error))
  })

export const mockGetQuestions = () => Promise.resolve({ data: questions })

export const mockGetFrontEndState = () =>
  Promise.resolve({ data: frontEndState })

export const patchScreener = (values, navigate) => (dispatch, getState) => {
  const { screenings, screenerCompleted } = getState().client
  const id = getClientId()

  const {
    categoryIndex,
    categoryOffset,
    currentStep,
    filteredHouseholdMembers,
    progressStatus,
    householdMembersIndex,
  } = getState().screener

  const url = `${API_BASE}/clients/${id}/screenings/${screenings}.json`

  const requestBody = {
    screening: {
      currentStep,
      screenerCompleted,
      frontEndState: {
        progressStatus,
        formValues: values,
        metadata: {
          categoryIndex,
          categoryOffset,
          filteredHouseholdMembers,
          householdMembersIndex,
        },
      },
    },
  }

  return axiosWithAuthHeaders.patch(url, requestBody).then(({ data }) => {
    const { completedAt } = data
    if (completedAt && screenerCompleted) {
      dispatch(updateClient({ screenerCompleted: true }))
      navigate('/completed-screener')
    }
  })
}

export const resetCategoryIndex = (args) => (dispatch) => {
  args?.always && dispatch(updateCurrentStep(args?.always))
  dispatch({
    type: RESET_CATEGORY_INDEX,
  })
}

export const resetCategoryOffest = () => ({
  type: RESET_CATEGORY_OFFSET,
})

export const resetHouseholdMembersIndex = () => ({
  type: RESET_HOUSEHOLD_MEMBERS_INDEX,
})

export const resetReturnStep = () => ({
  type: RESET_RETURN_STEP,
})

export const updateCurrentStep = (step) => ({
  type: UPDATE_CURRENT_STEP,
  step,
})

export const updateFilteredHouseholdMembers = (householdMembers) => ({
  type: UPDATE_FILTERED_HOUSEHOLD_MEMBERS,
  householdMembers,
})

export const updateInitialValues = (initialValues) => ({
  type: UPDATE_INITIAL_VALUES,
  initialValues,
})

export const updateReturnStep = (returnStep) => ({
  type: UPDATE_RETURN_STEP,
  returnStep,
})

export const updateScreeningLocation = (screeningLocation) => ({
  type: UPDATE_SCREENING_LOCATION,
  screeningLocation,
})

export const updateScreeningOrganization = (screeningOrganization) => ({
  type: UPDATE_SCREENING_ORGANIZATION,
  screeningOrganization,
})

export const updateScreenerQuestions = (questions) => ({
  type: UPDATE_SCREENER_QUESTIONS,
  questions,
})

/**
 * Redux Form onSubmit for Screener Wizard
 *
 * @param  {[type]} props   [description]
 * @param  {[type]} dispatch [description]
 * @param  {[type]} props    [description]
 * @return {[type]}          [description]
 *
 * {@link https://redux-form.com/8.3.0/docs/api/submissionerror.md SubmissionError}.
 */
export const onSubmit = (props) => (dispatch, getState) => {
  if (window.navigator.onLine) {
    const { navigate } = props
    return dispatch(
      patchScreener(screenerFormValuesSelector(getState()), navigate)
    ).catch((err) => {
      if (err.response && err.response.status === 401) {
        navigate('/login')
      } else {
        handleApiError(err)
      }
    })
  }
}

export const clearScreener = () => ({
  type: CLEAR_SCREENER,
})

export const updateProgress = (step, progressStatus, returnStep) => {
  const status = addToCounters(step, progressStatus, returnStep)
  return {
    type: UPDATE_PROGRESS,
    progressStatus: status,
  }
}

export const updateHasCustomQuestions = (hasCustomQuestions) => ({
  type: UPDATE_HAS_CUSTOM_QUESTIONS,
  hasCustomQuestions,
})

export const handleHouseholdMemberMultiAnswer =
  ({ fieldName }) =>
  (dispatch, getState) => {
    const { householdMembersIndex } = getState().screener
    const isClient = fieldName.split('.')[0] !== 'household'
    const rawData = screenerSelector(getState(), fieldName)

    const filtered = rawData
      ? Object.keys(rawData).filter(
          (key) => rawData[key][householdMembersIndex] === true
        )
      : []

    if (isClient) {
      dispatch(
        reduxFormChange(SCREENER_FORM_NAME, `client.${fieldName}`, filtered)
      )
    } else {
      const baseFieldName = fieldName.split('.')[1]
      const destinationFieldName = `household.household_members[${householdMembersIndex}].${baseFieldName}`

      dispatch(
        reduxFormChange(SCREENER_FORM_NAME, destinationFieldName, filtered)
      )
    }
  }

const navigationCallbacks = {
  handleClientCategoryIterator,
  handleHouseholdMemberCategoryIterator,
  handleLoopingQuestionNavigation,
  ifAnyHouseholdMember,
  ifCurrentFilteredHouseholdMember,
  ifCurrentHouseholdMember,
  triggerArrayPush,
  removeLastHouseHoldMember,
  resetCategoryIndex,
}
