import React from 'react'

const healthIconPath = (
  <g id='a'>
    <path
      d='M39.094.66c21.58-4.943,22.456,18.5,22.445,49.629s4.3,66.836-17.278,71.782S-.011,97.107,0,65.977,17.513,5.6,39.094.66Z'
      transform='translate(21.395 129.161) rotate(-115)'
      fill='rgba(255,171,171,0.6)'
      opacity='0.8'
    />
    <g transform='translate(14.019 17)'>
      <path
        d='M74.517,43.042a29.265,29.265,0,0,0,1.254-5.349c.421-3.594.669-8.776-1.839-13.042a19.133,19.133,0,0,0-25.075-7.774,28.7,28.7,0,0,0-6.687,5.183,29.5,29.5,0,0,0-6.942-5.1,16.776,16.776,0,0,0-14.377-1.086c-6.687,2.592-11.869,8.86-12.789,15.461-.836,6.353,1.421,13.624,6.186,19.976,5.354,7.109,17.891,15.3,18.474,15.635a2.376,2.376,0,0,0,1,.334,2.02,2.02,0,0,0,1.588-.836,1.829,1.829,0,0,0-.585-2.592c-.167-.083-12.537-8.191-17.469-14.711-4.179-5.6-6.186-11.869-5.517-17.219.752-5.349,4.932-10.364,10.448-12.537a12.983,12.983,0,0,1,11.2.919,21.386,21.386,0,0,1,7.1,5.68,1.862,1.862,0,0,0,1.421.752,2.014,2.014,0,0,0,1.5-.585C44.676,24.9,48.27,21.3,50.443,20.3a15.356,15.356,0,0,1,14.377.334,15.541,15.541,0,0,1,5.934,5.934c1.923,3.261,1.671,7.69,1.338,10.7a22.245,22.245,0,0,1-1.505,5.606H68.08V37.358a4.013,4.013,0,0,0-4.015-4.013h-5.6a4.013,4.013,0,0,0-4.013,4.013v5.517H48.94A3.924,3.924,0,0,0,45.011,46.8v5.6a4,4,0,0,0,3.928,4.013h5.517v3.929c-1.588,1.171-3.092,2.257-4.6,3.261l-1,.669c-.585.421-1.171.836-1.671,1.254a38.132,38.132,0,0,1-3.261,2.257l-.5.334c-.334.167-1,.669-1.086.669a9.828,9.828,0,0,1-1.588-.836l-.334-.25a1.878,1.878,0,1,0-2.006,3.176l.335.167a7.283,7.283,0,0,0,3.511,1.421h0a7.063,7.063,0,0,0,3.176-1.254l.334-.25c1.254-.752,2.419-1.588,3.594-2.419a20.276,20.276,0,0,1,1.671-1.171l1-.669c1.086-.752,2.173-1.5,3.344-2.34a3.964,3.964,0,0,0,3.092,1.5h5.6a4.068,4.068,0,0,0,4.013-4.013V56.331h5.433a4.013,4.013,0,0,0,4.017-4.011v-5.6a4.1,4.1,0,0,0-3.011-3.677Zm-.836,9.445a.222.222,0,0,1-.25.25H66.074a1.8,1.8,0,0,0-1.839,1.839v7.355a.267.267,0,0,1-.25.25h-5.6a.222.222,0,0,1-.25-.25V54.576A1.855,1.855,0,0,0,56.3,52.737H48.94c-.083,0-.167-.083-.167-.25v-5.6a.18.18,0,0,1,.167-.167h7.355a1.855,1.855,0,0,0,1.839-1.831V37.521a.267.267,0,0,1,.25-.25h5.6a.267.267,0,0,1,.25.25v7.362a1.855,1.855,0,0,0,1.839,1.839h7.354c.083,0,.25.083.25.167,0-.007,0,5.6,0,5.6Z'
        transform='translate(-7.891 -14.747)'
      />
      <path
        d='M26.314,51.738a1.781,1.781,0,0,1-1.338-.585c-5.6-5.6-7.857-11.2-7.1-17.474a1.858,1.858,0,1,1,3.681.5c-.669,5.1,1.171,9.528,6.018,14.377a1.867,1.867,0,0,1,0,2.675,1.843,1.843,0,0,1-1.258.506Z'
        transform='translate(-9.505 -17.592)'
      />
    </g>
  </g>
)

export default healthIconPath
