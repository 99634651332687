import React from 'react'

const UnLikeIconPath = (
  <>
    <g transform='translate(0 0.285)'>
      <rect
        className='a'
        fill='none'
        width='32'
        height='32'
        transform='translate(0 -0.285)'
      />
      <path
        className='b'
        fill='#fff'
        d='M10.319,6.253,8.343,4.263A13.625,13.625,0,0,1,27.224,23.144l-1.99-1.99a10.727,10.727,0,0,0,1.527-5.521A10.882,10.882,0,0,0,10.319,6.253Zm13.251,6.012-1.922-1.922-3.613,3.613,1.922,1.922ZM26.461,30.1,23.366,27A13.625,13.625,0,0,1,4.485,8.121L1.39,5.026,3.312,3.1,28.369,28.161Zm-5.071-5.085-5.29-5.29L13.932,21.89l-5.78-5.78,1.922-1.922,3.858,3.858.245-.245-7.7-7.7A10.882,10.882,0,0,0,21.389,25.012Z'
        transform='translate(0.146 0.367)'
      />
    </g>
  </>
)

export default UnLikeIconPath
