import { useNavigate } from 'react-router-dom'
import useStep from 'Components/PreScreener/Hooks/useStep'

export const useRoutes = () => {
  const { handleUpdateStep } = useStep()
  const navigate = useNavigate()

  const handleRouteToScreener = () => {
    navigate('/screening')
  }

  const handleRouteToScreenerCompleted = () => {
    navigate('/completed-screener')
  }

  const handleRouteToPreScreener = (url) => {
    handleUpdateStep(6)
    navigate(url)
  }

  const handleRouteToCustomQuestions = () => {
    navigate('/questions')
  }

  return {
    handleRouteToPreScreener,
    handleRouteToScreener,
    handleRouteToScreenerCompleted,
    handleRouteToCustomQuestions,
  }
}

const useRouteToOnboarding = () => {
  const {
    handleRouteToPreScreener,
    handleRouteToScreener,
    handleRouteToScreenerCompleted,
    handleRouteToCustomQuestions,
  } = useRoutes()

  const handleRouteToOnboarding = (onboardingSection, registrationUrl) => {
    switch (onboardingSection) {
      case 'COMPLETED_SCREENER':
        handleRouteToScreenerCompleted()
        break

      case 'PRESCREENER':
        handleRouteToPreScreener(registrationUrl)
        break

      case 'SCREENER':
        handleRouteToScreener()
        break

      case 'CUSTOM_FIELDS':
        handleRouteToCustomQuestions()
        break

      default:
        handleRouteToScreener()
        break
    }
  }
  return {
    handleRouteToOnboarding,
  }
}

export default useRouteToOnboarding
