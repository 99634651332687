import { ApolloLink, Observable } from '@apollo/client'
import { loadingStopped } from 'Reducers/uiSlice'
import store from 'Store'

const loadingIndicatorLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const subscription = forward(operation).subscribe({
      next: (result) => observer.next(result),
      error: (error) => {
        observer.error(error)
        store.dispatch(loadingStopped())
      },
      complete: () => {
        observer.complete()
        store.dispatch(loadingStopped())
      },
    })

    return () => subscription.unsubscribe()
  })
})

export default loadingIndicatorLink
