import { withStyles, FormControl } from '@material-ui/core'

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
    '& > div': {
      border: '0.0625rem solid rgba(0, 0, 0, 0.23)',
      borderRadius: '0.375rem',
      padding: '1.15625rem 0.875rem',
    },
    '& input': {
      color: '#29283E',
      fontSize: '1rem',
      fontWeight: '600',
    },
    '& p': {
      fontSize: '.875rem',
      fontWeight: '400',
    },
    '&&:hover': {
      borderColor: 'rgb(0, 0, 0)',
    },
  },
})(FormControl)
