import React from 'react'
import Header from 'Components/Shared/Header/Header'
import FilterMenu from 'Components/LocalResources/Filtering/FilterMenu/FilterMenu'
import {
  StyledTabletAndDesktopLeftColumn,
  StyledTabletContainer,
} from './style'
import LocationsList from './LocationsList/LocationsList'
import LocationsMap from 'Components/LocalResources/LocationsMap/LocationsMap'
import LocationDetail from 'Pages/LocationDetail/LocationDetail'
import ActionToggle from 'Components/Shared/ActionToggle/ActionToggle'
import SearchBox from 'Components/LocalResources/SearchBox/SearchBox'
import SearchBar from 'Components/Shared/SearchBar/SearchBar'
import filterIconPath from 'Shared/Icons/filterIconPath'
import closeIconPath from 'Shared/Icons/closeIconPath'
import { isDesktop } from 'react-device-detect'
import DisplayLogo from 'Components/LocalResources/DisplayLogo/DisplayLogo'
import { StyledMain } from 'Pages/NextSteps/style'
import { AlgoliaLocation } from './AlgoliaLocation'
import { useLocation, useParams } from 'react-router-dom'

type LocationsViewportPageProps = {
  device?: string
  filterMenuClickHandler: Function
  locationId?: number
  locations?: AlgoliaLocation[]
  modalLayout?: boolean
  setLocationId?: (locationId: number) => void
  showDesktopDetailView: boolean
  showFilterMenu: boolean
  showHeader?: boolean
  showLeftColumn: boolean
  showList: boolean
  showLocationCard?: boolean
  showLocationDetail: boolean
  zeroSearchResults?: boolean
  zipCode: string
}

const DesktopLocations = ({
  filterMenuClickHandler,
  locations,
  modalLayout,
  showDesktopDetailView,
  showFilterMenu,
  showLocationCard,
  showLeftColumn,
  showList,
  showLocationDetail,
  zeroSearchResults,
  zipCode,
  locationId,
  showHeader,
  setLocationId,
}: LocationsViewportPageProps) => {
  const { pathname } = useLocation()

  const params = useParams<{ id: string }>()

  const paramId =
    params.id && window.location.pathname.includes('locations')
      ? Number(params.id)
      : ''

  return (
    <>
      {showHeader && <Header pathname={pathname} />}
      <StyledMain>
        <StyledTabletContainer>
          {isDesktop && <DisplayLogo isSideOpen={showLeftColumn} />}
          {showLeftColumn && (
            <StyledTabletAndDesktopLeftColumn modalLayout={modalLayout}>
              {!showLocationDetail && showList && (
                <>
                  <SearchBar>
                    <SearchBox initialValue={zipCode} />
                    <ActionToggle
                      className={'cypress-filter-menu-toggle'}
                      activeIconPath={closeIconPath}
                      activeLabel={'filters'}
                      handleOnClick={filterMenuClickHandler}
                      inactiveIconPath={filterIconPath}
                      inactiveLabel={'filters'}
                      fill={'rgba(0,0,0,0.65)'}
                      showActive={showFilterMenu}
                    />
                  </SearchBar>
                  <FilterMenu onClick={filterMenuClickHandler} />
                  <LocationsList
                    locationID={locationId}
                    locations={locations}
                    modalLayout={modalLayout}
                    setLocationId={setLocationId}
                  />
                </>
              )}
              {showDesktopDetailView && !locationId && (
                <LocationDetail id={paramId} />
              )}
              {showDesktopDetailView && locationId && (
                <LocationDetail id={locationId} setLocationId={setLocationId} />
              )}
            </StyledTabletAndDesktopLeftColumn>
          )}
          <LocationsMap
            locationID={locationId || paramId}
            setLocationId={setLocationId ? setLocationId : undefined}
            showLocationCard={showLocationCard}
            zeroSearchResults={zeroSearchResults}
            locations={locations}
            zipCode={zipCode}
            modalLayout={modalLayout}
          />
        </StyledTabletContainer>
      </StyledMain>
    </>
  )
}

export default DesktopLocations
