import React from 'react'
import { StyledButtonsContainer } from '../style'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'

const ConfirmOrCancel = ({
  handleOnCancel,
  handleOnConfirm,
  confirmLabel,
  confirmOnly,
  cancelLabel,
  confirmButtonType = undefined,
  isReadOnly,
  isHidden,
  submitting,
}) => {
  return (
    <StyledButtonsContainer>
      {!confirmOnly && (
        <Button
          mdFontSize='0.8125rem'
          mdMinWidth='10.25rem'
          mdMinHeight='3rem'
          fontWeight='500'
          marginSmall='1rem'
          secondary
          md
          modalMobile
          disabled={submitting}
          onClick={handleOnCancel}
          margin={isHidden ? '0' : '0 1rem 0 0'}
          data-testid='rtl-cancel-button'
        >
          {isReadOnly ? 'close' : 'cancel'}
        </Button>
      )}

      <Button
        mdFontSize='0.8125rem'
        mdMinWidth='10.25rem'
        mdMinHeight='3rem'
        fontWeight='500'
        marginSmall='1rem'
        primary
        md
        modalMobile
        type={confirmButtonType}
        onClick={handleOnConfirm}
        disabled={isReadOnly || submitting}
        isHidden={isHidden}
        data-testid='rtl-confirm-button'
      >
        {confirmLabel}
      </Button>
    </StyledButtonsContainer>
  )
}

export default ConfirmOrCancel
