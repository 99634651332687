import styled from 'styled-components/macro'
import Surface from 'Components/Shared/Surface/Surface'
import { media } from 'styled-bootstrap-grid'

export const StyledInput = styled.input`
  font-size: 1.6rem;
  flex-grow: 1;
  padding: 0rem 0.4em;
  &:focus {
    outline: unset;
  }
  border: none;
`

export const StyledSearchBox = styled.div`
  border-radius: 7px;
  display: flex;
  position: relative;
  ${media.smaller`
    margin: 1rem;
  `}
`

export const StyledLabelSearchBox = styled.label`
  display: flex;
  width: 100%;
`

export const StyledSurface = styled(Surface)``
StyledSurface.displayName = 'StyledSurface'
StyledInput.displayName = 'StyledInput'
StyledSearchBox.displayName = 'StyledSearchBox'
StyledLabelSearchBox.displayName = 'StyledLabelSearchBox'
