export interface UiState {
  map: {
    locationID: number | null
  }
  loading: boolean
  mountScreener: boolean
  showCaseManagementFilter: boolean
  showCookiesConsent: boolean
  showFilterMenu: boolean
  showLeftColumn: boolean
  showLocationCard: boolean
  showLocationDetail: boolean
  showLocationList: boolean
  showLocationRequest: boolean
  showLoginLink: boolean
  showLogo: boolean
  showMainMenu: boolean
  showZeroSearchResultsMessage: boolean
  rehydrated: boolean
  persisted: boolean
}

export const ui: UiState = {
  map: {
    locationID: null,
  },
  showFilterMenu: false,
  showLocationCard: false,
  showLocationDetail: false,
  showLocationList: false,
  showMainMenu: false,
  showLocationRequest: true,
  showZeroSearchResultsMessage: false,
  showLogo: true,
  showCookiesConsent: true,
  showLoginLink: false,
  mountScreener: false,
  showLeftColumn: true,
  loading: false,
  showCaseManagementFilter: false,
  rehydrated: false,
  persisted: false,
}
