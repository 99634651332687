import gql from 'graphql-tag'
import { CUSTOM_FIELDS } from 'Components/Screener/Hooks/gql'

export const QUERY_GET_RESPONSES = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      ...ClientFields
    }
  }

  fragment ClientFields on Client {
    id
    displayDateOfBirth @client
    nickName
    ...PersonFields
    email
    primaryPhone
    alternateEmail
    alternatePhone
    user {
      id
      verified
      __typename
    }
    zipCode
    languages
    household {
      ...HouseholdFields
    }
    ...CustomFields
    lastYearAdjustedGrossIncome
    taxFilingStatus
    screening {
      legacy
    }
  }

  fragment HouseholdFields on Household {
    id
    size
    situation
    expense {
      ...ExpenseFields
    }
    members {
      ...HouseholdMemberFields
    }
    utilityExpenses {
      ...UtilityExpenseFields
    }
  }

  fragment HouseholdMemberFields on HouseholdMember {
    id
    ...PersonFields
    relationshipToClient
  }

  fragment DemographicFields on Demographic {
    id
    gender
    race
    ethnicity
    education
    healthInsurance
    hasHealthInsurance
    isStudent
    isVeteran
    isDisabled
    pregnancyStatus
    isUsCitizen
    immigrationStatus
    lengthOfPermanentResidency
    courseLoad
    hasWorkStudy
    hasEfc
    isEligibleForWorkStudy
    costOfAttendance
    expectedFamilyContribution
    displayEfc @client
    displayCoa @client
  }

  fragment PersonFields on Person {
    fullName
    firstName
    lastName
    age
    dateOfBirth
    claimedAsDependent

    demographic {
      ...DemographicFields
    }
    employments {
      ...EmploymentFields
    }
    financialAssets {
      ...FinancialAssetFields
    }
    additionalIncomes {
      ...AdditionalIncomeFields
    }
    legacy
  }

  fragment ExpenseFields on Expense {
    id
    hasAdultCareExpenses
    hasChildcareExpenses
    hasEducationExpenses
    hasRentOrMortgageExpenses
    hasOutOfPocketMedicalExpenses
    hasChildSupportExpenses
    adultCareExpensesAmount
    childcareExpensesAmount
    outOfPocketMedicalExpensesAmount
    childSupportExpensesAmount
    rentOrMortgageExpensesAmount
  }

  fragment FinancialAssetFields on FinancialAsset {
    id
    amount
    source
  }

  fragment EmploymentFields on Employment {
    id
    position
    status
    incomeAmount
    payFrequency
  }

  fragment UtilityExpenseFields on UtilityExpense {
    id
    source
  }

  fragment AdditionalIncomeFields on AdditionalIncome {
    id
    position
    source
    incomeAmount
  }
  ${CUSTOM_FIELDS}
`

export const ALTERNATE_CONTACT_MUTATION = gql`
  mutation saveAlternateContact(
    $clientId: ID!
    $alternateContact: String
    $alternateContactType: ClientAlternateContactType!
  ) {
    updateClientAlternateContactInfo(
      clientId: $clientId
      alternateContact: $alternateContact
      alternateContactType: $alternateContactType
    ) {
      client {
        alternatePhone
        alternateEmail
      }
    }
  }
`
