import { getScreeningType, getUserType } from 'Shared/helpers'

/**
 * Creates a Sentry User object.
 *
 * {@link https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/|Identify Users}
 *
 * @param {*} data Apollo query result data
 * @returns Sentry user object
 */
export const getSentryUserFromOtp = (data) => ({
  id: data?.userVerifyOtp?.authenticatable?.id,
  username: data?.userVerifyOtp?.credentials?.uid,
  email: data?.userVerifyOtp?.credentials?.uid,
  user_type: getUserType(),
  screening_type: getScreeningType(),
})

export const titleMap = [
  { path: '/locations/list', title: 'Single Stop™ - Locations' },
  { path: '/locations/map', title: 'Single Stop™ - Locations' },
  { path: '/locations/', title: 'Single Stop™ - Location Details' },
  { path: '/registration', title: 'Single Stop™ - Registration' },
  {
    path: '/registration',
    title: 'Single Stop™ - Registration',
  },
  { path: '/questions', title: 'Single Stop™ - Questions' },
  { path: '/screening', title: 'Single Stop™ - Screening' },
  {
    path: '/completed-screener',
    title: 'Single Stop™ - Completed Screener',
  },
  { path: '/next-steps', title: 'Single Stop™ - Next Steps' },
  {
    path: '/next-steps/preferred-partners',
    title: 'Single Stop™ - Next Steps - Preferred Partners',
  },
  {
    path: '/next-steps/documentation',
    title: 'Single Stop™ - Next Steps - Documentation',
  },
  {
    path: '/next-steps/apply-online/',
    title: 'Single Stop™ - Next Steps - Apply Online',
  },
  { path: '/case-management/reports', title: 'Single Stop™ - Reports' },
  {
    path: '/case-management/login',
    title: 'Single Stop™ - Case Management Login',
  },
  { path: '/case-management', title: 'Single Stop™ - Case Management' },
  { path: '/about', title: 'Single Stop™ - About' },
  { path: '/account', title: 'Single Stop™ - Account' },
  { path: '/login', title: 'Single Stop™ - Login' },
  { path: '/authenticate', title: 'Single Stop™ - Authenticate' },
  { path: '/checkemail', title: 'Single Stop™ - Check Email' },
  { path: '/review', title: 'Single Stop™ - Review' },
  { path: '/review/:action?/:section?', title: 'Single Stop™ - Review' },
  { path: '/privacy-policy', title: 'Single Stop™ - Privacy Policy' },
  {
    path: '/terms-and-conditions',
    title: 'Single Stop™ - Terms and Conditions',
  },
]
