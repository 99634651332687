import useInitializeForms from 'Components/Shared/Hooks/useInitializeForms'
import React from 'react'
import Step from './Step'

const Steps = ({ steps, householdMembersIndex }) => {
  useInitializeForms()

  return steps.map((step, index) => (
    <Step {...step} key={index} householdMembersIndex={householdMembersIndex} />
  ))
}

export default Steps
