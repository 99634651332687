import SelectMenuOptions from 'Components/Shared/ReduxForm/SelectMenu/SelectMenuOptions'

const CalendarYears = (props) => {
  const years = [{ value: '', label: '' }]
  const current = new Date().getFullYear()
  const past = current - 115
  for (let i = current; i >= past; i--) {
    years.push({
      label: i,
      value: i + '',
    })
  }
  return SelectMenuOptions({ options: years, questionLabel: 'years' })
}

export default CalendarYears
