import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

export const StyledAuthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledAuthButton = styled(NavLink)`
  text-decoration: none;
`

StyledAuthHeader.displayName = 'StyledAuthHeader'
StyledAuthButton.displayName = 'StyledAuthButton'
