import styled from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
export const StyledTabletAndDesktopSearchBar = styled.div`
  width: ${theme.layout.asideWidth};
  max-width: 100%;
  padding: 1.8em;
  z-index: 9;
  ${media.tablet`
    width: 100%;
    padding: 0;
  `}
  ${media.smaller`
    width: 100%;
     padding: 0;
  `}
  ${media.desktop`
    width: 100%;
    padding: 1.8em;
  `}
`

export const StyledTabletAndDesktopSearchBarInner = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  ${media.smaller`
    padding: .375rem;
    width: 100%;
  `}
  ${media.tablet`
    padding: 1rem;
    width: 100%;
  `}
  ${media.desktop`
    padding: 0;
    position: relative;
    width: auto;
  `}
`

StyledTabletAndDesktopSearchBar.displayName = 'StyledTabletAndDesktopSearchBar'
StyledTabletAndDesktopSearchBarInner.displayName =
  'StyledTabletAndDesktopSearchBarInner'
