import {
  StyledContainer,
  StyledTitle,
  StyledParagraph,
  CornerComponent,
  ContainerImages,
  StyledSideImage,
} from './style'
import singleStopLogo from 'Shared/Icons/singleStopLogo'
import BoardImage from './assets/board-image.svg'
import CornerPlantImage from './assets/corner_plant.svg'
import CornernLeftImage from './assets/corner_left.svg'
import PlantpotRightImage from './assets/plantpot_right.svg'
import PlantpotLeftImage from './assets/plant_pot_left.svg'
import SideImage from './assets/side_person.svg'
import SideImageRight from './assets/side_person_right.svg'
const Maintenance = () => {
  return (
    <StyledContainer>
      <CornerComponent
        right='0'
        top='-45px'
        src={CornerPlantImage}
        alt='corner right'
      />
      <CornerComponent
        left='-35px'
        top='-85px'
        src={CornernLeftImage}
        alt='corner left'
      />
      <CornerComponent bottom='0' right='0' src={PlantpotRightImage} alt='' />
      <CornerComponent bottom='0' left='0' src={PlantpotLeftImage} alt='' />

      <svg
        style={{
          width: `${'10rem'}`,
          margin: `${'0 1.2em 0 0'}`,
        }}
        viewBox='0 0 166.729 45.54'
      >
        {singleStopLogo()}
      </svg>
      <StyledTitle>Under Maintenance</StyledTitle>
      <StyledParagraph>
        Our website is currently undergoing scheduled maintenance. We should be
        back shortly.
      </StyledParagraph>
      <StyledParagraph>Thank you for your patience.</StyledParagraph>
      <ContainerImages>
        <StyledSideImage left='-16rem' src={SideImage} alt='' />
        <img
          style={{ width: '27rem', paddingTop: '1rem' }}
          src={BoardImage}
          alt=''
        />
        <StyledSideImage right='-17rem' src={SideImageRight} alt='' />
      </ContainerImages>
    </StyledContainer>
  )
}

export default Maintenance
