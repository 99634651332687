import React from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CustomQuestionsField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import FormHelperText from '@material-ui/core/FormHelperText'
import { CUSTOM_FIELDS_MUTATION } from 'Components/Screener/Hooks/gql'
import { mapResponses } from 'Components/Screener/Hooks/helpers'
import { getClientId } from 'Shared/helpers'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'
import { StyledQuestionsContainer } from 'Pages/CustomQuestions/style'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import { StyledForm } from './style'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import Grid from '@material-ui/core/Grid'
import { updateClient } from 'Actions/client'

const CustomQuestionsForm = ({ customFields, initialValues }) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [saveCustomFields] = useMutation(CUSTOM_FIELDS_MUTATION)

  const clientId = getClientId()

  const onSubmit = (formData) => {
    const responses = mapResponses(formData)

    saveCustomFields({ variables: { clientId, responses } })
      .then((_) => {
        dispatch(updateClient({ screeningSection: 'SCREENER' }))
        navigate('/screening')
      })
      .catch((error) => {
        return { [FORM_ERROR]: ERROR_GENERIC_MESSAGE }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitError, submitting }) => (
        <MuiThemeProvider theme={ssTheme}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item md={5}>
              <StyledForm onSubmit={handleSubmit} id={'custom-questions'}>
                <StyledQuestionsContainer>
                  {customFields.map((field, i) => (
                    <StyledMuiTextFieldWrapper key={i}>
                      <CustomQuestionsField
                        {...field}
                        name={`custom_field_${field.id}`}
                      />
                    </StyledMuiTextFieldWrapper>
                  ))}
                  {submitError && (
                    <FormHelperText>{submitError}</FormHelperText>
                  )}
                  <Grid
                    container
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item lg={8}>
                      <ColDynamicCtaButton
                        type='submit'
                        data-testid='continue-button'
                        disabled={submitting}
                      >
                        Submit
                      </ColDynamicCtaButton>
                    </Grid>
                  </Grid>
                </StyledQuestionsContainer>
              </StyledForm>
            </Grid>
          </Grid>
        </MuiThemeProvider>
      )}
    />
  )
}

export default CustomQuestionsForm
