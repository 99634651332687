import _get from 'lodash.get'

import {
  AGE_ERROR_NO_AGE_OR_DATE_OF_BIRTH,
  AGE_ERROR_BIRTHDAY_IN_FUTURE,
  REQUIRED,
  getAge,
  getBirthdayErrors,
} from 'Components/Shared/ReduxForm/helpers'

import {
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER,
} from 'Components/Shared/ReduxForm/constants/checkboxes'

const validateCategorySelector = (_value, allValues, props, _name) => {
  const { householdMembersIndex } = props
  const checked = Object.values(
    _get(allValues, props.question.name) || {}
  ).filter((item) => item[householdMembersIndex])

  return !checked || checked.length === 0 ? REQUIRED : undefined
}

const validators = {
  email: (value) => {
    let error = ''

    const emailRegex = /\S+@\S+\.\S+/

    if (!value) {
      error = 'Enter email'
    } else if (!emailRegex.test(value)) {
      error = 'Enter a valid email address'
    }

    return error ? error : undefined
  },
  emailOrPhone: (values) => {
    const { emailOrPhone } = values
    const errors = {}
    const emailRegex = /\S+@\S+\.\S+/
    const phoneNumber = /^[0-9]{10,10}$/
    const isEmail = emailRegex.test(emailOrPhone)
    const isPhoneNumber = phoneNumber.test(emailOrPhone)
    if (!emailOrPhone) {
      errors.emailOrPhone = 'Please enter an email address or phone number'
    }
    if (!(isEmail || isPhoneNumber)) {
      errors.emailOrPhone =
        'Email address or phone number is not valid or in the wrong format. A phone number must contain only digits without country code, spaces, or special characters. An email address must be in xxx@yyy.zzz format.'
    }
    return errors
  },
  SelectMenu: (value, allValues, props, name) => {
    const answer = Array.isArray(value)
      ? value[props.householdMembersIndex]
      : value
    return answer ? undefined : REQUIRED
  },
  FirstAndLastName: (values, allValues, props, name) =>
    !values ||
    !values[props.householdMembersIndex] ||
    !values[props.householdMembersIndex].first_name
      ? { first_name: REQUIRED }
      : undefined,
  BirthDateAndAge: (value, allValues, props, name) => {
    if (!value || !value[props.householdMembersIndex]) {
      return { age: AGE_ERROR_NO_AGE_OR_DATE_OF_BIRTH }
    }

    const {
      birth_month: birthMonth,
      birth_day: birthDay,
      birth_year: birthYear,
      age,
    } = value[props.householdMembersIndex]

    let errors = getBirthdayErrors({ birthMonth, birthDay, birthYear, age })

    if (getAge({ birthYear, birthMonth, birthDay }) < 0) {
      errors.birthYear = AGE_ERROR_BIRTHDAY_IN_FUTURE
    }

    return Object.keys(errors).length !== 0 ? errors : undefined
  },
  Checkboxes: {
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR]:
      validateCategorySelector,
    [CHECKBOX_TYPE_CATEGORY_SELECTOR]: validateCategorySelector,
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER]: validateCategorySelector,
    [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR]: (
      value,
      allValues,
      props,
      name
    ) =>
      ((Array.isArray(value) && !value.includes(true)) || !value) &&
      !props.disableValidation
        ? REQUIRED
        : undefined,
  },
  Input: (value, allValues, props, name) => {
    const parsedValue = Array.isArray(value)
      ? value[props.householdMembersIndex]
      : value
    const invalid =
      parsedValue === undefined || parsedValue === '' || parsedValue < 0
    return invalid ? REQUIRED : undefined
  },
  password: (value) => (!value ? 'Enter Password' : undefined),
  currentPassword: (value) =>
    !value ? 'Enter Your Current Password' : undefined,
  confirmPassword: (value, allValues) => {
    if (!value) {
      return 'Enter Password'
    } else if (value !== allValues.password) {
      return 'Passwords do not match'
    } else {
      return undefined
    }
  },
}

export default validators
