import { useDispatch, useSelector } from 'react-redux'
import { updateStep } from 'Actions/preScreener'

const useStep = () => {
  const step = useSelector((state) => state.preScreener.step)
  const dispatch = useDispatch()

  const handleUpdateStep = (nextStep) => {
    const next = nextStep ? nextStep : step + 1
    dispatch(updateStep(next))
  }
  return { step, handleUpdateStep }
}

export default useStep
