import React from 'react'
import Header from 'Components/Shared/Header/Header'
import { Link } from 'react-router-dom'
import { useDynamicHomeUrl } from 'Components/App/Hooks/index'

import WomanLookingOutOntoDistance from './Assets/WomanLookingOutOntoDistance.svg'
import {
  StyledContainer,
  StyledHeadingMain,
  StyledHeadingSecondary,
  StyledParagraph,
  StyledInfoContainer,
  StyledSvg,
  StyledButtonsContainer,
  StyledButton,
} from './style'

const PageNotFound = () => {
  const homeUrl = useDynamicHomeUrl()

  return (
    <>
      <Header />
      <StyledContainer>
        <StyledInfoContainer>
          <StyledHeadingMain>Oops!</StyledHeadingMain>
          <StyledHeadingSecondary>
            We can’t seem to find the page you are looking for.{' '}
          </StyledHeadingSecondary>
          <StyledParagraph>
            You might have typed the wrong address or the page has moved.
          </StyledParagraph>
          <StyledButtonsContainer>
            <StyledButton primary='true' as={Link} to={homeUrl}>
              Go to Home Page
            </StyledButton>
            <StyledButton
              secondary
              as='a'
              href={'https://singlestop.org/contact-single-stop-support/'}
            >
              Contact Us
            </StyledButton>
          </StyledButtonsContainer>
        </StyledInfoContainer>

        <StyledSvg src={WomanLookingOutOntoDistance} />
      </StyledContainer>
    </>
  )
}

export default PageNotFound
