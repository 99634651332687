import care from './careIconPath'
import education from './educationIconPath'
import food from './foodIconPath'
import goods from './goodsIconPath'
import health from './healthIconPath'
import housing from './housingIconPath'
import immigration from './immigrationIconPath'
import legal from './legalIconPath'
import money from './moneyIconPath'
import taxes from './taxesIconPath'
import transportation from './transportationIconPath'
import veterans from './veteransIconPath'
import work from './workIconPath'

const categoryIconPathsMap = {
  1108: care,
  1109: education,
  1102: food,
  1104: goods,
  1106: health,
  1103: housing,
  1381: immigration,
  1111: legal,
  1107: money,
  1105: transportation,
  1270: taxes,
  20010: veterans,
  1110: work,
}

export default categoryIconPathsMap
