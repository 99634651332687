import React from 'react'

const transportationIconPath = (
  <g id='a'>
    <path
      d='M1.385,98.776c9.372,21.181,68.959,17.308,87.293-10.193S86.059,0,67.282,0,48.493,41.244,40.748,53.356-7.987,77.594,1.385,98.776Z'
      transform='matrix(0.799, 0.602, -0.602, 0.799, 60.032, -10.026)'
      fill='#ffe49e'
      opacity='0.8'
    />
    <g transform='translate(27.954 8.927)' opacity='0.8'>
      <g transform='translate(0)'>
        <path
          d='M98.762,76.175,88.111,61.53a7.365,7.365,0,0,0,4.9-6.93V7.354A7.362,7.362,0,0,0,85.653,0H42.941a7.362,7.362,0,0,0-7.355,7.354V54.6a7.365,7.365,0,0,0,4.9,6.93L29.832,76.175a1.511,1.511,0,1,0,2.444,1.777l2.843-3.909H93.473l2.843,3.909a1.511,1.511,0,1,0,2.444-1.777ZM38.609,54.6V7.354a4.337,4.337,0,0,1,4.332-4.332H85.653a4.337,4.337,0,0,1,4.332,4.332V54.6a4.337,4.337,0,0,1-4.332,4.332H42.941A4.337,4.337,0,0,1,38.609,54.6ZM86.88,64.975H41.714l2.2-3.024H84.682ZM37.315,71.019l2.2-3.026H89.08l2.2,3.022Z'
          transform='translate(-29.513)'
        />
      </g>
      <g transform='translate(12.116 7.555)'>
        <path
          d='M148.048,49.231H113.9a5.6,5.6,0,0,0-5.591,5.591V73.861a5.6,5.6,0,0,0,5.591,5.591h34.15a5.6,5.6,0,0,0,5.591-5.591V54.822a5.6,5.6,0,0,0-5.591-5.591Zm2.569,24.63a2.572,2.572,0,0,1-2.569,2.569H113.9c-1.421,0-3.205,2.122-2.569-21.608a2.572,2.572,0,0,1,2.569-2.569h34.15a2.572,2.572,0,0,1,2.569,2.569Z'
          transform='translate(-108.307 -49.231)'
        />
      </g>
      <g transform='translate(42.342 42.309)'>
        <path
          d='M311.274,275.692a6.041,6.041,0,1,0,6.041,6.041A6.041,6.041,0,0,0,311.274,275.692Zm0,9.066a3.022,3.022,0,1,1,3.022-3.022,3.022,3.022,0,0,1-3.022,3.022Z'
          transform='translate(-305.233 -275.692)'
        />
      </g>
      <g transform='translate(15.14 42.309)'>
        <path
          d='M134.041,275.692a6.041,6.041,0,1,0,6.041,6.041A6.041,6.041,0,0,0,134.041,275.692Zm0,9.066a3.022,3.022,0,1,1,3.022-3.022,3.022,3.022,0,0,1-3.022,3.022Z'
          transform='translate(-128 -275.692)'
        />
      </g>
    </g>
  </g>
)

export default transportationIconPath
