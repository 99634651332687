import React from 'react'
import { StyledCard } from 'Components/CaseManagement/Reporting/Charts/Shared/style'
import { StyledGrid, StyledLeftPanel, StyledValue, StyledLabel } from './style'
import Icon from 'Components/Shared/Icon/Icon'
import { NumericFormat } from 'react-number-format'
import CardInfo from 'Components/CaseManagement/Reporting/Charts/Demographics/Overview/CardInfo/CardInfo'

const RateDisplay = ({
  value,
  label,
  icon,
  isCurrency,
  description,
  dataTestId,
  ...props
}) => {
  const currency = isCurrency ? (
    <NumericFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  ) : (
    <>{value}</>
  )
  return (
    <StyledCard data-testid={dataTestId}>
      <StyledGrid>
        <StyledLeftPanel>
          <Icon fill={'auto'} scale={'3.825rem'} viewBox={'0 0 60.705 60.705'}>
            {icon}
          </Icon>
        </StyledLeftPanel>
        <StyledValue>
          {currency}
          <CardInfo rateDisplay={true} title={label} descr={description} />
        </StyledValue>
        <StyledLabel>{label}</StyledLabel>
      </StyledGrid>
    </StyledCard>
  )
}

export default RateDisplay
