import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import { sortCaseMainCategories } from 'Components/CaseManagement/helpers'
import { calculateRecurringBenefitAmount } from 'Pages/NextSteps/helpers'

/**
 * [UNLIKELY description]
 * @type {String}
 */
const UNLIKELY = 'UNLIKELY'

/**
 * Reduces categories
 *
 * @param  {[type]} accumulator [description]
 * @param  {[type]} service     [description]
 * @return {[type]}             [description]
 */
const updateAccumulator = (accumulator, serviceCase) => {
  const {
    clientLocation: { followUp },
    service,
    people,
    eligibleAmount,
  } = serviceCase
  const { name, openEligibilityKey } = service.mainCategory

  if (typeof accumulator[openEligibilityKey] === 'undefined') {
    accumulator[openEligibilityKey] = {
      category: name,
      categoryIconPath: categoryIconPathsMap[openEligibilityKey],
      programs: [],
    }
  }

  accumulator[openEligibilityKey].programs.push({
    id: service.id,
    name: service.name,
    amountLabel: service?.amountLabel,
    description: service.description,
    eligible: serviceCase.eligibility !== UNLIKELY,
    findOutMore: followUp,
    link: service.url,
    toolkitUrl: service.toolkitUrl,
    people: people?.map((person) => person.fullName),
    eligibleAmount: eligibleAmount,
    frequency: service.frequency,
    recurringAmount: calculateRecurringBenefitAmount({
      eligibleAmount,
      service,
    }),
  })

  return accumulator
}

/**
 * The Benefits Categories Selector gets the data for client benefits
 *
 * @param  {Object} data clientLocation data
 * @return {Array}       Benefits Categories for rendering
 */
export const categoriesSelector = (data) => {
  if (data) {
    const { serviceCases } = data.calculateScreeningResults.screening

    const caseMainCategories = serviceCases.reduce(
      (accumulator, serviceCase) => updateAccumulator(accumulator, serviceCase),
      {}
    )

    return Object.keys(caseMainCategories)
      .sort(sortCaseMainCategories)
      .map((openEligibilityKey) => caseMainCategories[openEligibilityKey])
  }
  return []
}
