import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValueSelector } from 'redux-form'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { toggleIsOnCategories, toggleAllCategories } from 'Actions/preScreener'
import {
  PRE_SCREENER_FORM_NAME,
  DESKTOP_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import PreScreenerCategory from './PreScreenerCategory'

import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'

import { StyledForm } from 'Components/Screener/style'

import PreScreenerActions from 'Components/PreScreener/PreScreenerActions'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useMediaQuery } from 'react-responsive'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import {
  StyledCategoriesWrapper,
  StyledFieldset,
  StyledHeadingLegend,
} from 'Components/PreScreener/style'

const renderCategories = (categories) => {
  return categories.map((category) => {
    return <PreScreenerCategory key={category.id} icon={category} />
  })
}

const PreScreenerCategories = (props) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const {
    handleSubmit,
    toggleIsOnCategories,
    categories,
    allCategoriesSelected,
    toggleAllCategories,
    selectedCategories,
  } = props

  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  useEffect(() => {
    toggleIsOnCategories()
    return () => {
      toggleIsOnCategories()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <StyledCategoriesWrapper>
      <Container fluid className={isDesktop ? 'col-no-padding' : ''}>
        <Row>
          {isDesktop && (
            <ProgressSideBar
              preSelectedCategoryId={0}
              preProgressPercentage={90}
              position='absolute'
            />
          )}
        </Row>
        <StyledCard
          padding={isDesktop ? '0' : '2.5rem 0'}
          height='calc(100vh - 10.4rem)'
        >
          <Row>
            <Col lg='10' lgOffset='1'>
              <StyledForm
                onSubmit={handleSubmit}
                margin='2rem auto'
                height='auto'
                className='cypress-prescreen-categories-form'
              >
                <StyledFieldset>
                  <legend id='mainContent' tabIndex='-1'>
                    <StyledHeadingLegend>
                      Terrific! What can we help you find assistance with?
                    </StyledHeadingLegend>
                  </legend>

                  <p>
                    If you’d like, select one or more categories to let us know
                    what kind of help you’re looking for. Don’t worry –
                    selections aren’t required and won’t affect what you may be
                    eligible for.
                  </p>

                  <MuiThemeProvider theme={ssTheme}>
                    <FormGroup>
                      <FormControlLabel
                        style={{ padding: '.8rem 0' }}
                        control={
                          <Checkbox
                            color='primary'
                            checked={allCategoriesSelected}
                            onChange={() =>
                              toggleAllCategories(
                                allCategoriesSelected,
                                selectedCategories,
                                categories
                              )
                            }
                            style={{
                              padding: '0 .7rem',
                            }}
                            name='select-all-categories'
                          />
                        }
                        label='Select all'
                      />
                    </FormGroup>
                  </MuiThemeProvider>

                  <StyledHouseholdMembersListItem
                    margin={isMobile ? '1.6rem 0 6rem 0' : ''}
                  >
                    <StyledHouseholdMembersList>
                      <Row>{renderCategories(categories)}</Row>
                    </StyledHouseholdMembersList>
                  </StyledHouseholdMembersListItem>
                </StyledFieldset>

                <Row>
                  <Col lg='7'>
                    <PreScreenerActions
                      tabletWidth={'60%'}
                      tabletMargin={'0 auto 2rem'}
                    />
                  </Col>
                </Row>
              </StyledForm>
            </Col>
          </Row>
        </StyledCard>
      </Container>
    </StyledCategoriesWrapper>
  )
}

const selector = formValueSelector(PRE_SCREENER_FORM_NAME)

const mapStateToProps = (state, ownProps) => {
  const selectedCategories = selector(state, 'category') || {}

  return {
    selectedCategories,
    allCategoriesSelected: ownProps.categories.every(
      (category) => selectedCategories[`key-${category.id}`]
    ),
  }
}

export default compose(
  connect(mapStateToProps, { toggleIsOnCategories, toggleAllCategories }),
  reduxForm({
    form: PRE_SCREENER_FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(PreScreenerCategories)
