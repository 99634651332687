import React from 'react'
import { StyledBackgroundDoneIcon } from 'Components/Shared/ProgressSideBar/style'
import Icon from 'Components/Shared/Icon/Icon'

export const CompletedIcon = () => {
  return (
    <StyledBackgroundDoneIcon>
      <Icon fill={'#fff'} scale={'1.25rem'} viewBox={'0 0 36.658 36.658'}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='36.658'
          height='36.658'
          viewBox='0 0 36.658 36.658'>
          <defs></defs>
          <path className='a' fill='none' d='M0,0H36.658V36.658H0Z' />
          <path
            className='b'
            fill='#fff'
            d='M11.953,21.79,5.538,15.375,3.4,17.514l8.553,8.553L30.282,7.738,28.144,5.6Z'
            transform='translate(1.793 2.953)'
          />
        </svg>
      </Icon>
    </StyledBackgroundDoneIcon>
  )
}

export const BasicInformationIcon = (
  <>
    <path
      d='M56.823,0A6.859,6.859,0,1,1,50,6.823,6.815,6.815,0,0,1,56.823,0Zm0,1.669a5.153,5.153,0,1,0,5.153,5.153A5.173,5.173,0,0,0,56.823,1.669Z'
      transform='translate(-46.371)'
    />
    <path
      d='M4.645,198H16.258a4.631,4.631,0,0,1,4.645,4.645v7.621a4.678,4.678,0,0,1-4.645,4.645H4.645A4.678,4.678,0,0,1,0,210.266v-7.621A4.631,4.631,0,0,1,4.645,198Zm11.613,1.669H4.645a2.989,2.989,0,0,0-2.976,2.976v7.621a2.989,2.989,0,0,0,2.976,2.976H16.258a2.989,2.989,0,0,0,2.976-2.976v-7.621A2.989,2.989,0,0,0,16.258,199.669Z'
      transform='translate(0 -183.629)'
    />
  </>
)

export const DemographicsIcon = (
  <>
    <path
      className='a'
      fill='none'
      d='M30.356,34.8A2.152,2.152,0,1,0,28.2,36.95,2.154,2.154,0,0,0,30.356,34.8Z'
      transform='translate(-19.96 -29.186)'
    />
    <path
      className='a'
      fill='none'
      d='M30.067,53.512a1.6,1.6,0,0,0-1.523-1.119h-1.4a1.6,1.6,0,0,0-1.6,1.6v7.091a11.728,11.728,0,0,0,3.621,1.856V59.3a2.376,2.376,0,0,1,.911-1.872,2.825,2.825,0,0,1-.011-3.919Z'
      transform='translate(-19.601 -43.219)'
    />
    <path
      className='a'
      fill='none'
      d='M44.885,72.439a5.717,5.717,0,0,1-1.54,0,1.09,1.09,0,0,0-.287.1,1.13,1.13,0,0,0-.63,1.013v3.947a11.767,11.767,0,0,0,2.192.208q.6,0,1.186-.06v-4.1A1.136,1.136,0,0,0,44.885,72.439Z'
      transform='translate(-31.598 -57.464)'
    />
    <path
      className='a'
      fill='none'
      d='M56.929,43.789H55.277a2,2,0,0,0-2,2v1.132a2.827,2.827,0,0,1,.591,4.4,2.4,2.4,0,0,1,.909,1.873v3.9a11.691,11.691,0,0,0,4.148-1.773V45.786A2,2,0,0,0,56.929,43.789Z'
      transform='translate(-39.31 -37.104)'
    />
    <path
      className='a'
      fill='none'
      d='M44.4,60.719a1.573,1.573,0,1,0-1.573-1.573A1.575,1.575,0,0,0,44.4,60.719Z'
      transform='translate(-31.881 -46.9)'
    />
    <path
      className='a'
      fill='none'
      d='M59.177,23.341a2.652,2.652,0,1,0-2.652,2.652A2.655,2.655,0,0,0,59.177,23.341Z'
      transform='translate(-39.731 -20.689)'
    />
    <path
      className='a'
      fill='none'
      d='M13.356,25.761a3.34,3.34,0,0,1,1.688-2.9,3.986,3.986,0,1,1,4.938,0,3.35,3.35,0,0,1,1.383,1.513A3.284,3.284,0,0,1,22.5,24.17c.074,0,.147.006.219.011V23.32a3.806,3.806,0,0,1,2.05-3.372,4.569,4.569,0,1,1,5.44,0,3.806,3.806,0,0,1,2.052,3.373V33.28a13.721,13.721,0,1,0-18.907-.531Z'
      transform='translate(-7.893 -4.238)'
    />
    <path
      className='b'
      d='M20.2,35.391A15.2,15.2,0,1,0,5,20.2,15.213,15.213,0,0,0,20.2,35.391Zm-4.033-6.324V33.31a13.687,13.687,0,0,1-4.225-2.166V22.869A1.868,1.868,0,0,1,13.8,21h1.633a1.867,1.867,0,0,1,1.777,1.306,3.3,3.3,0,0,0,.013,4.573A2.776,2.776,0,0,0,16.163,29.067Zm-4.054-12.22a2.511,2.511,0,1,1,2.511,2.511A2.514,2.514,0,0,1,12.109,16.847Zm9.471,17a13.666,13.666,0,0,1-3.942-.173V29.067a1.319,1.319,0,0,1,.736-1.183,1.276,1.276,0,0,1,.335-.112,6.686,6.686,0,0,0,1.8,0,1.325,1.325,0,0,1,1.073,1.294v4.779Zm-3.807-9.257a1.836,1.836,0,1,1,1.836,1.836A1.839,1.839,0,0,1,17.773,24.589ZM27.9,31.545a13.655,13.655,0,0,1-4.841,2.069V29.067a2.807,2.807,0,0,0-1.061-2.186,3.3,3.3,0,0,0-.69-5.131V20.428a2.332,2.332,0,0,1,2.33-2.33h1.928a2.335,2.335,0,0,1,2.334,2.33ZM21.5,13.393A3.095,3.095,0,1,1,24.6,16.487,3.1,3.1,0,0,1,21.5,13.393ZM20.2,6.475A13.714,13.714,0,0,1,29.37,30.387V20.428a3.807,3.807,0,0,0-2.052-3.373,4.57,4.57,0,1,0-5.44,0,3.807,3.807,0,0,0-2.05,3.372v.861c-.073,0-.145-.011-.219-.011a3.292,3.292,0,0,0-1.137.205,3.35,3.35,0,0,0-1.383-1.513,3.986,3.986,0,1,0-4.938,0,3.34,3.34,0,0,0-1.688,2.9v6.988A13.713,13.713,0,0,1,20.2,6.475Z'
      transform='translate(-5 -1.346)'
    />
  </>
)

export const IncomeIcon = (
  <>
    <g transform='translate(0.5 0.506)'>
      <path
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M7.593,21.7V11.015A1.715,1.715,0,0,1,9.155,9.189h23a1.715,1.715,0,0,1,1.563,1.826V28.057a1.716,1.716,0,0,1-1.563,1.827H15.977'
        transform='translate(-3.223 -3.761)'
      />
      <path
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M8.493,6.745a1.557,1.557,0,0,1,.876-.66L27.678,1.049a1.575,1.575,0,0,1,1.88,1.359l.819,4.064'
        transform='translate(-3.526 -1)'
      />
      <rect
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        width='7.91'
        height='7.136'
        transform='translate(22.584 12.275)'
      />
      <ellipse
        cx='0.988'
        cy='1.155'
        rx='0.988'
        ry='1.155'
        transform='translate(25.549 14.686)'
      />
      <g transform='translate(0 17.536)'>
        <path
          className='a'
          fill='none'
          stroke='#000'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          d='M14.368,33.933a6.292,6.292,0,0,1-.427,2.284,6.682,6.682,0,0,1-6.257,4.194,6.482,6.482,0,1,1,0-12.957A6.583,6.583,0,0,1,14.368,33.933Z'
          transform='translate(-1 -27.454)'
        />
        <line
          className='b'
          fill='none'
          stroke='#000'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeLinecap='round'
          x2='5.276'
          transform='translate(4.046 6.479)'
        />
        <line
          className='b'
          fill='none'
          stroke='#000'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeLinecap='round'
          x2='5.276'
          transform='translate(6.684 3.841) rotate(90)'
        />
      </g>
    </g>
  </>
)

export const SavingsIcon = (
  <>
    <path
      d='M38.128,28.239h0c0-.073,0-.11-.037-.147h0c0-.037-.037-.073-.073-.11l-5.435-6.316A2.285,2.285,0,0,0,31,21.005H27.185a7.528,7.528,0,1,0-10.943,0H12.424a2.135,2.135,0,0,0-1.579.661L5.41,27.945c-.037.037-.037.073-.073.11h0c0,.037-.037.11-.037.147v9.364a1.355,1.355,0,0,0,1.359,1.359H31.3v-.918H6.659a.447.447,0,0,1-.441-.441v-8.85H37.21v8.85a.447.447,0,0,1-.441.441H33.685v.918h3.084a1.356,1.356,0,0,0,1.359-1.359ZM21.714,9.181a6.628,6.628,0,0,1-.037,13.256,9.7,9.7,0,0,1-1.065-.073,6.74,6.74,0,0,1-2.938-1.248h0a6.626,6.626,0,0,1-2.644-5.288A6.724,6.724,0,0,1,21.714,9.181ZM11.469,22.327a1.275,1.275,0,0,1,.918-.367h4.884a.367.367,0,0,1,.184.147,1.6,1.6,0,0,0,.257.147.738.738,0,0,0,.294.147,1.6,1.6,0,0,0,.257.147c.11.037.184.11.294.147.073.037.184.073.257.11a2.53,2.53,0,0,1,.294.11c.073.037.184.073.257.11l.33.11c.073.037.184.037.257.073.11.037.22.037.33.073.073,0,.147.037.257.037a1.506,1.506,0,0,0,.4.037.466.466,0,0,1,.22.037c.184,0,.4.037.624.037a3.563,3.563,0,0,0,.624-.037.466.466,0,0,0,.22-.037c.147,0,.257-.037.4-.037.073,0,.147-.037.257-.037.11-.037.22-.037.33-.073.073-.037.184-.037.257-.073l.33-.11c.073-.037.184-.073.257-.11a2.53,2.53,0,0,0,.294-.11c.073-.037.184-.073.257-.11a1.1,1.1,0,0,0,.294-.147,2.106,2.106,0,0,0,.257-.147c.11-.037.184-.11.294-.147.073-.037.147-.11.22-.147a.768.768,0,0,0,.22-.147h4.884a1.373,1.373,0,0,1,.918.367l4.737,5.508H6.769Z'
      transform='translate(-5.3 -8.3)'
    />
    <path
      d='M44.545,22.962c-.514-.11-1.359-.514-1.359-1.175h-.918a2.38,2.38,0,0,0,2.093,2.093c.073,0,.147.037.257.037v.991h.918v-.991a2.9,2.9,0,0,0,1.652-.771,1.6,1.6,0,0,0,.22-.257c.037-.037.037-.073.073-.11s.073-.11.11-.147a1.962,1.962,0,0,0,.11-1.8h0c-.037-.073-.073-.11-.11-.184v-.037a.645.645,0,0,0-.147-.184.036.036,0,0,0-.037-.037c-.037-.073-.11-.11-.147-.184l-.037-.037c-.073-.073-.147-.11-.22-.184a.036.036,0,0,1-.037-.037.92.92,0,0,0-.257-.147h0c-.11-.037-.184-.11-.294-.147h-.037c-.11-.037-.22-.073-.367-.11h-.073c-.11-.037-.257-.037-.4-.073H45.5V16.683h.037c.551.11,1.359.514,1.359,1.175h.918a2.347,2.347,0,0,0-2.13-2.056c-.073,0-.147-.037-.22-.037V14.7h-.918v1.065a3.044,3.044,0,0,0-1.689.808,2.054,2.054,0,0,0-.477,2.35c.037.073.11.184.147.257l.073.073c.037.037.073.11.147.147l.073.073c.037.037.11.073.147.147.037.037.073.037.11.073s.11.073.147.11.073.037.11.073c.073.037.11.073.184.073.037.037.11.037.147.073.073.037.11.037.184.073.037,0,.11.037.147.037.073,0,.11.037.184.037s.11.037.184.037c.037,0,.11.037.147.037v2.717ZM46.859,21.2a.864.864,0,0,1,.073.294v.184a2.375,2.375,0,0,1-.147.477,2.037,2.037,0,0,1-.22.294h0a1.957,1.957,0,0,1-1.028.514v-2.57A1.664,1.664,0,0,1,46.859,21.2Zm-3.3-4a1.957,1.957,0,0,1,1.028-.514v2.644a1.915,1.915,0,0,1-1.322-.845A1.246,1.246,0,0,1,43.554,17.2Z'
      transform='translate(-28.645 -12.35)'
    />
    <rect width='14.174' height='0.918' transform='translate(9.327 16.047)' />
  </>
)

export const ExpensesIcon = (
  <>
    <g transform='translate(0.5 0.506)'>
      <path
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M7.593,21.7V11.015A1.715,1.715,0,0,1,9.155,9.189h23a1.715,1.715,0,0,1,1.563,1.826V28.057a1.716,1.716,0,0,1-1.563,1.827H15.977'
        transform='translate(-3.223 -3.761)'
      />
      <path
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        d='M8.493,6.745a1.557,1.557,0,0,1,.876-.66L27.678,1.049a1.575,1.575,0,0,1,1.88,1.359l.819,4.064'
        transform='translate(-3.526 -1)'
      />
      <rect
        className='a'
        fill='none'
        stroke='#000'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        width='7.91'
        height='7.136'
        transform='translate(22.584 12.275)'
      />
      <ellipse
        cx='0.988'
        cy='1.155'
        rx='0.988'
        ry='1.155'
        transform='translate(25.549 14.686)'
      />
      <g transform='translate(0 17.536)'>
        <path
          className='a'
          fill='none'
          stroke='#000'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          d='M14.368,33.933a6.292,6.292,0,0,1-.427,2.284,6.682,6.682,0,0,1-6.257,4.194,6.482,6.482,0,1,1,0-12.957A6.583,6.583,0,0,1,14.368,33.933Z'
          transform='translate(-1 -27.454)'
        />
        <line
          className='b'
          fill='none'
          stroke='#000'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeLinecap='round'
          x2='5.276'
          transform='translate(4.046 6.479)'
        />
      </g>
    </g>
  </>
)

export const TaxInformationIcon = (
  <>
    <path
      d='M55.522,61.482V35.749a1.538,1.538,0,0,1,1.309-1.595,6.157,6.157,0,0,1,.9-.007H76.156c-.176-.073-.344-.146-.519-.212q2.545,2.831,5.091,5.669c.241.271.483.541.724.8-.073-.176-.146-.344-.212-.519v21.5a1.542,1.542,0,0,1-1.514,1.6c-.812.015-1.631,0-2.443,0H57.511a2.6,2.6,0,0,1-1.061-.117,1.525,1.525,0,0,1-.929-1.39.732.732,0,0,0-1.463,0,3.024,3.024,0,0,0,3.006,2.977c.358.007.724,0,1.083,0H77.531c1.185,0,2.736.212,3.774-.454a3.122,3.122,0,0,0,1.39-2.728V40.7a3.835,3.835,0,0,0-.029-1,1.844,1.844,0,0,0-.475-.644c-.271-.307-.549-.607-.819-.914q-2.205-2.469-4.418-4.93a1.4,1.4,0,0,0-.607-.5,1.581,1.581,0,0,0-.417-.029H60.488c-1.148,0-2.3-.022-3.452,0a3.021,3.021,0,0,0-2.977,2.992c-.007.307,0,.622,0,.929V61.474A.732.732,0,0,0,55.522,61.482Z'
      transform='translate(-54.056 -32.667)'
    />
    <g transform='translate(21.311 0.106)'>
      <path
        d='M345.4,34.821V39.5a3.611,3.611,0,0,0,.1,1.009,2.507,2.507,0,0,0,1.624,1.66,8.327,8.327,0,0,0,2.377.11h2.509a.732.732,0,0,0,0-1.463c-1.375,0-2.765.051-4.14,0a1.01,1.01,0,0,1-.936-.607,1.711,1.711,0,0,1-.073-.695v-4.7a.728.728,0,0,0-1.456.007Z'
        transform='translate(-345.4 -34.112)'
      />
    </g>
    <path
      d='M67.931,3083.98c-.266.42-.52.822-.775,1.222-.2.317-.464.413-.7.26-.256-.166-.281-.429-.07-.764.3-.472.6-.946.9-1.414a.253.253,0,0,0,0-.325c-.324-.492-.636-.993-.951-1.491a.481.481,0,0,1-.02-.56.433.433,0,0,1,.439-.192.546.546,0,0,1,.385.3c.258.407.517.814.79,1.242l.8-1.27a.483.483,0,0,1,.537-.265.461.461,0,0,1,.257.733c-.209.347-.434.686-.65,1.029-.13.207-.345.412-.358.627s.23.431.36.645c.2.333.421.658.626.99a.477.477,0,0,1-.111.721c-.234.14-.486.053-.673-.238C68.463,3084.824,68.207,3084.416,67.931,3083.98Z'
      transform='translate(-49.515 -3071.376)'
    />
    <path
      d='M-67.634,3245.577q2.151,0,4.3,0a1.083,1.083,0,0,1,.273.026.436.436,0,0,1,.331.441.425.425,0,0,1-.324.424.993.993,0,0,1-.272.029q-4.336,0-8.671,0c-.421,0-.665-.274-.542-.612a.479.479,0,0,1,.511-.31h4.393Z'
      transform='translate(81.302 -3226.755)'
    />
    <path
      d='M-66.851,3335.192h4.279a.773.773,0,0,1,.317.041.434.434,0,0,1,.283.432.423.423,0,0,1-.284.406.619.619,0,0,1-.224.041q-4.383,0-8.765,0a.5.5,0,0,1-.425-.186.452.452,0,0,1,.2-.7.8.8,0,0,1,.295-.035Z'
      transform='translate(80.544 -3311.212)'
    />
    <path
      d='M-35.1,3201.7c-1.135,0-2.269,0-3.4,0-.406,0-.649-.315-.5-.643a.5.5,0,0,1,.51-.28c.629,0,1.257,0,1.886,0h4.83a1.672,1.672,0,0,1,.23.007.456.456,0,0,1,.413.447.459.459,0,0,1-.422.462,1.62,1.62,0,0,1-.207.005H-35.1Z'
      transform='translate(49.713 -3184.537)'
    />
    <path
      d='M-35.124,3291.294h-3.313a1.528,1.528,0,0,1-.229-.008.469.469,0,0,1-.41-.454.475.475,0,0,1,.414-.452,1.563,1.563,0,0,1,.23-.008l6.648,0a1.188,1.188,0,0,1,.274.022.437.437,0,0,1,.348.451.428.428,0,0,1-.351.425,1.188,1.188,0,0,1-.274.021Q-33.456,3291.3-35.124,3291.294Z'
      transform='translate(49.736 -3268.971)'
    />
    <path
      d='M-71.387,3083.371c0-.513-.011-1.027.006-1.54.007-.2-.058-.248-.242-.23a3.349,3.349,0,0,1-.482,0,.484.484,0,0,1-.512-.477.473.473,0,0,1,.518-.442q1.172-.006,2.345,0a.469.469,0,0,1,.517.44.48.48,0,0,1-.508.479c-.053,0-.107,0-.161,0-.181.012-.428-.081-.525.037s-.033.349-.033.532c0,.927,0,1.854,0,2.781,0,.345-.2.559-.483.545a.462.462,0,0,1-.437-.519c0-.536,0-1.073,0-1.609Z'
      transform='translate(81.347 -3071.349)'
    />
    <path
      d='M-71.687,3201.3a.462.462,0,0,1-.446.459.48.48,0,0,1-.466-.469.481.481,0,0,1,.464-.448A.462.462,0,0,1-71.687,3201.3Z'
      transform='translate(81.329 -3184.594)'
    />
    <path
      d='M-72.139,3290.448a.463.463,0,0,1,.455.45.463.463,0,0,1-.439.466.478.478,0,0,1-.473-.461A.48.48,0,0,1-72.139,3290.448Z'
      transform='translate(81.327 -3269.043)'
    />
    <g transform='translate(12.104 9.366)'>
      <path
        d='M14.161,3109.656c-.177.415-.329.779-.488,1.139-.056.127.018.122.1.121.253,0,.505-.005.758,0,.14,0,.152-.037.1-.158C14.476,3110.413,14.332,3110.06,14.161,3109.656Z'
        transform='translate(-12.063 -3108.024)'
      />
      <path
        d='M-9.873,3085.343q-.781-1.849-1.564-3.7a.485.485,0,0,0-.439-.349.44.44,0,0,0-.45.292q-.814,1.909-1.624,3.821a.467.467,0,0,0,.232.637.468.468,0,0,0,.617-.286c.081-.173.158-.349.223-.528a.167.167,0,0,1,.188-.124c.522,0,1.044.006,1.565,0a.2.2,0,0,1,.219.15,5.681,5.681,0,0,0,.258.588.429.429,0,0,0,.53.21.473.473,0,0,0,.328-.451C-9.812,3085.531-9.835,3085.434-9.873,3085.343Zm-1.651-1.152c-.252-.007-.505,0-.758,0-.085,0-.158.006-.1-.121.159-.361.311-.725.488-1.139.171.4.315.756.47,1.1C-11.372,3084.154-11.384,3084.195-11.524,3084.191Z'
        transform='translate(13.995 -3081.297)'
      />
    </g>
  </>
)

export const ReviweIconPath = (
  <>
    <g>
      <path d='M4,7h16v2H4V7z M4,13h16v-2H4V13z M4,17h7v-2H4V17z M4,21h7v-2H4V21z M15.41,18.17L14,16.75l-1.41,1.41L15.41,21L20,16.42 L18.58,15L15.41,18.17z M4,3v2h16V3H4z' />
    </g>
  </>
)

export const NextIconPath = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z' />
  </>
)
