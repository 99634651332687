import React from 'react'
import { connect } from 'react-redux'
import FirstAndLastNameRenderField from './FirstAndLastNameRenderField'
import { screenerSelector } from 'Selectors'
import { useFields } from './hooks'
import FieldArrayContainer from 'Components/Shared/ReduxForm/FieldArrayContainer'
import { v4 as uuidv4 } from 'uuid'

const FirstAndLastName = (props) => {
  useFields(props, {
    uuid: uuidv4(),
  })

  return (
    <FieldArrayContainer
      {...props}
      fieldArrayComponent={FirstAndLastNameRenderField}
    />
  )
}

const stateToProps = (state, ownProps) => {
  return {
    backButtonClicked: state.screener.returnStep,
    fieldValues: screenerSelector(state, ownProps.name),
  }
}

export default connect(stateToProps)(FirstAndLastName)
