import styled from 'styled-components/macro'

type StyledMobileActionsContainerProps = {
  padding?: string
}

export const StyledMobileActionsContainer = styled.div<StyledMobileActionsContainerProps>`
  display: flex;
  justify-content: space-between;
  min-height: 5.6rem;
  align-items: center;
  padding: ${(props) => props.padding};
`
