import styled, { css } from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'

export const StyledDateLabel = styled.label`
  margin-top: 3px;
  color: black;
  ${media.smaller`
    display: none;
  `}
`

export const StyledDatePickerWrap = styled.div`
  width: 17rem;
  margin: 0;
  display: flex;
  align-items: flex-end;
  & label {
    width: 60%;
    & + div {
      width: 40%;
    }
  }
  & input {
    height: auto;
    padding: 0;
    font-size: 0.75rem !important;
    font-weight: 400;
    color: ${theme.palette.primary.dark};
    margin-left: 4px;
  }
  & button {
    padding: 0;
    color: ${theme.palette.color.black};
  }
  ${media.smaller`
    width: 10rem;
    display: flex;
    flex-direction: column;
    & label {
      display: flex;
      width: 100%;
      margin-bottom: 0.5rem;
      & + div{
        width: 100%;
      }
    }
  `}
  ${({ review }) =>
    review &&
    css`
      width: 100%;
      ${media.smaller`
        width: 100%;
      `}
    `}
`

StyledDatePickerWrap.displayName = 'StyledDatePickerWrap'
StyledDateLabel.displayName = 'StyledDateLabel'
