import styled from 'styled-components/macro'

export const ParentCover = styled.div`
  position: relative;
  width: 100%;
  height: 11.875rem;
  max-height: 11.875rem;
  min-height: 11.875rem;
`

export const Coverimg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
ParentCover.displayName = 'parentCover'
Coverimg.displayName = 'coverImg'
