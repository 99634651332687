import styled from 'styled-components/macro'
import { sharedModalStyling } from 'Components/CaseManagement/Modals/style'
import { media } from 'styled-bootstrap-grid'

export const StyledModalConfirmation = styled.div`
  ${sharedModalStyling}
  ${media.smaller`
  width: 90% ;
  top: 50%;
  `}
  ${media.tablet`
  top: 40%;
  width: 46% ;
  padding: 2rem 1rem;
  `}
`

export const StyledHeading = styled.h1`
  font-size: 1.5rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  text-align: center;
  line-height: 2.6rem;
  width: 80%;
  margin: 0 auto;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '9rem'};
  ${media.smaller`
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.6rem;
    width: 100%;
    margin: 0 auto .75rem;
    `}
`

export const StyledDetail = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.5rem;
`

export const StyledButtonsContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
`

export const StyledSpan = styled.span`
  font-weight: 500;
`

export const StyledParagraph = styled.p`
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 3rem;
`

export const StyledError = styled.p`
  color: red;
  text-align: center;
  font-size: 1.6rem;
`

export const StyledDescription = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
`
