import React, { useEffect } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { PRE_SCREENER_FORM_NAME, noop } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { getPreScreenLocation } from 'Actions/geolocation'
import { parseQuery } from 'Actions/filters'
import InputRenderField from 'Components/Shared/ReduxForm/InputRenderField'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import {
  StyledLocationForm,
  StyledHeadingMain,
  StyledParagraph,
} from 'Components/Screener/style'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { StyledLocationButton } from 'Components/PreScreener/style'
import FormSubmissionError from '../Shared/ReduxForm/FormSubmissionError'
import ModalError from 'Components/Shared/Modals/ModalError'
import {
  ERROR_MISSING_ZIP_CODE,
  ERROR_GENERIC_GEOLOCATION_MESSAGE,
  DESKTOP_MEDIA_QUERY,
} from 'Shared/constants'
import { getAddressByPostalCode } from 'Shared/Address/helpers'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import PreScreenerBackButton from './PreScreenerBackButton'
import { useMediaQuery } from 'react-responsive'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import { StyledCategoriesWrapper } from 'Components/PreScreener/style'

const validate = (values) => {
  const errors = {}
  const { zipCode } = values
  if (!zipCode) {
    errors.zipCode = ERROR_MISSING_ZIP_CODE
  }
  return errors
}

/**
 * Prescreener Location Form Component. <code>handleSubmit</code> is hacked to
 * overcome {@link https://github.com/redux-form/redux-form/issues/4026|Issue 4026}.
 * Comment containing <code>handleSubmit</code> hack:
 * {@link https://github.com/redux-form/redux-form/issues/4026#issuecomment-445159076|Issue 4026 Comment}</p>
 *
 * @param  {Object}                 props Properties Object
 * @return {PreScreenerLocation}          Prescreener Location Form
 */
const PreScreenerLocation = (props) => {
  const { error } = props

  const handleSubmit = (event) => {
    const { handleSubmit } = props
    const submitPromise = handleSubmit(event)

    if (submitPromise && submitPromise.catch) {
      submitPromise.catch(noop)
    }
  }

  const { zipCode, geolocationError } = useSelector(
    (state) => state.geolocation
  )

  const dispatch = useDispatch()
  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  const handleChange = useDebouncedCallback(
    (_event, newValue) => dispatch(parseQuery(newValue)),
    500
  )

  useEffect(() => {
    dispatch(change(PRE_SCREENER_FORM_NAME, 'zipCode', zipCode))
  }, [dispatch, zipCode])

  return (
    <StyledCategoriesWrapper>
      <Container fluid className={isDesktop ? 'col-no-padding' : ''}>
        <Row>
          {isDesktop && (
            <ProgressSideBar
              preSelectedCategoryId={0}
              preProgressPercentage={99}
            />
          )}
        </Row>
        <StyledCard
          padding={isDesktop ? '0' : '2.5rem 0'}
          height='calc(100vh - 10.4rem)'
        >
          <Row>
            <Col lg='10' lgOffset='1'>
              <StyledLocationForm
                margin='0 auto'
                height='auto'
                padding={'2rem'}
                onSubmit={handleSubmit}
                className='cypress-prescreen-location-form'
              >
                <StyledHeadingMain
                  id='mainContent'
                  tabIndex='-1'
                  textAlign='center'
                >
                  Wonderful! Now, where are you looking to find help?
                </StyledHeadingMain>

                <StyledHouseholdMembersListItem>
                  <MuiThemeProvider theme={ssTheme}>
                    <StyledHouseholdMembersList>
                      <Row>
                        <Col lg='12'>
                          <StyledMuiTextFieldWrapper
                            width='50%'
                            marginRight='auto'
                            marginLeft='auto'
                          >
                            <StyledLocationButton
                              onClick={() => dispatch(getPreScreenLocation())}
                              variant='contained'
                              startIcon={<LocationOnIcon />}
                              className='cypress-location-button'
                            >
                              Share your location
                            </StyledLocationButton>

                            {geolocationError && (
                              <ModalError
                                type={'error'}
                                message={ERROR_GENERIC_GEOLOCATION_MESSAGE}
                              />
                            )}
                          </StyledMuiTextFieldWrapper>
                          <StyledParagraph
                            textalign='center'
                            className='cypress-share-zip-code-heading'
                          >
                            Or share your zip code.
                          </StyledParagraph>
                          <StyledMuiTextFieldWrapper
                            className='cypress-zip-code'
                            width='50%'
                            marginRight='auto'
                            marginLeft='auto'
                          >
                            <Field
                              name='zipCode'
                              type='text'
                              component={InputRenderField}
                              label='Zip Code'
                              onChange={handleChange}
                            />
                          </StyledMuiTextFieldWrapper>
                        </Col>
                      </Row>
                    </StyledHouseholdMembersList>
                    <FormSubmissionError error={error} />
                  </MuiThemeProvider>
                </StyledHouseholdMembersListItem>

                <StyledHouseholdMembersList textalign='center'>
                  <Row>
                    <Col lg='12'>
                      <StyledDynamicCtaButton
                        width='50%'
                        type='submit'
                        className='cypress-continue-button'
                      >
                        Continue
                        <ContinueIcon />
                      </StyledDynamicCtaButton>
                    </Col>
                    <Col lg='12'>
                      <PreScreenerBackButton margin='1rem auto 0' />
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </StyledLocationForm>
            </Col>
          </Row>
        </StyledCard>
      </Container>
    </StyledCategoriesWrapper>
  )
}

/**
 * <p><code>asyncBlurFields: []</code><br/>validates on submit, doesn't muck
 * up being blurred for the Share Your Location button (see below)</p>
 *
 * <p><code>asyncBlurFields: undefined</code><br/>excessive validation occurs
 * with every keystroke</p>
 *
 * <p><code>asyncBlurFields: ['zipCode']</code><br/>forms race condition with
 * reverse geolocation look up if the user blurs the zip code field by clicking
 * the Share Your Location button. You'll typically get the error for your
 * invalid zip, just after the app geolocates you and populates your real zip
 * code. This ends up looking like your actual zip code is 'invalid'</p>
 *
 * [form Prescreener Location Form]
 * @type {Object}
 */
export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate: (values) => getAddressByPostalCode(values.zipCode),
  asyncBlurFields: [],
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerLocation)
