import { API_BASE, SCREENER_FORM_NAME } from '../Shared/constants'
import { axiosWithAuthHeaders } from '../Shared/axiosInstances'
import { getClientId, handleApiError } from 'Shared/helpers'
import { populateScreener } from './screener'
import { updatePhysicalAddress } from 'Actions/geolocation'
import { change } from 'redux-form'
import { parseScreeningData } from 'Components/PreScreener/helpers'
import cleanDeep from 'clean-deep'
import { AppDispatch } from 'Store'
import { orgAndLoc, PhysicalAddress } from './types'
import { Client } from 'Components/Shared/Hooks/types'

export const COMPLETE_SCREENER = 'COMPLETE_SCREENER'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const LOGOUT_CLIENT = 'LOGOUT_CLIENT'
export const CLEAR_CLIENT = 'CLEAR_CLIENT'
export const START_EDITING = 'START_EDITING'
export const STOP_EDITING = 'STOP_EDITING'
export const RESET_COMPLETE_SCREENER = 'RESET_COMPLETE_SCREENER'
export const CLIENT_AUTHENTICATED = 'CLIENT_AUTHENTICATED'
/**
 * @return {Object} action
 */
export const startEditing =
  (editingStepNumber: number) =>
  (dispatch: AppDispatch, getState: Function) => {
    const { id: editingCategoryId } =
      getState().screener.steps[editingStepNumber].category

    dispatch({
      type: START_EDITING,
      editing: true,
      editingStepNumber,
      editingCategoryId,
    })
  }

/**
 * @returns {Object} action
 */
export const stopEditing = () => ({
  type: STOP_EDITING,
  editing: false,
})

export const completeScreener = () => ({
  type: COMPLETE_SCREENER,
})

export const resetCompleteScreener = () => ({
  type: RESET_COMPLETE_SCREENER,
})

export const finishEditing = () => (dispatch: AppDispatch) => {
  dispatch(stopEditing())
  dispatch(completeScreener())
}

export const updateClient =
  (
    client: Client,
    physicalAddress?: PhysicalAddress,
    completed: boolean | null = null
  ) =>
  (dispatch: AppDispatch) => {
    const { date_of_birth, age } = client
    const client_date_of_birth = date_of_birth ?? (age && { age })
    if (!client.screenerCompleted) client.screenerCompleted = completed
    client_date_of_birth &&
      dispatch(
        change(SCREENER_FORM_NAME, 'client.date_of_birth', client_date_of_birth)
      )

    physicalAddress &&
      dispatch(
        change(SCREENER_FORM_NAME, 'client.physical_address', physicalAddress)
      )

    dispatch({
      type: UPDATE_CLIENT,
      client,
    })
  }

export const patchClient =
  (
    fieldValues: any,
    clientId: number,
    physicalAddress: PhysicalAddress,
    orgAndLoc: orgAndLoc,
    completed: boolean | null
  ) =>
  (dispatch: AppDispatch) => {
    const url = `${API_BASE}/clients/${clientId}.json`
    const formData = {
      ...fieldValues,
      physical_address: physicalAddress,
      ...orgAndLoc,
    }
    const parsed = parseScreeningData({ formData, orgAndLoc })
    const requestBody = cleanDeep(parsed, { emptyStrings: false })
    return axiosWithAuthHeaders
      .patch(url, requestBody)
      .then((response) => {
        const { data } = response
        dispatch(updatePhysicalAddress(physicalAddress) as VoidFunction)
        dispatch(updateClient(data, physicalAddress, completed))
        return response
      })
      .catch((e) => {
        console.error(e)
        handleApiError(e)
      })
  }

export const createClientScreening = (fieldValues: any) => {
  return (dispatch: AppDispatch) => {
    return axiosWithAuthHeaders
      .post(`${API_BASE}/clients/create_for_screening.json`, fieldValues)
      .then((response) => {
        const { data } = response
        const { id: clientId } = data
        dispatch(updateClient(data))
        sessionStorage.setItem('clientId', clientId)
        return response
      })
  }
}

export const rehydrateClient = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) => {
    const clientId = getClientId()
    axiosWithAuthHeaders
      .get(`${API_BASE}/clients/${clientId}.json`)
      .then((response) => {
        const { data } = response
        dispatch(updateClient(data))
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })

export const getOnboardingSection =
  () => (_dispatch: AppDispatch, getState: Function) =>
    new Promise((resolve) => {
      resolve(getState().client?.screeningSection)
    })

export const resumeOnboarding = () => (dispatch: AppDispatch) =>
  new Promise((resolve, reject) => {
    dispatch(rehydrateClient())
      .then(() => dispatch(populateScreener()))
      .then(() => dispatch(getOnboardingSection()))
      .then((onboardingSection: unknown) => resolve(onboardingSection))
      .catch((err: Error) => reject(err))
  })

export const clearClient = () => ({
  type: CLEAR_CLIENT,
})

export const clientAuthenticated = (authenticated: boolean) => ({
  type: CLIENT_AUTHENTICATED,
  authenticated: authenticated,
})
