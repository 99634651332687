import React from 'react'

const foodIconPath = (
  <g id='b'>
    <g transform='translate(-20 -24)'>
      <path
        d='M2.42,18C15.17-4.26,77.364-7.56,92.919,17.67s-14.826,88.7-34.6,90.955S44.173,69.732,37.667,58.578-10.334,40.253,2.42,18Z'
        transform='matrix(-0.799, 0.602, -0.602, -0.799, 147.248, 99.673)'
        fill='rgba(145,255,189,0.7)'
        opacity='0.8'
      />
      <path
        d='M74.581,27.017a12.153,12.153,0,0,1-3.122-1.148A21.527,21.527,0,0,0,41.285,52.706l9.348,23.907a18.667,18.667,0,0,0,26.549,9.47,18.7,18.7,0,0,0,9.16,2.4h0a18.672,18.672,0,0,0,17.381-11.872l9.35-23.907A21.527,21.527,0,0,0,82.888,25.869a12.152,12.152,0,0,1-3.122,1.148V18.841h4.405A10.3,10.3,0,0,0,94.461,8.55V0H84.875a10.3,10.3,0,0,0-8.939,5.2A15.3,15.3,0,0,0,64.438,0V5.183A10.157,10.157,0,0,1,74.581,15.324Zm5.184-16.726a5.114,5.114,0,0,1,5.108-5.108h4.405V8.551a5.114,5.114,0,0,1-5.108,5.108H79.765Zm28.473,40.528-9.346,23.9A13.489,13.489,0,0,1,86.333,83.3h0a13.513,13.513,0,0,1-7.685-2.4l-1.477-1.022L75.694,80.9a13.485,13.485,0,0,1-20.24-6.173L46.107,50.814A16.341,16.341,0,0,1,69.022,30.44a17.313,17.313,0,0,0,16.306,0A16.346,16.346,0,0,1,108.24,50.819Z'
        transform='translate(5.744 26.228)'
        opacity='0.8'
      />
    </g>
  </g>
)

export default foodIconPath
