import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useStep from './useStep'
import { SubmissionError } from 'redux-form'
import { getSentryUserFromOtp } from 'Pages/helpers'
import { getScreeningType } from 'Shared/helpers'
import * as Sentry from '@sentry/browser'
import {
  EMAIL_ALREADY_TAKEN_ERROR,
  ERROR_GENERIC_MESSAGE,
} from 'Shared/constants'
import {
  parseEmailOrPhone,
  parseScreeningData,
} from 'Components/PreScreener/helpers'
import { createClientScreening, clientAuthenticated } from 'Actions/client'
import { toggleShowLoginLink } from 'Reducers/uiSlice'
import useOtp from 'Components/Auth/Hooks/useOtp'
import useAcceptTerms from 'Components/Client/termsHooks/useAcceptTerms'

const useAuthenticationPath = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSkipClientOtp,
    handleRequestOtp,
    handleVerifyOtp,
    setClientId,
    setClientCredentials,
    parseIdFromClient,
    parseIdFromUserable,
  } = useOtp()

  const { handleAcceptTerms } = useAcceptTerms()

  const { handleUpdateStep, step } = useStep()

  const state = useSelector((state) => state)

  const selectFromState = (state) => {
    const {
      organization,
      location,
      hasCustomQuestions: customQuestionsFlag,
    } = state.screener
    const { client, preScreener } = state
    return {
      client,
      preScreener,
      orgAndLoc: {
        organization,
        location,
      },
      customQuestionsFlag,
    }
  }

  const handleAuthenticateAndContinue = ({ formData, isACaseManager }) => {
    const { otp } = formData
    const { email, primaryPhone } = parseEmailOrPhone(formData)
    const response = handleVerifyOtp({
      email,
      otp,
      primaryPhone,
      isACaseManager,
    })
      .then(handleAuthenticationResponse(isACaseManager))
      .then((response) => {
        const getId = isACaseManager ? parseIdFromClient : parseIdFromUserable
        return handleAcceptTerms(getId(response))
      })
      .then((response) => response)
    return response
  }

  const handleCreateClient = (formData) => {
    const { orgAndLoc } = selectFromState(state)
    const screeningData = parseScreeningData({ formData, orgAndLoc })
    return dispatch(createClientScreening(screeningData))
      .then((_) => handleRequestOtp(parseEmailOrPhone(formData)))
      .then(({ graphQLErrors }) => {
        if (!graphQLErrors) {
          nextStep()
        } else {
          throw new SubmissionError({ emailOrPhone: ERROR_GENERIC_MESSAGE })
        }
      })
      .catch(({ response: { status } }) => {
        const userIdTaken = status === 422
        const errorMessage = userIdTaken
          ? EMAIL_ALREADY_TAKEN_ERROR
          : ERROR_GENERIC_MESSAGE
        if (userIdTaken) {
          dispatch(toggleShowLoginLink())
        }
        throw new SubmissionError({ emailOrPhone: errorMessage })
      })
  }

  const handleSkipAuthenticateAndContinue = ({ email, primaryPhone }) => {
    handleSkipClientOtp({ email, primaryPhone })
      .then(handleSkipAuthResponse)
      .then((response) => {
        return handleAcceptTerms(parseIdFromClient(response))
      })
      .then((response) => response)
  }

  const handleAuthenticationResponse = (isACaseManager) =>
    handleMutationResponse({
      errorObject: { otp: 'Invalid credentials' },
      operationName: isACaseManager
        ? 'caseManagerVerifyClientOtp'
        : 'userVerifyOtp',
      isACaseManager,
    })

  const handleSkipAuthResponse = handleMutationResponse({
    errorObject: { otp: ERROR_GENERIC_MESSAGE },
    operationName: 'caseManagerSkipClientOtp',
    isACaseManager: false,
  })

  function handleMutationResponse({
    operationName,
    errorObject,
    isACaseManager,
  }) {
    return ({ graphQLErrors, data }) => {
      if (graphQLErrors) {
        throw new SubmissionError(errorObject)
      } else {
        setClientId({ data, operationName, isACaseManager })
        let user
        if (!isACaseManager) {
          user = getSentryUserFromOtp(data)
          setClientCredentials({ operationName, data })
          dispatch(clientAuthenticated(true))
        } else {
          user = {
            ...JSON.parse(localStorage.getItem('user')),
            screening_type: getScreeningType(),
          }
        }
        Sentry.setUser(user)
        localStorage.setItem('user', JSON.stringify(user))
        nextStep()
        return { data, operationName }
      }
    }
  }

  //ToDo: refactor so that step does not come from closure
  const nextStep = () => {
    const { customQuestionsFlag } = selectFromState(state)
    if (step === 7) {
      if (customQuestionsFlag) {
        navigate('/questions')
      } else {
        navigate('/screening')
      }
    } else {
      handleUpdateStep(step + 1)
      //ToDo: figure out how to decouple this
      /* if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }*/
    }
  }

  return {
    handleAuthenticateAndContinue,
    handleCreateClient,
    handleSkipAuthenticateAndContinue,
    nextStep,
  }
}

export default useAuthenticationPath
