import React from 'react'
import { connect } from 'react-redux'

import ActionBar from '../ActionBar/ActionBar'
import ActionToggle from '../ActionToggle/ActionToggle'
import Surface from '../Surface/Surface'
import { mapIconPath } from 'Shared/Icons/mapIconPath'
import { listIconPath } from 'Shared/Icons/listIconPath'

import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'

const SecondaryActionBar = (props) => {
  const { activeLabel, inactiveLabel, pageName, zIndex } = props
  const {
    showFilterMenu,
    showLocationCard,
    showLocationList,
    showMainMenu,
  } = props.ui
  const width = useMediaQuery({ query: DESKTOP_MEDIA_QUERY })
    ? '76.8rem'
    : '100%'

  if (showFilterMenu || showMainMenu || showLocationCard) return null

  return (
    <Surface
      opacity={props.opacity || 0}
      className={'cypress-secondary-action-bar'}
      elevation={1}
      position={props.position || 'absolute'}
      bottom={props.bottom || 0}
      left={props.left}
      right={props.right}
      width={props.width || width}
      tabletWidth={props.tabletWidth}
      background={props.background}
      boxShadow={props.boxShadow}
      margin={props.margin}
      minHeight={props.minHeight || '60px'}
      minHeightMob={props.minHeightMob}
      minHeightTab={props.minHeightTab}
      pageName={pageName}
      zIndex={zIndex}>
      <ActionBar
        borderRadius={props.borderRadiusToggle}
        actionType={'SECONDARY'}
        backgroundColor={props.backgroundBtn}
        padding={props.actionPadding || '0 1.6rem'}>
        <ActionToggle
          fillIcon={props.fillIcon}
          justContAction={props.justContAction}
          className={'cypress-back-to-list-action-button'}
          opacity={props.opacityToggle || 0.7}
          minWidth={props.minWidthToggle || '16rem'}
          activeIconPath={mapIconPath}
          activeLabel={activeLabel || 'Map'}
          handleOnClick={props.handleClick}
          inactiveIconPath={listIconPath}
          inactiveLabel={inactiveLabel || 'List'}
          showActive={showLocationList}
          backgroundAction={props.backgroundAction}>
          {showLocationList ? 'Map' : 'List'}
        </ActionToggle>
      </ActionBar>
    </Surface>
  )
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    ui,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleClick: () => {
      ownProps.callback()
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryActionBar)
