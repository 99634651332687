import { startEditingOnStep } from 'Actions/screener'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useHasHouseholdMembers } from 'Components/Shared/ReduxForm/hooks'

const useHandleOnEditClick = (editSection, index) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const hasHouseholdMembers = useHasHouseholdMembers()

  const editInline = () => {
    navigate(`/review/edit/${index}`)
  }

  const editAtStep = () => {
    dispatch(
      startEditingOnStep(
        hasHouseholdMembers
          ? editSection?.householdStep
          : editSection?.clientStep
      )
    )
    navigate('/screening')
  }

  return () => (editSection?.inline ? editInline() : editAtStep())
}

export default useHandleOnEditClick
