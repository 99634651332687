import React from 'react'
import { StyledSurface, StyledCustomSurface } from './style'

const Surface = (props) => {
  const { children, isCustomSurface } = props
  if (isCustomSurface) {
    return <StyledCustomSurface {...props}>{children}</StyledCustomSurface>
  } else {
    return <StyledSurface {...props}>{children}</StyledSurface>
  }
}

export default Surface
