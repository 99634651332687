import { Client } from 'Components/Shared/Hooks/types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'Store'

/**
 * If screener is not completed, redirect to screener.
 *
 * @param {String} path Where to redirect to
 */
const useRedirectToIfScreeningIncomplete = (path: string) => {
  const navigate = useNavigate()
  const { screenerCompleted } = useSelector<RootState>(
    (state) => state.client
  ) as Client
  useEffect(() => {
    if (screenerCompleted === false || screenerCompleted === null) {
      navigate(path)
    }
  }, [navigate, path, screenerCompleted])
}

export default useRedirectToIfScreeningIncomplete
