import React from 'react'
import styled from 'styled-components/macro'
import { formatPhoneNumber } from '../../../Shared/helpers'

import LocationInfo from './LocationInfo/LocationInfo'
import { outlinePhoneIconPath } from 'Shared/Icons/LocationInfo/paths'

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const PhoneNumber = (props) => {
  const { numbers, isPrimaryNumberOnly } = props

  if (!numbers) return null

  const formatPhoneNumberExtension = (extension) =>
    extension ? `Ext. ${extension}` : ''

  const renderPhoneNumber = (number, extension) => {
    return (
      <StyledLink href={`tel:${number}`}>
        {`${formatPhoneNumber(number)} ${formatPhoneNumberExtension(
          extension
        )}`}
      </StyledLink>
    )
  }

  return (
    <>
      {Object.keys(numbers).map((key) => {
        const { number } = numbers[key]

        if (!number) return null

        const { extension } = numbers[key]
        const label = `${key} Phone Number`
        const isPrimaryNumber = key === 'primary'
        const renderIcon = isPrimaryNumber ? outlinePhoneIconPath : null
        if (isPrimaryNumberOnly && !isPrimaryNumber) return null

        return (
          <LocationInfo
            alignitems={props.alignitems}
            key={key}
            content={renderPhoneNumber(number, extension)}
            screenReaderLabel={label}
            icon={renderIcon}
            isPhoneNumer={true}
          />
        )
      })}
    </>
  )
}

export default PhoneNumber
