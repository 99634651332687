import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import theme from 'Shared/Theme/ssTheme'

export const StyledWrapper = styled.div`
  max-width: 25%;
  ${media.smaller`
    max-width: 100%;
  `}
`
export const StyledParagraph = styled.p`
  font-size: 0.75rem;
  margin: 1rem 0 2rem;
  line-height: 2.4;
  color: ${theme.palette.text.default};
  ${media.smaller`
    margin: 1rem 0;
  `}
`

StyledWrapper.displayName = 'StyledWrapper'
StyledParagraph.displayName = 'StyledParagraph'
