import React from 'react'
import { StyledActionBar } from './style'

const ActionBar = (props) => {
  const { children, actionType, callback, backgroundColor } = props

  return (
    <StyledActionBar
      padding={props.padding || '0 1.6rem'}
      actionType={actionType}
      backgroundColor={backgroundColor}
      borderRadius={props.borderRadius}
      callback={callback}>
      {children}
    </StyledActionBar>
  )
}

export default ActionBar
