import React from 'react'
import NextStepsDocumentationListItem from 'Pages/NextSteps/NextStepsDocumentationListItem'
import { mockDocumentations } from 'Pages/NextSteps/mockPayloads'
import { StyledDocumentationList } from './style'

const NextStepsDocumentationList = () => {
  return (
    <StyledDocumentationList>
      {mockDocumentations.map((doc, i) => (
        <NextStepsDocumentationListItem doc={doc} key={i} />
      ))}
    </StyledDocumentationList>
  )
}

export default NextStepsDocumentationList
