import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import Steps from 'Components/Screener/Steps'
import { StyledTabletContainer } from 'Pages/Locations/style'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'

import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import ScreenerProgressBar from 'Components/Screener/ScreenerProgressBar'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import { Col, Row } from 'styled-bootstrap-grid'
import { StyledMain } from 'Pages/NextSteps/style'

const TabletAndDesktopScreener = (props) => {
  const { device, steps, showFilterMenu, showMainMenu, householdMembersIndex } =
    props

  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  const menuOrFiltersOpen = showFilterMenu || showMainMenu

  const location = useLocation()

  const [containerHeight, setContainerHeight] = useState(0)

  const ref = useRef(null)

  const searchBarHeight = 80

  const secondaryActionHeight = 56

  const menuHeight = containerHeight + searchBarHeight

  const resizeContainer = useCallback(() => {
    if (ref && ref.current) {
      const { clientHeight } = ref.current

      const containerHeight =
        !menuOrFiltersOpen && device === 'tablet'
          ? clientHeight - secondaryActionHeight
          : clientHeight

      setContainerHeight(containerHeight)
    }
  }, [device, ref, menuOrFiltersOpen])

  useLayoutEffect(() => {
    resizeContainer()
  }, [resizeContainer])

  window.addEventListener('orientationchange', () => {
    resizeContainer()
  })

  window.addEventListener('resize', () => {
    resizeContainer()
  })

  window.addEventListener('hideLogo', () => {
    resizeContainer()
  })

  return (
    <>
      <Header zIndex={99} />
      <Menu pathname={location.pathname} height={menuHeight} />
      <StyledMain>
        <StyledTabletContainer ref={ref}>
          {!isDesktop && <ScreenerProgressBar />}
          <Row>
            {isDesktop && <ProgressSideBar />}
            <Col lg='12'>
              <StyledCard
                padding={isDesktop ? '0' : '2.5rem 0'}
                height='calc(100vh - 10.4rem)'
              >
                <Steps
                  steps={steps}
                  householdMembersIndex={householdMembersIndex}
                />
              </StyledCard>
            </Col>
          </Row>
        </StyledTabletContainer>
      </StyledMain>
    </>
  )
}

const stateToProps = (state) => {
  const { showFilterMenu, showMainMenu } = state.ui
  return {
    showFilterMenu,
    showMainMenu,
  }
}

export default connect(stateToProps)(TabletAndDesktopScreener)
