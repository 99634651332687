import React from 'react'
import InputField from './InputField'
import InputFieldArray from './InputFieldArray'

const Input = (props) => {
  const { iterable } = props

  return iterable ? <InputFieldArray {...props} /> : <InputField {...props} />
}

export default Input
