import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import useDeepCompareEffect from 'use-deep-compare-effect'

import DesktopLocations from './DesktopLocations'
import TabletMobileLocations from './TabletMobileLocations'
import LocationRequest from 'Pages/LocationRequest/LocationRequest'
import { filterLocations } from 'Actions/filters'
import {
  handleToggleFilterMenu,
  updateShowLocationList,
  updateShowLocationDetail,
} from 'Reducers/uiSlice'
import {
  MOBILE_AND_TABLET_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector, useAppDispatch } from 'Store/hooks'
import type { AppDispatch } from 'Store'

export type LocationsPageProps = {
  showDesktopDetailView?: boolean
  showList?: boolean
  toggleFilterMenu: () => void
  updateShowList: (flag: boolean) => void
  updateShowLocationDetail: (flag: boolean) => void
}

const Locations = (props: LocationsPageProps) => {
  const {
    showDesktopDetailView = false,
    showList = false,
    toggleFilterMenu,
    updateShowList,
    updateShowLocationDetail,
  } = props

  const filters = useAppSelector((state) => state.filters)

  const {
    showLocationList,
    showLocationRequest,
    showFilterMenu,
    showLocationDetail,
    showLeftColumn,
  } = useAppSelector((state) => state.ui)

  const coordinates = useAppSelector((state) => state.geolocation.coordinates)
  const locations = useAppSelector((state) => state.locations.searchResults)
  const zipCode = useAppSelector((state) => state.geolocation?.zipCode)

  const navigate = useNavigate()

  const pageName = showLocationList ? 'LocationsList' : 'LocationsMap'

  const secondaryActionBarCallback = () => {
    const returnToUrl = showLocationList ? '/locations/map' : '/locations/list'
    navigate(returnToUrl)
  }

  const dispatch = useAppDispatch()
  useEffect(() => {
    updateShowList(showList)
  }, [showList, updateShowList])
  useDeepCompareEffect(() => {
    dispatch(filterLocations())
    updateShowLocationDetail(showDesktopDetailView)
  }, [filters, coordinates, showList, updateShowLocationDetail])

  return (
    <>
      {showLocationRequest && <LocationRequest />}
      <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
        <TabletMobileLocations
          filterMenuClickHandler={toggleFilterMenu}
          locations={locations}
          pageName={pageName}
          secondaryActionBarCallback={secondaryActionBarCallback}
          showFilterMenu={showFilterMenu}
          showList={showList}
          zipCode={zipCode}
        />
      </MediaQuery>

      <MediaQuery query={DESKTOP_MEDIA_QUERY}>
        <DesktopLocations
          filterMenuClickHandler={toggleFilterMenu}
          locations={locations}
          showDesktopDetailView={showDesktopDetailView}
          showFilterMenu={showFilterMenu}
          showHeader={true}
          showLeftColumn={showLeftColumn}
          showList={showList}
          showLocationDetail={showLocationDetail}
          zipCode={zipCode}
        />
      </MediaQuery>
    </>
  )
}
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateShowList: (flag: boolean) => dispatch(updateShowLocationList(flag)),
    updateShowLocationDetail: (flag: boolean) =>
      dispatch(updateShowLocationDetail(flag)),
    toggleFilterMenu: () => {
      dispatch(handleToggleFilterMenu())
    },
  }
}

export default connect(null, mapDispatchToProps)(Locations)
