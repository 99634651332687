import styled from 'styled-components/macro'

export const StyledActionButton = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.text.alt};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0;
  margin-right: ${(props) => props.marginRight || `0`};
  font-weight: 500;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

StyledActionButton.displayName = 'StyledActionButton'
