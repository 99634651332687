import React from 'react'
import theme from 'Shared/Theme/ssTheme'

const signUpPath = (
  <g transform='translate(0)'>
    <g transform='translate(0 0)'>
      <path className='a' fill='none' d='M0,0H32V32H0Z' />
      <path
        className='b'
        fill={theme.palette.primary.dark}
        d='M15.74,6.935A2.935,2.935,0,1,1,12.805,9.87,2.944,2.944,0,0,1,15.74,6.935m0,13.208c3.962,0,8.512,1.893,8.805,2.935v1.468H6.935V23.093c.294-1.057,4.843-2.95,8.805-2.95M15.74,4a5.87,5.87,0,1,0,5.87,5.87A5.869,5.869,0,0,0,15.74,4Zm0,13.208C11.822,17.208,4,19.174,4,23.078v4.4H27.481v-4.4C27.481,19.174,19.659,17.208,15.74,17.208Z'
        transform='translate(-0.16 0.184)'
      />
    </g>
  </g>
)

export default signUpPath
