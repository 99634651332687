import { createSlice, createAction } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { ui as initialState } from 'Reducers/initialState'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.loading = true
    },
    loadingStopped: (state) => {
      state.loading = false
    },
    hideLogo: (state) => {
      state.showLogo = false
    },
    hideCookiesConsent: (state) => {
      state.showCookiesConsent = false
    },
    toggleMainMenu: (state) => {
      state.showMainMenu = !state.showMainMenu
    },
    updateLocationId: (state, action: PayloadAction<number | null>) => {
      state.map.locationID = action.payload
      state.showLeftColumn = true
    },
    toggleShowLoginLink: (state) => {
      state.showLoginLink = !state.showLoginLink
    },
    updateShowLocationCard: (state, action: PayloadAction<boolean>) => {
      state.showLocationCard = action.payload
    },
    updateShowLocationDetail: (state, action: PayloadAction<boolean>) => {
      state.showLocationDetail = action.payload
    },
    updateShowLocationList: (state, action: PayloadAction<boolean>) => {
      state.showLocationList = action.payload
    },
    updateShowLeftColumn: (state, action: PayloadAction<boolean>) => {
      state.showLeftColumn = action.payload
    },
    updateShowLocationRequest: (state) => {
      state.showLocationRequest = !state.showLocationRequest
    },
    updateShowZeroSearchResults: (state, action: PayloadAction<boolean>) => {
      state.showZeroSearchResultsMessage = action.payload
    },
    toggleMountScreener: (state) => {
      state.mountScreener = !state.mountScreener
    },
    updateShowCaseManagementFilter: (state, action: PayloadAction<boolean>) => {
      state.showCaseManagementFilter = action.payload
    },
    handleToggleFilterMenu: (state) => {
      if (state.showLocationCard) {
        state.showLocationCard = false
      }
      state.showFilterMenu = !state.showFilterMenu
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action.payload.ui,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})

export const {
  loadingStarted,
  loadingStopped,
  hideLogo,
  hideCookiesConsent,
  toggleMainMenu,
  updateLocationId,
  toggleShowLoginLink,
  updateShowLocationCard,
  updateShowLocationDetail,
  updateShowLocationList,
  updateShowLeftColumn,
  updateShowLocationRequest,
  updateShowZeroSearchResults,
  toggleMountScreener,
  updateShowCaseManagementFilter,
  handleToggleFilterMenu,
} = uiSlice.actions

export default uiSlice.reducer
