import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'

export const StyledBusinessHoursData = styled.td`
  padding: ${(props) => props.paddingProp};
  padding-inline-end: 0.5rem;
`

export const StyledDays = styled.table`
  width: 100%;
  margin: 10px 0 0;
  position: relative;
  border-spacing: 0px;
  text-transform: capitalize;
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
  opacity: 0.7;
  ${media.smaller`
  width: 100%;
  margin: 10px;
  position: relative;
  `}
`

export const StyledBusinessHoursButton = styled.button`
  background: unset;
  border: unset;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`

StyledBusinessHoursData.displayName = 'StyledBusinessHoursData'
StyledDays.displayName = 'StyledDays'
StyledBusinessHoursButton.displayName = 'StyledBusinessHoursButton'
