import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import Timer from 'react-compound-timer'
import useLogOut from 'Components/Auth/Hooks/useLogOut'
import { ME } from 'Components/Auth/Hooks/gql'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { StyledParagraph } from 'Components/CaseManagement/Modals/style'
import { IDLE_TIME_MODAL_COUNTDOWN } from 'Shared/constants'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'

const timeFormat = (value) => (value < 10 ? `0${value}` : value)

const IdleTimeoutModal = ({ open, handleClose, reset, redirectUrl }) => {
  const [handleMeQuery] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
  })

  const { clearUserData } = useLogOut()
  const navigate = useNavigate()

  const handleLogOut = () => {
    clearUserData()
    navigate(redirectUrl)
    handleClose()
  }

  const handleStayLoggedIn = () => {
    handleMeQuery()
    reset()
    handleClose()
  }

  return (
    <ModalTemplate
      open={open}
      iconKey={'autoLogout'}
      heading={'Inactive Session'}
      description={'You are about to be logged out due to inactivity'}
    >
      <Timer
        initialTime={IDLE_TIME_MODAL_COUNTDOWN}
        direction='backward'
        checkpoints={[
          {
            time: 0,
            callback: handleLogOut,
          },
        ]}
      >
        {() => (
          <StyledParagraph>
            You will be logged out in <Timer.Minutes />:
            <Timer.Seconds formatValue={timeFormat} />
          </StyledParagraph>
        )}
      </Timer>

      <ConfirmOrCancel
        handleOnConfirm={handleStayLoggedIn}
        handleOnCancel={handleLogOut}
        confirmLabel={'Stay Logged In'}
        cancelLabel={'Log Out'}
      />
    </ModalTemplate>
  )
}

export default IdleTimeoutModal
