import styled from 'styled-components/macro'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

interface StyledButtonProps extends CSSProperties {
  margin?: string
}

export const StyledButton = styled.button<StyledButtonProps>`
  border: none;
  background-color: inherit;
  padding: 0;
  margin: ${(props) => props.margin};
  color: #255979;
  cursor: pointer;
`

StyledButton.displayName = 'StyledButton'
