import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormError from 'Components/Shared/FormError/FormError'
import Radio from '@material-ui/core/Radio'
import {
  StyledFormControlLabel,
  StyledRadioGroup,
} from 'Components/CustomQuestions/CustomQuestionField/Inputs/BooleanInput/style'

const BooleanInput = ({
  id,
  input: { onChange, name, ...rest },
  label,
  meta: { touched, error, submitError },
  dataTestId,
}) => (
  <FormControl
    component='fieldset'
    error={touched && (!!error || !!submitError)}>
    <StyledRadioGroup
      {...rest}
      aria-label={label}
      data-testid={dataTestId}
      id={id}
      onChange={({ target: { value } }) => onChange(value)}>
      <StyledFormControlLabel
        value='true'
        control={<Radio name={name} />}
        label='Yes'
      />
      <StyledFormControlLabel
        value='false'
        control={<Radio name={name} />}
        label='No'
      />
    </StyledRadioGroup>
    <FormError error={error ?? submitError} touched={touched} />
  </FormControl>
)

export default BooleanInput
