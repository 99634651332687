import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NextStepsBenefitListItem from 'Pages/NextSteps/NextStepsBenefitListItem'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { CALCULATE_SCREENING_RESULTS } from './gql'
import { categoriesSelector } from 'Selectors/benefits'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import {
  StyledSubHeading,
  StyledTabPanelDiv,
  StyledDynamicCtaButton,
  StyledAppBar,
} from 'Components/Screener/style'
import backIconPath from 'Shared/Icons/backIconPath'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Box from '@material-ui/core/Box'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import 'Components/Uielements/styles/globalStyle.css'
import { calculateTotalBenefitAmount } from './helpers'
import RateDisplay from 'Components/CaseManagement/Reporting/Charts/Shared/RateDisplay/RateDisplay'
import { TotalDrawdownIcon } from 'Components/CaseManagement/Shared/Icons'
import { StyledFlexContainer, StyledHeading } from './style'
import NextStepsBenefitsTabs from './NextStepsBenefitsTabs'
import { singleStopGreen } from 'Shared/Theme'

const NextStepsBenefits = (props) => {
  const [getClientBenefitsCategories, { data }] = useMutation(
    CALCULATE_SCREENING_RESULTS
  )

  const clientId = useSelector((state) => state.client.id)

  useEffect(() => {
    getClientBenefitsCategories({
      variables: { clientId },
    })
    // eslint-disable-next-line
  }, [])

  const benefitsCategories = categoriesSelector(data)

  const [tabIndex, setTabIndex] = useState(0)

  const totalBenefitAmount = calculateTotalBenefitAmount(data)

  const handleTabChange = (event, index) => {
    setTabIndex(index)
  }
  const filterPrograms = (programs) => {
    return programs.filter((program) => {
      if (tabIndex === 0) return program.eligible
      else if (tabIndex === 1) return !program.eligible
      else return program
    })
  }
  const renderPrograms = (programs, categoryIconPath, category) => {
    return filterPrograms(programs).map((program, index) => {
      return (
        <Col md='6' key={index + program.id}>
          <NextStepsBenefitListItem
            {...program}
            category={category}
            categoryIcon={renderCategoryIcon(categoryIconPath)}
          />
        </Col>
      )
    })
  }
  const renderCategoryIcon = (categoryIconPath) => {
    return (
      <Icon fill={'unset'} scale={'4.5rem'} margin='0 1rem 0 0'>
        {categoryIconPath}
      </Icon>
    )
  }
  const renderLocationBtn = () => {
    return (
      <StyledDynamicCtaButton
        component={Link}
        to={'/locations/list'}
        background={singleStopGreen}
        textshadow='none'
        border={'0.0625rem solid ' + singleStopGreen}
        margin='0'
        padding={isTabletAndMobile ? '2rem' : '1rem 0'}
        fontSize={isTabletAndMobile ? '0.8125rem' : '0.6875rem'}
        width={isTabletAndMobile ? '100%' : '75%'}
        height={isTabletAndMobile ? '4rem' : '3rem'}
        justcont={isTabletAndMobile ? 'flex-start' : ''}
        borderhover={'0.0625rem solid ' + singleStopGreen}
        // we should pass 1 / 0 instead of true / false when passing props to styled-components
        $nextSteps={true}
      >
        Next Steps
        <Icon
          position={'absolute'}
          right={'1rem'}
          fill={'#FFFFFF'}
          margin={'0'}
          scale={isTabletAndMobile ? '1.375rem' : '0.8125rem'}
          style={{ transform: 'rotate(180deg)' }}
          viewBox={'0 0 24 24'}
          cursorOnHover={'pointer'}
        >
          {backIconPath}
        </Icon>
      </StyledDynamicCtaButton>
    )
  }

  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })
  return (
    <MuiThemeProvider theme={ssTheme}>
      <StyledAppBar position='static'>
        <Container>
          <StyledMuiTextFieldWrapper mbottom='0'>
            <Row alignItems='center'>
              <Col lg='3'>
                <StyledSubHeading
                  fontWeight='600'
                  fontSize='1.5rem'
                  marginBottom='0'
                  padding='0'
                  paddingTopMobile='1.5rem'
                  id='mainContent'
                  tabIndex='-1'
                >
                  Here Are Your Results
                </StyledSubHeading>
              </Col>
              <Col lg='6'>
                <NextStepsBenefitsTabs
                  onChange={handleTabChange}
                  value={tabIndex}
                />
              </Col>
              {!isTabletAndMobile && (
                <Col md='3'>
                  <StyledMuiTextFieldWrapper
                    width='100%'
                    mbottom='0'
                    display='flex'
                    justCont='flex-end'
                  >
                    {renderLocationBtn()}
                  </StyledMuiTextFieldWrapper>
                </Col>
              )}
            </Row>
          </StyledMuiTextFieldWrapper>
        </Container>
      </StyledAppBar>
      <Container role='tabpanel' aria-labelledby={`tab${tabIndex + 1}`}>
        <StyledFlexContainer>
          <StyledHeading nextSteps={true}>
            {tabIndex === 0 && (
              <>
                Based on your responses, here are benefits you’re likely
                eligible for. You may
                <br />
                <br />
                <Link to='/review'>review your responses</Link> if you’d like.
              </>
            )}

            {tabIndex === 1 && (
              <>
                Based on your responses, you are unlikely to be eligible for the
                programs listed below.
                <br />
                <br />
                You may <Link to='/review'>review your responses</Link> to make
                sure you’ve entered everything correctly.
              </>
            )}

            {tabIndex === 2 && (
              <>
                There are a variety of benefits available. See a list below. To
                check your screening information,
                <br />
                <br />
                <Link to='/review'>review your responses</Link> now.
              </>
            )}
          </StyledHeading>
          <RateDisplay
            value={Math.floor(totalBenefitAmount)}
            label='Potential Yearly Benefit Value'
            isCurrency={true}
            icon={TotalDrawdownIcon}
          />
        </StyledFlexContainer>
        <Box p={0}>
          <StyledTabPanelDiv>
            {benefitsCategories.map((benefitsCategory, index) => {
              let { category, categoryIconPath, programs } = benefitsCategory
              return filterPrograms(programs).length ? (
                <StyledMuiTextFieldWrapper
                  data-testid='benefit'
                  background='#fff'
                  mbottom='1.5rem'
                  padding='1rem 3rem'
                  shadow='0px 0.1875rem 0.375rem #1D283117'
                  radius='0.4375rem'
                  key={index}
                  role='group'
                  aria-label='benefit'
                >
                  <Row>
                    <Col md='4'>
                      <StyledHeading categoryTitle={true}>
                        {renderCategoryIcon(categoryIconPath)}
                        {category}
                      </StyledHeading>
                    </Col>
                    <Col md='8'>
                      <Row>
                        {renderPrograms(programs, categoryIconPath, category)}
                      </Row>
                    </Col>
                  </Row>
                </StyledMuiTextFieldWrapper>
              ) : null
            })}
          </StyledTabPanelDiv>
        </Box>
      </Container>
      {isTabletAndMobile && (
        <StyledMuiTextFieldWrapper
          mbottom='0'
          display='flex'
          justCont='center'
          alignitems='center'
          position='fixed'
          bottom='0'
          left='0'
          background='#fff'
          padding='1rem 2rem'
          width='100%'
        >
          {renderLocationBtn()}
        </StyledMuiTextFieldWrapper>
      )}
    </MuiThemeProvider>
  )
}

export default NextStepsBenefits
