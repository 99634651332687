import React from 'react'

const personalizedBenefitsIconPath = (
  <>
    <g transform='translate(0 0)'>
      <rect className='a' fill='none' />
    </g>
    <g transform='translate(1.491 3.622)'>
      <path
        className='b'
        fillRule='evenodd'
        d='M26.366,3H4.707A2.721,2.721,0,0,0,2,5.718V24.744a2.721,2.721,0,0,0,2.707,2.718H26.366a2.721,2.721,0,0,0,2.707-2.718V5.718A2.721,2.721,0,0,0,26.366,3Zm0,21.744H4.707V5.718H26.366Z'
        transform='translate(-2 -3)'
      />
      <path
        className='b'
        fillRule='evenodd'
        d='M21.954,10.907,20.046,9l-4.258,4.258-1.894-1.907L12,13.245l3.788,3.815Z'
        transform='translate(1.641 -0.799)'
      />
      <rect width='6.716' height='2.687' transform='translate(4.071 5.444)' />
      <rect width='6.716' height='2.687' transform='translate(4.071 10.888)' />
      <rect width='6.716' height='2.687' transform='translate(4.071 16.332)' />
    </g>
  </>
)

export default personalizedBenefitsIconPath
