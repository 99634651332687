import { CREATE_REFERRAL } from './gql'
import { CALCULATE_SCREENING_RESULTS } from 'Pages/NextSteps/gql'
import { useMutation } from '@apollo/client'

interface Service {
  locationId: string
  recipientType: 'CLIENT' | 'PARTNER' | 'BOTH'
  referralCategories: Array<string>
}

const useCreateReferral = () => {
  const [createReferral] = useMutation(CREATE_REFERRAL)
  const [clientLocation] = useMutation(CALCULATE_SCREENING_RESULTS)

  const handleCreateReferral = (
    clientLocationId: string,
    services: Array<Service>
  ) => {
    return createReferral({
      variables: {
        clientLocationId: clientLocationId,
        inputs: services,
      },
    })
  }
  const handleGetClientLocation = (clientId: String) => {
    return clientLocation({
      variables: {
        clientId: clientId,
      },
    })
  }
  return {
    handleCreateReferral,
    handleGetClientLocation,
  }
}

export default useCreateReferral
