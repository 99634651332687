import React from 'react'
import Surface from '../Surface/Surface'
import {
  StyledBrandingLogo,
  StyledSkipToMainContentLink,
} from 'Components/Shared/TabletAndDesktopHeader/style'
import { BrandStyle } from 'Components/Shared/Header/style'
import MenuItems from 'Components/Shared/MenuItems/MenuItems'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import { Container } from 'styled-bootstrap-grid'
import { HeaderContainer, HeaderFlex } from 'Components/Shared/PageHeader/style'
import { StyledNavBarWrapper } from 'Components/Shared/NavBar/NavBar.style'
import ClientAuthHeader from 'Components/Shared/ClientAuthHeader/ClientAuthHeader'
import useMenuItemsData from 'Components/Shared/Hooks/useMenuItemsData'
import { isScreener, isNextSteps, isLandingPage } from 'Shared/helpers'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { getClientSummaryRoute } from 'Components/Shared/Hooks/useMenuItemsData'
import { showMenu } from './helpers'
import { isCaseManagementPage } from 'Shared/helpers'
import useBranding from 'Components/LandingPage/Hooks/useBranding'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'

const TabletAndDesktopHeader = ({ zIndex }) => {
  const { tabletTopNavMenuData, menuItemsLoggedInAsCM } = useMenuItemsData()
  const { logo: brandingLogoUrl } = useBranding()

  const menuItemsToUse = () => {
    if (!localStorage.getItem('caseManagerId')) return tabletTopNavMenuData

    const clientId = sessionStorage.getItem('clientId')
    const clientLocationId = sessionStorage.getItem('clientLocationId')

    if (clientId && clientLocationId) {
      menuItemsLoggedInAsCM.unshift(getClientSummaryRoute(clientLocationId))
    }

    return menuItemsLoggedInAsCM
  }

  return (
    <Surface
      elevation={5}
      zIndex={zIndex}
      backgroundColor
      display={'flex'}
      boxShadow={isLandingPage() ? 'none' : '0px 2px 5px #0000000A'}
      padding={'0'}
    >
      <Container fluid={isLandingPage() ? '' : 'fluid'}>
        <HeaderContainer>
          <HeaderFlex>
            <StyledSkipToMainContentLink href='#mainContent'>
              Skip to Main Content
            </StyledSkipToMainContentLink>
            <BrandStyle data={'cypress-header-logo'}>
              <SrOnly>Logo</SrOnly>
              <SingleStopLogo />
              {brandingLogoUrl && (
                <>
                  <SrOnly>Logo</SrOnly>
                  <StyledBrandingLogo
                    data-testid='partner-logo'
                    src={brandingLogoUrl}
                  />
                </>
              )}
            </BrandStyle>
            {showMenu({
              hasCaseManagerId: localStorage.getItem('caseManagerId'),
              isNextSteps: isNextSteps(),
              isScreener: isScreener(),
            }) && (
              <StyledNavBarWrapper>
                <MenuItems items={menuItemsToUse()} fontSize='0.9rem' />
              </StyledNavBarWrapper>
            )}
          </HeaderFlex>
          <HeaderFlex width='auto'>
            {!isCaseManagementPage() && (
              <>
                <TranslationWidget />
                <ClientAuthHeader />
              </>
            )}
          </HeaderFlex>
        </HeaderContainer>
      </Container>
    </Surface>
  )
}

export default TabletAndDesktopHeader
