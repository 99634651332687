import React, { FocusEventHandler, CSSProperties } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import styled from 'styled-components/macro'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

interface buttonStyleProps {
  error?: boolean
}

interface labelStyleProps {
  isFocused: boolean
}

interface InputLabelProps {
  isActive: boolean
  isFocused: boolean
  label: string
  id: string
  disabled?: boolean
}

interface ButtonSelectProps {
  children: any
  className: any
  variant: 'standard' | 'outlined'
  elemRef: any
  error?: boolean
  id: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  onFocus?: FocusEventHandler<HTMLButtonElement>
  onBlur?: FocusEventHandler<HTMLButtonElement>
}

interface SelectContainerProps extends CSSProperties {
  disabled?: boolean
}

const useStyles = (props: buttonStyleProps) =>
  makeStyles({
    standard: {
      textTransform: 'none',
      '&:hover': {
        color: '#0d8762',
        backgroundColor: 'white',
      },
      '&:focus': {
        outline: '2px solid #000',
      },
    },
    outlined: {
      textTransform: 'capitalize',
      width: '100%',
      outline: props.error ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
      color: props.error ? '#E41A0C' : 'rgba(0, 0, 0, 0.54)',
      boxSizing: 'border-box',
      '&:hover': {
        outline: '1px solid #000',
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
        color: '#000',
      },
      '&:focus': {
        outline: '2px solid #0d8762',
        color: '#0d8762',
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })

const labelStyles = (props: labelStyleProps) =>
  makeStyles({
    normal: {
      zIndex: 0,
      color: 'inherit !important',
      height: '0.975rem',
      position: 'absolute',
      paddingLeft: '13px',
    },
    filled: {
      zIndex: 1,
      position: 'absolute',
      transform: 'translateX(5px) scale(0.75)',
      padding: '0px 7px',
      backgroundColor: 'white',
      color: props.isFocused ? '#0d8762 !important' : 'inherit !important',
      top: '-14%',
    },
    animation: {
      transition: 'transform 0.1s ease-in, top 0.1s ease-in',
    },
  })
export const StyledSpan = styled.span`
  color: rgba(0, 0, 0, 0.9);
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 1.85em;
  overflow: hidden;
  white-space: nowrap;
`
export const ContainerSelect = styled.div<SelectContainerProps>`
  display: flex;
  color: rgba(0, 0, 0, 0.54) !important;
  &:hover {
    color: ${(props) => (!props.disabled ? 'black !important' : '')};
  }
  align-items: center;
  text-transform: capitalize;
`
export const InputLabel = (props: InputLabelProps) => {
  const { isActive, isFocused, label, id } = props
  const { normal, filled, animation } = labelStyles(props)()
  return (
    <label
      className={`${isActive || isFocused ? filled : normal} ${animation}`}
      id={id}
    >
      {label}
    </label>
  )
}

export const StyledButtonSelect = ({
  children,
  className = '',
  variant,
  elemRef,
  ...props
}: ButtonSelectProps) => {
  const styles = useStyles(props)()

  return (
    <Button
      role='combobox'
      aria-haspopup='listbox'
      endIcon={<ArrowDropDown />}
      ref={elemRef}
      className={`${styles[variant]} ${className}`}
      {...props}
      disableFocusRipple={true}
    >
      {children}
    </Button>
  )
}
