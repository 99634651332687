import gql from 'graphql-tag'

export const CREATE_REFERRAL = gql`
  mutation createReferral($clientLocationId: ID!, $inputs: [ReferralInput!]!) {
    createReferral(clientLocationId: $clientLocationId, inputs: $inputs) {
      referrals {
        id
        origin
        recipientType
        referralType
        createdAt
        location {
          id
        }
      }
    }
  }
`
