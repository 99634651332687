import React from 'react'
import { connect } from 'react-redux'
import { StyledDesktopSecondaryActionButton } from './style'
import theme from 'Shared/Theme/ssTheme'
import { SrOnly } from 'Shared/Theme/utilities.styles'
import { updateShowLeftColumn } from 'Reducers/uiSlice'
const DesktopSecondaryActionButton = (props) => {
  const {
    showLocationList,
    showMainMenu,
    showLeftColumn,
    updateShowLeftColumn,
  } = props

  const label = showLocationList ? 'hide list' : 'show list'

  const leftPosition = showLeftColumn ? theme.layout.asideWidth : '0'

  const handleClick = () => {
    updateShowLeftColumn(!showLeftColumn)
  }

  if (showMainMenu) {
    return null
  }

  return (
    <StyledDesktopSecondaryActionButton
      className={'cypress-toggle-list-action-button'}
      leftPosition={leftPosition}
      transformButton={showLeftColumn}
      tabIndex={0}
      onClick={() => {
        handleClick()
      }}
    >
      <SrOnly>{label}</SrOnly>
    </StyledDesktopSecondaryActionButton>
  )
}

const mapStateToProps = (state) => {
  const {
    showFilterMenu,
    showLocationDetail,
    showLocationList,
    showMainMenu,
    showLeftColumn,
  } = state.ui
  return {
    showFilterMenu,
    showLocationDetail,
    showLocationList,
    showMainMenu,
    showLeftColumn,
  }
}
const mapDispatchToProps = (dispatch) => ({
  updateShowLeftColumn: (flag) => dispatch(updateShowLeftColumn(flag)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopSecondaryActionButton)
