import React from 'react'
import theme from 'Shared/Theme/ssTheme'

const loginIconPath = (
  <g transform='translate(0 0.288)'>
    <g transform='translate(0 -0.288)'>
      <rect className='a' fill='none' />
    </g>
    <g transform='translate(2.476 3.714)'>
      <path
        className='b'
        fill={theme.palette.primary.dark}
        d='M14.042,8.4l-1.873,1.889,3.479,3.508H2v2.7H15.648L12.169,20l1.873,1.889,6.69-6.746ZM26.084,24.586H15.38v2.7h10.7a2.7,2.7,0,0,0,2.676-2.7V5.7A2.7,2.7,0,0,0,26.084,3H15.38V5.7h10.7Z'
        transform='translate(-1.476 -3)'
      />
    </g>
  </g>
)

export default loginIconPath
