import React, { createContext, useState } from 'react'

export const PreScreenerContext = createContext()

const PreScreenerProvider = ({ children }) => {
  const defaultState = {
    modal: 'NONE',
  }

  const [preScreenerState, setPreScreenerState] = useState(defaultState)

  const resetContext = () => setPreScreenerState(defaultState)

  return (
    <PreScreenerContext.Provider
      value={{ preScreenerState, setPreScreenerState, resetContext }}
    >
      {children}
    </PreScreenerContext.Provider>
  )
}

export default PreScreenerProvider
