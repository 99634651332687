import React from 'react'

const pinDropPath = (
  <>
    <g transform='translate(-729.332 -243.822)'>
      <path
        className='a'
        style={{ fill: '#c1ffda' }}
        d='M801.8,280.8s-8.189-45.058-51.988-29.786S765.4,329.2,765.4,329.2Z'
      />
      <path
        d='M37.279,83.6v9.453l-2.286-1.846A153.24,153.24,0,0,1,17.715,74.315C5.96,60.819,0,48.7,0,38.309v-1.03a37.279,37.279,0,1,1,74.558,0v1.03c0,.873-.046,1.76-.13,2.657l-7.29-6.651A30.005,30.005,0,0,0,7.274,37.278v1.03c0,17.567,22.634,38.834,30.005,45.292Zm22.367-5.225H70.557V67.465H59.646ZM37.279,21.821A15.457,15.457,0,1,1,21.822,37.278,15.475,15.475,0,0,1,37.279,21.821Zm0,7.274a8.183,8.183,0,1,0,8.183,8.183A8.192,8.192,0,0,0,37.279,29.1ZM88.433,73.433l-2.045-1.866V83.245a9.873,9.873,0,0,1-9.862,9.861H53.5a9.873,9.873,0,0,1-9.862-9.861V71.568l-2.045,1.866-4.9-5.373L65.011,42.22,93.335,68.06Zm-9.319-8.5-14.1-12.865-14.1,12.865V83.244A2.59,2.59,0,0,0,53.5,85.832h23.03a2.59,2.59,0,0,0,2.588-2.588Zm0,0'
        transform='translate(729.332 243.823)'
      />
    </g>
  </>
)

export default pinDropPath
