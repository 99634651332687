export const UPDATE_NEXT_STEPS = 'UPDATE_NEXT_STEPS'

/**
 * Action type for review section update action
 */
export const UPDATE_REVIEW_SECTION_INDEX = 'UPDATE_REVIEW_SECTION_INDEX'

/**
 * Updates review section index at end of screener
 *
 * @param {Number} reviewSectionIndex
 */
export const updateReviewSectionIndex = (reviewSectionIndex) => ({
  type: UPDATE_REVIEW_SECTION_INDEX,
  reviewSectionIndex,
})

export const updateNextSteps = (nextSteps) => {
  return {
    type: UPDATE_NEXT_STEPS,
    nextSteps,
  }
}

export const getBenefit = (id) => {
  // TEMP STUBBED OUT MOCK BENEFIT RESPONSE
  return function (dispatch) {
    dispatch(
      updateNextSteps({
        name: 'Mock Benefits Place',
        link: 'https://mockBenefits.org',
      })
    )
  }

  // USE THIS ONCE API BUILT
  // return function(dispatch) {
  //   return axios
  //     .get(`${API_BASE}/benefits/id.json`)
  //     .then(function({ data }) {
  //       dispatch(updateNextSteps(data))
  //     })
  //     .catch(err => console.log(err))
  // }
}
