import React from 'react'
import { StyledExpandedCardContent } from 'Components/LocalResources/LocationCard/style'
import { StyledCard } from 'Components/Shared/LocationDetails/style'
import BusinessHours from 'Components/Shared/LocationDetails/BusinessHours/BusinessHours'
import Distance from 'Components/Shared/LocationDetails/Distance/Distance'
import EmailAddress from 'Components/Shared/LocationDetails/EmailAddress/EmailAddress'
import Languages from 'Components/Shared/LocationDetails/Languages'
import PhoneNumber from 'Components/Shared/LocationDetails/PhoneNumber'
import { AlgoliaLocation } from 'Pages/Locations/AlgoliaLocation'

const MainContent = ({
  location,
}: {
  location: AlgoliaLocation
  padding: string
  className: string
}) => {
  const {
    businessHours,
    distance,
    address,
    emailAddress,
    languageAccommodation: languages,
    numbers,
  } = location

  return (
    <StyledCard>
      <StyledExpandedCardContent padding={'.8rem 1.6rem'}>
        <Distance distance={distance} address={address} />
        <PhoneNumber numbers={numbers} />
        <EmailAddress emailAddress={emailAddress} />
        {languages && <Languages languages={languages} />}
        {businessHours && <BusinessHours businessHours={businessHours} />}
      </StyledExpandedCardContent>
    </StyledCard>
  )
}

export default MainContent
