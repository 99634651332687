import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ClientAuth from 'Components/Auth/ClientAuth'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import useOtp from 'Components/Auth/Hooks/useOtp'
import useRouteToOnboarding from 'Components/Client/ClientHooks/useRouteToOnboarding'
import { SubmissionError } from 'redux-form'
import { parseEmailOrPhone } from 'Components/PreScreener/helpers'
import { emailOrPhone as emailOrPhoneSelector } from 'Selectors'
import { resumeOnboarding, clientAuthenticated } from 'Actions/client'
import { useQuery } from '@apollo/client'
import { ME } from 'Components/Auth/Hooks/gql'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks'
import * as Sentry from '@sentry/browser'
import { getSentryUserFromOtp } from 'Pages/helpers'
import { useNavigate } from 'react-router-dom'

const ClientAuthPage = () => {
  const { handleVerifyOtp, setClientId, setClientCredentials } = useOtp()
  const { handleRouteToOnboarding } = useRouteToOnboarding()
  const dispatch = useDispatch()
  const { data } = useQuery(ME)
  const isACaseManager = data && data.me.isACaseManager
  const emailOrPhone = emailOrPhoneSelector(useSelector((state) => state))
  const registrationUrl = useDynamicRegistrationUrl()
  const navigate = useNavigate()

  useEffect(() => {
    if (!emailOrPhone) {
      navigate('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async ({ otp }) => {
    const { email, primaryPhone } = parseEmailOrPhone({ emailOrPhone })

    const { graphQLErrors, data } = await handleVerifyOtp({
      email,
      otp,
      primaryPhone,
      isACaseManager,
    })

    if (graphQLErrors) {
      throw new SubmissionError({ otp: 'Invalid credentials' })
    } else {
      setClientId({
        operationName: 'userVerifyOtp',
        data,
        isACaseManager,
      })

      if (!isACaseManager) {
        setClientCredentials({ data, operationName: 'userVerifyOtp' })
        dispatch(clientAuthenticated(true))
      }

      dispatch(resumeOnboarding()).then((onboardingSection) => {
        handleRouteToOnboarding(onboardingSection, registrationUrl)
      })

      const user = getSentryUserFromOtp(data)
      Sentry.setUser(user)
      localStorage.setItem('user', JSON.stringify(user))
    }
  }

  return (
    <HeaderWithContainerAndCard>
      <ClientAuth onSubmit={handleSubmit} isACaseManager={isACaseManager} />
    </HeaderWithContainerAndCard>
  )
}

export default ClientAuthPage
