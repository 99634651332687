import { RootState } from 'Store/index'
import { useSelector } from 'react-redux'
const urls = {
  CUSTOM_FIELDS: '/questions',
  SCREENER: '/screening',
  COMPLETED_SCREENER: '/completed-screener',
}

const useRegisterUrl = (dynamicUrl: string): string => {
  const client = useSelector((state: RootState) => state.client)
  if (!client.authenticated) {
    return dynamicUrl
  } else {
    return urls[client.screeningSection as keyof typeof urls] || dynamicUrl
  }
}

export default useRegisterUrl
