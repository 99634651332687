export const geolocation = {
  zipCode: null,
  geolocationError: false,
  coordinates: {
    lat: null,
    lng: null,
  },
  rehydrated: false,
  persisted: false,
}
