import React from 'react'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import Steps from 'Components/Screener/Steps'

import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import ScreenerProgressBar from 'Components/Screener/ScreenerProgressBar'
import { useLocation } from 'react-router-dom'
import { StyledMain } from 'Pages/NextSteps/style'

const MobileScreener = ({ steps, history, householdMembersIndex }) => {
  const { pathname } = useLocation()
  return (
    <>
      <Header />
      <Menu pathname={pathname} />
      <StyledMain>
        <ScreenerProgressBar />
        <StyledCard>
          <Steps steps={steps} householdMembersIndex={householdMembersIndex} />
        </StyledCard>
      </StyledMain>
    </>
  )
}

export default MobileScreener
