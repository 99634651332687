import React from 'react'
import MediaQuery from 'react-responsive'

import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'
import LocationMap from 'Components/LocalResources/LocationsMap/LocationsMap'

const LocationsMap = () => {
  const { showMainMenu } = useAppSelector((state) => state.ui)
  const hideMobileContent = showMainMenu

  const mobileRender = () => {
    if (hideMobileContent) {
      return null
    } else {
      return <LocationMap />
    }
  }

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>{mobileRender()}</MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <LocationMap />
      </MediaQuery>
    </>
  )
}

export default LocationsMap
