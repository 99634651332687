import { SCREENER_FORM_NAME } from '../../Shared/constants'
import BirthDateAndAge from '../Shared/ReduxForm/BirthDateAndAge'
import FirstAndLastName from '../Shared/ReduxForm/FirstAndLastName'
import Checkboxes from '../Shared/ReduxForm/Checkboxes'
import Input from '../Shared/ReduxForm/Input'
import SectionIntroduction from './SectionIntroduction'
import SelectMenu from '../Shared/ReduxForm/SelectMenu'
import YesNoQuestion from './YesNoQuestion'
import HouseholdMembersTable from 'Components/Shared/ReduxForm/HouseholdMembersTable/HouseholdMembersTable'
import {
  CHECKBOX_TYPE_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
} from 'Components/Shared/ReduxForm/constants/checkboxes'

export const componentMap = {
  BirthDateAndAge,
  FirstAndLastName,
  Checkboxes,
  Input,
  SectionIntroduction,
  SelectMenu,
  YesNoQuestion,
  HouseholdMembersTable,
}

export const defaultCheckboxMap = (householdMembersIndex, options) => {
  return options.map((option) => {
    const { description, label, value } = option
    return {
      description,
      label,
      name: `${value}[${householdMembersIndex}]`,
    }
  })
}

export const handleBirthDateAndAge = (callbacks, component, name, filtered) => {
  const { assignToFilteredHouseholdMembers, assignToHouseholdMembers } =
    callbacks

  if (component === 'BirthDateAndAge') {
    if (filtered) {
      assignToFilteredHouseholdMembers({ fieldName: name })
    } else {
      assignToHouseholdMembers({ fieldName: name })
    }
  }
}

/**
 * Used to assign <code>filterComparator</code> to callbacks
 *
 * @param  {Boolean} checkboxFilterComparator Flag from JSON
 * @return {Boolean}                          filterComparator
 */
export const getFilterComparator = (checkboxFilterComparator) =>
  checkboxFilterComparator !== undefined ? checkboxFilterComparator : true

export const handleCheckboxes = (
  callbacks,
  checkboxFilterComparator,
  checkboxType,
  component,
  name
) => {
  if (component !== 'Checkboxes') return

  switch (checkboxType) {
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR:
      callbacks.handleHouseholdMemberCheckboxes({
        form: SCREENER_FORM_NAME,
        fieldName: name,
        filterComparator: getFilterComparator(checkboxFilterComparator),
      })
      break

    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT:
      callbacks.handleHouseholdMemberExcludingClientCheckboxes({
        form: SCREENER_FORM_NAME,
        fieldName: name,
        filterComparator: getFilterComparator(checkboxFilterComparator),
      })
      break

    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR:
      callbacks.handleHouseholdMemberCategorySelectors({ fieldName: name })
      break

    case CHECKBOX_TYPE_CATEGORY_SELECTOR:
      callbacks.handleCategorySelectors({ fieldName: name })
      break

    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER:
      callbacks.handleHouseholdMemberMultiAnswer({ fieldName: name })
      break

    default:
      return
  }
}

export const handleInputFieldArray = (
  callbacks,
  categoryIterator,
  component,
  filtered,
  iterable,
  name
) => {
  const { assignToFilteredHouseholdMembers, assignToHouseholdMembers } =
    callbacks
  const applicable = component === 'Input' && iterable
  if (!applicable) return
  if (filtered) {
    assignToFilteredHouseholdMembers({
      fieldName: name,
      categoryIterator: categoryIterator,
    })
  } else {
    assignToHouseholdMembers({ fieldName: name })
  }
}

export const handleOnClickCallbacks = (button, callbacks) => {
  const { onClickCallbacks } = button
  if (onClickCallbacks && onClickCallbacks.length) {
    onClickCallbacks.forEach((callback) => {
      const { name, args } = callback
      const fn = callbacks[name]
      fn(args)
    })
  }
}

export const handleSelectMenuFieldArray = (
  callbacks,
  component,
  filtered,
  iterable,
  name
) => {
  const { assignToFilteredHouseholdMembers, assignToHouseholdMembers } =
    callbacks

  if (component === 'SelectMenu' && iterable) {
    if (filtered) {
      assignToFilteredHouseholdMembers({ fieldName: name })
    } else {
      assignToHouseholdMembers({ fieldName: name })
    }
  }
}

export const handleYesOrNoQuestions = (
  buttonName,
  callbacks,
  component,
  filtered,
  iterable,
  name
) => {
  const { handleHouseholdMemberYesOrNoQuestions, reduxFormChange } = callbacks

  if (component === 'YesNoQuestion') {
    if (iterable) {
      handleHouseholdMemberYesOrNoQuestions({
        form: SCREENER_FORM_NAME,
        fieldName: name,
        value: buttonName === 'next',
        filtered: filtered,
      })
    } else {
      reduxFormChange(SCREENER_FORM_NAME, name, buttonName === 'next')
    }
  }
}

export const householdMembersExcludingClientMap = (
  householdMembers,
  fieldName
) => {
  return householdMembers.map((member, index) => {
    const { first_name } = member
    return {
      label: first_name,
      name: `[${index}]`,
    }
  })
}

export const householdMembersSelectorMap = (
  clientName,
  householdMembers,
  fieldName
) => {
  const data = [
    { first_name: `${clientName} (you)`, index: null },
    ...(householdMembers ?? []),
  ]
  return data.map((member, index) => {
    const { first_name } = member
    return {
      label: first_name,
      name: `${fieldName}[${index}]`,
    }
  })
}
