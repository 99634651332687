import { Link, useNavigate } from 'react-router-dom'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'
import { useMutation } from '@apollo/client'
import { CALCULATE_SCREENING_RESULTS } from 'Pages/NextSteps/gql'
import React from 'react'

export type ScreenerCompletedPrimaryCallToActionProps = {
  isReturningUser: boolean
  clientId: string
  setError: (string) => void
}

const ScreenerCompletedPrimaryButton = ({
  setError,
  isReturningUser,
  clientId,
}: ScreenerCompletedPrimaryCallToActionProps) => {
  const [handleUpdateBenefits, { loading }] = useMutation(
    CALCULATE_SCREENING_RESULTS
  )

  const navigate = useNavigate()

  const mainCtaText = isReturningUser
    ? 'Check for updated eligibility'
    : 'See your eligibility & next steps'

  return (
    <StyledDynamicCtaButton
      $hasExtraButton={false}
      $isYesNoQuestion={false}
      $nextSteps={false}
      disabled={loading}
      variant='contained'
      component={Link}
      to={'/next-steps'}
      onClick={(e: React.SyntheticEvent) => {
        if (isReturningUser) {
          e.preventDefault()
          handleUpdateBenefits({
            variables: {
              clientId,
            },
          })
            .then(() => {
              navigate('/next-steps')
            })
            .catch((error) => {
              console.error(error)
              setError(ERROR_GENERIC_MESSAGE)
            })
        } else {
          return true
        }
      }}
    >
      {mainCtaText}
    </StyledDynamicCtaButton>
  )
}

export default ScreenerCompletedPrimaryButton
