export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'

// Gray
export const singleStopLightGray = '#0000001F'
export const singleStopGray = 'rgb(81,88,97)'
export const singleStopDarkGray = 'rgb(52, 52, 52)'
export const singleStopMediumGray = 'rgb(125,130,137)'
export const singleStopMediumDarkGray = 'rgb(182,186,190)'
export const singleStopDarkerGray = '#5C666F'
export const singleStopTableGray = 'rgb(236, 236, 236)'
export const singleStopSwitchGray = '#BDBDBD'
export const modalBackground = '#fff'

// Green
export const singleStopGreen = '#0d8762'
export const singleStopGreenLight = '#9dccbe'
const singleStopDarkerGreen = 'rgb(115, 157, 60)'
export const checkmarkGreen = '#62AA37'

// Blue
export const singleStopBlue = '#006699'
export const singleStopBlueLight = 'rgb(153, 192, 216)'
export const singleStopBlueAlt = '#3281B2'
export const singleStopBlueText = '#227bb2'
export const singleStopBlueBorder = '#9AC1D9'

// Near-black
export const singleStopBlack = 'rgb(42, 42, 42)'
export const singleStopIconBlack = 'rgb(81,88,97)'

// Common
export const black = 'rgb(0, 0, 0)'
export const white = 'rgb(255, 255, 255)'
export const redOrange = '#EA4335'
export const lightRedOrange = 'rgb(243, 230, 230)'

// Case Management
export const gray = '#666666'
export const green = '#339933'
export const salmon = '#E18887'
export const salmonAlt = '#E4947D'
export const magenta = '#990066'
export const caseNoteIconColor = '#808080'
export const noteIconColor = '#0d8762'
export const yellow = '#F4AF3D'
export const blue = '#227bb2'
export const navyBlue = '#0E3E8D'
export const metalGray = '#515861'

// Reporting
export const reportingLightGray = '#52575d'
export const reportingSkyBlue = '#3899F0'
export const reportingSublteBackgroundBlue = 'rgb(236, 242, 249)'
export const reportingSublteGray = 'rgb(220, 226, 230)'
export const reportingDarkGray = '#707070'
export const reportingSolidGray = '#cccccc'
export const reportingDefaultTextGray = '#52575d'
export const reportingMainContentContainerBackground = '#f5f6f9'
export const reportingEmeraldGreen = '#10A170'
export const reportingPink = '#F9DAFF'
export const reportingLightEmeraldGreen = '#BCFED6'
export const reportingPastelYellow = '#FFE6A9'

export const fontFamily = `'Noto Sans HK, sans-serif', 'Helvetica', sans-serif;`
export const reportingTitleFont =
  'normal normal medium 1.125rem/1.0625rem Roboto'
export const chartClearButtonTooltipFont =
  'normal normal normal 0.6875rem/0.8125rem Noto Sans HK'

const surfaceElevation = (elevation) => {
  switch (elevation) {
    case 1:
      return `0 .16rem .48rem rgba(0,0,0,.10), 0 .16rem .32rem rgba(0,0,0,.12)`
    case 2:
      return `0 .16rem .48rem rgba(0,0,0,.12), 0 .16rem .32rem rgba(0,0,0,.24)`
    case 3:
      return `0 .48rem .96rem rgba(0,0,0,.16), 0 .48rem .96rem rgba(0,0,0,.23)`
    case 4:
      return `0 2.56rem 3.2rem rgba(0,0,0,.19), 0 .96rem .96rem rgba(0,0,0,.23)`
    case 5:
      return `0 2.24rem 4.48rem rgba(0,0,0,.25), 0 1.6rem 1.6rem rgba(0,0,0,.23)`
    case 6:
      return `0 3.04rem 6.08rem rgba(0,0,0,.30), 0 1.6rem 1.6rem rgba(0,0,0,.22)`
    default:
      return `0 0 .24rem rgba(0,0,0,.24)`
  }
}

const theme = {
  mode: {
    [LIGHT_THEME]: {
      background: {
        default: white,
        alt: white,
        secondary: singleStopDarkerGreen,
        buttonDefault: singleStopGray,
        buttonHover: singleStopLightGray,
      },
      brand: {
        default: singleStopDarkGray,
      },
      filterItem: {
        selected: {
          default: singleStopGray,
          alt: white,
          borderColor: singleStopGray,
        },
        unselected: {
          default: white,
          alt: singleStopGray,
          borderColor: singleStopGray,
        },
      },
      icon: {
        default: singleStopGray,
        alt: white,
        dark: singleStopBlack,
      },
      menuToggle: {
        fill: {
          opened: white,
          closed: singleStopBlack,
        },
      },
      text: {
        default: singleStopGray,
        alt: singleStopBlack,
        buttonDefault: white,
        buttonSecondary: singleStopDarkGray,
        dark: singleStopGreen,
        caseManagerLink: singleStopBlueText,
      },
      border: {
        default: singleStopLightGray,
      },
      surfaceElevation,
    },
    [DARK_THEME]: {
      background: {
        default: white,
        alt: singleStopGray,
      },
      brand: {
        default: white,
      },
      icon: {
        default: singleStopGray,
        alt: white,
      },
      text: {
        default: singleStopGray,
        alt: singleStopBlack,
      },
      surfaceElevation,
    },
  },
}

export default theme
