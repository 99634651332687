import gql from 'graphql-tag'

export const GET_MODAL_STATUS = gql`
  query GetModalStatus {
    showFileUploadModal @client
  }
`

export const GET_FILES = gql`
  query GetFiles {
    files @client {
      uppyId
      key
      storage
      fileName
      size
      mimeType
      etag
    }
  }
`
export const GET_HOUSEHOLD_MEMBERS = gql`
  query GetHouseholdMembers($id: ID!) {
    clientLocation(id: $id) {
      id

      client {
        id
        firstName
        lastName
        nickName
        household {
          id
          members {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
