import React from 'react'

import { StyledDynamicCtaButton } from 'Components/Screener/style'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'
const ColDynamicCtaButton = (props) => (
  <StyledDynamicCtaButton
    data-testid={props['data-testid']}
    type='submit'
    className='cypress-continue-button'
    width={props.continueWidth}
    lgwidth={props.lgWidth}
  >
    {props.label ? props.label : 'Continue'}
    {!props.label && <ContinueIcon />}
  </StyledDynamicCtaButton>
)

export default ColDynamicCtaButton
