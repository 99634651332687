import React from 'react'

const singleStopLogo = () => {
  return (
    <g transform='translate(0 59.157)'>
      <path
        fill='#11ab7c'
        d='M33.5-19.157l-2.54,2.54H14.581L3-28.2V-44.576L14.581-56.157H30.959l10,10H45.2l-13-13H13.338L0-45.819v18.863L13.338-13.617H32.2l5.54-5.54Z'
      />
      <g>
        <g>
          <path d='M25.452-24.694l2.294-2.754a7.858,7.858,0,0,0,5.075,2.214c2.078,0,3.185-.891,3.185-2.294,0-1.485-1.215-1.944-3-2.7l-2.673-1.133c-2.024-.837-4.048-2.457-4.048-5.372,0-3.266,2.86-5.776,6.91-5.776a8.867,8.867,0,0,1,6.262,2.564l-2.024,2.51A6.375,6.375,0,0,0,33.2-39.082c-1.755,0-2.862.784-2.862,2.106,0,1.431,1.4,1.943,3.1,2.618L36.06-33.25c2.429,1,4.022,2.537,4.022,5.424,0,3.267-2.727,6.047-7.342,6.047A10.644,10.644,0,0,1,25.452-24.694Z' />
          <path d='M41.625-41.888a2.129,2.129,0,0,1,2.321-2.16,2.121,2.121,0,0,1,2.294,2.16A2.127,2.127,0,0,1,43.946-39.7,2.134,2.134,0,0,1,41.625-41.888Zm.323,4.615h3.969v15.116H41.948Z' />
          <path d='M48.806-37.273h3.239l.3,2h.081a6.966,6.966,0,0,1,4.912-2.376c3.267,0,4.67,2.268,4.67,6.073v9.421H58.037v-8.934c0-2.349-.647-3.159-2.132-3.159-1.242,0-2.025.594-3.131,1.7v10.392H48.806Z' />
          <path d='M63.764-19.485a3.67,3.67,0,0,1,2.159-3.131v-.134a2.866,2.866,0,0,1-1.377-2.538,3.71,3.71,0,0,1,1.7-2.835v-.107A4.952,4.952,0,0,1,64.223-32.2c0-3.536,2.888-5.453,6.1-5.453a7.106,7.106,0,0,1,2.267.378h5.453v2.915H75.479a4,4,0,0,1,.674,2.3c0,3.4-2.591,5.129-5.83,5.129a6.442,6.442,0,0,1-1.944-.352,1.542,1.542,0,0,0-.648,1.3c0,.81.594,1.241,2.376,1.241h2.564c3.644,0,5.642,1.134,5.642,3.78,0,3.051-3.186,5.372-8.233,5.372C66.517-15.6,63.764-16.812,63.764-19.485Zm10.743-.809c0-1.08-.891-1.35-2.51-1.35H70.161a8.519,8.519,0,0,1-2.105-.216,2.23,2.23,0,0,0-.973,1.755c0,1.269,1.4,2,3.591,2C72.941-18.108,74.507-19.107,74.507-20.294ZM72.7-32.2c0-1.755-1.026-2.754-2.375-2.754s-2.376,1-2.376,2.754c0,1.809,1.053,2.807,2.376,2.807S72.7-30.389,72.7-32.2Z' />
          <path d='M79.531-26.4V-43.7H83.5v17.464c0,.918.4,1.215.783,1.215a2.229,2.229,0,0,0,.539-.054l.486,2.943a5.311,5.311,0,0,1-2.051.35C80.5-21.779,79.531-23.587,79.531-26.4Z' />
          <path d='M85.741-29.714c0-4.886,3.455-7.937,7.045-7.937,4.157,0,6.317,3.051,6.317,7.207a7.506,7.506,0,0,1-.189,1.836H89.6a3.972,3.972,0,0,0,4.184,3.779,6.238,6.238,0,0,0,3.4-1.052l1.35,2.428a9.51,9.51,0,0,1-5.264,1.674C89.034-21.779,85.741-24.721,85.741-29.714Zm9.96-1.539c0-2.025-.917-3.321-2.861-3.321-1.592,0-3,1.134-3.293,3.321Z' />
          <path d='M104.8-24.694l2.294-2.754a7.858,7.858,0,0,0,5.075,2.214c2.078,0,3.185-.891,3.185-2.294,0-1.485-1.215-1.944-3-2.7l-2.673-1.133c-2.024-.837-4.048-2.457-4.048-5.372,0-3.266,2.86-5.776,6.91-5.776a8.867,8.867,0,0,1,6.262,2.564l-2.024,2.51a6.373,6.373,0,0,0-4.238-1.647c-1.755,0-2.862.784-2.862,2.106,0,1.431,1.4,1.943,3.1,2.618l2.619,1.108c2.429,1,4.022,2.537,4.022,5.424,0,3.267-2.726,6.047-7.342,6.047A10.644,10.644,0,0,1,104.8-24.694Z' />
          <path d='M121.029-27.448v-6.693H118.9v-2.942l2.348-.19.459-4.075H125v4.075h3.725v3.132H125v6.693c0,1.728.7,2.538,2.052,2.538a4.412,4.412,0,0,0,1.484-.324l.648,2.889a9.789,9.789,0,0,1-3.185.566C122.406-21.779,121.029-24.073,121.029-27.448Z' />
          <path d='M128.995-29.714c0-5.022,3.482-7.937,7.261-7.937s7.261,2.915,7.261,7.937-3.482,7.935-7.261,7.935S128.995-24.694,128.995-29.714Zm10.473,0c0-2.835-1.161-4.7-3.212-4.7s-3.212,1.863-3.212,4.7c0,2.861,1.161,4.7,3.212,4.7S139.468-26.853,139.468-29.714Z' />
          <path d='M145.491-37.273h3.239l.3,1.565h.081a7.18,7.18,0,0,1,4.454-1.943c3.725,0,5.938,3.051,5.938,7.693,0,5.183-3.1,8.179-6.424,8.179a5.621,5.621,0,0,1-3.752-1.646l.135,2.483v4.589h-3.968Zm9.934,7.369c0-2.888-.918-4.48-3-4.48a4.163,4.163,0,0,0-2.969,1.565v6.641a4.123,4.123,0,0,0,2.727,1.133C154.021-25.045,155.425-26.611,155.425-29.9Z' />
        </g>
      </g>
      <g>
        <g>
          <path d='M159.048-40.632h-1.513v-.687h3.845v.687h-1.52v4.475h-.812Z' />
          <path d='M162.267-41.319h.932l.945,2.612c.119.344.224.694.343,1.038h.035c.12-.344.218-.694.337-1.038l.931-2.612h.939v5.162h-.757v-2.55c0-.461.063-1.126.1-1.6h-.028l-.413,1.19-.9,2.459h-.505l-.9-2.459-.406-1.19H162.9c.036.47.1,1.135.1,1.6v2.55h-.729Z' />
        </g>
      </g>
    </g>
  )
}

export default singleStopLogo
