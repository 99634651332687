export const filters = {
  quickFilters: {
    1102: { name: 'Food', selected: false },
    1103: { name: 'Housing', selected: false },
    1104: { name: 'Goods', selected: false },
    1105: { name: 'Transit', selected: false },
    1106: { name: 'Health', selected: false },
    1107: { name: 'Money', selected: false },
    1108: { name: 'Care', selected: false },
    1109: { name: 'Education', selected: false },
    1110: { name: 'Work', selected: false },
    1111: { name: 'Legal', selected: false },
    1270: { name: 'Tax Preparation', selected: false },
    1381: { name: 'Citizenship \u0026 Immigration', selected: false },
    20010: { name: 'Veterans', selected: false },
  },
  attributes: {
    singleStop: { label: 'Single Stop', id: 'singleStop', selected: false },
  },
  distance: { meters: 32187, value: 20, unit: 'mi' },
  languageAccommodation: {
    ar: { id: 'ar', name: 'Arabic', nativeName: 'العربية', selected: false },
    zh: {
      id: 'zh',
      name: 'Chinese',
      nativeName: '中文 (Zhōngwén), 汉语, 漢語',
      selected: false,
    },
    en: { id: 'en', name: 'English', nativeName: 'English', selected: false },
    fr: {
      id: 'fr',
      name: 'French',
      nativeName: 'français, langue française',
      selected: false,
    },
    it: { id: 'it', name: 'Italian', nativeName: 'Italiano', selected: false },
    ru: { id: 'ru', name: 'Russian', nativeName: 'русский', selected: false },
    es: { id: 'es', name: 'Spanish', nativeName: 'Español', selected: false },
    tl: {
      id: 'tl',
      name: 'Tagalog',
      nativeName: 'Wikang Tagalog',
      selected: false,
    },
    vi: {
      id: 'vi',
      name: 'Vietnamese',
      nativeName: 'Tiếng Việt',
      selected: false,
    },
  },
  query: '',
  rehydrated: false,
  persisted: false,
}
