import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  StyledBookmarkSpan,
  StyledButtonFilled,
  StyledButtonOutlined,
  StyledHeadingMain,
  StyledHeadingReferralCode,
  StyledHeadingReferralLabel,
  StyledIconWrapper,
  StyledParagraph,
  StyledReferralNumberWrapper,
} from './style'

import { formattedReferralNumber } from 'Pages/NextSteps/helpers'

import { mockReferralNumber } from 'Pages/NextSteps/mockPayloads'

import ScreeningPairSvg from 'Components/PreScreener/screeningPair.svg'
import { StyledScreeningPairSvg } from 'Components/Screener/style'
import NextStepsDocumentationList from 'Pages/NextSteps/NextStepsDocumentationList'

const NextStepsPrepareVisit = ({ selectedLocation }) => {
  const handleEmailNextSteps = () => {
    alert('send something to backend to email the client their next steps')
  }

  const handleShareNextSteps = () => {
    alert('share next steps with someone?')
  }

  return (
    <>
      <StyledHeadingMain id='mainContent' tabIndex='-1'>
        let's prepare for your visit
      </StyledHeadingMain>
      <StyledIconWrapper>
        <StyledScreeningPairSvg
          alt='Single Stop case workers'
          src={ScreeningPairSvg}
        />
      </StyledIconWrapper>

      <StyledReferralNumberWrapper>
        <StyledHeadingReferralLabel>
          your referral number
        </StyledHeadingReferralLabel>
        <StyledHeadingReferralCode>
          {formattedReferralNumber(mockReferralNumber)}
        </StyledHeadingReferralCode>
      </StyledReferralNumberWrapper>

      <StyledParagraph>We've shared your screening results.</StyledParagraph>
      <StyledParagraph>
        And have requested {selectedLocation ? selectedLocation.name : 'derp'}{' '}
        follow up with you to schedule a visit.
      </StyledParagraph>
      <StyledParagraph>
        Before your first visit here is a checklist of common documents you will
        need.
      </StyledParagraph>
      <StyledParagraph>
        For best assistance, all household members should provide documentation.
      </StyledParagraph>
      <StyledParagraph>
        Be sure to check with your Single Stop Preferred Partner for their
        specific documentation requirements.
      </StyledParagraph>

      <NextStepsDocumentationList />

      <StyledParagraph>Are you missing some documentation?</StyledParagraph>

      <StyledParagraph>
        No worries, expand any document requirement above to learn how to obtain
        it.
      </StyledParagraph>

      <StyledHeadingMain>We're always here for you</StyledHeadingMain>

      <StyledParagraph>
        Remember to{' '}
        <StyledBookmarkSpan>
          bookmark your Single Stop Dashboard
        </StyledBookmarkSpan>
        . It's your one stop for resources and help.
      </StyledParagraph>

      <StyledParagraph>
        Be sure to check back regularly for updates as we continue to regularly
        add new features and resources.
      </StyledParagraph>

      <StyledButtonFilled
        variant='outlined'
        onClick={() => window.print()}
        style={{ marginBottom: '2.5rem' }}>
        print next steps instructions
      </StyledButtonFilled>

      <StyledButtonOutlined
        variant='outlined'
        style={{ marginBottom: '2.5rem' }}
        onClick={handleEmailNextSteps}>
        email next steps instructions
      </StyledButtonOutlined>

      <StyledButtonOutlined
        variant='outlined'
        onClick={handleShareNextSteps}
        style={{ marginBottom: '2.5rem' }}>
        share next steps instructions
      </StyledButtonOutlined>

      <StyledButtonOutlined variant='outlined' component={Link} to={'/'}>
        go to your dashboard
      </StyledButtonOutlined>
    </>
  )
}

const mapStateToProps = (state) => {
  const { selectedLocation } = state.nextSteps
  return {
    selectedLocation,
  }
}

export default connect(mapStateToProps)(NextStepsPrepareVisit)
