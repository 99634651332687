import React from 'react'
import { StyledErrorSpan, StyledTextField } from './style'
import InputAdornment from '@material-ui/core/InputAdornment'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import { isCaseManagementPage } from 'Shared/helpers'

const FormatCustom = (props) => {
  const { inputRef, decimalScale, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
    />
  )
}

const NumberFormatCustom = (props) => (
  <FormatCustom {...props} decimalScale={2} />
)

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const IntegerFormatCustom = (props) => (
  <FormatCustom {...props} decimalScale={0} />
)

IntegerFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const InputRenderField = (props) => {
  const {
    disabled,
    input,
    label,
    meta: { touched, error },
    numberFormat,
    type,
    charLimit,
  } = props

  const getHelperText = () => {
    if (touched && error) {
      return (
        <span
          data-testid={'inputRenderFieldError'}
          aria-label={`Error: ${error}`}
          aria-live='polite'
        >
          {error ? (
            <StyledErrorSpan role='alert'>{error}</StyledErrorSpan>
          ) : (
            <StyledErrorSpan role='alert' aria-hidden='true'>
              {error}
            </StyledErrorSpan>
          )}
        </span>
      )
    }
    if (charLimit && charLimit - input.value.length < 20 && touched) {
      return <span>{charLimit - input.value.length} characters remaining</span>
    }
  }

  const customInputProps = () => {
    if (numberFormat === 'integer')
      return {
        inputComponent: IntegerFormatCustom,
      }

    if (numberFormat === 'currency')
      return {
        inputComponent: NumberFormatCustom,
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }

    return {}
  }

  return (
    <StyledTextField
      type={type}
      fullWidth
      id={input.name}
      label={label}
      variant='outlined'
      disabled={disabled}
      autoComplete={props.autoComplete ? 'on' : ' off'}
      error={touched && !!error}
      required={isCaseManagementPage() ? true : false}
      helperText={getHelperText()}
      InputProps={customInputProps()}
      inputProps={{ maxLength: numberFormat === 'currency' ? '10' : charLimit }}
      {...input}
    />
  )
}

export default InputRenderField
