import { locations as initialState } from './initialState'
import {
  UPDATE_LOCATION_DETAIL,
  LOAD_INITIAL_LOCATIONS,
} from 'Actions/locations'
import { UPDATE_FILTERED_LOCATIONS } from 'Actions/filters'
import { REMEMBER_REHYDRATED, REMEMBER_PERSISTED } from 'redux-remember'

export default (state = initialState, action) => {
  switch (action.type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.locations,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_LOCATION_DETAIL:
      const { id, locationDetail: location } = action

      return {
        ...state,
        locationDetail: {
          [id]: location,
        },
      }

    case LOAD_INITIAL_LOCATIONS:
      const { initialLocations } = action

      return {
        ...state,
        searchResults: initialLocations,
      }

    case UPDATE_FILTERED_LOCATIONS:
      const { filteredLocations } = action

      return {
        ...state,
        searchResults: filteredLocations,
      }

    default:
      return state
  }
}
