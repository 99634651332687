import moment from 'moment'
import groupBy from 'lodash.groupby'
import { calculateRecurringBenefitAmount } from 'Pages/NextSteps/helpers'
import { toCapitalized } from 'Shared/helpers'

// PRIVATE FUNCTIONS
// These are used by other parsers

const parseAssignedTo = ({ caseManager }) => {
  return caseManager ? caseManager.fullName : 'N/A'
}

const parseClientRelationship = (member) => {
  const { relationshipToClient } = member
  if (!relationshipToClient) return 'N/A'
  const strArr = relationshipToClient.split('_')
  const capitalized = strArr.map((item) => toCapitalized(item))
  return capitalized.join('/')
}

const parseLastUpdated = ({ updatedAt }) =>
  updatedAt ? formatLocaleTime(updatedAt) : 'N/A'

// PUBLIC FUNCTIONS
// These are used to create props for the components

export const ParseScreeningType = ({ screening }) => {
  if (!screening) return 'N/A'
  return screening.isAssisted ? 'Assisted Screening' : 'Self Screened'
}

export const parseCreated = ({ createdAt }) => formatLocaleTime(createdAt)

export const parseCalendarMinDate = ({ createdAt }) => createdAt

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{languages: string, phone: string, email: string, status: string}}
 */

export const parseClientInfo = ({ client, status }) => ({
  nickName: client.nickName ? client.nickName : 'N/A',
  firstName: client.firstName ? client.firstName : '',
  lastName: client.lastName ? client.lastName : '',
  status: status ? status : 'N/A',
  email: client.email ? client.email : 'N/A',
  primaryPhone: client.primaryPhone ? client.primaryPhone : 'N/A',
  zipCode: client.zipCode ? client.zipCode : 'N/A',
  languages: client.languages ? client.languages.join(', ') : 'English',
  screening: client.screening.status ? client.screening.status : '',
  user: client.user ? client.user : '',
})

/**
 *
 *  @param clientLocation {{client: {object}, status: {string}}}
 * @returns {string}
 */
export const parseClientLocationNeeds = ({ needs }) =>
  needs.length ? needs.map((item) => item.name).join(', ') : 'N/A'

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{count: number, householdMembers: {object}}}
 */
export const parseHouseholdMembers = ({ client }) => {
  const members = client.household ? client.household.members : []
  const withClient = [{ relationshipToClient: 'Self', ...client }, ...members]
  return {
    count: withClient.length,
    householdMembers: withClient.map((member) => ({
      name: parseName({ client: member }),
      relationship: parseClientRelationship(member),
      age: member.age ? member.age : 'N/A',
      firstName: member.firstName || '',
      lastName: member.lastName || '',
      nickName: member.nickName || '',
    })),
  }
}

/**
 *
 * @param {*} otherData
 * @returns
 */
const parseScreeningSite = (otherData) => otherData?.location?.name

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {{lastUpdated: string, created: string, screeningType: string, assignTo: string}}
 */
export const parseInternalInfo = ({ client, ...otherData }) => ({
  assignTo: parseAssignedTo(otherData),
  created: parseCreated(client),
  followUp: otherData.followUp,
  id: otherData.id,
  lastUpdated: parseLastUpdated(otherData),
  screeningType: ParseScreeningType(client),
  screeningSite: parseScreeningSite(otherData),
  locationId: otherData.location.id,
})

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {string}
 */
export const parseName = ({ client: { firstName, lastName, nickName } }) => {
  if (!firstName && !lastName && !nickName) return 'N/A'
  const strArr = []
  nickName && strArr.push(firstName && lastName ? `${nickName},` : nickName)
  firstName && strArr.push(firstName)
  lastName && strArr.push(lastName)
  return strArr.join(' ')
}

/**
 *
 * @param clinetLocation {{activities: {nodes}}},
 * @returns {array}
 */

export const parseRecentActivities = ({
  activities: { nodes: activities },
}) => {
  return activities.filter((activity) => {
    // 5/28/2020 BE seeds displaying weird ServiceCase type notes as well; currently fixing
    // 6/18/2020 an update to an activity counts as a new activity; a quick fix for now
    return (
      activity.action !== 'UPDATED' &&
      ['Note', 'ServiceCase', 'ClientTransfer', 'Referral'].includes(
        activity.actionable.__typename
      )
    )
  })
}
export const sortActivityDate = (activities) => {
  return activities.sort((a, b) =>
    a.actionable.activityDate > b.actionable.activityDate ? -1 : 1
  )
}

/**
 *
 * @param item {Object}
 * @returns {string}
 */
export const parseCasesIteratee = (item) => item.mainCategory.openEligibilityKey

/**
 *
 * @param clientLocation {{client: {object}, status: {string}}}
 * @returns {Object}
 */
export const parseCases = ({ serviceCases }) => {
  const cases = serviceCases?.map((serviceCase) => ({
    amountLabel: serviceCase.service?.amountLabel ?? 'POTENTIAL',
    id: serviceCase.id,
    position: serviceCase.service?.position,
    name: serviceCase.service?.name,
    eligibility:
      serviceCase.eligibility && toCapitalized(serviceCase.eligibility),
    status: serviceCase.eligibility && toCapitalized(serviceCase.status),
    mainCategory: {
      openEligibilityKey: serviceCase.service?.mainCategory?.openEligibilityKey,
      position:
        mainCategoryMap[serviceCase.service?.mainCategory?.openEligibilityKey]
          ?.position,
    },
    people: serviceCase.people,
    eligibleAmount: serviceCase?.eligibleAmount,
    recurringAmount: calculateRecurringBenefitAmount(serviceCase),
    frequency: parseFrequency(serviceCase),
    closureReason: serviceCase?.serviceCaseClosure?.reason,
    closed: serviceCase?.closed ?? false,
  }))
  return groupBy(cases, parseCasesIteratee)
}

export const parseFrequency = (serviceCase) => {
  if (!serviceCase?.service?.frequency) return ''
  return serviceCase?.service?.frequency === 'NONRECURRENT'
    ? ''
    : serviceCase?.service?.frequency.toLowerCase()
}

export const parseServices = ({ services }) => {
  return groupBy(services, parseCasesIteratee)
}

export const sortCaseMainCategories = (a, b) => {
  const { position: positionA } = mainCategoryMap[a]
  const { position: positionB } = mainCategoryMap[b]
  return positionA - positionB
}

export const sortCases = (a, b) => a.position - b.position

export const mainCategoryMap = {
  1102: {
    openEligibilityKey: 1102,
    position: 0,
    name: 'Food',
  },
  1270: {
    openEligibilityKey: 1270,
    position: 1,
    name: 'Tax Preparation',
  },
  1106: {
    openEligibilityKey: 1106,
    position: 2,
    name: 'Health',
  },
  1108: {
    openEligibilityKey: 1108,
    position: 3,
    name: 'Care',
  },
  1381: {
    openEligibilityKey: 1381,
    position: 4,
    name: 'Citizenship & Immigration',
  },
  1109: {
    openEligibilityKey: 1109,
    position: 5,
    name: 'Education',
  },
  1104: {
    openEligibilityKey: 1104,
    position: 6,
    name: 'Goods',
  },
  1103: {
    openEligibilityKey: 1103,
    position: 7,
    name: 'Housing',
  },
  1111: {
    openEligibilityKey: 1111,
    position: 8,
    name: 'Legal',
  },
  1107: {
    openEligibilityKey: 1107,
    position: 9,
    name: 'Money',
  },
  1105: {
    openEligibilityKey: 1105,
    position: 10,
    name: 'Transportation',
  },
  20010: {
    openEligibilityKey: 20010,
    position: 11,
    name: 'Veterans',
  },
  1110: {
    openEligibilityKey: 1110,
    position: 12,
    name: 'Work',
  },
}

export const parseScreeningStatus = ({ client }) => client.screening.status

export const parseAssignCaseManagerInitialValue = ({ caseManager }) => {
  return caseManager && caseManager.id ? { caseManager: caseManager.id } : null
}

export const parseAssignCaseManagerMenuItems = ({
  caseManagers,
  initialValues,
}) => {
  const data = initialValues
    ? [
        {
          id: 'UNASSIGN',
          fullName: 'Unassign',
        },
        ...caseManagers,
      ]
    : caseManagers
  return data.map((item) => ({
    value: item.id,
    name: item.fullName,
  }))
}

// FUNCTIONS USED BY BOTH CLIENT RECORDS & CLIENT SUMMARY
export const formatLocaleTime = (timestamp) => moment(timestamp).format('ll')

export const formatTimeFromNow = (timestamp) => moment(timestamp).fromNow()

export const timeRightNow = moment().format()

export const startDateOfSingleStop = moment('2007-01-01')
  .startOf('day')
  .toISOString()

export const convertRelativeTimeAgoToISO = ({ time, unit }) =>
  moment().subtract(time, unit).startOf('day').toISOString()

export const formatTimeRecentActivity = (timestamp) =>
  timestamp ? moment(timestamp).format('MMM DD, YYYY') : ''

export const formatFileSize = (fileSizeInBytes) => {
  let i = -1
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fileSizeInBytes = fileSizeInBytes / 1024
    i++
  } while (fileSizeInBytes > 1024)

  return Math.round(fileSizeInBytes) + byteUnits[i]
}

export const formatDatePickerDate = (date) => moment(date).startOf('day')

export const formatEnum = (enumValue) => {
  if (!enumValue) return null
  return enumValue
    .toLowerCase()
    .split('_')
    .map((s) => toCapitalized(s))
    .join(' ')
}

export const isEmptyArray = (obj) => Array.isArray(obj) && obj.length === 0

export const listHouseholdPeople = (people) => {
  return people
    .map((person) =>
      person.nickName
        ? person.nickName
        : `${person.firstName ?? ''}${
            person.lastName ? ' ' + person.lastName : ''
          }`
    )
    .join(', ')
}

export const parseClientStatus = ({ status }) =>
  status === 'FRESH' ? 'New' : toCapitalized(status)

// REPORTING

export const reportingParseName = (options) => {
  const nickName = options.readField('nickName')
  const firstName = options.readField('firstName')
  const lastName = options.readField('lastName')
  if (!firstName && !lastName && !nickName) return 'N/A'
  const strArr = []
  nickName && strArr.push(firstName && lastName ? `${nickName},` : nickName)
  firstName && strArr.push(firstName)
  lastName && strArr.push(lastName)
  return strArr.join(' ')
}

export const isUpdatedToday = (updatedAt) =>
  moment(updatedAt).isSame(moment(), 'day')

export const formatTimeNow = () => moment().format('MM/DD/YYYY')

export const formatTypedDate = (date) => moment(date).format('MM/DD/YYYY')

export const getFirstOfTheYear = () => moment().startOf('year')

export const getFirstOfPreviousMonth = () =>
  moment().subtract(1, 'months').startOf('month')

export const getEndOfPreviousMonth = () =>
  moment().subtract(1, 'months').endOf('month')

export const enumToDisplayString = (val) => {
  if (!val) return ''
  const split = val.split('_')
  const capitalized = split.map((str) => toCapitalized(str))
  return capitalized.join(' ')
}

export const toDollars = (num) => (num ? `$${num.toLocaleString()}` : '')
