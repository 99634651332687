import {
  Container,
  Card,
  CardHeader,
  TableCell,
  TableHead,
  TableBody,
  Box,
  CardContent,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'

export const StyledSectionsContainer = withStyles({
  root: {
    overflow: 'auto',
    scrollBehavior: 'smooth',
    height: 'calc(100vh - 12.3rem)',
    width: '100%',
    maxWidth: '100%',
    '@media (max-width: 576px)': {
      height: 'calc(100vh - 17rem)',
    },
    '@media (min-width: 768px) and (max-width: 1025px)': {
      height: 'calc(100vh - 13.7rem)',
    },
  },
})(Container)

export const StyledBox = withStyles({
  root: {
    margin: '1rem 0',
    width: '33.333%',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
})(Box)

export const StyledCustomBox = withStyles({
  root: {
    margin: '1rem 0',
    width: '50%',
    '@media (max-width: 576px)': {
      width: '100%',
    },
  },
})(Box)

export const StyledWidthCard = styled.div`
  ${media.smaller`
    width: 100%;
    margin : auto
  `}
  ${media.tablet`
    width: 86%;
    margin : auto
  `}
  ${media.desktop`
    width: 72%;
    margin : auto
  `}
`

export const StyledCard = withStyles({
  root: {
    boxShadow: '0px 3px 6px #1D283117',
    margin: '1.5rem 0',
    borderRadius: '7px',
    padding: '1.875rem 3.75rem',
    '@media (max-width: 576px)': {
      position: 'relative',
    },
  },
})(Card)

export const StyledCardHeader = withStyles({
  root: {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    '& .MuiCardHeader-avatar': {
      marginRight: '0.725rem',
      '& img': {
        width: '100%',
        height: '100%',
      },
    },
    '& span': {
      fontSize: '1.75rem',
      fontFamily: 'Noto Sans HK,sans-serif',
      fontWeight: 500,
      '@media (max-width: 576px)': {
        fontSize: '1.25rem !important',
      },
    },
    '& .MuiCardHeader-action': {
      padding: '0',
      borderRadius: '7px',
      position: 'relative',
      flex: 'unset',
      alignSelf: 'auto',
      marginTop: 0,
      marginRight: 0,
      display: 'flex',
      '& button': {
        padding: '1.25rem 2rem 1.25rem 3rem',
        display: 'flex',
        background: '#f1f1f1',
        '& span': {
          fontSize: '0.75rem',
          textTransform: 'capitalize',
          '& img': {
            position: 'absolute',
            left: '1rem',
            top: '50%',
            transform: 'translateY(-50%)',
          },
        },
      },
      '& button.cancel-button': {
        padding: '1.25rem 2rem 1.25rem 2rem',
        marginRight: '1rem',
      },
      '@media (max-width: 576px)': {
        width: '90%',
        position: 'absolute',
        bottom: '1rem',
        left: '0',
        right: '0',
        margin: 'auto',
        borderRadius: '5px',
        '& button': {
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '2rem',
          '& span': {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
              right: '2rem',
              left: 'unset !important',
            },
          },
        },
      },
    },
  },
})(CardHeader)

export const StyledCardContent = withStyles({
  root: {
    padding: '0.625rem 0 0',
    '&:last-child': {
      paddingBottom: '0',
    },
  },
})(CardContent)

export const StyledTableHead = withStyles({
  root: {
    background: '#f1f1f1',
    fontSize: '0.6875rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    fontWeight: 500,
  },
})(TableHead)

export const StyledTableCell = withStyles({
  root: {
    lineHeight: 'normal',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    padding: '0.625rem 1rem',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
})(TableCell)

export const StyledTableBody = withStyles({
  root: {
    fontSize: '.75rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    fontWeight: 500,
  },
})(TableBody)
