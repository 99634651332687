import React, { memo } from 'react'

import radioButtonUncheckedPath from 'Shared/Icons/radioButtonUncheckedIconPath'
import radioButtonCheckedPath from 'Shared/Icons/radioButtonCheckedIconPath'
import theme from 'Shared/Theme/ssTheme'
import { SrOnly } from 'Shared/Theme/utilities.styles'

import Icon from '../Icon/Icon'
import { StyledLabel, StyledRadioButton, StyledFieldset } from './styles'

type RadioButtonProps = {
  checked: boolean
  handleChange: (value: string) => void
  id: string
  labelText: string
  name: string
  value: string
}

export const RadioButton = (props: RadioButtonProps) => {
  const { checked, handleChange, id, labelText, name, value } = props

  return (
    <>
      <StyledLabel>
        {!checked && (
          <Icon
            radius={'80rem'}
            scale={'1.8rem'}
            padding={'0.32rem'}
            margin={'0rem'}
            border={'none'}
            viewBox={'0 0 24 24'}
            screenReaderLabel={labelText}
          >
            {radioButtonUncheckedPath}
          </Icon>
        )}
        {checked && (
          <Icon
            radius={'80rem'}
            scale={'1.8rem'}
            padding={'0.32rem'}
            margin={'0rem'}
            border={'none'}
            viewBox={'0 0 24 24'}
            fill={theme.palette.primary.dark}
            screenReaderLabel={'location'}
          >
            {radioButtonCheckedPath}
          </Icon>
        )}
        <StyledRadioButton
          type={'radio'}
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={(evt) => {
            handleChange(evt.target.value)
          }}
        />
        <SrOnly>
          <StyledFieldset>
            <legend>{labelText}</legend>
          </StyledFieldset>
        </SrOnly>
        {labelText}
      </StyledLabel>
    </>
  )
}

export default memo(RadioButton)
