export const isCommandOrControlKey = (key, keyCode) => {
  if (key) {
    if (key === 'Meta' || key === 'Control') {
      return true
    }
  }
  if (keyCode) {
    if (keyCode === '91' || keyCode === '17') {
      return true
    }
  }
  return false
}

export const isAlphanumericKey = (key, keyCode) => {
  if (key) {
    if (key.length === 1 && /\w/.test(key)) {
      return true
    }
  }
  if (keyCode) {
    if (keyCode > 47 && keyCode < 91) {
      return true
    }
  }
  return false
}

export const selectText = target => {
  target.setSelectionRange(0, target.value.length)
}

export const isEnterKey = (key, keyCode) => {
  if (key && key === 'Enter') {
    return true
  } else if (keyCode && keyCode === '13') {
    return true
  } else {
    return false
  }
}
