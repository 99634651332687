import { preScreener as initialState } from './initialState'
import { UPDATE_PRE_SCREENER, CLEAR_PRE_SCREENER } from 'Actions/preScreener'
import { REMEMBER_REHYDRATED, REMEMBER_PERSISTED } from 'redux-remember'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.preScreener,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_PRE_SCREENER:
      const { preScreener } = action
      return {
        ...state,
        ...preScreener,
      }

    case CLEAR_PRE_SCREENER:
      return initialState

    default:
      return state
  }
}
