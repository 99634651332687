import React from 'react'

const FORCE_REFRESHED = 'page-has-been-force-refreshed'

type SuspenseErrorBoundaryProps = {
  children: React.ReactNode
}

class SuspenseErrorBoundary extends React.Component<
  SuspenseErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: Readonly<SuspenseErrorBoundaryProps>) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, info: unknown) {
    if (error.name === 'ChunkLoadError') {
      this.retryPageLoading()
      this.setState({ hasError: true })
      console.log(error, info)
    }
  }

  retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(FORCE_REFRESHED) || 'false'
    ) as boolean

    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem(FORCE_REFRESHED, 'true')
      return window.location.reload()
    } else {
      window.localStorage.setItem(FORCE_REFRESHED, 'false')
    }
  }

  render() {
    if (this.state.hasError) {
      return 'Something went wrong.'
    }
    return this.props.children
  }
}

export default SuspenseErrorBoundary
