import styled from 'styled-components/macro'

export const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
`

export const StyledListItem = styled.li`
  margin-left: 0.5rem;
`

export const StyledContainer = styled.div`
  font-size: 1.8rem;
  margin: 0.8rem;
  padding: 0 2.4rem;
`

export const StyledSpan = styled.span`
  width: 20%;
  margin-top: 0.8rem;
  text-align: center;
`

StyledList.displayName = 'StyledList'
StyledListItem.displayName = 'StyledListItem'
StyledContainer.displayName = 'StyledContainer'
