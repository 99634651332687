import { patchClient } from 'Actions/client'
import { parseClientData } from 'Components/PreScreener/helpers'
import { useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from 'Store'
import { Client } from './types'
import {
  getAddressByPostalCode,
  getPhysicalAddress,
} from 'Shared/Address/helpers'
import { noop } from 'Shared/constants'
import { orgAndLoc, PhysicalAddress } from 'Actions/types'

const useHandlePatchClient = (callback: Function) => {
  const dispatch = useDispatch<AppDispatch>()
  const clientId = useSelector<RootState>(({ client }) => client.id) as number
  const completed = useSelector<RootState>(
    ({ client }) => client.screenerCompleted
  ) as boolean | null
  const { organization, location } = useSelector<RootState>(
    ({ screener }) => screener
  ) as orgAndLoc

  return async (formValues: any) => {
    const clientData = parseClientData(formValues) as Client
    const physicalAddress = (await getAddressByPostalCode(clientData.zipCode!)
      .then(getPhysicalAddress)
      .catch(noop)) as unknown as PhysicalAddress
    return dispatch(
      patchClient(
        clientData,
        clientId,
        physicalAddress,
        {
          organization,
          location,
        },
        completed
      )
    ).then((_) => {
      callback && callback()
    })
  }
}
export default useHandlePatchClient
