import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import theme from 'Shared/Theme/ssTheme'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { questions } from 'Mocks/ResponsePayloads/screener-questions'
import { addToCounters } from 'Shared/helpers'
const StyledLinearProgress = withStyles(() => ({
  root: {
    height: 5,
  },
  bar: {
    backgroundColor: theme.palette.primary.dark,
  },
}))(LinearProgress)

const ScreenerProgressBar = (props) => {
  const steps = questions.steps
  const { currentStep, progressStatus } = props
  const totalQLength = Object.keys(steps).length
  //set counters for each question types
  const arrayOfSetsCounters = addToCounters(currentStep, progressStatus)

  const progressBar =
    (arrayOfSetsCounters.reduce((a, b) => a + (b['counter'] || 0), 0) /
      totalQLength) *
    100
  return (
    <div>
      <StyledLinearProgress
        thickness={10}
        variant='determinate'
        value={progressBar}
        aria-label='progressbar'
      />
    </div>
  )
}
const mapStateToProps = ({ screener }) => {
  return {
    ...screener,
  }
}

export default connect(mapStateToProps)(ScreenerProgressBar)
