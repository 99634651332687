import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from 'Components/Shared/Icon/Icon'

import {
  StyledDocumentationListItem,
  StyledDocumentationListItemExpandedDetail,
  StyledDocumentationListItemHeading,
  StyledDocumentationListItemHeadingWrapper,
  StyledDocumentationListItemButton,
  StyledDocumentationExample,
  StyledDocumentationExampleLabel,
  StyledDocumentationExamples,
} from './style'

import { singleStopMediumGray } from 'Shared/Theme'
import arrowDropDownIconPath from 'Shared/Icons/arrowDropDownIconPath'
import arrowDropUpIconPath from 'Shared/Icons/arrowDropUpIconPath'
import { StyledParagraph } from 'Components/Screener/style'

const NextStepsDocumentationListItem = ({ doc }) => {
  const { type, description, examples } = doc
  const [selected, setSelected] = useState(false)

  const arrowDropPath = selected ? arrowDropUpIconPath : arrowDropDownIconPath

  const renderExamples = () => {
    return examples.map((example, i) => (
      <StyledDocumentationExample key={i}>
        <div>
          <Checkbox
            color='default'
            value='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
        <StyledDocumentationExampleLabel>
          {example}
        </StyledDocumentationExampleLabel>
      </StyledDocumentationExample>
    ))
  }

  return (
    <StyledDocumentationListItem>
      <StyledDocumentationListItemHeadingWrapper>
        <Checkbox
          color='default'
          value='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <StyledDocumentationListItemHeading>
          {type}
        </StyledDocumentationListItemHeading>
        <StyledDocumentationListItemButton
          onClick={() => setSelected(!selected)}>
          <Icon
            scale={'3rem'}
            fill={singleStopMediumGray}
            cursorOnHover={'pointer'}>
            {arrowDropPath}
          </Icon>
        </StyledDocumentationListItemButton>
      </StyledDocumentationListItemHeadingWrapper>
      <StyledDocumentationListItemExpandedDetail
        display={selected ? 'block' : 'none'}>
        <StyledParagraph>{description}</StyledParagraph>
        {examples && (
          <>
            <StyledParagraph>This may be one of the following:</StyledParagraph>
            <StyledDocumentationExamples>
              {renderExamples()}
            </StyledDocumentationExamples>
          </>
        )}
      </StyledDocumentationListItemExpandedDetail>
    </StyledDocumentationListItem>
  )
}

export default NextStepsDocumentationListItem
