import React from 'react'

const aboutIconPath = (
  <path
    d='M14.6,9h2.8v2.8H14.6Zm0,5.6h2.8V23H14.6ZM16,2A14,14,0,1,0,30,16,14.005,14.005,0,0,0,16,2Zm0,25.2A11.2,11.2,0,1,1,27.2,16,11.215,11.215,0,0,1,16,27.2Z'
    transform='translate(-2 -2)'
  />
)

export default aboutIconPath
