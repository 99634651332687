import React from 'react'
import { connect } from 'react-redux'
import { FieldArray } from 'redux-form'
import SelectMenuFieldArrayRenderField from './SelectMenuFieldArrayRenderField'
import { screenerSelector } from 'Selectors'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'

import { useFields } from './hooks'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'

import { Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ScreenerActions from 'Components/Screener/screenerActions'

const SelectMenuFieldArray = (props) => {
  const {
    label,
    name,
    options,
    questionDirectionText,
    questionText,
    validate,
  } = props

  useFields(props)

  return (
    <>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}

      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <StyledHouseholdMembersListItem margin='0'>
        <MuiThemeProvider theme={ssTheme}>
          <StyledHouseholdMembersList>
            <Row>
              <Col lg='12'>
                <StyledMuiTextFieldWrapper width='100%'>
                  <FieldArray
                    name={name}
                    label={label}
                    options={options}
                    validate={validate}
                    component={SelectMenuFieldArrayRenderField}
                  />
                </StyledMuiTextFieldWrapper>
              </Col>
            </Row>
          </StyledHouseholdMembersList>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>

      <ScreenerActions {...props} />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  backButtonClicked: state.screener.returnStep,
  fieldValues: screenerSelector(state, ownProps.name),
})

export default connect(mapStateToProps)(SelectMenuFieldArray)
