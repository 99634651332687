import React from 'react'

const moneyIconPath = (
  <g id='a'>
    <path
      d='M1.4,13.672C10.863-7.717,71.036-3.8,89.55,23.964s-2.645,89.453-21.613,89.453S48.97,71.771,41.148,59.535-8.066,35.059,1.4,13.672Z'
      transform='matrix(-0.799, 0.602, -0.602, -0.799, 128.685, 76.569)'
      fill='rgba(145,255,189,0.7)'
      opacity='0.8'
    />
    <g transform='translate(19.27 11.446)'>
      <g transform='translate(43.29 36.463)'>
        <g transform='translate(0 0)'>
          <path
            d='M306,266.591a2.771,2.771,0,1,1,3.168-2.742,1.44,1.44,0,1,0,2.88,0,5.729,5.729,0,0,0-4.61-5.46v-.744a1.44,1.44,0,1,0-2.88,0v.744a5.729,5.729,0,0,0-4.608,5.46A5.853,5.853,0,0,0,306,269.47a2.771,2.771,0,1,1-3.168,2.742,1.44,1.44,0,0,0-2.88,0,5.73,5.73,0,0,0,4.608,5.46v.626a1.441,1.441,0,1,0,2.881,0v-.622a5.729,5.729,0,0,0,4.608-5.46A5.853,5.853,0,0,0,306,266.591Z'
            transform='translate(-299.951 -256.205)'
          />
        </g>
      </g>
      <g transform='translate(55.384 31.355)'>
        <path
          d='M393.652,227.707a18.762,18.762,0,0,0-7.663-6.782,1.44,1.44,0,0,0-1.248,2.6,15.853,15.853,0,0,1,7.139,21.712,1.44,1.44,0,0,0,2.542,1.352,18.739,18.739,0,0,0-.77-18.879Z'
          transform='translate(-383.925 -220.782)'
        />
      </g>
      <g transform='translate(50.067 29.661)'>
        <path
          d='M348.537,209h-.1a1.44,1.44,0,0,0,0,2.88h.09a1.44,1.44,0,0,0,.006-2.88Z'
          transform='translate(-347.001 -209)'
        />
      </g>
      <g transform='translate(30.628 38.836)'>
        <path
          d='M223.868,296.54a15.853,15.853,0,0,1-7.139-21.712,1.44,1.44,0,0,0-2.542-1.352,18.727,18.727,0,0,0,8.437,25.66,1.44,1.44,0,0,0,1.247-2.6Z'
          transform='translate(-212.002 -272.712)'
        />
      </g>
      <g transform='translate(45.659 64.219)'>
        <g transform='translate(0 0)'>
          <path
            d='M317.908,449h-.083a1.44,1.44,0,0,0-.013,2.88h.1a1.44,1.44,0,0,0,0-2.88Z'
            transform='translate(-316.379 -449)'
          />
        </g>
      </g>
      <g transform='translate(0 0)'>
        <path
          d='M55.782,27.79a6.609,6.609,0,0,0-.637-1.176h3.748a6.624,6.624,0,1,0,0-13.247H47.36A6.619,6.619,0,0,0,41.9,3H6.624a6.624,6.624,0,1,0,0,13.247h11.53a6.6,6.6,0,0,0,0,7.488H14.4a6.62,6.62,0,0,0-4.116,11.806,6.611,6.611,0,0,0,0,10.367,6.611,6.611,0,0,0,0,10.367A6.62,6.62,0,0,0,14.4,68.082H31.368A24.473,24.473,0,1,0,55.782,27.79Zm3.109-11.543a3.744,3.744,0,0,1,0,7.488H23.614a3.744,3.744,0,0,1,0-7.488ZM6.622,13.367a3.744,3.744,0,0,1,0-7.488H41.9a3.744,3.744,0,1,1,0,7.488ZM14.4,26.614H49.676a3.742,3.742,0,0,1,1.634.376c-.681-.057-1.369-.088-2.065-.088a24.4,24.4,0,0,0-17.317,7.2H14.4a3.744,3.744,0,0,1,0-7.488Zm0,10.367H29.462a24.408,24.408,0,0,0-3.7,7.488H14.393a3.744,3.744,0,1,1,0-7.488Zm0,10.367H25.1a24.5,24.5,0,0,0-.089,7.488H14.4a3.744,3.744,0,1,1,0-7.488Zm0,17.854a3.744,3.744,0,0,1,0-7.488H25.6A24.382,24.382,0,0,0,29.058,65.2Zm34.846,7.776a21.6,21.6,0,1,1,21.6-21.6,21.6,21.6,0,0,1-21.6,21.6Z'
          transform='translate(0 -3)'
        />
      </g>
    </g>
  </g>
)

export default moneyIconPath
