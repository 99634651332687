import styled from 'styled-components/macro'
import { StyledHeader } from '../Header/style'
import * as u from 'Shared/Theme/utilities.styles'

export const StyledMenuParent = styled.div`
  position: relative;
`

export const StyledMenu = styled.nav`
  ${u.pFixed}
  ${u.wd100}
  background-color: #fff;
  z-index: 9;
  padding: 0 0 6rem 0;
  height: 100vh;
  overflow-y: auto;
  top: 85px;
  z-index: 99;
`
export const StyledDesktopAndTabletMenuLogo = styled(StyledHeader)`
  ${u.pFixed}
  bottom: 0;
  width: ${(props) => (props.device === 'tablet' ? '50%' : '76.8rem')};
  padding: 0;
  background-color: rgb(255, 255, 255);
`
export const StyledMobileMenuLogo = styled(StyledDesktopAndTabletMenuLogo)`
  ${u.wd100}
  background-color: rgb(255, 255, 255);
`

StyledMenu.displayName = 'StyledMenu'
StyledDesktopAndTabletMenuLogo.displayName = 'DesktopAndTabletMenuLogo'
StyledMobileMenuLogo.displayName = 'MobileMenuLogo'
StyledMenuParent.displayName = 'StyledMenuParent'
