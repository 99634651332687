import { StyledCardContent } from 'Components/Review/ReviewSection/style'
import React from 'react'
import ReviewSectionValues from './ReviewSectionValues'
import ReviewSectionsTables from './ReviewSectionTables'

/**
 * Review Section Contents are rendered in this component
 *
 * @param {{data:{valueDisplays:Array.<{name:string,value:string}>, tableDisplays:Array.<{name:string,labels:Array.<string>,rows:Array}>},isEditing:boolean}} param0
 * @returns {ReviewSectionContent}
 */
const ReviewSectionContent = ({
  data: { valueDisplays, tableDisplays },
  isEditing,
}) => (
  <StyledCardContent>
    {valueDisplays && (
      <ReviewSectionValues
        valueDisplays={valueDisplays}
        isEditing={isEditing}
      />
    )}
    {tableDisplays && (
      <ReviewSectionsTables
        tableDisplays={tableDisplays}
        isEditing={isEditing}
      />
    )}
  </StyledCardContent>
)

export default ReviewSectionContent
