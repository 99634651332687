import styled, { css } from 'styled-components/macro'

export const StyledIcon = styled.div`
  position: ${(props) => (props.position ? props.position : 'relative')};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  z-index: ${(props) => props.zIndex};
  align-items: center;
  align-self: ${(props) => props.alignSelf || 'center'};
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  display: ${(props) => (props.display ? props.display : 'flex')};
  fill: ${(props) => props.fill || props.theme.text.default};
  justify-content: center;
  margin: ${(props) => props.margin};
  box-shadow: ${(props) => props.boxShadow};
  padding: ${(props) => props.padding};
  width: ${(props) => (props.scale ? props.scale : props.width)};
  height: ${(props) => (props.scale ? props.scale : props.height)};
  &:hover {
    cursor: ${(props) => props.cursorOnHover || 'default'};
  }
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`

export const screenReaderContext = {
  opacity: 0,
}

StyledIcon.displayName = 'StyledIcon'
