import React from 'react'

const veteransIconPath = (
  <g id='a'>
    <g transform='translate(-30.748 -40.62)'>
      <path
        d='M40,124.764c22.08,5.058,22.975-18.927,22.963-50.774S67.361,5.609,45.283.551-.011,26.09,0,57.938,17.918,119.7,40,124.764Z'
        transform='matrix(0.485, -0.875, 0.875, 0.485, 15.804, 106.351)'
        fill='rgba(242,187,255,0.6)'
        opacity='0.8'
      />
      <g transform='translate(68.737 58.62)'>
        <path
          d='M11.388,1021.36A3.4,3.4,0,0,0,8,1024.748v14.682a7.917,7.917,0,0,0,.834,3.539l.06.118,4.809,5.7,1.725-1.459-4.592-5.44a5.651,5.651,0,0,1-.578-2.453v-14.681a1.113,1.113,0,0,1,1.129-1.129H40.751a1.113,1.113,0,0,1,1.129,1.129v14.681a5.656,5.656,0,0,1-.591,2.511l-3.543,5.5,1.9,1.222,3.631-5.642.026-.052a7.917,7.917,0,0,0,.834-3.538v-14.682a3.4,3.4,0,0,0-3.388-3.388Z'
          transform='translate(-3.483 -1021.36)'
          fillRule='evenodd'
        />
        <path
          d='M14.112,1023.868A1.129,1.129,0,0,0,13,1025v12.392a1.129,1.129,0,0,0,2.259,0V1025a1.13,1.13,0,0,0-1.147-1.143Z'
          transform='translate(2.811 -1018.22)'
          fillRule='evenodd'
        />
        <path
          d='M22.3,1036.16l-2.572,8.205H11l2.581,2.016,4.266,3.34-2.44,7.782,6.891-4.416,6.891,4.416-2.44-7.782,6.847-5.356H24.869Zm0,7.548.913,2.918h3.833l-2.918,2.285,1.05,3.34L22.3,1050.4l-2.876,1.848,1.05-3.34-2.918-2.285h3.833Z'
          transform='translate(0.291 -1002.731)'
          fillRule='evenodd'
        />
        <path
          d='M28.588,1031.36a22.588,22.588,0,1,0,22.588,22.588A22.588,22.588,0,0,0,28.588,1031.36Zm0,2.259a20.312,20.312,0,1,1-14.38,5.949,20.329,20.329,0,0,1,14.38-5.949Z'
          transform='translate(-6 -1008.772)'
        />
        <path
          d='M25.44,1033.86a16.94,16.94,0,1,0,16.94,16.941,16.941,16.941,0,0,0-16.94-16.941Zm0,2.259a14.664,14.664,0,1,1-10.387,4.295,14.682,14.682,0,0,1,10.387-4.295Z'
          transform='translate(-2.854 -1005.626)'
        />
        <path
          d='M19.112,1023.868A1.129,1.129,0,0,0,18,1025v12.392a1.129,1.129,0,0,0,2.259,0V1025a1.13,1.13,0,0,0-1.147-1.143Z'
          transform='translate(9.105 -1018.22)'
          fillRule='evenodd'
        />
      </g>
    </g>
  </g>
)

export default veteransIconPath
