import React from 'react'
import EditButton from './EditButton'
import SaveButton from './SaveButton'

const ActionButton = ({ editDisabled, isEditing, ...buttonProps }) => {
  const ButtonComponent = isEditing ? SaveButton : EditButton

  return !editDisabled ? <ButtonComponent {...buttonProps} /> : null
}

export default ActionButton
