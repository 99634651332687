import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import _debounce from 'lodash.debounce'
import { LOADING_INDICATOR_DEBOUNCE } from 'Shared/constants'

/**
 * Dictates when open should change. If loading=true is quickly followed
 * by loading=false, this hook won't change open to true.
 *
 * @param {Boolean} loading
 */
export const useLoading = (loading) => {
  const [open, _setOpen] = useState(false)
  const setOpen = useMemo(
    () => _debounce(_setOpen, LOADING_INDICATOR_DEBOUNCE),
    [_setOpen]
  )

  useEffect(() => setOpen(loading), [loading, setOpen])

  return open
}

/**
 * Hook to determine when to open and close the modal that the loading
 * indicator sits in.
 */
export const useOpen = () => {
  const loading = useSelector(({ ui: { loading } }) => loading)
  const open = useLoading(loading)

  return open
}
