import React from 'react'
import { Field } from 'redux-form'
import InputRenderField from './InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'
import ToolTip from './ToolTip'
import formatters from '../../Shared/ReduxForm/formatters/formatters'
import { Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ScreenerActions from 'Components/Screener/screenerActions'

const InputField = (props) => {
  const {
    name,
    numberFormat,
    questionDirectionText,
    questionText,
    label,
    toolTip,
    validate,
    disableValidation,
  } = props

  return (
    <MuiThemeProvider theme={ssTheme}>
      {questionText && <StyledHeadingMain>questionText</StyledHeadingMain>}

      {questionDirectionText && (
        <StyledParagraph>questionDirectionText</StyledParagraph>
      )}

      {toolTip && <ToolTip text={toolTip} />}

      <StyledHouseholdMembersListItem margin='0'>
        <StyledHouseholdMembersList>
          <Row>
            <Col lg='12'>
              <StyledMuiTextFieldWrapper>
                <Field
                  name={name}
                  label={label}
                  component={InputRenderField}
                  props={{ numberFormat: numberFormat }}
                  validate={disableValidation ? undefined : validate}
                  normalize={
                    numberFormat === 'currency'
                      ? formatters.toNumber
                      : undefined
                  }
                />
              </StyledMuiTextFieldWrapper>
            </Col>
          </Row>
        </StyledHouseholdMembersList>
      </StyledHouseholdMembersListItem>
      <ScreenerActions {...props} />
    </MuiThemeProvider>
  )
}

export default InputField
