import { withStyles } from '@material-ui/styles'
import styled from 'styled-components/macro'
import { DialogTitle, DialogContent, Dialog, Button } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'

export const StyledDialogTitle = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& h3': {
      margin: '0',
    },
  },
})(DialogTitle)

export const StyledDialog = withStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
      padding: '1rem',
    },
  },
})(Dialog)

export const StyledDialogContent = withStyles({
  root: {
    '& h4': {
      margin: '0',
    },
  },
})(DialogContent)

export const CancelButton = withStyles({
  root: {
    textTransform: 'none',
    backgroundColor: theme.palette.secondary.default,
    fontSize: '1rem',
    '&:hover': {
      background: theme.palette.secondary.darken10,
    },
  },
})(Button)

export const FooterForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`
export const StyledParagraph = styled.p`
  text-jusitify: left;
  padding: 0.5rem 1rem;
`
export const StyledFormControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledServiceContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
`
