import React, { Suspense } from 'react'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { useLoading } from 'Components/Shared/LoadingIndicator/hooks'
import SuspenseErrorBoundary from './SuspenseErrorBoundary'

const Fallback = ({ children }: { children: React.ReactNode }) => (
  <SuspenseErrorBoundary>
    <Suspense fallback={<LoadingIndicator show={useLoading(true)} />}>
      {children}
    </Suspense>
  </SuspenseErrorBoundary>
)

export default Fallback
