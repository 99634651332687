import {
  useCallback,
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_BASE } from 'Shared/constants'
import { useParams } from 'react-router-dom'
import { hideLogo } from 'Reducers/uiSlice'
import { handleUpdateScreeningOrgAndLoc } from 'Actions/screener'
import { DEFAULT_LOCATION, DEFAULT_ORGANIZATION } from 'Shared/constants'
import { HIDE_LOGO_EVENTS, ORIENTATION_CHANGE_DELAY } from 'Shared/constants'
import { isPhoneIos, dispatchEvent } from 'Shared/helpers'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import { PartnerLocationModalContext } from 'Components/PartnerLocationModal/PartnerLocationModal'

/**
 * Dispatched in the Hide Logo Hook.
 *
 * @type {Event}
 */
const EVENT_HIDE_LOGO = { eventName: 'hideLogo', bubbles: true }

/**
 * Hide Logo Hook
 *
 * @return {undefined}
 */
const useHideLogo = () => {
  const dispatch = useDispatch()
  const callback = useCallback(() => {
    dispatch(hideLogo())
    dispatchEvent(EVENT_HIDE_LOGO)

    HIDE_LOGO_EVENTS.forEach((event) => {
      window.removeEventListener(event, callback)
    })
  }, [dispatch])

  return callback
}

/**
 * Initial render hook. Used to add event listeners to trigger Hide Logo.
 *
 * @param  {Object} props Props from component
 * @return {undefined}
 */
export const useInitialRender = (props) => {
  const isInitialRender = useRef(true)
  const handleHideLogo = useHideLogo(props)

  useLayoutEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false

      HIDE_LOGO_EVENTS.forEach((event) => {
        window.addEventListener(event, handleHideLogo, {
          capture: false,
          once: true,
        })
      })
    }
  }, [handleHideLogo, isInitialRender])
}

/**
 * Event handler for orientation change
 *
 * @param  {Event} event Orientation change event
 * @return {undefined}
 */
const orientationChangeHandler = (event) => {
  setTimeout(() => {
    window.scrollTo(0, 1)
  }, ORIENTATION_CHANGE_DELAY)
}

/**
 * iOS Hack Hook. If the app is running on a phone running iOS, handle event
 * listeners for orientation change.
 *
 * @return {undefined}
 */
export const usePhoneIosHack = () => {
  const callback = useCallback(orientationChangeHandler)
  useEffect(() => {
    if (isPhoneIos) {
      window.addEventListener('orientationchange', callback)
    }
    return () => {
      if (isPhoneIos) {
        window.removeEventListener('orientationchange', callback)
      }
    }
  }, [callback])
}

/**
 * New Relic Hook
 *
 * @return {undefined}
 */
export const useNewRelic = () => {
  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === 'true') {
      const script = document.createElement('script')
      script.src = `${process.env.PUBLIC_URL}/scripts/newrelic.js`
      script.async = true
      document.body.appendChild(script)
    }
  }, [])
}

/*
Hook that is (so far) only used in:
  1. the <LandingPage /> (url: [/, /:organization/:location])
  2. the <PreScreener /> (url: [/registration, /:organization/:location/registration])

  The way it works:

  We scan the url to see if slugs :organization and :location are present.

  If they aren't we update the redux store with the default organization ('single-stop') and default location ('hq')

  If slugs are present, we POST to the BE to see if the slugs are valid

  If slugs are valid, we update the redux store with the organization and location slugs

  If slugs are invalid we set isValidUrl to false

*/
export const useIsValidOrganizationAndLocationSlugs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isValidUrl, setIsValidUrl] = useState(true)
  const { organization, location } = useParams() || {}
  const modalContext = useContext(PartnerLocationModalContext)
  const dispatch = useDispatch()

  useEffect(() => {
    const updateOrgAndLoc = (orgAndLoc) =>
      dispatch(handleUpdateScreeningOrgAndLoc(orgAndLoc))

    if (!organization && !location) {
      updateOrgAndLoc({
        organization: DEFAULT_ORGANIZATION,
        location: DEFAULT_LOCATION,
      })
      setIsLoading(false)
    } else if (organization && !location) {
      // If the URL contains the organization slug but NO LOCATION SLUG
      axiosWithoutAuthHeaders
        .get(`${API_BASE}/organizations/${organization}`)
        .then((response) => {
          const data = response ? response?.data : {}
          const locations = data?.locations ?? []
          const options = locations.map(({ id, ...rest }) => ({
            label: rest.name,
            value: rest.slug,
          }))

          switch (options.length) {
            case 0: // If there are no locations we will render the custom 404 page
              setIsValidUrl(false)
              break
            case 1: // If there is ONLY ONE location, redirect to it
              const [{ value: location }] = options
              setIsValidUrl(true)
              // Was originally useHistory() ('react-router-dom') but Jest thinks that is not a function!
              // Opted for .replace() over setting location.href so that we can mock / spyOn it in the unit test
              window.location.replace(
                `${window.location.origin}/${organization}/${location}`
              )
              break
            default:
              // If there is more than one location, display the modal
              modalContext.showModal = true
              modalContext.locationOptions = options
              modalContext.orgSlug = organization
              setIsValidUrl('true')
              break
          }
        })
        .catch((e) => {
          console.error(e)
        })
      setIsLoading(false)
    } else {
      axiosWithoutAuthHeaders
        .post(`${API_BASE}/clients/valid_location`, {
          organization_slug: organization,
          location_slug: location,
        })
        .then((result) => {
          const valid = result?.data?.valid
          if (valid) {
            updateOrgAndLoc({
              organization,
              location,
            })
          } else {
            setIsValidUrl(false)
          }
          setIsLoading(false)
        })
    }
  }, [
    organization,
    location,
    dispatch,
    modalContext.locationOptions,
    modalContext.showModal,
    modalContext.orgSlug,
  ])

  return { isValidUrl, isLoading }
}

/*
links to the home and registration pages check the redux store 
to see if they should be namespaced with /:organization/:location
*/

export const useDynamicHomeUrl = () => {
  const { organization, location } = useSelector(selectFromOrgAndLocState)

  // if case manager is logged in, the home url will be namespaced as the case manager's organization/location
  const caseManagerOrg = localStorage.getItem('caseManagerOrg')
  const caseManagerLoc = localStorage.getItem('caseManagerLoc')

  if (caseManagerOrg && caseManagerLoc) {
    return caseManagerOrg !== DEFAULT_ORGANIZATION &&
      caseManagerLoc !== DEFAULT_LOCATION
      ? `/${caseManagerOrg}/${caseManagerLoc}`
      : '/'
  } else if (organization && location) {
    return organization !== DEFAULT_ORGANIZATION &&
      location !== DEFAULT_LOCATION
      ? `/${organization}/${location}`
      : '/'
  } else {
    return '/'
  }
}

export const useDynamicRegistrationUrl = () => {
  const organization = sessionStorage.getItem('organization')
  const location = sessionStorage.getItem('location')

  if (organization && location) {
    return organization !== DEFAULT_ORGANIZATION &&
      location !== DEFAULT_LOCATION
      ? `/${organization}/${location}/registration`
      : '/registration'
  } else {
    return '/registration'
  }
}

const selectFromOrgAndLocState = (state) => {
  const { organization, location } = state.screener

  return {
    organization,
    location,
  }
}
