import React from 'react'
import styled, { css } from 'styled-components/macro'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DescriptionIcon from '@material-ui/icons/Description'
import AssignmentIcon from '@material-ui/icons/Assignment'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CloseIcon from '@material-ui/icons/Close'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'
import WarningIcon from '@material-ui/icons/Warning'
import InputLabel from '@material-ui/core/InputLabel'
import {
  fontFamily,
  yellow,
  blue,
  magenta,
  green,
  navyBlue,
  modalBackground,
  singleStopGray,
} from 'Shared/Theme'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import theme from 'Shared/Theme/ssTheme'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { Field } from 'react-final-form'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

const modalIconMap = {
  note: {
    IconComponent: DescriptionIcon,
    color: yellow,
  },
  activity: {
    IconComponent: AssignmentIcon,
    color: blue,
  },
  addCase: {
    IconComponent: PostAddIcon,
    color: green,
  },
  screeningIncomplete: {
    IconComponent: IndeterminateCheckBoxIcon,
    color: magenta,
  },
  assignCaseManager: {
    IconComponent: AssignmentIndIcon,
    color: navyBlue,
  },
  addClient: {
    IconComponent: PersonAddIcon,
    color: singleStopGray,
  },
  autoLogout: {
    IconComponent: WarningIcon,
    color: yellow,
  },
  closeRecord: {
    IconComponent: PowerSettingsNewIcon,
    color: 'red',
  },
}

export const StyledAmountField = withStyles({
  root: {
    padding: 'inherit',
    '& div': {
      padding: 'inherit',
    },
    '& input': {
      padding: 'inherit',
    },
  },
})(Field)

interface StyledModalBodyProps {
  smallerHeight?: string
  smallerRadius?: string
  addCase?: boolean
}

const StyledAddCaseModalBody = css<StyledModalBodyProps>`
  padding: ${(props) => (props.addCase ? '0' : '2rem')};
  height: ${(props) => props.smallerHeight};
  border-radius: ${(props) => props.smallerRadius};
`
interface sharedModalStylingProps extends CSSProperties {
  tabletwidth?: string
  width?: string
  modalPadding?: string
}

export const sharedModalStyling = css<sharedModalStylingProps>`
  font-family: ${fontFamily};
  background: ${modalBackground};
  color: ${(props: any) => props.theme.text.default};
  background: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  max-height: 100%;
  overflow: auto;
  border: unset;
  border-radius: 23px;
  outline: 0;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
    ${StyledAddCaseModalBody};
    width: 100%;
    padding: 1rem;
  }
  @media only screen and (min-width: 481px) {
    ${StyledAddCaseModalBody};
    width: ${(props: sharedModalStylingProps) =>
      props.tabletwidth ? props.tabletwidth : '70%'};
    padding: 2rem 2rem;
  }
  @media only screen and (min-width: 1025px) {
    width: ${(props: sharedModalStylingProps) => props.width};
    padding: ${(props: sharedModalStylingProps) =>
      props.modalPadding ? props.modalPadding : '43px 55px'};
    height: unset;
    border-radius: 23px;
  }
`

export const sharedModalStyling2 = css`
  font-family: ${fontFamily};
  color: ${(props) => props.theme.text.default};
  background: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StyledModalBody = styled.div<sharedModalStylingProps>`
  ${sharedModalStyling}
`

const StyledAddCaseModalHeading = css`
  width: 100%;
  padding: 1rem 2rem;
  box-shadow: 0 0.0625rem 0.25rem rgba(51, 51, 51, 0.12);
`

interface StyledModalHeadingProps {
  headingMarginBottom?: string
  addCase?: boolean
}

export const StyledModalHeading = styled.div<StyledModalHeadingProps>`
  margin-bottom: ${(props) =>
      props.headingMarginBottom ? props.headingMarginBottom : ''}
    ${({ addCase }) =>
      addCase &&
      css`
        @media only screen and (max-width: 480px) {
          ${StyledAddCaseModalHeading}
        }
        @media only screen and (min-width: 481px) {
          ${StyledAddCaseModalHeading}
        }
        @media only screen and (min-width: 1025px) {
          padding: 0;
          box-shadow: unset;
        }
      `};
`

interface StyledHeadingContainerProps extends CSSProperties {
  alignitems?: string
  justCont?: string
  flexDir?: string
}

export const StyledHeadingContainer = styled.div<StyledHeadingContainerProps>`
  display: flex;
  align-items: ${(props) => props.alignitems};
  justify-content: ${(props) => props.justCont};
  flex-direction: ${(props) => props.flexDir};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => (props.padding ? props.padding : 'auto')};
`

export const StyledDatePickerParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.75rem;
  & label {
    padding: 5px;
  }
`

interface StyledHeadingMainProps extends CSSProperties {
  modal?: string
}
export const StyledHeadingMain = styled.h2<StyledHeadingMainProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.25rem')};
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin: ${(props) => (props.margin ? props.margin : '0')};
  text-align: ${(props) => props.textAlign};
  ${({ modal }) =>
    modal &&
    css`
      @media only screen and (max-width: 480px) {
        font-size: 1rem;
      }
    `}
`

export const StyledFileName = styled.span`
  font-weight: 600;
`

export const StyledForm = styled.form`
  max-height: auto;
  margin-top: 0.5rem;
`

interface StyledFormBodyProps {
  withOutBorder?: string
}

export const StyledFormBody = styled.div<StyledFormBodyProps>`
  width: 100%;
  max-height: calc(100vh - 16rem);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 0;
  & input,
  textarea {
    border: 1px solid #dddddd;
  }
  ${({ withOutBorder }) =>
    withOutBorder &&
    css`
      & textarea,
      input {
        border: unset;
        &:hover {
          border: unset;
        }
      }
    `}
`

interface StyledModalIconProps {
  iconKey: string
}
export const StyledModalIcon = ({ iconKey }: StyledModalIconProps) => {
  const iconHash = modalIconMap[iconKey as keyof typeof modalIconMap]
  const { IconComponent, color } = iconHash
  const StyledModalIcon = withStyles({
    root: {
      color,
      fontSize: '3rem',
      marginLeft: '-1rem',
      marginRight: '1rem',
    },
  })(IconComponent)
  return <StyledModalIcon />
}

export const StyledParagraph = styled.p<CSSProperties>`
  font-size: 0.8125rem;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.1rem')};
  font-weight: 500;
  color: rgb(57, 57, 70);
  font-family: inherit;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1rem'};
  margin-top: 0.5rem;
  text-align: ${(props) => props.textAlign};
`

export const StyledDescriptionParagraph = styled(StyledParagraph)`
  display: ${(props) => props.display};
  font-size: 0.8125rem;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 0.625rem;
`

export const StyledReferralSelectionParagraph = styled(StyledParagraph)`
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.8;
  margin-top: 0.625rem;
  padding: 0.5rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
`

export const StyledModalLink = styled(StyledParagraph)`
  color: #0d8762;
  cursor: pointer;
  display: flex;
  text-decoration: underline;
`

const inputStyle = css`
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  font-size: 0.75rem;
  resize: none;
  line-height: 1.5rem;
  padding: 3px 10px;
  border: unset !important;
  &:focus-visible {
    outline: unset !important;
  }
`

export const StyledInput = styled.input`
  height: 3rem;
  ${inputStyle}
  padding: 0;
  @media only screen and (max-width: 480px) {
    height: 2.5rem;
  }
`

export const StyledTextArea = styled.textarea`
  height: 5rem;
  ${inputStyle}
  padding: 0;
  background: ${({ disabled }) =>
    disabled && theme.palette.secondary.lightGrey};
`

export const StyledAttachmentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  margin-bottom: 0rem;
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    margin-left: auto;
  }
  @media only screen and (min-width: 481px) {
    margin-right: auto;
  }
`

export const StyledAttachmentButtonLabel = styled.label`
  margin-left: 5px;
  font-size: 0.75rem;
  letter-spacing: normal;
  font-weight: 500;
  margin-top: 3px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`
export const StyledLabel = styled.p<CSSProperties>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  letter-spacing: 1px;
  margin: 0;
`

export const StyledLabelAsterisk = styled.span`
  color: red;
  margin-left: 0.7rem;
`

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.625rem 0;
  margin-left: 5px;
`

export const StyledMaxLimitCount = styled.label`
  color: #fff;
  margin-left: 1rem;
  background: #1e95de;
  font-size: 1rem;
  border-radius: 1rem;
  padding: 3.5px 6px;
  position: relative;
  top: 2px;
`

export const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const StyledMaxLimitCountParent = styled.div`
  display: flex;
`

export const StyledButtonsContainer = styled.div<CSSProperties>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop} @media only screen and
    (max-width: 480px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 481px) {
    margin-top: 2.25rem;
    flex-direction: row;
  }
`

const rootProps = {
  width: '25rem',
}

export const StyledButtonOutlined = withStyles({
  root: {
    ...rootProps,
    borderColor: `${theme.palette.color.black}`,
    color: `${theme.palette.text.darken}`,
    width: '30%',
    letterSpacing: '0',
    '& > span': {
      fontSize: '0.875rem',
      fontFamily: 'Noto Sans HK,sans-serif !important',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

export const StyledButtonFilled = withStyles({
  root: {
    ...rootProps,
    background: `${theme.palette.primary.dark}`,
    color: 'white',
    width: '30%',
    letterSpacing: '0',
    '&:hover': {
      background: `${theme.palette.primary.lighten5}`,
      color: 'white',
    },
    '& > span': {
      fontSize: '0.875rem',
      fontFamily: 'Noto Sans HK,sans-serif !important',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

///////Uppy//////
export const StyledModalUploadBody = styled.div`
  top: 50%;
  ${sharedModalStyling2}
  @media only screen and (max-width : 480px) {
    width: 90%;
  }
`

export const StyledModalCloseButton = styled.button`
  position: absolute;
  top: -10px;
  right: -40px;
  background: none;
  color: white;
  border: none;
  font-size: 2rem;
  padding: none;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    top: 0.5rem;
    right: 0.5rem;
    color: #000;
    z-index: 9;
  }
`

export const StyledFilesLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  margin-right: 2.375rem;
  padding-top: 1.375rem;
`

export const StyledFilesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1rem;
  margin-top: 1rem;
  .uploaded-Files {
    display: flex;
    flex-wrap: wrap;
    > div {
    }
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    grid-template-columns: unset;
    margin-bottom: 1rem;
    margin-top: 1rem;
    flex-direction: column;
  }
`

export const StyledFileIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(DescriptionIcon)

export const StyledCloseUploadIcon = withStyles({
  root: {
    fontSize: '1.25rem',
  },
})(CloseIcon)

export const StyledFileContainer = styled.div`
  background-color: ${theme.palette.secondary.lightGrey};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  padding: 0.3125rem;
  border-radius: 17px;
  color: black;
  margin-right: 0.3125rem;
  @media only screen and (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
    padding: 0.375rem 0.875rem;
    width: 100%;
  }
`

export const StyledPictureAsPdfIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(PictureAsPdfIcon)
export const StyledPhotoLibraryIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(PhotoLibraryIcon)
export const StyledFileCopyIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    marginRight: '.5rem',
  },
})(FileCopyIcon)

export const StyledFileIconParent = styled.div`
  transform: rotate(90deg);
  margin-right: 0.3125rem;
  width: 1.5rem;
  height: 1.5rem;
`

export const StyledFileDataContainer = styled.div``

export const StyledFileMetaDataContainer = styled.div``

export const StyledFileDescription = styled.div`
  word-break: break-word;
`

export const StyledFileSize = styled.span`
  color: #999;
`

export const StyledProgressBar = styled.div`
  height: 0.5rem;
  background: #3784b4;
  border-radius: 0.3rem;
  margin-top: 0.3rem;
`

export const StyledFileRemoveButton = styled.button`
  background: none;
  border: none;
  margin-left: auto;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`

export const StyledFormControl = withStyles({
  root: {
    fontSize: '0.95rem',
    marginBottom: '0.3rem',
    '& > label': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '& > div': {
      height: '2.6875rem',
    },
    '& div:focus': {
      background: 'unset',
    },
  },
})(FormControl)

export const StyledInputLabel = withStyles({
  root: {
    transform: 'translate(14px, 14px) scale(1)',
    '& .MuiInputLabel-outlined-145.MuiInputLabel-shrink-142': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
})(InputLabel)

export const StyledInputLabelCustomPosition = withStyles({
  root: {
    transform: 'translate(14px, 28px) scale(1)',
  },
})(InputLabel)

export const StyledSelectWithIcon = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Noto Sans HK,sans-serif',
    '& svg': {
      marginRight: '0.625rem !important',
      fontSize: '15px !important',
    },
  },
})(CustomDropdown)

export const StyledSelectCustomHeight = withStyles({
  root: {
    height: 'auto',
  },
})(CustomDropdown)

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Noto Sans HK,sans-serif',
    '& ~ li': {
      '& svg': {
        marginRight: '0.625rem !important',
        fontSize: '15px !important',
      },
    },
  },
})(MenuItem)

export const StyledMenuItemWithCheckbox = withStyles({
  root: {
    fontSize: '.75rem',
    fontFamily: 'Noto Sans HK,sans-serif',
    '& svg': {
      marginRight: '0.625rem !important',
      fontSize: '1.4rem !important',
    },
  },
})(MenuItem)

type StyledCheckboxProps = {
  checked: boolean
  name: string
}

const DefaultCheckbox = (props: StyledCheckboxProps) => (
  <Checkbox color='default' {...props} />
)

export const StyledCheckbox = withStyles({
  root: {
    color: '#C1C1C1',
    padding: '0 !important',
    marginRight: '12px',

    '&$checked': {
      color: theme.palette.primary.dark,
    },
    '& svg': {
      width: '22px',
      height: '22px',
      //fill: '#c1c1c1',
    },
  },
  checked: {},
})(DefaultCheckbox)

export const StyledErrorSpan = styled.span`
  position: relative;
  top: -0.4rem;
  line-height: 1.7rem;
`

export const StyledParseName = styled.span``

export const StyledListItemText = withStyles({
  root: {
    '& > span': {
      display: 'flex',
    },
  },
})(ListItemText)

export const StyledGridContainer = styled.div`
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  display: grid;
  margin-bottom: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  @media only screen and (max-width: 480px) {
    grid-template-columns: unset;
    grid-gap: unset;
    display: flex;
    flex-direction: column;
  }
`

export const StyledGridFullRow = styled.div<CSSProperties>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  grid-column: 1/3;
  @media only screen and (max-width: 480px) {
    grid-column: unset;
  }
`

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  background: none;
  border: none;
  color: #333;
  &:hover {
    cursor: pointer;
  }
`

export const StyledCloseIcon = withStyles({
  root: {
    fontSize: '1.25rem',
  },
})(CloseIcon)

export const StyledStrikeThrough = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  font-size: 1.6rem;
  margin: 2rem 0;

  &::before,
  &::after {
    content: '';
    height: 1px;
    display: block;
    background-color: rgb(181, 181, 181);
  }
`
export const StyledSelectLocationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`

export const StyledLineBreakSpan = styled.span`
  display: block;
`

StyledCheckbox.displayName = 'StyledCheckbox'
StyledCloseUploadIcon.displayName = 'StyledCloseUploadIcon'
StyledErrorSpan.displayName = 'StyledErrorSpan'
StyledFileContainer.displayName = 'StyledFileContainer'
StyledFileDataContainer.displayName = 'StyledFileDataContainer'
StyledFileDescription.displayName = 'StyledFileDescription'
StyledFileIcon.displayName = 'StyledFileIcon'
StyledFileMetaDataContainer.displayName = 'StyledFileMetaDataContainer'
StyledFileRemoveButton.displayName = 'StyledFileRemoveButton'
StyledFileSize.displayName = 'StyledFileSize'
StyledFilesLabel.displayName = 'StyledFilesLabel'
StyledFormControl.displayName = 'StyledFormControl'
StyledGridContainer.displayName = 'StyledGridContainer'
StyledInputLabelCustomPosition.displayName = 'StyledInputLabelCustomPosition'
StyledMenuItem.displayName = 'StyledMenuItem'
StyledMenuItemWithCheckbox.displayName = 'StyledMenuItemWithCheckbox'
StyledProgressBar.displayName = 'StyledProgressBar'
StyledSelectCustomHeight.displayName = 'StyledSelectCustomHeight'
StyledSelectLocationForm.displayName = 'StyledSelectLocationForm'
StyledReferralSelectionParagraph.displayName =
  'StyledReferralSelectionParagraph'
