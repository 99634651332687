import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'

export const StyledNavBarWrapper = styled.nav`
  ${u.dFlex};
  ${u.alignItemsCenter}
  ${u.justifyContentCenter}
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '15px')};
  margin: ${(props) => props.margin};
  width: ${(props) => (props.width ? props.width : '100%')};
  ${media.smaller`
      margin-left: 0;
      margin-top: 2rem;
      margin-bottom: 1rem;
  `}
  ${media.desktop`
    margin-top: 0;
    margin-bottom: 0;
  `}
`
export const StyledNavBarMenu = styled.ul`
  ${u.dFlex};
  ${u.zeroPadding}
  ${u.zeroMargin}
  ${media.smaller`
    ${u.flexCol}
  `}
  ${media.desktop`
    ${u.flexRow}
  `}
`
export const StyledNavBarItem = styled.li`
  ${u.dFlex}
`
export const StyledNavBarLink = styled.a`
  color: ${theme.palette.text.default};
  font-size: 0.9rem;
  padding: 1em;
  font-weight: 500;
  &:hover {
    color: ${theme.palette.primary.dark};
    cursor: pointer;
  }
  ${media.smaller`
    padding: 1em 1em 1em 0;
  `}
  ${media.desktop`
    padding: 1em ;
  `}
`
StyledNavBarWrapper.displayName = 'StyledNavBarWrapper'
StyledNavBarMenu.displayName = 'StyledNavBarMenu'
StyledNavBarItem.displayName = 'StyledNavBarItem'
StyledNavBarLink.displayName = 'StyledNavBarLink'
