import {
  BasicInformationIcon,
  DemographicsIcon,
  IncomeIcon,
  SavingsIcon,
  ExpensesIcon,
  TaxInformationIcon,
} from 'Components/Shared/ProgressSideBar/Icons/PathIcons'

const ProgressSteps = [
  {
    id: 0,
    name: 'Basic Information',
    icon: BasicInformationIcon,
    progressBar: true,
    viewBox: '0 0 20.903 31.282',
  },
  {
    id: 1,
    name: 'Demographics',
    icon: DemographicsIcon,
    progressBar: true,
    viewBox: '0 0 30.391 34.045',
  },
  {
    id: 2,
    name: 'Income',
    icon: IncomeIcon,
    progressBar: true,
    viewBox: '0 0 31.493 31.499',
  },
  {
    id: 3,
    name: 'Savings',
    icon: SavingsIcon,
    progressBar: true,
    viewBox: '0 0 32.828 30.625',
  },
  {
    id: 4,
    name: 'Expenses',
    icon: ExpensesIcon,
    progressBar: true,
    viewBox: '0 0 31.493 31.499',
  },
  {
    id: 5,
    name: 'Tax Information',
    icon: TaxInformationIcon,
    progressBar: false,
    viewBox: '0 0 28.659 31.824',
  },
]

export default ProgressSteps
