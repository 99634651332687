import React from 'react'

import LocationInfo from './LocationInfo/LocationInfo'
import { outlineLanguagesIconPath } from 'Shared/Icons/LocationInfo/paths'

const Languages = (props) => {
  const { languages: languageObject } = props
  const languages = Object.values(languageObject)

  const content = languages.map((language) => language['nativeName']).join(', ')

  if (languages.length) {
    return (
      <LocationInfo
        content={content}
        screenReaderLabel={'Spoken Languages'}
        icon={outlineLanguagesIconPath}
      />
    )
  } else {
    return null
  }
}

export default Languages
