import React from 'react'

export const ISOLogoIcon = (
  <g transform='translate(-1.384 650)'>
    <path
      className='a'
      fill='#393946'
      d='M184-638.9v11.1h1.906l.272-.545a1.722,1.722,0,0,1,.354-.545,1.9,1.9,0,0,1,.477.286,7.454,7.454,0,0,0,6.876.531c1.389-.681,2.11-1.688,2.655-3.744a18.82,18.82,0,0,0,.068-6.536c-.776-3.186-2.4-4.561-5.406-4.589a6.878,6.878,0,0,0-3.35.776l-.585.327V-650H184Zm7.9-.9c1.2.64,1.648,1.865,1.648,4.575,0,2.546-.245,3.458-1.157,4.262s-2.737.885-4.412.163l-.708-.3v-4.058c0-3.309.041-4.085.191-4.207A5.853,5.853,0,0,1,191.9-639.8Z'
      transform='translate(-157.751)'
    />
    <path
      className='a'
      fill='#393946'
      d='M373.376-643.447a2.358,2.358,0,0,0-1.239,1.511,2.129,2.129,0,0,0,3.077,2.369,2.188,2.188,0,0,0,.123-3.69A2.355,2.355,0,0,0,373.376-643.447Z'
      transform='translate(-320.235 -5.545)'
    />
    <path
      className='a'
      fill='#393946'
      d='M290.157-598.337c-2.968.381-4.534,1.961-4.561,4.616a3.413,3.413,0,0,0,.844,2.75c.79.871,1.756,1.253,4.03,1.539,2.315.3,2.846.64,2.846,1.825a1.59,1.59,0,0,1-1.1,1.552,4.628,4.628,0,0,1-2.274.272,11,11,0,0,1-3.976-.667c-.313-.082-.313-.082-.313,1.362v1.43l.722.2a12.845,12.845,0,0,0,3.908.531q6.495.021,6.236-5.2c-.123-2.519-1.4-3.554-4.861-3.962-2.288-.272-2.873-.6-2.873-1.647a1.525,1.525,0,0,1,.871-1.525c.98-.531,4.194-.313,5.977.381.218.1.232,0,.2-1.375l-.041-1.484-.681-.218A14.327,14.327,0,0,0,290.157-598.337Z'
      transform='translate(-245.513 -44.569)'
    />
    <path
      className='a'
      fill='#393946'
      d='M375.137-595.7c-.041.1-.054,2.873-.027,6.154.054,5.283.082,6.045.286,6.6a3.389,3.389,0,0,0,1.471,1.688,5.394,5.394,0,0,0,2.287.368l.422-.054v-1.348c0-1.225-.027-1.348-.259-1.348a1.062,1.062,0,0,1-.545-.245c-.3-.231-.3-.286-.327-6.086l-.027-5.855-1.607-.041C375.532-595.886,375.205-595.858,375.137-595.7Z'
      transform='translate(-322.827 -46.762)'
    />
    <path
      className='a'
      fill='#393946'
      d='M61.134-590.629a1.108,1.108,0,0,0,.054,1.811c.218.082,14.351.136,38.247.136,24.441,0,38.4.054,39.3.136a38.277,38.277,0,0,1,20.274,8.02A47.922,47.922,0,0,1,164.683-575a39.19,39.19,0,0,1,7.884,15.686,32.787,32.787,0,0,1,.926,8.755,31.228,31.228,0,0,1-1.144,9.6,38.165,38.165,0,0,1-28.58,27.709c-4.289.939-.408.871-47.261.912L54.23-512.3l1.416-.545a40.389,40.389,0,0,0,17.592-12.935,40.2,40.2,0,0,0,1.607-47.261,43.734,43.734,0,0,0-8.156-8.837c-1.947-1.552-4.575-3.268-5-3.268a.966.966,0,0,0-.994,1.294,6.438,6.438,0,0,0,1.7,1.362,38.108,38.108,0,0,1,16.8,25.8,38.137,38.137,0,0,1-5.773,27.164,38.187,38.187,0,0,1-25.6,16.666,28.07,28.07,0,0,1-5.719.49,37.626,37.626,0,0,1-27.137-10.743A37.818,37.818,0,0,1,3.783-544.77a45.4,45.4,0,0,1-.286-9.068,38.139,38.139,0,0,1,15.2-27.246c.9-.667,2.042-1.471,2.56-1.77a4.386,4.386,0,0,0,1.048-.8.951.951,0,0,0-.177-1.185c-.545-.517-.953-.395-2.8.831a40.646,40.646,0,0,0-14.76,17.66,44.757,44.757,0,0,0-2.887,10.621c-.054.49-.123,1.008-.163,1.157a61.668,61.668,0,0,0,.027,8.238,39.893,39.893,0,0,0,11.015,23.665,39.822,39.822,0,0,0,24.182,12.1c1.756.232,4.207.245,51.6.245,40.29,0,50.066-.041,51.332-.177a41.483,41.483,0,0,0,16.271-5.433A40.271,40.271,0,0,0,175.29-545.4a59.054,59.054,0,0,0,.15-8.7,43.577,43.577,0,0,0-1.117-6.44,41.407,41.407,0,0,0-7.285-14.8,52.239,52.239,0,0,0-7.38-7.23,40.488,40.488,0,0,0-19.171-7.856c-1.294-.2-5.092-.218-40.3-.245C78.807-590.7,61.229-590.67,61.134-590.629Z'
      transform='translate(0 -51.242)'
    />
    <path
      className='a'
      fill='#393946'
      d='M665.69-529.56a2.19,2.19,0,0,0-.912,3.622,4.1,4.1,0,0,0,1.824.94c1.77.585,2.179,1.089,1.675,2.056-.286.572-.926.722-2.6.654l-1.416-.068v.517a1.051,1.051,0,0,0,.123.6,10.408,10.408,0,0,0,1.416.163c2.641.163,3.949-.654,3.949-2.492,0-1.307-.6-1.893-2.641-2.573-1.253-.422-1.62-.735-1.62-1.362a.833.833,0,0,1,.368-.749c.354-.272.517-.286,1.879-.245l1.5.041.041-.49c.054-.626-.054-.667-1.743-.749A5.026,5.026,0,0,0,665.69-529.56Z'
      transform='translate(-572.518 -103.91)'
    />
    <path
      className='a'
      fill='#393946'
      d='M711.859-529.6c-1.661.545-2.4,2.083-2.26,4.725s1.539,3.949,3.976,3.772a3.05,3.05,0,0,0,2.9-1.947,4.735,4.735,0,0,0,.313-2.383,4.735,4.735,0,0,0-.313-2.383,2.934,2.934,0,0,0-1.457-1.607A5.387,5.387,0,0,0,711.859-529.6Zm2.641,1.307a1.95,1.95,0,0,1,.654.681,6.72,6.72,0,0,1,.327,3.064c-.218,1.729-.817,2.288-2.4,2.219-.871-.041-1.035-.1-1.389-.436a2.641,2.641,0,0,1-.586-.885,10.255,10.255,0,0,1-.068-3.309,2.4,2.4,0,0,1,1.1-1.471A3.651,3.651,0,0,1,714.5-528.291Z'
      transform='translate(-611.77 -103.872)'
    />
    <path
      className='a'
      fill='#393946'
      d='M864.63-529.728c-1.8.449-2.6,1.947-2.492,4.657.068,1.62.327,2.356,1.076,3.091a4.511,4.511,0,0,0,4.384.49c.64-.218.667-.245.586-.64-.123-.613-.218-.654-.953-.436a6.416,6.416,0,0,1-1.5.2c-1.661,0-2.288-.858-2.315-3.132a3.577,3.577,0,0,1,.327-1.974c.49-.98.967-1.212,2.437-1.185.64.014,1.293.041,1.443.068.231.027.272-.054.272-.517,0-.517-.014-.545-.545-.64A10.083,10.083,0,0,0,864.63-529.728Z'
      transform='translate(-743.545 -103.81)'
    />
    <path
      className='a'
      fill='#393946'
      d='M647-524.779v4.221h1.225V-529H647Z'
      transform='translate(-557.708 -104.525)'
    />
    <path
      className='a'
      fill='#393946'
      d='M768.336-528.7c-.109.286-2.955,7.571-3.1,7.965-.055.123.1.177.477.177.313,0,.572-.068.613-.177.041-.082.749-1.947,1.593-4.112s1.552-3.989,1.593-4.057-.191-.1-.5-.1C768.554-529,768.431-528.946,768.336-528.7Z'
      transform='translate(-659.832 -104.525)'
    />
    <path
      className='a'
      fill='#393946'
      d='M802-524.779v4.221h1.225V-529H802Z'
      transform='translate(-691.604 -104.525)'
    />
    <path
      className='a'
      fill='#393946'
      d='M821.271-528.735c-.041.068-.068,1.961-.041,4.194l.027,4.071H826.3v-1.089h-3.744V-524.2l1.539-.041,1.525-.041v-.953l-1.525-.041-1.539-.041v-2.369H826.3v-1.089l-2.478-.041A16.661,16.661,0,0,0,821.271-528.735Z'
      transform='translate(-708.206 -104.681)'
    />
    <path
      className='a'
      fill='#393946'
      d='M132.264-458.565a13.439,13.439,0,0,0-11.07,10.947,15.973,15.973,0,0,0,.218,6.141c.163.449,1.321,2.56,2.56,4.7s2.587,4.466,3,5.174c.694,1.185,4.589,7.925,7.339,12.663.64,1.089,1.7,2.927,2.369,4.085,4.97,8.592,8.115,13.956,8.21,13.956s6.154-10.4,19.662-33.836c4.3-7.489,4.18-7.176,4.153-10.348a12.574,12.574,0,0,0-1.171-5.882,13.114,13.114,0,0,0-7.993-7.108,11.436,11.436,0,0,0-4.221-.572,12.756,12.756,0,0,0-9.967,5.256l-.368.477-.381-.477a19.189,19.189,0,0,0-2.832-2.832,14.057,14.057,0,0,0-4.425-2.07A16.971,16.971,0,0,0,132.264-458.565Zm3.921,4.7a8.082,8.082,0,0,1,3.949,2.3,8.311,8.311,0,0,1,2.505,4.834l.109.694H125.77v-.381a6.925,6.925,0,0,1,.2-1.185,8.908,8.908,0,0,1,6.2-6.25A10.492,10.492,0,0,1,136.186-453.867Zm21.513,0a8.909,8.909,0,0,1,6.25,6.141,6.623,6.623,0,0,1,.218,1.266v.422H147.283v-.381a6.926,6.926,0,0,1,.2-1.185,8.932,8.932,0,0,1,6.2-6.25A10.492,10.492,0,0,1,157.7-453.867Zm-17.428,12.826a9.682,9.682,0,0,0-.694,5.623c.64,2.573,2.029,4.18,5.664,6.6,2.764,1.838,3.921,2.9,4.534,4.139a4.077,4.077,0,0,1,.436,2.533,4.009,4.009,0,0,1-.381,2.4,5.763,5.763,0,0,1-2.247,2.287,7.143,7.143,0,0,1-6.25-.054c-1.77-.817-2.138-1.28-4.943-6.331-.776-1.389-1.784-3.2-2.247-4.017-3.472-6.141-7.421-13.248-7.421-13.33,0-.041,3.064-.082,6.822-.082C139.985-441.272,140.366-441.259,140.271-441.041Zm22.671-.163c0,.054-2.859,5.215-7.23,13.044l-1.266,2.274L153.859-427c-1.117-2.179-2.356-3.363-5.991-5.814-3.077-2.07-3.772-2.873-3.744-4.344a4.057,4.057,0,0,1,3.023-3.921,75.744,75.744,0,0,1,8.21-.177C159.524-441.272,162.941-441.245,162.941-441.2Zm-16.2,29.166c0,.027-1.784,3.227-1.838,3.281s-1.838-3.145-1.838-3.254a16.444,16.444,0,0,1,1.838-.054C145.908-412.066,146.738-412.052,146.738-412.039Z'
      transform='translate(-103.333 -165.293)'
    />
    <path
      className='a'
      fill='#393946'
      d='M646.159-429.806a9.031,9.031,0,0,0-.98.259c-.613.2-.64.218-.5.572.068.2.15.436.191.531s.218.109.708-.041a4.39,4.39,0,0,1,2.4-.068,1.186,1.186,0,0,1,.586.64c.436,1.035-.109,2.015-2.288,4.139-1.416,1.389-1.593,1.62-1.579,2l.014.449h5.406v-1.253l-1.77.041c-.98.027-1.77.054-1.77.041s.626-.654,1.4-1.43c1.607-1.62,2-2.3,2-3.458a2.124,2.124,0,0,0-.817-1.879A3.724,3.724,0,0,0,646.159-429.806Z'
      transform='translate(-555.642 -190.144)'
    />
    <path
      className='a'
      fill='#393946'
      d='M737.311-429.742a2.4,2.4,0,0,0-1.511,1.43c-.259.558-.3.885-.3,2.682,0,2.315.163,3,.926,3.7a3.3,3.3,0,0,0,4.112.027c.64-.558.9-1.457.967-3.322.109-3.363-.79-4.725-3.1-4.684A4.566,4.566,0,0,0,737.311-429.742Zm2.206,1.307c.517.368.667.994.667,2.914.014,2-.136,2.519-.831,2.955a1.323,1.323,0,0,1-1.021.218c-1.28-.163-1.539-.694-1.539-3.213,0-2.342.191-2.818,1.225-3.186A2.264,2.264,0,0,1,739.517-428.435Z'
      transform='translate(-634.158 -190.125)'
    />
    <path
      className='a'
      fill='#393946'
      d='M788.173-429.718a2.764,2.764,0,0,0-1.525,1.661,12.512,12.512,0,0,0,0,5.079,2.7,2.7,0,0,0,2.8,1.811,2.71,2.71,0,0,0,2.124-.831c.6-.667.763-1.239.844-3.023.15-2.873-.463-4.344-1.947-4.711A4.842,4.842,0,0,0,788.173-429.718Zm2.206,1.225c.531.259.735,1.076.735,2.968,0,2.587-.34,3.2-1.811,3.2a1.067,1.067,0,0,1-1.062-.436c-.49-.545-.585-1.144-.517-3.281.041-1.416.082-1.7.327-2.029A1.871,1.871,0,0,1,790.379-428.493Z'
      transform='translate(-678.117 -190.163)'
    />
    <path
      className='a'
      fill='#393946'
      d='M690-428.455v.545h2.042a20.126,20.126,0,0,1,2.042.054c0,.027-.395.871-.885,1.865a33.59,33.59,0,0,0-1.974,5.3c0,.082.3.136.681.136h.667l.15-.586a29.214,29.214,0,0,1,1.852-4.983,7.739,7.739,0,0,0,.871-2.342V-429H690Z'
      transform='translate(-594.854 -190.909)'
    />
    <path
      className='a'
      fill='#393946'
      d='M832.03-428.455l-1.13.545.218.408c.245.463.272.477.9.218.259-.1.6-.231.776-.272l.3-.1v7.094h1.225V-429h-.572A4.4,4.4,0,0,0,832.03-428.455Z'
      transform='translate(-716.569 -190.909)'
    />
    <path
      className='a'
      fill='#393946'
      d='M645-333.75v3.336h.953v-6.617l-.477-.027L645-337.1Z'
      transform='translate(-555.981 -270.295)'
    />
    <path
      className='a'
      fill='#393946'
      d='M692.28-336.775a1.356,1.356,0,0,0-.6,1.266c0,.395-.054.49-.34.558a.369.369,0,0,0-.34.408c0,.259.068.327.34.327h.34v3.949h.953v-3.949h.531c.477,0,.517-.027.5-.381-.014-.327-.068-.368-.517-.409s-.517-.082-.517-.422c0-.5.231-.694.844-.694.49,0,.517-.027.517-.408,0-.408-.014-.408-.708-.395A2.9,2.9,0,0,0,692.28-336.775Z'
      transform='translate(-595.717 -270.444)'
    />
    <path
      className='a'
      fill='#393946'
      d='M876.809-336.625c-.286.409-.054.817.436.763a.52.52,0,0,0,.3-.871C877.313-337.006,877.055-336.965,876.809-336.625Z'
      transform='translate(-756.119 -270.457)'
    />
    <path
      className='a'
      fill='#393946'
      d='M972.6-336.78c-.8.49-.994.912-.912,2.015.041.5.749,1.062,1.756,1.375,1.253.395,1.539.613,1.539,1.171,0,.776-.368.967-1.893.98h-1.307l-.041.422c-.041.395-.014.408.6.5a4.115,4.115,0,0,0,3.2-.545,2.2,2.2,0,0,0,.476-1.743c-.136-.708-.708-1.2-1.838-1.552-1.1-.354-1.511-.681-1.511-1.185,0-.558.5-.762,1.824-.762h1.171v-.381c0-.34-.054-.395-.5-.477A4.578,4.578,0,0,0,972.6-336.78Z'
      transform='translate(-838.174 -270.344)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1123.8-336.828a.507.507,0,0,0,.395.926.478.478,0,0,0,.109-.9C1123.991-336.964,1123.991-336.964,1123.8-336.828Z'
      transform='translate(-969.37 -270.445)'
    />
    <path
      className='a'
      fill='#393946'
      d='M851.817-332.605c0,.64-.231,1.1-.545,1.1-.2,0-.272.1-.272.34,0,.272.068.34.34.34h.34v3.3l.422.409c.368.381.463.408,1.048.327.763-.109.885-.2.776-.667-.082-.313-.136-.34-.572-.272-.327.055-.517.027-.6-.109a6.491,6.491,0,0,1-.122-1.593v-1.389h.681c.667,0,.681-.014.681-.409s-.014-.409-.681-.409h-.681v-.681c0-.667-.014-.681-.409-.681C851.858-333,851.817-332.959,851.817-332.605Z'
      transform='translate(-733.932 -273.837)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1134.043-332.319c0,.654-.027.694-.395.763-.5.109-.517.626-.027.735.313.068.327.1.313,1.43a7.381,7.381,0,0,0,.15,1.838,1.174,1.174,0,0,0,1.43.749c.776-.123.885-.231.694-.64-.136-.313-.218-.34-.681-.286l-.531.082v-3.173h.613c.585,0,.613-.014.613-.409s-.027-.409-.613-.409H1135V-333h-.953Z'
      transform='translate(-977.76 -273.837)'
    />
    <path
      className='a'
      fill='#393946'
      d='M660.756-323.484a6.939,6.939,0,0,0-.64.272c-.245.136-.3.123-.3-.054s-.109-.2-.368-.177l-.381.041-.041,2.342L659-318.7h1.089v-3.676l.368-.15a2.136,2.136,0,0,1,.776-.1c.626.068.763.463.763,2.315v1.607h.953v-1.784c0-2.356-.231-2.859-1.375-3.036A2.273,2.273,0,0,0,660.756-323.484Z'
      transform='translate(-568.075 -282.005)'
    />
    <path
      className='a'
      fill='#393946'
      d='M713.181-323.425c-.994.3-1.416,1.062-1.389,2.546.014,1.525.722,2.274,2.124,2.274,1.511,0,2.056-.654,2.056-2.451,0-1.212-.231-1.77-.885-2.151A2.694,2.694,0,0,0,713.181-323.425Zm1.484,1.062a2.621,2.621,0,0,1-.027,2.7,1.529,1.529,0,0,1-1.266.15c-.422-.191-.6-.667-.6-1.593,0-1.048.327-1.511,1.1-1.511A1.122,1.122,0,0,1,714.665-322.363Z'
      transform='translate(-613.678 -282.037)'
    />
    <path
      className='a'
      fill='#393946'
      d='M748.716-323.487a1.917,1.917,0,0,0-.64.34c-.245.218-.259.218-.259-.054,0-.245-.082-.3-.409-.3H747v4.766h.953v-1.811c0-1.825,0-1.825.34-1.974a2.464,2.464,0,0,1,.9-.163c.531,0,.545-.014.5-.436C749.655-323.582,749.424-323.677,748.716-323.487Z'
      transform='translate(-644.092 -281.975)'
    />
    <path
      className='a'
      fill='#393946'
      d='M770.756-323.436c-.191.068-.477.191-.64.272-.245.136-.3.123-.3-.054q0-.2-.408-.2H769v4.766h1.089v-1.893c0-1.838.014-1.893.313-1.974,1.226-.327,1.457.014,1.457,2.192v1.675h.953V-320.5c0-2.029.041-2.11.94-2.11.735,0,.831.231.831,2.192v1.756h.953v-1.825a11.09,11.09,0,0,0-.136-2.179,1.771,1.771,0,0,0-2.437-.572c-.368.191-.463.2-.722.027A2.055,2.055,0,0,0,770.756-323.436Z'
      transform='translate(-663.097 -282.053)'
    />
    <path
      className='a'
      fill='#393946'
      d='M822.276-323.577c-.15.041-.395.1-.558.122-.218.055-.272.136-.218.449l.054.395.98-.068c.926-.054,1.008-.041,1.225.259.436.64.313.8-.626.8-1.021,0-1.607.3-1.879.953a1.58,1.58,0,0,0,2.356,1.825c.286-.163.436-.177.476-.068.027.082.232.15.449.15h.395v-1.756c0-1.947-.136-2.519-.667-2.791A4.5,4.5,0,0,0,822.276-323.577Zm1.675,3.281c.041.463,0,.558-.313.694-.79.368-1.62.014-1.5-.626a2.273,2.273,0,0,0,.054-.327c.014-.15.844-.34,1.307-.313C823.869-320.84,823.91-320.786,823.951-320.3Z'
      transform='translate(-708.135 -281.953)'
    />
    <path
      className='a'
      fill='#393946'
      d='M889.607-323.5c-1.28.409-1.8,2.233-1.117,3.881a2.187,2.187,0,0,0,2.8.8c.776-.313,1.144-1.035,1.144-2.3a2.323,2.323,0,0,0-.871-2.124A2.739,2.739,0,0,0,889.607-323.5Zm1.539,1.171a1.646,1.646,0,0,1,.286,1.157c0,1.321-.409,1.811-1.362,1.607-.654-.136-.817-.436-.817-1.457,0-1.28.245-1.661,1.117-1.661A.814.814,0,0,1,891.146-322.329Z'
      transform='translate(-766.058 -281.976)'
    />
    <path
      className='a'
      fill='#393946'
      d='M925.375-323.233c-.49.218-.585.218-.64.054a.481.481,0,0,0-.408-.2H924v4.766h.953v-3.676l.354-.136a1.347,1.347,0,0,1,1.334.082c.177.163.218.558.218,1.974v1.756h.953v-1.838c0-2.41-.191-2.832-1.389-2.982A2.177,2.177,0,0,0,925.375-323.233Z'
      transform='translate(-796.992 -282.093)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1007.721-323.449a1.822,1.822,0,0,0-1.334,1.484,2.566,2.566,0,0,0,.953,3.145,2.755,2.755,0,0,0,2.015.136c.586-.163.708-.3.586-.667-.068-.245-.177-.259-1.008-.191-1.076.1-1.416-.054-1.634-.64-.177-.531-.163-.531,1.484-.572l1.293-.041-.014-.681A1.818,1.818,0,0,0,1007.721-323.449Zm1.253,1.117c.245.34.286.749.082.817-.068.014-.5.014-.953,0-.667-.041-.817-.1-.817-.286A1.009,1.009,0,0,1,1008.973-322.332Z'
      transform='translate(-868.023 -282.027)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1038.33-323.336a1.52,1.52,0,0,0-1.1.953,4.351,4.351,0,0,0,.027,2.791,1.709,1.709,0,0,0,1.838,1.021,4.24,4.24,0,0,0,1.212-.163c.34-.136.354-.191.231-.558s-.177-.395-.422-.272a1.936,1.936,0,0,1-1.784-.136,2.631,2.631,0,0,1-.177-2.3c.259-.49.613-.613,1.566-.545.654.041.681.027.735-.34.027-.2.014-.408-.041-.436A4.451,4.451,0,0,0,1038.33-323.336Z'
      transform='translate(-894.615 -282.086)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1103.716-323.6a2.51,2.51,0,0,0-.654.354c-.245.231-.259.231-.34-.055a.354.354,0,0,0-.395-.3H1102v4.766h.953v-1.838c0-2.07.027-2.111,1.076-2.111.5,0,.558-.027.558-.34a1.72,1.72,0,0,1,.041-.449C1104.669-323.72,1104.165-323.734,1103.716-323.6Z'
      transform='translate(-950.756 -281.878)'
    />
    <path
      className='a'
      fill='#393946'
      d='M877-320.617v2.383h.953V-323H877Z'
      transform='translate(-756.392 -282.476)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1066.941-320.958c.041,1.988.054,2.042.395,2.4a2.048,2.048,0,0,0,2.07.286c.286-.123.531-.163.572-.082a.622.622,0,0,0,.422.123h.327V-323h-.953v1.893c0,2.124.027,2.056-1.021,2.056-.463,0-.64-.068-.749-.259a9.747,9.747,0,0,1-.136-1.974V-323h-.967Z'
      transform='translate(-920.435 -282.476)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1124-320.617v2.383h.953V-323H1124Z'
      transform='translate(-969.76 -282.476)'
    />
    <path
      className='a'
      fill='#393946'
      d='M1156-322.888c0,.109.844,3.077,1.1,3.867.218.681.34.858.558.858s.245.055.177.231c-.055.136-.2.572-.354.967l-.245.722.49-.041.49-.041.531-1.566c.286-.858.708-2.247.94-3.064s.449-1.607.49-1.743c.054-.218-.014-.245-.436-.2l-.5.041-.517,1.879c-.286,1.035-.545,1.92-.585,1.961-.082.082-.327-.708-.817-2.614l-.3-1.225-.517-.041A1.164,1.164,0,0,0,1156-322.888Z'
      transform='translate(-997.403 -282.547)'
    />
    <path
      className='a'
      fill='#393946'
      d='M644.159-256.446c0,.354-.027.8-.041.994s-.068,1.362-.123,2.614l-.1,2.287H645l.027-1.675c.014-.912.041-1.784.054-1.933s.041-.585.055-.953l.014-.681.531,1.5c1.062,2.941.9,2.669,1.539,2.628l.545-.041.694-1.947.694-1.947.1.722c.054.395.109,1.389.123,2.219.055,2.1.055,2.11.64,2.11h.477l-.1-2.56c-.041-1.4-.123-2.873-.163-3.268l-.082-.708h-1.443l-.654,1.865c-.354,1.035-.694,2-.749,2.151l-.1.272-.109-.272c-.068-.15-.395-1.1-.749-2.111l-.64-1.838-.762-.041-.763-.041Z'
      transform='translate(-555.031 -339.403)'
    />
    <path
      className='a'
      fill='#393946'
      d='M974.8-253.471a3.863,3.863,0,0,1-.054.586,1.981,1.981,0,0,0-.041.3c-.014.1-.177.177-.368.177-.272,0-.34.068-.34.34s.068.34.34.34h.34v1.566a8.385,8.385,0,0,0,.15,1.893,1.356,1.356,0,0,0,1.443.586c.667-.109.8-.272.613-.708-.136-.286-.2-.313-.477-.218a.748.748,0,0,1-.545,0c-.191-.123-.232-.368-.232-1.62v-1.5h.681c.667,0,.681-.014.681-.408s-.014-.408-.667-.408h-.681l-.041-.654c-.041-.586-.068-.64-.408-.681S974.817-253.879,974.8-253.471Z'
      transform='translate(-840.184 -342.174)'
    />
    <path
      className='a'
      fill='#393946'
      d='M697.053-243.74a.48.48,0,0,0-.136.449c.041.313.082.327,1.008.286,1.062-.054,1.416.123,1.416.735,0,.313-.041.327-.762.327-1.5,0-2.274.79-1.947,1.974a1.665,1.665,0,0,0,2.369.831c.286-.123.5-.15.545-.068a.621.621,0,0,0,.422.123h.34l-.041-2.042c-.041-1.906-.054-2.056-.354-2.342-.259-.272-.477-.313-1.5-.354A4.922,4.922,0,0,0,697.053-243.74Zm2.287,3.173c0,.6-.245.8-.98.8-.558,0-.79-.2-.79-.694,0-.449.2-.626.817-.694C699.2-241.235,699.341-241.153,699.341-240.567Z'
      transform='translate(-600.518 -350.87)'
    />
    <path
      className='a'
      fill='#393946'
      d='M730.1-243.917a15.642,15.642,0,0,0-.1,2.383v2.287h1.076l.041-1.879.041-1.865.5-.123a1.287,1.287,0,0,1,.858.027c.34.163.34.163.34,2v1.838h1.089v-1.7c0-1.852-.15-2.451-.694-2.846a2.415,2.415,0,0,0-1.947-.014c-.477.245-.49.245-.49,0C730.817-244.012,730.272-244.094,730.1-243.917Z'
      transform='translate(-629.407 -350.707)'
    />
    <path
      className='a'
      fill='#393946'
      d='M763.053-243.74a.48.48,0,0,0-.136.449c.041.313.082.327,1.008.286,1.062-.054,1.416.123,1.416.735,0,.313-.041.327-.763.327-1.5,0-2.274.79-1.947,1.974a1.664,1.664,0,0,0,2.369.831c.286-.123.5-.15.545-.068a.621.621,0,0,0,.422.123h.34l-.041-2.042c-.041-1.906-.054-2.056-.354-2.342-.259-.272-.477-.313-1.5-.354A4.922,4.922,0,0,0,763.053-243.74Zm2.287,3.173c0,.6-.245.8-.98.8-.558,0-.79-.2-.79-.694,0-.449.2-.626.817-.694C765.2-241.235,765.341-241.153,765.341-240.567Z'
      transform='translate(-657.531 -350.87)'
    />
    <path
      className='a'
      fill='#393946'
      d='M795.8-243.718c-.5.272-.8,1.117-.8,2.26,0,1.525.449,2.26,1.484,2.4a2.413,2.413,0,0,0,.953-.068c.408-.15.422-.136.422.3,0,.64-.2.762-1.307.762-.9,0-.98.027-1.062.3-.109.422,0,.49,1.008.585a2.776,2.776,0,0,0,1.443-.123c.9-.368.939-.49.98-3.581l.027-2.75-.5-.136A5.308,5.308,0,0,0,795.8-243.718Zm2.056,2.165c0,1.7,0,1.716-.871,1.716-.735,0-.967-.381-.967-1.634,0-1.348.2-1.634,1.185-1.634h.654Z'
      transform='translate(-685.557 -350.796)'
    />
    <path
      className='a'
      fill='#393946'
      d='M828.5-243.682c-.654.395-.912.994-.912,2.179a2.651,2.651,0,0,0,.259,1.5c.5.858,1.511,1.144,2.873.8.572-.15.613-.191.531-.517-.054-.191-.109-.368-.123-.395a3.5,3.5,0,0,0-.708.109c-.912.191-1.43.027-1.716-.545a1.209,1.209,0,0,1-.191-.517,9.961,9.961,0,0,1,1.362-.054c1.5,0,1.579-.054,1.511-1.035a2.064,2.064,0,0,0-.9-1.62A2.418,2.418,0,0,0,828.5-243.682Zm1.539.654a1.478,1.478,0,0,1,.463.9c0,.177-.177.218-.94.218a2.928,2.928,0,0,1-1.021-.109c-.082-.136.286-.871.5-1.008A1.26,1.26,0,0,1,830.04-243.029Z'
      transform='translate(-713.707 -350.764)'
    />
    <path
      className='a'
      fill='#393946'
      d='M860-241.617v2.383h.953v-3.676l.354-.136a1.13,1.13,0,0,1,1.2.082c.177.163.218.558.218,1.974v1.757h.953v-3.649l.49-.163c.626-.218,1.117-.082,1.28.354a8.829,8.829,0,0,1,.136,1.906v1.552h.98l-.054-1.988-.041-1.988-.449-.395a1.326,1.326,0,0,0-1.089-.395,2.15,2.15,0,0,0-1.062.259c-.395.245-.422.245-.722,0a2.171,2.171,0,0,0-1.933-.055c-.354.191-.409.191-.477,0a.463.463,0,0,0-.409-.2H860Z'
      transform='translate(-741.706 -350.719)'
    />
    <path
      className='a'
      fill='#393946'
      d='M912.426-243.742c-.681.408-.926.994-.926,2.179a2.614,2.614,0,0,0,.286,1.5c.49.858,1.5,1.144,2.859.8.572-.15.613-.191.531-.517-.054-.191-.109-.368-.123-.395a3.3,3.3,0,0,0-.694.109c-1.089.245-1.838-.109-1.838-.885,0-.259.082-.272,1.293-.245,1.144.014,1.307-.014,1.43-.231a2.758,2.758,0,0,0-.2-1.784A1.971,1.971,0,0,0,912.426-243.742Zm1.539.654a1.478,1.478,0,0,1,.463.9c0,.177-.177.218-.953.218-.9,0-.953-.014-.953-.313A1.018,1.018,0,0,1,913.965-243.089Z'
      transform='translate(-786.194 -350.704)'
    />
    <path
      className='a'
      fill='#393946'
      d='M944-241.617v2.383h.953v-3.649l.422-.15c1.253-.436,1.484-.1,1.484,2.124v1.675h.953v-1.852c0-1.906-.082-2.247-.6-2.669a2.419,2.419,0,0,0-2-.041c-.354.191-.409.191-.477,0a.463.463,0,0,0-.408-.2H944Z'
      transform='translate(-814.269 -350.719)'
    />
    <path
      className='a'
      fill='#393946'
      d='M644.322-162.771c-1.185.422-1.634,1.307-1.634,3.227a3.458,3.458,0,0,0,.327,1.974,2.329,2.329,0,0,0,1.852,1.362,6.077,6.077,0,0,0,2.519-.327.822.822,0,0,0-.041-.558c-.136-.395-.2-.436-.517-.368-2.138.477-2.859-.041-2.859-2.1,0-1.852.531-2.383,2.274-2.26l.98.054v-.5c0-.5-.027-.531-.5-.613A5.689,5.689,0,0,0,644.322-162.771Z'
      transform='translate(-553.982 -420.728)'
    />
    <path
      className='a'
      fill='#393946'
      d='M718.449-162.627l-.449.054v6.522h1.226v-2.587h.477c.463,0,.49.027,1.117,1.294l.654,1.294h1.4l-.735-1.321c-.408-.735-.735-1.362-.735-1.4a1.224,1.224,0,0,1,.408-.3,2.029,2.029,0,0,0-.218-3.363A9.309,9.309,0,0,0,718.449-162.627Zm2.655,1.307a1.251,1.251,0,0,1-.014,1.416,1.816,1.816,0,0,1-1.048.286l-.817.054v-.94a2.9,2.9,0,0,1,.109-1.048A2.663,2.663,0,0,1,721.1-161.32Z'
      transform='translate(-619.041 -420.968)'
    />
    <path
      className='a'
      fill='#393946'
      d='M898.517-162.8l-.517.054v6.481l.422.1a8.59,8.59,0,0,0,2.778-.068,2.553,2.553,0,0,0,1.688-1.865,4.568,4.568,0,0,0-.654-3.99C901.5-162.828,900.546-163.005,898.517-162.8Zm2.7,1.416a3.549,3.549,0,0,1,.068,3.7c-.327.354-.463.408-1.185.422l-.8.014-.041-2.11a20.924,20.924,0,0,1,.041-2.287C899.416-161.957,900.805-161.766,901.213-161.385Z'
      transform='translate(-774.532 -420.794)'
    />
    <path
      className='a'
      fill='#393946'
      d='M683-158.732v3.268h4.085v-1.076l-1.389-.041-1.4-.041-.041-.858-.041-.844h2.328v-1.089h-2.328l.041-.722.041-.708,1.4-.041,1.389-.041V-162H683Z'
      transform='translate(-588.807 -421.554)'
    />
    <path
      className='a'
      fill='#393946'
      d='M754-161.455v.531l.858.041.844.041.041,2.682.027,2.7H757v-5.446h1.77V-162H754Z'
      transform='translate(-650.139 -421.554)'
    />
    <path
      className='a'
      fill='#393946'
      d='M793-158.732v3.268h1.226V-162H793Z'
      transform='translate(-683.829 -421.554)'
    />
    <path
      className='a'
      fill='#393946'
      d='M810-158.732v3.268h1.225v-2.723h2.451v-1.089h-2.465l.041-.79.041-.776,1.471-.041,1.457-.041V-162H810Z'
      transform='translate(-698.514 -421.554)'
    />
    <path
      className='a'
      fill='#393946'
      d='M846-158.732v3.268h1.225V-162H846Z'
      transform='translate(-729.613 -421.554)'
    />
    <path
      className='a'
      fill='#393946'
      d='M863-158.732v3.268h4.085v-1.076l-1.389-.041-1.4-.041-.041-.858-.041-.844h2.328v-1.089h-2.328l.041-.722.041-.708,1.4-.041,1.389-.041V-162H863Z'
      transform='translate(-744.298 -421.554)'
    />
  </g>
)
