import React from 'react'
import { withTheme } from 'styled-components/macro'
import MediaQuery from 'react-responsive'

import Icon from '../Icon/Icon'

import { StyledAction } from './style'
import { useMediaQuery } from 'react-responsive'
import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
} from 'Shared/constants'
import { arrowLeftIconPath } from 'Shared/Icons/LocationInfo/paths'
import { isLocalResourcesMap, isLocalResourcesList } from 'Shared/helpers'

const ActionToggle = (props) => {
  const {
    activeIconPath,
    activeLabel,
    className,
    handleOnClick,
    inactiveIconPath,
    inactiveLabel,
    minWidth,
    opacity,
    showActive,
  } = props

  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const isTablet = useMediaQuery({ query: TABLET_MEDIA_QUERY })

  const mobileAndTabletRenderFillColor = () => {
    const { theme, showActive } = props
    const { opened } = '#000'
    const { alt } = theme.text

    return showActive ? opened : alt
  }

  const desktopRenderFillColor = () => {
    const { theme, showActive } = props
    const { opened, closed } = theme.menuToggle.fill

    return showActive ? opened : closed
  }

  const renderActionIconPath = () => {
    return showActive ? activeIconPath : inactiveIconPath
  }

  const renderActionLabel = () => {
    return showActive ? activeLabel : inactiveLabel
  }

  const renderActionToggle = (renderFillColor) => (
    <StyledAction
      onClick={handleOnClick}
      opacity={opacity}
      activeFilter={showActive}
      minWidth={minWidth}
      className={className}
      filterinmobile={props.filterinmobile}
      topMob={props.topMob}
      rightMob={props.rightMob}
      leftMob={props.leftMob}
      topTab={props.topTab}
      rightTab={props.rightTab}
      leftTab={props.leftTab}
      background={props.backgroundAction}
      justCont={props.justContAction || 'center'}
    >
      <Icon
        fill={props.fillIcon || renderFillColor()}
        scale={isMobile || isTablet ? '1.8rem' : '1.5rem'}
        viewBox={'0 0 24 24'}
        screenReaderLabel={renderActionLabel()}
        cursorOnHover={'pointer'}
      >
        {renderActionIconPath()}
      </Icon>
      {props.children}
    </StyledAction>
  )

  const renderActionToggleForLocationDetails = (renderFillColor) => (
    <StyledAction
      onClick={handleOnClick}
      opacity={opacity}
      activeFilter={showActive}
      minWidth={minWidth}
      className={className}
      padding={'unset'}
      fontSize={isMobile ? '1.25rem' : ''}
      flex={'auto'}
      color={'#000'}
      background={'unset'}
    >
      <Icon
        fill={renderFillColor()}
        scale={isMobile ? '2rem' : '1.6rem'}
        viewBox={'0 0 24 24'}
        screenReaderLabel={'Back'}
        cursorOnHover={'pointer'}
      >
        {arrowLeftIconPath}
      </Icon>
      Back
    </StyledAction>
  )

  return (
    <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
      {(matches) => {
        const renderFillColor = matches
          ? mobileAndTabletRenderFillColor
          : desktopRenderFillColor

        return isLocalResourcesList() || isLocalResourcesMap()
          ? renderActionToggle(renderFillColor)
          : renderActionToggleForLocationDetails(renderFillColor)
      }}
    </MediaQuery>
  )
}

export default withTheme(ActionToggle)
