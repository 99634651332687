import React from 'react'
import {
  StyledDesktopAndTabletTopNavMenuItem,
  StyledDesktopAndTabletExternalLink,
} from './style'

const TabletAndDesktopTopNavLink = ({
  activelink,
  attr,
  color,
  externalUrl,
  fontSize,
  onClickHandler,
  path,
  text,
}) => {
  if (externalUrl) {
    return (
      <StyledDesktopAndTabletExternalLink
        color={color}
        fontSize={fontSize}
        href={externalUrl}
        target='_blank'>
        {text}
      </StyledDesktopAndTabletExternalLink>
    )
  }

  return (
    <StyledDesktopAndTabletTopNavMenuItem
      {...attr}
      activelink={activelink ? 'active' : 'inactive'}
      color={color}
      fontSize={fontSize}
      onClick={(e) => onClickHandler && onClickHandler(e)}
      to={path}>
      {text}
    </StyledDesktopAndTabletTopNavMenuItem>
  )
}

export default TabletAndDesktopTopNavLink
