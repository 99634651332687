import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks/index'
import {
  Field,
  reduxForm,
  change as reduxFormChange,
  SubmissionError,
} from 'redux-form'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { LOG_IN_FORM_NAME } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import Error from 'Components/Shared/Error/Error'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import {
  StyledDynamicCtaButton,
  StyledForm,
  StyledHeadingMain,
  StyledParagraph,
  StyledPrivacyTermsLoginPage,
} from 'Components/Screener/style'
import { StyledLink } from './style'
import { preScreenerSelector } from 'Selectors'
import useOtp from 'Components/Auth/Hooks/useOtp'
import { parseEmailOrPhone } from 'Components/PreScreener/helpers'
import { USER_NOT_FOUND_ERROR, ERROR_GENERIC_MESSAGE } from 'Shared/constants'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import theme, { ssTheme } from 'Shared/Theme/ssTheme'
import { useNavigate } from 'react-router-dom'

const Login = ({ error, handleSubmit }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const registrationUrl = useDynamicRegistrationUrl()

  const { handleRequestOtp } = useOtp()

  const emailOrPhone = preScreenerSelector(
    useSelector((state) => state),
    'emailOrPhone'
  )

  const onSubmit = ({ emailOrPhone }) => {
    return handleRequestOtp(parseEmailOrPhone({ emailOrPhone })).then(
      ({
        data: {
          userRequestOtp: { errors },
        },
      }) => {
        if (!errors.length) {
          navigate('/authenticate')
        } else {
          const errorMessage =
            errors[0] === 'USER_NOT_FOUND'
              ? USER_NOT_FOUND_ERROR
              : ERROR_GENERIC_MESSAGE

          if (errors[0] === 'USER_UNVERIFIED') navigate('/zip-code-validation')
          throw new SubmissionError({ emailOrPhone: errorMessage })
        }
      }
    )
  }

  const populateLogin = () =>
    dispatch(reduxFormChange(LOG_IN_FORM_NAME, 'emailOrPhone', emailOrPhone))

  useEffect(() => {
    populateLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderWithContainerAndCard>
      <Container>
        <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <StyledHeadingMain textalign='center' id='mainContent'>
            Log in to your account
          </StyledHeadingMain>
          <StyledHouseholdMembersListItem>
            <MuiThemeProvider theme={ssTheme}>
              <StyledHouseholdMembersList>
                <Row>
                  <Col lg='12'>
                    <StyledMuiTextFieldWrapper
                      className='cypress-email-or-phone'
                      width='50%'
                      marginRight='auto'
                      marginLeft='auto'
                    >
                      <Field
                        name='emailOrPhone'
                        type='text'
                        component={TextField}
                        label='Email or Mobile Phone Number'
                        normalize={formatters.lowercase}
                      />
                      {error && <Error error={error} />}
                    </StyledMuiTextFieldWrapper>
                    <StyledParagraph textalign='center'>
                      Don't have an account?{' '}
                      <StyledLink
                        loginpage={1}
                        fontSize='1.2rem'
                        to={registrationUrl}
                      >
                        Register here
                      </StyledLink>
                    </StyledParagraph>
                  </Col>
                </Row>
              </StyledHouseholdMembersList>
            </MuiThemeProvider>
          </StyledHouseholdMembersListItem>
          <StyledHouseholdMembersList textalign='center'>
            <Row>
              <Col lg='12'>
                <StyledDynamicCtaButton width='50%' type='submit'>
                  Log in
                </StyledDynamicCtaButton>
              </Col>
            </Row>
          </StyledHouseholdMembersList>
          <StyledParagraph textalign='center' marginTop='3rem'>
            Single Stop Case Manager?{' '}
            <StyledLink
              to='/case-management/login'
              color={theme.palette.text.default}
              fontSize='1.2rem'
              fontWeight='500'
            >
              Log in here
            </StyledLink>
          </StyledParagraph>
          <StyledPrivacyTermsLoginPage>
            By logging in to your account, you agree to our{' '}
            <StyledLink to='/privacy-policy' target='_blank'>
              Privacy Policy
            </StyledLink>{' '}
            and{' '}
            <StyledLink to='/terms-and-conditions' target='_blank'>
              Terms and Conditions.
            </StyledLink>
          </StyledPrivacyTermsLoginPage>
        </StyledForm>
      </Container>
    </HeaderWithContainerAndCard>
  )
}

export default reduxForm({
  form: LOG_IN_FORM_NAME,
  validate: validators.emailOrPhone,
  onSubmitFail: setFocusOnFirstError,
  destroyOnUnmount: false,
})(Login)
