import { SubmissionError } from 'redux-form'
import {
  ERROR_GENERIC_MESSAGE,
  ERROR_LOCKED_ACCOUNT,
  ERROR_MISSING_MAIL_PHONE,
  ERROR_VALIDATION_FAIL,
} from 'Shared/constants'
import { parseEmailOrPhone } from 'Components/PreScreener/helpers'
import { removeEmptyProperties } from 'Components/CaseManagement/Reporting/Hooks/helpers'
import { ValidateUnverifiedClientPayload } from 'generated/graphql'

export type SubmitHandlerArgs = {
  emailOrPhone: string | undefined
  validateUnverifiedClient: Function
  navigate: Function
}

interface SubmitHandlerResponse {
  data: {
    validateUnverifiedClient: ValidateUnverifiedClientPayload
  }
}

export const submitHandler =
  ({ emailOrPhone, validateUnverifiedClient, navigate }: SubmitHandlerArgs) =>
  (values: { [key: string]: string }) => {
    const zipCode = values?.zipCode
    const { email, primaryPhone: phoneNumber } = parseEmailOrPhone({
      emailOrPhone,
    })
    const variables = removeEmptyProperties({ email, phoneNumber, zipCode })

    if (email || phoneNumber) {
      return validateUnverifiedClient({
        variables,
      })
        .then((response: SubmitHandlerResponse) => {
          const success = response?.data?.validateUnverifiedClient?.success
          if (success) {
            navigate('/authenticate')
          } else {
            const responseError =
              response?.data?.validateUnverifiedClient?.errors?.[0]
            let displayError = ''
            switch (responseError) {
              case 'INVALID_ZIP':
                displayError = ERROR_VALIDATION_FAIL
                break
              case 'TOO_MANY_ATTEMPTS':
                displayError = ERROR_LOCKED_ACCOUNT
                break
              default:
                displayError = ERROR_GENERIC_MESSAGE
                break
            }
            throw displayError
          }
        })
        .catch((error: string) => {
          throw new SubmissionError({
            zipCode: error,
          })
        })
    } else {
      throw new SubmissionError({
        zipCode: ERROR_MISSING_MAIL_PHONE,
      })
    }
  }
