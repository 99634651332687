import React, { useEffect, useRef } from 'react'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import MainFooter from 'Components/Shared/PageFooter/Footer'
import { Container } from 'styled-bootstrap-grid'
import { StyledLegalContainer } from './style'
import {
  StyledSubHeading,
  StyledParagraph,
  StyledHeadingMain,
} from 'Components/Screener/style'

// CSS styling based off https://wordhtml.com/ DOCX to HTML conversion
const PrivacyPolicy = () => {
  useEffect(() => {
    scrollToTop()
  }, [])
  const scrollRef = useRef()
  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <HeaderWithContainer overflow={'hidden'}>
      <Container ref={scrollRef}>
        <StyledHeadingMain
          textalign='center'
          marginBottom='0'
          padding='2rem 0'
          border={'border-bottom: 1px solid #ddd'}>
          Single Stop Web Application Privacy Policy
        </StyledHeadingMain>
        <StyledLegalContainer width='80%' margin='auto'>
          <StyledParagraph privacyTerms>
            <em>
              <span data-contrast='auto'>
                You are important to us, and your privacy is also important to
                us. This policy tells you how&nbsp;
              </span>
              <span data-contrast='auto'>Single Stop</span>
              <span data-contrast='auto'>&nbsp;USA INC</span>
              <span data-contrast='auto'>&nbsp;</span>
              <span data-contrast='auto'>referred to as either&nbsp;</span>
              <span data-contrast='auto'>&ldquo;Single Stop&rdquo;,&nbsp;</span>
              <span data-contrast='auto'>
                "the Company", "We", "Us" or "Our" in this Agreement
              </span>
              <span data-contrast='auto'>&nbsp;</span>
              <span data-contrast='auto'>
                uses and protects your personal information
              </span>
              <span data-contrast='auto'>
                &nbsp;stored within the Single Stop
              </span>
              <span data-contrast='auto'>&trade;</span>
              <span data-contrast='auto'>&nbsp;</span>
              <span data-contrast='auto'>Site&nbsp;</span>
              <span data-contrast='auto'>(</span>
              <a href='https://app.singlestop.org/'>
                <span data-contrast='none'>
                  <span data-ccp-charstyle='Hyperlink'>
                    https://app.singlestop.org
                  </span>
                </span>
              </a>
              <span data-contrast='auto'>)</span>
              <span data-contrast='auto'>&nbsp;hereinafter &ldquo;</span>
              <span data-contrast='auto'>T</span>
              <span data-contrast='auto'>he&nbsp;</span>
              <span data-contrast='auto'>S</span>
              <span data-contrast='auto'>ite</span>
              <span data-contrast='auto'>&rdquo; or &ldquo;Our Site</span>
              <span data-contrast='auto'>&rdquo;</span>
              <span data-contrast='auto'>. </span>
              <span data-contrast='auto'>
                Please read the following carefully to understand our policies
                and practices regarding your&nbsp;
              </span>
              <span data-contrast='auto'>P</span>
              <span data-contrast='auto'>ersonal&nbsp;</span>
              <span data-contrast='auto'>D</span>
              <span data-contrast='auto'>
                ata, how we use it, protect it, and your options.&nbsp;
              </span>
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
              <span data-contrast='auto'>
                We use Your Personal data to provide and improve services
                provided by the Site. By using The Site,&nbsp;
              </span>
              <span data-contrast='auto'>You</span>
              <span data-contrast='auto'>
                &nbsp;agree to the collection and use of information in
                accordance with this Privacy Policy.
              </span>
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </em>
          </StyledParagraph>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              About Single Stop
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  We are a non-profit organization that helps people identify
                  and obtain benefits to which they may be lawfully entitled. We
                  are not the government. We are not a healthcare provider or
                  insurance company. We are not a money lender, credit provider,
                  or credit rating agency. 
                </span>
                <strong>
                  <span data-contrast='auto'>
                    We do not provide funds or benefits, or guarantee
                    eligibility for, or receipt of, any benefits.
                  </span>
                </strong>
                <span data-contrast='auto'>
                   We do not have the final say on whether you are eligible for
                  benefits. We are not the actual source or provider of any
                  benefits to which you may be entitled.   
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Why do you need my information?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Single Stop asks you for information in order to screen you
                  for benefits for which you can apply. We may also ask you for
                  information needed&nbsp;
                </span>
                <span data-contrast='auto'>for</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  case workers to better provide you with assistance.  We may
                  ask you for information in the following categories:&nbsp;
                </span>
                <span data-contrast='auto'>demographics,&nbsp;</span>
                <span data-contrast='auto'>
                  household, finances, work, education, military service,
                  health,&nbsp;
                </span>
                <span data-contrast='auto'>taxes&nbsp;</span>
                <span data-contrast='auto'>
                  and citizenship. It is voluntary for you to share your
                  personal information, but without&nbsp;
                </span>
                <span data-contrast='auto'>sufficient</span>
                <span data-contrast='auto'>&nbsp;information</span>
                <span data-contrast='auto'>, we&nbsp;</span>
                <span data-contrast='auto'>may not be able to</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  screen your eligibility for benefits.&nbsp;
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>By using&nbsp;</span>
                  <span data-contrast='auto'>T</span>
                  <span data-contrast='auto'>he&nbsp;</span>
                  <span data-contrast='auto'>S</span>
                  <span data-contrast='auto'>
                    ite, you are consenting to us, or any party acting on our
                    behalf&nbsp;
                  </span>
                  <span data-contrast='auto'>to</span>
                  <span data-contrast='auto'>&nbsp;collect such data.</span>
                  <span data-contrast='auto'> </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              How does this work?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>We use your&nbsp;</span>
                <span data-contrast='auto'>Personal Data</span>
                <span data-contrast='auto'>
                   in order to look through our list of top government benefits
                  and find estimates of benefits or services that might be a
                  match for you. These are benefits&nbsp;
                </span>
                <span data-contrast='auto'>for which&nbsp;</span>
                <span data-contrast='auto'>
                  you may be able to apply. We look for federal and state
                  benefits, including, but not limited to
                </span>
                <span data-contrast='auto'>,</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  SNAP (Supplemental Nutrition Assistance Program), WIC (Women,
                  Infants, and Children), Medicaid for Adults Under 65 and
                  Children, the Children&rsquo;s Health Insurance Program
                  (CHIP), subsidized Qualified Health Plans, Emergency Medicaid,
                  the Earned Income Tax Credit, the Child Tax Credit, Free Tax
                  Prep, and Lifetime Learning Credits.
                </span>
                <span data-contrast='auto'>
                  &nbsp; Available benefits information may change at any time
                  subject to changes in applicable law or regulation&nbsp;
                </span>
                <span data-contrast='auto'>in particular&nbsp;</span>
                <span data-contrast='auto'>jurisdictions</span>
                <span data-contrast='auto'>.</span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Do you do anything else with my personal information?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>W</span>
                <span data-contrast='auto'>
                  e save your personal information as a&nbsp;
                </span>
                <span data-contrast='auto'>digital&nbsp;</span>
                <span data-contrast='auto'>
                  client file so that you can return to Single Stop
                  offices&nbsp;
                </span>
                <span data-contrast='auto'>or partners&nbsp;</span>
                <span data-contrast='auto'>
                  and talk to case managers about your needs, including checking
                  up on your benefits or looking for additional support.   
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;may use Personal Data for the following purposes:
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
            <ul>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='1'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      To provide and maintain Our Site
                    </span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>,&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    including to monitor the usage of Our Site.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='2'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      To manage&nbsp;
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>y</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>our Account</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>,&nbsp;</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>and</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;to manage&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>y</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    our registration as a user of The&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>Site</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    . The Personal Data&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>y</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    ou provide can give You access to different functionalities
                    of The Site that are available to You as a registered user.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
            </ul>
            <ul>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='1'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>To&nbsp;</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>analyze</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;and evaluate our performance as a nonprofit
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>,</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>look</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>ing</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;at how much we have helped clients and how we can help
                    you better. We do not study you as an individual, but
                    we&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>analyze</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    all our clients as an aggregated group, in which the&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>P</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>ersonal&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>Data&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    (names, email and addresses, etc.) have been anonymized.
                    This anonymized and aggregated data helps us improve our
                    services to you and helps us report on our productivity to
                    our funders
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;and partners
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>.</span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='2'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>To contact You</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>,&nbsp;</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    by email, telephone calls, SMS, or other equivalent forms of
                    electronic communication, such as a mobile application's
                    push notifications regarding updates or informative
                    communications related to the functionalities, products or
                    services
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='3'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>To provide You</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;with news, and general information about other goods,
                    services and events which we offer that are&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>similar to</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;those that you have already enquired about unless You
                    have opted not to receive such information.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='4'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      To manage&nbsp;
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      and respond to&nbsp;
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Your requests</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    that are made to&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>Us.</span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
            </ul>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>
                    We may share your personal information in the following
                    situations:
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
            <ul>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='1'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      With Service Providers:
                    </span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;We may share&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>y</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>our&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>Personal Data</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;with Service Providers to monitor and analyze the use
                    of our&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>Site</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    , to contact You.
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;These service providers are required to have strong
                    access controls and to only use your information for the
                    intended purpose.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='2'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      For Business&nbsp;
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>T</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>ransfers:</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;We may share or transfer Your personal information in
                    connection with, or during negotiations of, any merger, sale
                    of Company assets, financing, or acquisition of all or a
                    portion of our business to another company.
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;We will provide notice before Your Personal Data is
                    transferred and becomes subject to a different Privacy
                    Policy.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='3'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      With Affiliates:
                    </span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;We may share Your information with Our affiliates, in
                    which case we will require those affiliates to honor this
                    Privacy Policy. Affiliates include Our parent company and
                    any other subsidiaries, joint venture partners or other
                    companies that We control or that are under common control
                    with Us.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='4'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      With Business partners:
                    </span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;We may share Your information with Our business
                    partners to offer You certain products
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>&nbsp;or&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>services.&nbsp;</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    These business partners are required to have strong access
                    controls and to only use your information for the intended
                    purpose.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='5'
                data-aria-level='1'>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>With&nbsp;</span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      Law enforcement
                    </span>
                  </span>
                </strong>
                <strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>:&nbsp;</span>
                  </span>
                </strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    Under certain circumstances,&nbsp;
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>We</span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;may be required to disclose Your Personal Data if
                    required to do so by law or in response to valid requests by
                    public authorities (e.g. a court or a government agency).
                  </span>
                </span>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    &nbsp;which may include a search warrant, national security
                    letter or other law enforcement request.
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
            </ul>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>Other legal requirements</span>
                  <span data-ccp-props='{"201341983":0,"335559731":360,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Single Stop may disclose Your Personal Data in the good faith
                  belief that such action is necessary to:
                </span>
                <span data-ccp-props='{"201341983":0,"335559685":360,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <ul>
              <li>
                <span data-contrast='auto'>
                  Comply with a legal obligation&nbsp;
                </span>
                <span data-contrast='auto'>
                  such as an administrative order, a court order or
                  subpoena&nbsp;
                </span>
              </li>
              <li>
                <span data-contrast='auto'>
                  Protect and defend the rights or property of Single Stop
                </span>
              </li>
              <li>
                <span data-contrast='auto'>
                  Prevent or investigate possible wrongdoing in connection with
                  the Site
                </span>
              </li>
              <li>
                <span data-contrast='auto'>
                  Protect the personal safety of Users of the Site or the public
                </span>
              </li>
              <li>
                <span data-contrast='auto'>
                  Protect against legal liability
                </span>
              </li>
            </ul>
          </StyledLegalContainer>

          <StyledParagraph privacyTerms>
            <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":360,"335559739":200,"335559740":276,"335559991":360,"469777462":[360],"469777927":[0],"469777928":[8]}'></span>
          </StyledParagraph>
          <StyledParagraph privacyTerms>
            <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":360,"335559739":200,"335559740":276,"335559991":360,"469777462":[360],"469777927":[0],"469777928":[8]}'></span>
          </StyledParagraph>
          <StyledParagraph privacyTerms>
            <em>
              <strong>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    Please note we do not sell any of your Personal Data for any
                    purpose.;
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":360,"335559739":200,"335559740":276,"335559991":360,"469777462":[360],"469777927":[0],"469777928":[8]}'></span>
              </strong>
            </em>
          </StyledParagraph>
          <StyledParagraph privacyTerms>
            <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":360,"335559739":200,"335559740":276,"335559991":360,"469777462":[360],"469777927":[0],"469777928":[8]}'></span>
          </StyledParagraph>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Do you collect any other information about me?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>

            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Usage Data is collected automatically when using&nbsp;
                </span>
                <span data-contrast='auto'>The</span>
                <span data-contrast='auto'>
                  &nbsp;Site. Usage Data may include information such as Your
                  Device's Internet Protocol address (e.g. IP address), browser
                  type, browser version, the pages of The Site that You visit,
                  the time and date of Your visit, the time spent on those
                  pages, unique device identifiers and other diagnostic data.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  When You access The Site by or through a mobile device, We may
                  collect certain information automatically, including, but not
                  limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your
                  mobile operating system, the type of Internet browser You use,
                  unique device identifiers and other diagnostic data.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  We may also collect information that Your browser sends
                  whenever You visit&nbsp;
                </span>
                <span data-contrast='auto'>Our Site</span>
                <span data-contrast='auto'>&nbsp;or when You access&nbsp;</span>
                <span data-contrast='auto'>T</span>
                <span data-contrast='auto'>he&nbsp;</span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>
                  &nbsp;by or through a mobile device.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>
                    While using Our Site, we may collect, with your prior
                    permission:
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
            <ul>
              <li
                data-leveltext=''
                data-font='Symbol'
                data-listid='2'
                data-aria-posinset='1'
                data-aria-level='1'>
                <span data-contrast='auto'>
                  <span data-ccp-parastyle='List Bullet'>
                    Information regarding your location
                  </span>
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                  &nbsp;
                </span>
              </li>
            </ul>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  We use this information to provide features of Our&nbsp;
                </span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>
                  , to improve and customize Our&nbsp;
                </span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>,</span>
                <span data-contrast='auto'>&nbsp;and to provide&nbsp;</span>
                <span data-contrast='auto'>You with in</span>
                <span data-contrast='auto'>form</span>
                <span data-contrast='auto'>ation</span>
                <span data-contrast='auto'>
                  &nbsp;about services available in your area
                </span>
                <span data-contrast='auto'>
                  . The information may be uploaded to&nbsp;
                </span>
                <span data-contrast='auto'>Our&nbsp;</span>
                <span data-contrast='auto'>
                  servers and/or a Service Provider's server or it may be simply
                  stored on Your device.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  You can enable or disable access to this information at any
                  time, through Your Device settings.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>We</span>
                <span data-contrast='auto'>&nbsp;also use</span>
                <span data-contrast='auto'>&nbsp;cookie</span>
                <span data-contrast='auto'>s</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>which are&nbsp;</span>
                <span data-contrast='auto'>file</span>
                <span data-contrast='auto'>s</span>
                <span data-contrast='auto'>&nbsp;stored on&nbsp;</span>
                <span data-contrast='auto'>your Device</span>
                <span data-contrast='auto'>
                  . Cookies enable us to improve our site and to deliver a
                  better and more personalized service.&nbsp;
                </span>
                <span data-contrast='auto'>
                  You may refuse to accept cookies by activating the setting on
                  your browser which allows you to refuse the setting of
                  cookies. However, if you select this setting you may be unable
                  to access certain parts of our site and/or your user
                  experience when using our website might be affected. Unless
                  you have adjusted your browser setting so that it will refuse
                  cookies, our system will issue cookies when you visit our
                  site.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Where does my personal information go? 
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Your personal information&nbsp;
                </span>
                <span data-contrast='auto'>is stored in</span>
                <span data-contrast='auto'>&nbsp;our secure database</span>
                <span data-contrast='auto'>s</span>
                <span data-contrast='auto'>
                  . It is saved into the system each time you advance to the
                  next page/step.&nbsp;
                </span>
                <span data-contrast='auto'>We</span>
                <span data-contrast='auto'>
                  &nbsp;save your information&nbsp;
                </span>
                <span data-contrast='auto'>
                  as you enter it, this allows us to help you continue where you
                  left off in your next session
                </span>
                <span data-contrast='auto'>
                  . We do not save your personal information on any standalone
                  computers. Our database is stored in computer servers operated
                  by companies that specialize in safely storing data. They are
                  called cloud storage companies. We work with these companies
                  to safeguard&nbsp;
                </span>
                <span data-contrast='auto'>your Personal Data</span>
                <span data-contrast='auto'>
                  &nbsp; in keeping with prevailing industry practices and
                  requirements.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Please note that Your information, including Personal Data, is
                  processed at&nbsp;
                </span>
                <span data-contrast='auto'>Single Stop</span>
                <span data-contrast='auto'>
                  's operating offices and in any other places where the parties
                  involved in the processing are located. It means that this
                  information may be transferred to &mdash; and maintained on
                  &mdash; computers located outside of Your state, province,
                  country or other governmental jurisdiction where the data
                  protection laws may differ than those from Your jurisdiction.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Your consent to this Privacy Policy followed by Your
                  submission of such information represents Your agreement to
                  that transfer.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>Single Stop</span>
                <span data-contrast='auto'>
                  &nbsp;will take all steps reasonably necessary to ensure that
                  Your data is treated securely and in accordance with this
                  Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are
                  adequate controls in place including the security of Your data
                  and other personal information.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Who is looking at&nbsp; my information ?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Some of our staff have permission to look at information in
                  the database in order to do their Single Stop work. They can
                  do so from any computer using a protected login and password.
                  We have a lot of rules for our employees and partners about
                  how to protect access to our database and how to use the
                  database.  Also, case workers
                </span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  are looking at your information to provide you with assistance
                  in accessing benefits and services. Your information is made
                  available to a case worker when they make a referral to a
                  provider. These case workers will reach out to you to schedule
                  an appointment.  You have no obligation to meet with them. 
                  Your information is only shared with the case workers at
                  locations whose&nbsp;
                </span>
                <span data-contrast='auto'>URL</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  (website) you have entered through or who you have referred
                  yourself to.   
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Why do you need my contact information?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  This information is needed so&nbsp;
                </span>
                <span data-contrast='auto'>
                  that we can provide you with access to your&nbsp;
                </span>
                <span data-contrast='auto'>A</span>
                <span data-contrast='auto'>
                  ccount by sending you an e-mail or text to your preferred
                  method of contact. This&nbsp;
                </span>
                <span data-contrast='auto'>is how&nbsp;</span>
                <span data-contrast='auto'>
                  we verify it is you trying to access your information. C
                </span>
                <span data-contrast='auto'>ase workers can </span>
                <span data-contrast='auto'>also&nbsp;</span>
                <span data-contrast='auto'>
                  reach out to you to set up an appointment.  We will also send
                  you follow-up information on your&nbsp;
                </span>
                <span data-contrast='auto'>A</span>
                <span data-contrast='auto'>
                  ccount and on your appointment
                  through the communication method (phone or email) you have
                  entered.  Single Stop may also use this information to send
                  you reminders about your benefits and to inform you of any
                  important information about rules or policy updates that may
                  impact you.  Finally, Single Stop may reach out to let you
                  know of additional services being offered that you may be
                  interested in.   
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Can I remove my information?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>You can close your&nbsp;</span>
                <span data-contrast='auto'>A</span>
                <span data-contrast='auto'>
                  ccount at any time, so that you will no longer be an active
                  Single Stop client. This does not remove your information from
                  our database, where it stays as a record of how we helped you.
                  If you want to delete your file from our database, please
                  email sssupport@singlestop.org, and we will remove as much of
                  your personal information as possible to the best of our
                  ability. Once you remove your information, Single Stop will no
                  longer be able to assist you with the online screening
                  process. 
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  You may remove our cookies by accessing your
                  browser&rsquo;s settings which will allow you to delete
                  cookies. For more information on how to delete cookies from
                  your browser, see: 
                </span>
                <a href='https://www.lifewire.com/how-to-delete-cookies-2617981'>
                  <span data-contrast='none'>
                    <span data-ccp-charstyle='Hyperlink'>
                      https://www.lifewire.com/how-to-delete-cookies-2617981
                    </span>
                  </span>
                </a>
                <span data-contrast='auto'>.  </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Are there age restrictions for using this technology? 
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Single Stop wants this technology to be as accessible as
                  possible to those people that need it.  We do not advertise
                  this technology to anyone under the age of 13.  The technology
                  is not directed at children under the age of 13. 
                </span>
                <span data-contrast='auto'>
                  Users who enter an age under 13 are not allowed to proceed
                  further on the&nbsp;
                </span>
                <span data-contrast='auto'>S</span>
                <span data-contrast='auto'>ite.</span>
                <span data-contrast='auto'> </span>
                <span data-contrast='auto'>
                  Adults entering children as household members must&nbsp;
                </span>
                <span data-contrast='auto'>
                  first seek parental or guardian consent before entering&nbsp;
                </span>
                <span data-contrast='auto'>information f</span>
                <span data-contrast='auto'>
                  or children under the age of 13.&nbsp;
                </span>
                <span data-contrast='auto'>In the event that</span>
                <span data-contrast='auto'>
                   we learn that we have collected personal information from a
                  child under the age of 13 without parental consent, we will
                  delete&nbsp;
                </span>
                <span data-contrast='auto'>
                  that information as quickly as possible.  If you believe that
                  we might have any information from a child under the age of
                  13, please contact us at sssupport@singlestop.org. 
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Why are there so many questions about me? 
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Sometimes, an answer to one question tells us that you might
                  be able to get other kinds of benefits. This may prompt you to
                  answer some additional questions, which indicates that we
                  are trying to determine if you qualify for additional
                  benefits. 
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Do you follow me online?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  No, this website does not use online tracking. We do not sell
                  any information about your online behavior or track any
                  information about individuals through social media. We do not
                  let third parties collect visitors&rsquo; information from our
                  website. We do not share information about what happens on our
                  website except to work on the software and Single Stop program
                  and make it better. 
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              How long do you keep my data?
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>Single Stop</span>
                <span data-contrast='auto'>
                  &nbsp;will retain Your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy
                </span>
                <span data-contrast='auto'>&nbsp;or regulation</span>
                <span data-contrast='auto'>.&nbsp;</span>
                <span data-contrast='auto'>
                  We will retain and use Your Personal Data to the extent
                  necessary to comply with our legal obligations (for example,
                  if we are req
                </span>
                <span data-contrast='auto'>
                  uired to retain your data to comply with applicable laws or
                  valid subpoenas or regulatory inquiry), resolve disputes, and
                  enforce our legal agreements and policies
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>Single Stop</span>
                <span data-contrast='auto'>
                  &nbsp;will also retain Usage Data for internal analysis
                  purposes. Usage Data is generally retained for a shorter&nbsp;
                </span>
                <span data-contrast='auto'>period of time</span>
                <span data-contrast='auto'>
                  , except when this data is used to strengthen the security or
                  to improve the functionality of Our&nbsp;
                </span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>
                  , or We are legally obligated to retain this data for longer
                  time periods.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Security of Your Personal Data
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  The security of Your Personal Data is important to Us,&nbsp;
                </span>
                <span data-contrast='auto'>We</span>
                <span data-contrast='auto'>&nbsp;us</span>
                <span data-contrast='none'>e several layered se</span>
                <span data-contrast='auto'>
                  curity measures, designed to protect our&nbsp;
                </span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>&nbsp;</span>
                <span data-contrast='auto'>
                  and your information from loss, theft, misuse, and
                  unauthorized access, disclosure, alteration, and
                  destruction.&nbsp;
                </span>
                <span data-contrast='auto'>However, you acknowledge&nbsp;</span>
                <span data-contrast='auto'>
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While We strive to use
                  commercially acceptable means to protect Your Personal
                  Data,&nbsp;
                </span>
                <span data-contrast='auto'>We</span>
                <span data-contrast='auto'>
                  &nbsp;cannot guarantee its absolute security.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'></span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'></span>
            </StyledParagraph>

            <StyledSubHeading
              marginBottom='1rem'
              fontWeight='700'
              fontSize='1rem'
              data-contrast='none'>
              <em>
                <span data-ccp-parastyle='heading 2'>
                  Detailed Information on the Processing of Your Personal Data
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Service Providers have access to Your Personal Data only to
                  perform their tasks on Our behalf and are obligated not to
                  disclose or use it for any other purpose.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>Analytics:&nbsp; </span>
                </strong>
                <span data-contrast='auto'>
                  We may use third-party Service providers to monitor and
                  analyze the use of our&nbsp;
                </span>
                <span data-contrast='auto'>Site</span>
                <span data-contrast='auto'>.</span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>

            <StyledSubHeading
              marginBottom='1rem'
              fontWeight='700'
              fontSize='1rem'
              data-contrast='none'>
              <em>
                <span data-ccp-parastyle='heading 2'>Google Analytics</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                  &nbsp;
                </span>
              </em>
            </StyledSubHeading>

            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualize and personalize the ads of its
                  own advertising network.
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  You may opt-out of certain Google Analytics features through
                  your mobile device settings, such as your device advertising
                  settings or by following the instructions provided by Google
                  in their Privacy Policy:&nbsp;
                </span>
                <a href='https://policies.google.com/privacy'>
                  <span data-contrast='auto'>
                    https://policies.google.com/privacy
                  </span>
                </a>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <strong>
                <em>
                  <span data-contrast='auto'>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy &amp; Terms web page:&nbsp;
                  </span>
                  <a href='https://policies.google.com/privacy'>
                    <span data-contrast='auto'>
                      https://policies.google.com/privacy
                    </span>
                  </a>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </strong>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Email Communication
              <span data-ccp-props='{"201341983":0,"335559738":200,"335559739":0,"335559740":276}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  We may use Email Marketing Service Providers to manage and
                  send emails to You.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>

          <StyledLegalContainer>
            <StyledSubHeading
              marginBottom='1.25rem'
              fontWeight='700'
              fontSize='1.5rem'
              data-contrast='auto'>
              Twilio
              <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                &nbsp;
              </span>
            </StyledSubHeading>
            <StyledParagraph privacyTerms>
              <em>
                <span data-contrast='auto'>
                  Their Privacy Policy can be viewed at&nbsp;
                </span>
                <a href='https://www.twilio.com/legal/privacy'>
                  <span data-contrast='auto'>
                    https://www.twilio.com/legal/privacy
                  </span>
                </a>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                  &nbsp;
                </span>
              </em>
            </StyledParagraph>

            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>
                    You may opt-out of receiving any, or all, of these
                    communications from Us by following the unsubscribe link or
                    instructions provided in any email We send or by contacting
                    Us.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                General Data Protection Regulation (GDPR) Privacy
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    Legal Basis for Processing Personal Data under GDPR
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      We may process Personal Data under the following
                      conditions:
                    </span>
                    <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>Consent:</span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You have given Your consent for processing Personal
                      Data for one or more specific purposes.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Performance of a contract:
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;Provision of Personal Data is necessary for the
                      performance of an agreement with You and/or for any
                      pre-contractual obligations thereof.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='2'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Legal obligations:
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;Processing Personal Data is necessary for compliance
                      with a legal obligation to which&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Single Stop</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;is subject
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;such as lawful court order or subpoena
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='3'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Vital interests:
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;Processing Personal Data is necessary in order to
                      protect Your vital interests or of another natural person.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='4'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Public interests:
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;Processing Personal Data is related to a task that
                      is carried out in the public interest or in the exercise
                      of official authority vested in&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Single Stop</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='5'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Legitimate interests:
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;Processing Personal Data is necessary for the
                      purposes of the legitimate interests pursued by&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Single Stop</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>In any case,&nbsp;</span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>&trade;</span>
                  <span data-contrast='auto'>
                    &nbsp;will gladly help to clarify the specific legal basis
                    that applies to the processing, and&nbsp;
                  </span>
                  <span data-contrast='auto'>in particular whether</span>
                  <span data-contrast='auto'>
                    &nbsp;the provision of Personal Data is a statutory or
                    contractual requirement, or a requirement necessary to enter
                    into a contract.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      Your Rights under the&nbsp; GDPR
                    </span>
                    <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>&trade;</span>
                  <span data-contrast='auto'>&nbsp;</span>
                  <span data-contrast='auto'>
                    undertakes to respect the confidentiality of Your Personal
                    Data and to guarantee You can exercise Your rights.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      You have the right under this Privacy Policy, and by law
                      if You are within the EU, to:
                    </span>
                    <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Request access to Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;The right to access, update or delete the
                      information&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      you have shared with Us.
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>&nbsp;</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      Whenever possible, you can access, update or request
                      deletion of Your Personal Data directly within Your&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>A</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      ccount settings section. If you are unable to perform
                      these&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      actions yourself, please contact Us to assist You. This
                      also enables You to receive a copy of the Personal
                      Data&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      you have shared with&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Us</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>&nbsp;</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Request correction of the Personal Data that We hold
                        about You.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;If you feel that the personal information we have
                      about you in is incorrect or incomplete,&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      you have the right
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;to ask us to amend your personal information.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Restrict processing of Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You may request a restriction on processing your
                      personal data if you feel that your data is not
                      accurate,&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      or if you believe the processing is unlawful,
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;or if you believe we no longer have a need to
                      process your data.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='2'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Object to processing of Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;This right exists where We are relying on a
                      legitimate interest as the legal basis for Our processing
                      and there is something about Your&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      particular situation
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      , which makes You want to object to our processing of Your
                      Personal Data on this ground.&nbsp;
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='3'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Request erasure of Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You have the right to ask Us to delete or remove
                      Personal Data when there are no other legal obligations
                      for Us to continue processing it.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='4'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Request the transfer of Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;We will provide to You, or to a third-party You have
                      chosen, Your Personal Data in a structured, commonly used,
                      machine-readable format. Please note that this right only
                      applies to automated information which You initially
                      provided consent for Us to use or where We used the
                      information to perform a contract with You.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='5'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        Withdraw Your consent.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You have the right to withdraw Your consent on using
                      your Personal Data. If You withdraw Your consent,&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>We</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;may not be able to provide You with access to
                      certain specific functionalities of the&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Site</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Exercising of Your GDPR Data Protection Rights
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If you wish to exercise any of your rights, your request
                    must be in writing, signed, and dated. It must specify the
                    records to which you are referring and give the reason for
                    your request. We will respond to you within 30 days. We may
                    deny your request,&nbsp;
                  </span>
                  <span data-contrast='auto'>if we do,</span>
                  <span data-contrast='auto'>
                    &nbsp;we will tell you why and explain your options. You may
                    send your request to our Support at
                    sssupport@singlestop.org.
                  </span>
                  <span data-contrast='auto'>
                    &nbsp;Please note that we may ask You to verify Your
                    identity before responding to such requests.{' '}
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    You have the right to complain to a Data Protection
                    Authority about Our collection and use of Your Personal
                    Data. For more information, if You are in the European
                    Economic Area (EEA), please contact Your local data
                    protection authority in the EEA.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                CCPA Privacy
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledSubHeading
                marginBottom='1rem'
                fontWeight='700'
                fontSize='1rem'
                data-contrast='none'>
                <em>
                  <span data-ccp-parastyle='heading 2'>
                    Your Rights under the CCPA
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      Under this Privacy Policy, and by law if You are a
                      resident of California,&nbsp;
                    </span>
                    <span data-contrast='auto'>You</span>
                    <span data-contrast='auto'>
                      &nbsp;have the following rights:
                    </span>
                    <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        The right to notice.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You must be properly notified which categories of
                      Personal Data are being collected and the purposes for
                      which the Personal Data is being used.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='2'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        The right to access / the right to request.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;The CCPA permits You to request and obtain from
                      the&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Us</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;information regarding the disclosure of Your
                      Personal Data that has been collected in the past 12
                      months by&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      Single Stop&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      or its subsidiaries to a third-party for the third party's
                      direct marketing purposes.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='3'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        The right to know about Your Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You have the right to request and obtain from&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>Us</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;information regarding the disclosure of the
                      following:&nbsp;
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='4'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      The categories of Personal Data collected
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      The sources from which the Personal Data was collected
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='2'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      The business or commercial purpose for collecting the
                      Personal Data
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='3'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      Categories of third parties with whom We share Personal
                      Data
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='4'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      The specific pieces of Personal Data we collected about
                      You
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='5'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        The right to delete Personal Data.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You also have the right to request the deletion of
                      Your Personal Data that have been collected in the past 12
                      months.
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <strong>
                    <span data-contrast='auto'>
                      <span data-ccp-parastyle='List Bullet'>
                        The right not to be discriminated against.
                      </span>
                    </span>
                  </strong>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      &nbsp;You have the right not to be discriminated against
                      for exercising any of Your Consumer's rights, including
                      by:&nbsp;
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='2'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      Denying goods or services to You
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='3'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      Providing a different level or quality of services to You
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='3'
                  data-aria-posinset='4'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>
                      Suggesting that You will receive a different quality
                      of&nbsp;
                    </span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>s</span>
                  </span>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet 2'>ervices.</span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[720],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Exercising Your CCPA Data Protection Rights
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    In order to exercise any of Your rights under the CCPA, and
                    if you are a California resident,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>&nbsp;can email&nbsp;</span>
                  <strong>
                    <span data-contrast='auto'>sssupport@singlestop.org</span>
                  </strong>
                  <span data-contrast='auto'>.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;will disclose and deliver the required information
                    free of charge within 45 days of receiving Your verifiable
                    request. The time period to provide the required information
                    may be extended once by an additional 45 days when&nbsp;
                  </span>
                  <span data-contrast='auto'>reasonably</span>
                  <span data-contrast='auto'>
                    &nbsp;necessary and with prior notice.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Links to Other Websites
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>Our&nbsp;</span>
                  <span data-contrast='auto'>Site</span>
                  <span data-contrast='auto'>
                    &nbsp;may contain links to other websites that are not
                    operated by Us. If You click on a&nbsp;
                  </span>
                  <span data-contrast='auto'>third-party</span>
                  <span data-contrast='auto'>&nbsp;link,&nbsp;</span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>
                    &nbsp;will be directed to that third party's site. We
                    strongly advise You to review the Privacy Policy of every
                    site You visit.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any&nbsp;
                  </span>
                  <span data-contrast='auto'>third-party</span>
                  <span data-contrast='auto'>&nbsp;sites or services.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Changes to this Privacy Policy
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    We may update our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    We will let You know via email and/or a prominent notice on
                    Our&nbsp;
                  </span>
                  <span data-contrast='auto'>Site</span>
                  <span data-contrast='auto'>
                    , prior to the change becoming effective and update the
                    "Last updated" date at the top of this Privacy Policy.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Definitions
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      For the purposes of this Privacy Policy:
                    </span>
                    <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Account: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;means a unique account created for You to access our
                    Site or parts of our Site.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Affiliate: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;means an entity that controls, is controlled by or is
                    under common control with a party, where "control" means
                    ownership of 50% or more of the shares, equity interest or
                    other securities entitled to vote for election of directors
                    or other managing authority.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Company: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;(referred to as either&nbsp;
                  </span>
                  <span data-contrast='auto'>&ldquo;</span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>&rdquo;,&nbsp;</span>
                  <span data-contrast='auto'>
                    "the Company", "We", "Us" or "Our" in this Agreement) refers
                    to SINGLE STOP USA INC, 123 William St, New York NY 10038.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      For the purpose of the GDPR, the Company is the Data
                      Controller.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Device: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;means any device that can access the Service such as a
                    computer, a cellphone or a digital tablet.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Personal Data: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;is any information that relates to an identified or
                    identifiable individual.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      For the purposes for GDPR, Personal Data means any
                      information relating to You such as a name, an
                      identification number, location data, online identifier or
                      to one or more factors specific to the physical,
                      physiological, genetic, mental, economic, cultural or
                      social identity.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>
                      For the purposes of the CCPA, Personal Data means any
                      information that identifies, relates to, describes or is
                      capable of being associated with, or could reasonably be
                      linked, directly or indirectly, with You.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                  </strong>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Sale: </span>
                  </strong>
                  <span data-contrast='auto'>&nbsp;</span>
                  <span data-contrast='auto'>(</span>
                  <span data-contrast='auto'>
                    for the purpose of the CCPA (California Consumer Privacy
                    Act)
                  </span>
                  <span data-contrast='auto'>)</span>
                  <span data-contrast='auto'>
                    , means selling, renting, releasing, disclosing,
                    disseminating, making available, transferring, or otherwise
                    communicating orally, in writing, or by electronic or other
                    means, a Consumer's Personal information to another business
                    or a third party for monetary or other valuable
                    consideration.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Site&nbsp;: </span>
                  </strong>
                  <span data-contrast='auto'>refers to&nbsp;</span>
                  <span data-contrast='auto'>O</span>
                  <span data-contrast='auto'>ur site:&nbsp;</span>
                  <a href='https://app.singlestop.org/'>
                    <span data-contrast='none'>
                      <span data-ccp-charstyle='Hyperlink'>
                        https://app.singlestop.org
                      </span>
                    </span>
                  </a>
                  <span data-contrast='auto'>.</span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Service Provider: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;means any natural or legal person who processes the
                    data on behalf of&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    . It refers to third-party companies or individuals employed
                    by&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;to facilitate the Site, to provide the Site on behalf
                    of the Company, to perform services related to the Site or
                    to assist the Company in analyzing how the Site is used. For
                    the purpose of the GDPR, Service Providers are considered
                    Data Processors.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>Usage Data: </span>
                  </strong>
                  <span data-contrast='auto'>
                    &nbsp;refers to data collected automatically, either
                    generated&nbsp;
                  </span>
                  <span data-contrast='auto'>by the use of</span>
                  <span data-contrast='auto'>&nbsp;the&nbsp;</span>
                  <span data-contrast='auto'>Site</span>
                  <span data-contrast='auto'>&nbsp;or from the&nbsp;</span>
                  <span data-contrast='auto'>Site</span>
                  <span data-contrast='auto'>
                    &nbsp;infrastructure itself (for example, the duration of a
                    page visit).
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <StyledParagraph privacyTerms>
                <em>
                  <strong>
                    <span data-contrast='auto'>You: </span>
                  </strong>
                  <span data-contrast='auto'>&nbsp;</span>
                  <span data-contrast='auto'>means</span>
                  <span data-contrast='auto'>
                    &nbsp;the individual accessing or using the Site, or the
                    company, or other legal entity on behalf of which such
                    individual is accessing or using the Site, as applicable.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>
                    Under GDPR (General Data Protection Regulation), You can be
                    referred to as the Data Subject or as the User as you are
                    the individual using the Site.
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'></span>
                </strong>
              </em>
            </StyledParagraph>

            <StyledParagraph privacyTerms>
              <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'></span>
            </StyledParagraph>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='auto'>
                Contact Us
                <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If you have any questions about this Privacy Policy,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>&nbsp;can contact us:</span>
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='2'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      By email: sssupport@singlestop.org
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
            </StyledLegalContainer>

            <StyledParagraph privacyTerms>
              <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'></span>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'></span>
            </StyledParagraph>
            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  This policy was last updated 04/18/2021
                  <span data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>
        </StyledLegalContainer>
      </Container>
      <MainFooter />
    </HeaderWithContainer>
  )
}

export default PrivacyPolicy
