import React, { Children, ReactElement, useState, ChangeEvent } from 'react'
import {
  FooterForm,
  StyledParagraph,
  CancelButton,
  StyledFormControl,
  StyledDialogContent,
  StyledServiceContainer,
} from './style'
import CheckField from './CheckFieldForm'
import { StyledButtonReferral } from '../style'
import { Form } from 'react-final-form'
import { FORM_ERROR, MutableState } from 'final-form'
import CheckboxInput from 'Components/Shared/CheckboxInput/CheckboxInput'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'

interface StartReferral {
  open: boolean
  location: {
    id: string
    name: String
    algoliaQuickFilters: number[]
  }
  serviceBadges: Array<ReactElement>
  handleClose: () => void
  setReferred: Function
  submitReferral: Function
}

interface FormValues {
  services?: []
}

interface MutatorState extends Omit<MutableState<FormValues>, 'formState'> {
  formState: {
    values: {
      services: number[]
    }
  }
}

const validValues = (values: FormValues, serviceIds: number[]) =>
  serviceIds.length !== 0 && (!values.services || values.services.length === 0)

const getCheckAllValue = (
  services: number[],
  serviceIds: number[],
  event: ChangeEvent<HTMLInputElement>
) => services.length === serviceIds.length - 1 && event.target.checked

const getServiceValues = (status: boolean, serviceIds: number[]) =>
  status ? serviceIds : []

const StartReferralModal = ({
  open,
  location,
  serviceBadges,
  handleClose,
  submitReferral,
}: StartReferral) => {
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const serviceIds = Array.from(new Set(location.algoliaQuickFilters)) || []

  const onSubmit = async (values: FormValues) => {
    if (validValues(values, serviceIds)) {
      return { [FORM_ERROR]: 'Select at least one service' }
    }
    setCheckAll(false)
    submitReferral({ locationId: location.id, services: values.services })
  }

  const checkAllAction = (
    event: boolean[],
    state: MutableState<FormValues>
  ) => {
    setCheckAll(event[1])
    state.fields['services'].change(getServiceValues(event[1], serviceIds))
  }

  const checkGroupServices = (
    event: ChangeEvent<HTMLInputElement>[],
    state: MutatorState
  ) => {
    const selectedServices = state.formState?.values?.services || []
    setCheckAll(getCheckAllValue(selectedServices, serviceIds, event[0]))
  }
  return (
    <ModalTemplate
      open={open}
      hasXToClose={false}
      hasCalendar={false}
      handleCancel={handleClose}
      heading={`Starting Referral for: ${location.name}`}
      width='40%'
      modalPadding={'2rem'}
    >
      <StyledDialogContent>
        {serviceBadges.length !== 0 && (
          <div>
            <h4>Select service(s):</h4>
            <p>
              Please select the services you're interested in at this location:
            </p>
          </div>
        )}
        <Form
          onSubmit={onSubmit}
          //@ts-ignore
          mutators={{ checkGroupServices, checkAllAction }}
          render={({
            handleSubmit,
            form,
            submitError,
            hasSubmitErrors,
            dirtySinceLastSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <StyledServiceContainer>
                  {Children.map(serviceBadges, (child, i) => {
                    return <CheckField form={form}>{child}</CheckField>
                  })}
                  {serviceBadges.length > 1 && (
                    <StyledFormControl>
                      <CheckboxInput
                        input={{
                          checked: checkAll,
                          onChange: form.mutators.checkAllAction,
                          name: 'select-all',
                        }}
                        label='Select all'
                      />
                    </StyledFormControl>
                  )}
                </StyledServiceContainer>
                <StyledParagraph>
                  By starting a referral, an entry will be made in your client
                  record. Your case manager may follow up with you about your
                  experience.
                </StyledParagraph>
                <StyledParagraph>
                  We'll also send you a message that includes the location's
                  name, address, and contact information.
                </StyledParagraph>
                {hasSubmitErrors && !dirtySinceLastSubmit && (
                  <FooterForm>
                    <FormSubmissionError error={submitError} />
                  </FooterForm>
                )}
                <FooterForm>
                  <CancelButton
                    onClick={() => {
                      setCheckAll(false)
                      handleClose()
                    }}
                  >
                    Cancel
                  </CancelButton>
                  <StyledButtonReferral type='submit'>
                    Start referral
                  </StyledButtonReferral>
                </FooterForm>
              </form>
            )
          }}
        />
      </StyledDialogContent>
    </ModalTemplate>
  )
}

export default StartReferralModal
