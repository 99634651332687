import React from 'react'
import { StyledBenefitAmount } from './style'
import { formatToDollarsEven } from 'Shared/helpers'

const BenefitAmount = ({ eligibleAmount }) => (
  <StyledBenefitAmount highlight={eligibleAmount !== 'N/A'}>
    {` ${formatToDollarsEven(eligibleAmount)}`}
  </StyledBenefitAmount>
)

export default BenefitAmount
