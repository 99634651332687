import { TableRow } from '@material-ui/core'
import React from 'react'
import ReviewSectionTableCell from './ReviewSectionTableCell'
import ReviewSectionTableInfoCell from './ReviewSectionTableInfoCell'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import { StyledSubHeading } from 'Components/Screener/style'
import { StyledCircle } from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'

const ReviewSectionTableRow = ({ row, labels }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const firstLetter = row?.[0]?.[0]
  const fullName = row?.[0]?.toString()?.split(' ')
  const fullNameArray = [fullName]
  const secondName = fullNameArray?.[0]?.[1]
  const secondNameSplit = [secondName].toString().split('')
  const secondLetter = secondNameSplit[0]

  const RenderCircle = () => {
    return (
      <>
        {isMobile && isNaN(firstLetter) && firstLetter !== undefined && (
          <StyledCircle
            display='flex'
            margin='.75rem 0 0.875rem 0'
            scale='4rem'
          >
            {firstLetter}
            {secondLetter}
          </StyledCircle>
        )}
        {!isMobile && (
          <StyledCircle>
            {firstLetter}
            {secondLetter}
          </StyledCircle>
        )}
      </>
    )
  }

  return (
    <>
      {isMobile && (
        <>
          {RenderCircle()}
          {row?.map((column, i) => {
            return (
              <div
                key={i}
                style={{
                  width: '50%',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  marginBottom: '1rem',
                }}
              >
                <StyledSubHeading
                  fontSize='.875rem'
                  fontWeight='400'
                  marginBottom='4px'
                  opacity='.7'
                >
                  {labels[i]}
                </StyledSubHeading>
                {Array.isArray(column) ? (
                  column?.map((item, i) => {
                    return (
                      <StyledSubHeading
                        key={i}
                        fontSize='1rem'
                        fontWeight='600'
                        marginBottom='10x'
                      >
                        {item}
                      </StyledSubHeading>
                    )
                  })
                ) : (
                  <StyledSubHeading
                    fontSize='1rem'
                    fontWeight='600'
                    marginBottom='0'
                  >
                    {column}
                  </StyledSubHeading>
                )}
              </div>
            )
          })}
          {labels[labels.length - 1] === '' && <ReviewSectionTableInfoCell />}
        </>
      )}

      {!isMobile && (
        <TableRow>
          {row?.map((column, i) => (
            <ReviewSectionTableCell key={i} column={column} />
          ))}
          {labels[labels.length - 1] === '' && <ReviewSectionTableInfoCell />}
        </TableRow>
      )}
    </>
  )
}
export default ReviewSectionTableRow
