import {
  getNextStep,
  startEditingOnStep,
  updateCurrentStep,
  updateReturnStep,
  updateProgress,
} from 'Actions/screener'
import { FIRST_STEP } from 'Shared/constants'

export const stateToProps = (state) => {
  const { steps, currentStep, progressStatus, hasCustomQuestions } =
    state.screener
  const { screenerCompleted } = state.client
  return {
    progressStatus,
    steps,
    currentStep,
    screenerCompleted,
    hasCustomQuestions,
  }
}

export const dispatchToProps = (dispatch) => {
  return {
    handleChangeAnswers: () => dispatch(startEditingOnStep(FIRST_STEP)),
    updateStep: (stepNumber, progressStatus, returnStep) => {
      const backStep = dispatch(getNextStep(stepNumber, 'backButton'))
      if (backStep) {
        dispatch(updateCurrentStep(backStep))
        dispatch(updateProgress(backStep, progressStatus, returnStep))
      }
    },
    handleUpdateReturnStep: (returnStep) =>
      dispatch(updateReturnStep(returnStep)),
  }
}
