import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { EditIcon } from 'Components/Review/assets'
import useHandleRescreen from 'Components/Screener/Hooks/useHandleRescreen'
import { READ_ONLY_ROLES } from 'Components/CaseManagement/Reporting/Charts/Shared/constants'
import { ProtectedRouteContext } from 'Components/Shared/ProtectedRoute/ProtectedRouteProvider'

const EditButton = ({ handleEditOnClick, legacy }) => {
  const handleRescreen = useHandleRescreen()
  const ctx = useContext(ProtectedRouteContext)
  const role = ctx?.me?.role
  const isReadOnly = READ_ONLY_ROLES.includes(role)

  let currentStep

  const editHandler =
    legacy && !currentStep ? handleRescreen : handleEditOnClick
  return (
    <Button
      data-testid='editButton'
      startIcon={EditIcon}
      onClick={editHandler}
      disabled={isReadOnly}
    >
      Edit
    </Button>
  )
}

export default EditButton
