import React from 'react'
import { StyledBenefitAmount, StyledBenefitsSpan } from './style'
import { toCapitalized, formatToDollarsEven } from 'Shared/helpers'

const RecurringBenefitAmount = ({ frequency, recurringAmount }) =>
  recurringAmount && frequency && frequency !== 'NONRECURRENT' ? (
    <div>
      <StyledBenefitsSpan>{`${toCapitalized(frequency)}: `}</StyledBenefitsSpan>
      <StyledBenefitAmount highlight={true} small>
        {formatToDollarsEven(recurringAmount)}
      </StyledBenefitAmount>
    </div>
  ) : null

export default RecurringBenefitAmount
