import React, { ReactElement } from 'react'
import { StyledFormControl } from './style'
import { Field } from 'react-final-form'
import CheckboxInput from 'Components/Shared/CheckboxInput/CheckboxInput'
interface CheckFieldInterface {
  children: ReactElement
  form: any
}
const CheckField = ({ children, form }: CheckFieldInterface) => {
  return (
    <StyledFormControl>
      <Field
        name='services'
        onChange={form.mutators.checkGroupServices}
        component={CheckboxInput}
        type='checkbox'
        value={children.props.id}
      />
      {children}
    </StyledFormControl>
  )
}

export default CheckField
