import React, { ChangeEvent } from 'react'
import { Window } from 'Window'
import { isLoginPage } from 'Shared/helpers'

export const handleSetLanguage =
  (setLanguage: (value: string) => void) =>
  (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const {
      target: { value },
    } = event as React.ChangeEvent<HTMLInputElement>
    setLanguage(value)
    const { Localize } = window as unknown as Window
    Localize.setLanguage(value)
  }

export const getOptions = (
  options: { value: string; label: string }[],
  labelledby: string
) =>
  options.map(({ value, label }, i) => (
    <option
      key={i}
      value={value}
      id={`language-${value}`}
      aria-labelledby={labelledby}
    >
      <span lang={value}>{label}</span>
    </option>
  ))

export const getClassName = (isMobile: boolean, insideMenu = false) => {
  if (!isMobile) return ''
  if (!isLoginPage()) {
    if (insideMenu) return 'make-animation'
  } else {
    return 'no-margin'
  }
}
