import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import theme from 'Shared/Theme/ssTheme'

const headerHeight = `${theme.layout.headerHeight}`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding-top: 0.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${media.smaller`
    padding-bottom: 5rem;
    max-height: calc(100vh - ${headerHeight} - 8rem);
  `}
`

export const StyledLocationCardContainer = styled.div`
  background: unset;
  border: unset;
  width: 100%;
`

StyledContainer.displayName = 'StyledContainer'
