import { connect } from 'react-redux'

import { updateDistanceUI, updateDistanceAround } from 'Actions/filters'
import type { AppDispatch, RootState } from 'Store'

import DistanceFilter from './DistanceFilter'

interface Distance {
  value: number
  unit: string
}

const convertToMeters = ({ value, unit }: Distance) => {
  switch (unit) {
    case 'mi':
      return Math.round(value * 1609.344)
    case 'km':
      return value * 1000
    default:
      return Math.round(value * 1609.344)
  }
}

const stateToProps = (state: RootState) => {
  const { filters } = state
  const { value: distanceValue, unit: distanceUnit } = filters.distance
  return {
    distanceValue,
    distanceUnit,
  }
}

const dispatchToProps = (dispatch: AppDispatch) => ({
  handleUpdateDistanceUI: (distance: Distance) => {
    dispatch(updateDistanceUI(distance))
    dispatch(updateDistanceAround(convertToMeters(distance)))
  },
  handleUpdateDistanceAround: (distance: Distance) => {
    dispatch(updateDistanceUI(distance))
    dispatch(updateDistanceAround(convertToMeters(distance)))
  },
})

export default connect(stateToProps, dispatchToProps)(DistanceFilter)
