import React from 'react'

import LocationInfo from '../LocationInfo/LocationInfo'
import { outlineMailIconPath } from 'Shared/Icons/LocationInfo/paths'

import { StyledEmailATag } from './style'

const EmailAddress = (props) => {
  const { emailAddress } = props

  const emailComponent = (
    <StyledEmailATag href={`mailto:${emailAddress}`}>
      {emailAddress}
    </StyledEmailATag>
  )

  if (emailAddress) {
    return (
      <LocationInfo
        content={emailComponent}
        screenReaderLabel={'EmailAddress'}
        icon={outlineMailIconPath}
      />
    )
  } else {
    return null
  }
}

export default EmailAddress
