import React from 'react'

import HeroSection from './HeroSection'
import BenefitsSection from './BenefitsSection'
import HelpSec from './HelpSection'
import { KindsSec } from './ResourcesSection'

const Main = () => (
  <>
    <HeroSection />
    <BenefitsSection />
    <HelpSec />
    <KindsSec />
  </>
)

export default Main
