import {
  householdMember,
  incomeMember,
  savingsMember,
  workingMember,
} from './objects'
import {
  allPersons,
  amount,
  getHousehold,
  isHouseholdMemberWithIncome,
  isHouseholdMemberWithSavings,
  isWorkingHouseholdMember,
  yesNo,
} from './utilities'

/**
 * Rent or Mortgage
 * Utility Expenses
 * Child Care Expenses
 * Adult Care Expenses
 * Child Support Expenses
 * Out Of Pocket Medical Expenses
 * Educational Expenses
 *
 * @param {Object} client
 */
export const householdExpenses = (client) => {
  const household = getHousehold(client)
  const expense = household?.expense
  const utilityExpenses = household?.utilityExpenses

  return [
    [
      amount(expense?.rentOrMortgageExpensesAmount),
      utilityExpenses?.length > 0
        ? utilityExpenses?.map((utility) => utility.source)
        : 'No',
      amount(expense?.childcareExpensesAmount),
      amount(expense?.adultCareExpensesAmount),
      amount(expense?.childSupportExpensesAmount),
      amount(expense?.outOfPocketMedicalExpensesAmount),
      yesNo(expense?.hasEducationExpenses),
    ],
  ]
}

/**
 *
 * @param {Object} client
 */
export const otherIncomes = (client) =>
  allPersons(client)?.filter(isHouseholdMemberWithIncome).map(incomeMember)

/**
 *
 * @param {Object} client
 */
export const savingsMembers = (client) =>
  allPersons(client)?.filter(isHouseholdMemberWithSavings).map(savingsMember)

/**
 *
 * @param {Object} client
 */
export const workingMembers = (client) =>
  allPersons(client)?.filter(isWorkingHouseholdMember).map(workingMember)

/**
 * Maps an array of Household Members
 *
 * @param {Object} members Array of Household Members
 * @return {Array} Array of formatted Household Members
 */
export const householdMembers = (members) => members?.map(householdMember)
