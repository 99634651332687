export const mockReferralNumber = 'sssr96756500'

export const mockLocations = [
  {
    id: 1,
    name: 'Williamsburg Community Center',
    address: '123 Washington St NY, New York 11125',
    distance: 1.17,
    phoneNumber: '(555) 555-1245 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
  {
    id: 2,
    name: 'ASAP at LaGuardia',
    address: '123 Washington St NY, New York 11125',
    distance: 1.36,
    phoneNumber: '(555) 555-2356 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
  {
    id: 3,
    name: 'Brooklyn Legal Services',
    address: '123 Washington St NY, New York 11125',
    distance: 2.0,
    phoneNumber: '(555) 555-1245 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
]

export const mockDocumentations = [
  {
    type: 'identity',
    description: 'A legal form of ID that proves you are who you say you are.',
    examples: ["driver's license", 'passport', 'state id'],
  },
  {
    type: 'social security card',
    description: 'Lorem ipsum',
    examples: null,
  },
  {
    type: 'proof of address',
    description: 'Lorem ipsum',
    examples: ['utility bill', 'postmarked letter', 'lease'],
  },
  {
    type: 'citizenship or alien status',
    description: 'Lorem ipsum',
    examples: ['passport', 'US Citizenship', 'H1B Visa'],
  },
  {
    type: 'income',
    description: 'Lorem ipsum',
    examples: ['pay stubs', 'bank statements'],
  },
  {
    type: 'wages & earning statements',
    description: 'Lorem ipsum',
    examples: ['pay stubs', 'bank statements'],
  },
  {
    type: 'dependent costs',
    description: 'Lorem ipsum',
    examples: ['bills', 'school enrollment form'],
  },
  {
    type: 'medical expenses',
    description: 'Lorem ipsum',
    examples: ['HSA statements', 'EOB', 'Claims'],
  },
  {
    type: 'housing & shelter expenses',
    description: 'Lorem ipsum',
    examples: ['Lease', 'Bank statements', 'Venmo history'],
  },
]
