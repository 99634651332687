import { validateEmail, validatePhone } from 'Shared/helpers'
import {
  householdExpenses,
  householdMembers,
  otherIncomes,
  savingsMembers,
  workingMembers,
} from './collections'
import {
  householdHasSavings,
  isAnyoneWorking,
  yesNo,
  claimedHouseholdMembers,
  getHousehold,
  allPersons,
  adjustedGrossIncome,
  taxFilingStatus,
} from './utilities'

export const parseCustomFields = ({
  rawCustomFields,
  rawCustomFieldResponses,
}) =>
  rawCustomFields.map(({ label: name, dataType, id, options }) => {
    const [rawValue] = rawCustomFieldResponses
      .filter(({ customField }) => customField.id === id)
      .map((response) => response.value)

    const value = rawValue ?? 'Not Available'

    return {
      name,
      id: `custom_field_${id}`,
      options,
      value,
      displayValue:
        dataType === 'BOOLEAN' ? yesNo(value.toLowerCase() === 'true') : value,
      dataType,
      editable: true,
    }
  })

/**
 * - First Name
 * - Last Name
 * - Nickname
 * - Contact Info
 * - Age/DOB
 * - Looking for
 * - Location
 * - Custom Field Responses (in the order received)
 *
 * @param {Object} client Client data object
 *
 * {@link https://fedcapdev.atlassian.net/browse/SS-2103|[SS-2103]}
 *
 * @returns {{valueDisplays:Array}} valueDisplays and/or tableDisplays
 */
export const basicInformation = (client) => {
  let valueDisplays = [
    {
      id: 'first_name',
      name: 'First Name',
      value: client?.firstName ?? '',
      editable: true,
    },
    {
      id: 'last_name',
      name: 'Last Name',
      value: client?.lastName ?? '',
      editable: true,
    },
    {
      id: 'nick_name',
      name: 'Preferred Name',
      value: client?.nickName ?? '',
      editable: true,
    },
    {
      name: 'Age/DOB',
      value: client?.displayDateOfBirth ?? client?.age,
    },
    {
      name: 'ZIP Code',
      value: client?.zipCode,
    },
  ]

  const rawCustomFields = client?.screening?.location?.customFields ?? []

  const rawCustomFieldResponses = client?.customFieldResponses ?? []

  if (rawCustomFields.length) {
    valueDisplays = [
      ...valueDisplays,
      ...parseCustomFields({ rawCustomFields, rawCustomFieldResponses }),
    ]
  }

  return {
    valueDisplays,
  }
}

export const contactInformation = (client) => {
  let valueDisplays = [
    {
      name: 'Login - Phone Number',
      value: client?.primaryPhone ? client.primaryPhone : 'Not Available',
      verified: client?.user?.verified,
    },
    {
      name: 'Login - Email',
      value: client?.email ? client.email : 'Not Available',
      verified: client?.user?.verified,
    },
    {
      id: 'alternate_phone',
      name: 'Alternate Phone Number',
      value: client?.alternatePhone,
      editable: true,
      verified: false,
      hideIfEmpty: true,
      validator: validatePhone,
      maxLength: 10,
      inputTypePhone: true,
    },
    {
      id: 'alternate_email',
      name: 'Alternate Email',
      value: client?.alternateEmail,
      editable: true,
      verified: false,
      hideIfEmpty: true,
      validator: validateEmail,
    },
  ]

  return {
    valueDisplays,
  }
}

/**
 * Values:
 * Housing situation
 *
 * Table:
 * Name
 * Relationship
 * Gender
 * Race
 * Ethnicity
 * Education
 * Health Insurance
 * Health Insurance Type
 * Student
 * Course Load
 * Work Study
 * Veteran
 * Disabled
 * Pregnant
 * US Citizen
 * Immigration Status
 * Length of Permanent residency
 *
 * @param {Object} client
 *
 * {@link https://fedcapdev.atlassian.net/browse/SS-2103|[SS-2103]}
 *
 * @returns {Object} valueDisplays and/or tableDisplays
 */
export const demographics = (client) => ({
  valueDisplays: [
    {
      name: 'What is your current housing situation?',
      value: getHousehold(client)?.situation,
    },
  ],
  tableDisplays: [
    {
      name: 'Household Members',
      labels: [
        'Name',
        'DOB/Age',
        'Relationship',
        'Gender',
        'Race',
        'Ethnicity',
        'Education',
        'Health Insurance',
        'Health Insurance Type',
        'Student',
        'Course Load',
        'EFC Amount',
        'COA Amount',
        'Eligible For Work Study',
        'Work Study',
        'Veteran',
        'Disabled',
        'Pregnant',
        'US Citizen',
        'Immigration Status',
        'Length of Permanent residency',
        '',
      ],
      rows: householdMembers(allPersons(client)),
    },
  ],
})

/**
 *
 * @param {Object} client
 */
export const income = (client) => ({
  valueDisplays: [
    {
      name: 'Is anyone in your household currently working?',
      value: yesNo(isAnyoneWorking(client)),
    },
  ],
  tableDisplays: [
    {
      name: 'Members Work',
      labels: ['Name', 'Job', 'Status', 'Pay Frequency ', 'Amount'],
      rows: workingMembers(client),
    },
    {
      name: 'Other Income',
      labels: ['Name', 'Income Source', 'Amount'],
      rows: otherIncomes(client),
    },
  ],
})

/**
 * Household Savings
 *
 * @param {Object} client
 * @returns {Object} values and/or tables
 */
export const savings = (client) => ({
  valueDisplays: [
    {
      name: 'Savings',
      value: yesNo(householdHasSavings(client)),
    },
  ],
  tableDisplays: [
    {
      name: 'Household Savings',
      labels: ['Name', 'Amount'],
      rows: savingsMembers(client),
    },
  ],
})

/**
 *
 * Rent or Mortgage
 * Utility Expenses
 * Child Care Expenses
 * Adult Care Expenses
 * Child Support Expenses
 * Out Of Pocket Medical Expenses
 * Educational Expenses
 *
 * @param {Object} client
 */
export const expenses = (client) => ({
  tableDisplays: [
    {
      name: 'Household Expenses',
      labels: [
        'Rent or Mortgage',
        'Utility Expenses',
        'Child Care Expenses',
        'Adult Care Expenses',
        'Child Support Expenses',
        'Out Of Pocket Medical Expenses',
        'Educational Expenses',
      ],
      rows: householdExpenses(client),
    },
  ],
})

/**
 *
 * @param {Object} client
 */
export const taxInformation = (client) => ({
  valueDisplays: [
    {
      name: 'Will someone claim you as a dependent on their taxes?',
      value: yesNo(client?.claimedAsDependent),
    },
    {
      name: 'Will you claim any household members as a dependent on your taxes?',
      value: claimedHouseholdMembers(client),
    },
    {
      name: 'Tax Filing Status',
      value: taxFilingStatus(client?.taxFilingStatus),
    },
    {
      name: 'Last Year Adjusted Gross Income',
      value: adjustedGrossIncome(
        client?.lastYearAdjustedGrossIncome || '$0.00'
      ),
    },
  ],
})
