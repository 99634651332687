import React, { useEffect, useRef } from 'react'
import { Field, reduxForm } from 'redux-form'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import TextField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import {
  StyledForm,
  StyledHeadingMain,
  StyledSubHeading,
  StyledParagraph,
} from 'Components/Screener/style'

import Icon from 'Components/Shared/Icon/Icon'
import backIconPath from 'Shared/Icons/backIconPath'
import { white } from 'Shared/Theme/index'

import { StyledLink } from './style'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import { setFocusOnFirstError } from 'Shared/helpers'
import { useSelector } from 'react-redux'
import formatters from 'Components/Shared/ReduxForm/formatters/formatters'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import { StyledParentPreScreenerButton } from 'Components/Screener/style'

const PreScreenerEmailOrPhone = (props) => {
  const { handleSubmit, error } = props
  const focusRef = useRef(null)

  useEffect(() => {
    setFocusToMainHeading()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const showLoginLink = useSelector((state) => state.ui.showLoginLink)

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='7'>
          <StyledForm
            padding={'2rem 0 0'}
            onSubmit={handleSubmit}
            noValidate
            className='cypress-prescreen-email-phone-form'
          >
            <StyledHeadingMain id='mainContent' tabIndex='-1' ref={focusRef}>
              Great! What is the best way to contact you?
            </StyledHeadingMain>
            <StyledSubHeading>
              We will send a verification code to your preferred method of
              communication. Data rates may apply.
            </StyledSubHeading>
            <StyledParagraph>
              This helps us confirm your identity and secure your account. You
              can also use this email or phone number to log back in at any
              time.
            </StyledParagraph>
            <StyledHouseholdMembersListItem margin='.6rem 0 2.5rem'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  <Row>
                    <Col lg='12'>
                      <StyledMuiTextFieldWrapper
                        className='cypress-email-or-phone'
                        width='100%'
                        marginRight='auto'
                        marginLeft='auto'
                        mbottom='0'
                      >
                        <Field
                          name='emailOrPhone'
                          type='text'
                          component={TextField}
                          label='Email or Mobile Phone Number'
                          normalize={formatters.lowercase}
                        />
                        <FormSubmissionError error={error} />
                        {showLoginLink && (
                          <StyledLink to='/login'>
                            Log in to my account
                          </StyledLink>
                        )}
                      </StyledMuiTextFieldWrapper>
                    </Col>
                  </Row>
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>
            <StyledHouseholdMembersList textalign='center'>
              <Row>
                <Col lg='7'>
                  <StyledParentPreScreenerButton $isOnlyContinue={true}>
                    <ColDynamicCtaButton
                      width='100%'
                      type='submit'
                      className='cypress-continue-button'
                    >
                      Continue
                      <Icon
                        position={'absolute'}
                        right={'1.375rem'}
                        fill={white}
                        margin={'0'}
                        scale={'1.5rem'}
                        style={{ transform: 'rotate(180deg)' }}
                        viewBox={'0 0 24 24'}
                        cursorOnHover={'pointer'}
                      >
                        {backIconPath}
                      </Icon>
                    </ColDynamicCtaButton>
                  </StyledParentPreScreenerButton>
                </Col>
              </Row>
            </StyledHouseholdMembersList>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

export default reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validators.emailOrPhone,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerEmailOrPhone)
