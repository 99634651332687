import React, { createRef, useEffect, useRef } from 'react'
import { Col } from 'styled-bootstrap-grid'
import { useSelector } from 'react-redux'
import { ProgressSideParent } from 'Components/Shared/ProgressSideBar/style'
import ProgressSteps from 'Components/Shared/ProgressSideBar/ProgressBarArray'
import ProgressSideBarBox from './ProgressSideBarBox'
import { questions } from 'Mocks/ResponsePayloads/screener-questions'

const ProgressSideBar = (props) => {
  const { preSelectedCategoryId, preProgressPercentage, position } = props
  const currentStep = useSelector(
    ({ screener: { currentStep } }) => currentStep
  )
  const stepsArray = Object.values(questions.steps)
  const currentCategory = stepsArray.find(
    ({ stepNumber }) => stepNumber === currentStep
  ).category
  const preSelectedCategory = ProgressSteps.find(
    ({ id }) => id === preSelectedCategoryId
  )
  const selectedCategory = preSelectedCategory || currentCategory
  const categoriesRefs = ProgressSteps.reduce((acc, value) => {
    acc[value.id] = createRef()
    return acc
  }, {})
  const sideParentRef = useRef(null)
  useEffect(() => {
    const activeCategoryRef = categoriesRefs[selectedCategory.id]
    const current = activeCategoryRef && activeCategoryRef.current.children[0]
    if (current) {
      const count = current.offsetTop - sideParentRef.current.scrollTop - 20
      sideParentRef.current.scrollBy({
        top: count,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [selectedCategory, categoriesRefs])
  return (
    <Col lg='12' className='col-no-padding'>
      <ProgressSideParent
        ref={sideParentRef}
        position={position}
        role='region'
        aria-label='Progress Region'
      >
        {ProgressSteps.map((category) => {
          const { id } = category
          const ref = categoriesRefs[id]
          return (
            <div key={id} ref={ref}>
              <ProgressSideBarBox
                selectedCategory={selectedCategory}
                preProgressPercentage={preProgressPercentage}
                stepsArray={stepsArray}
                category={category}
                currentStep={currentStep}
              ></ProgressSideBarBox>
            </div>
          )
        })}
      </ProgressSideParent>
    </Col>
  )
}
export default ProgressSideBar
