import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { getScreeningType } from 'Shared/helpers'

/**
 * When Review mounts, refresh the user object's screening type.
 * It could be assisted or self at this point.
 */
const useUpdateScreeningType = () => {
  useEffect(() => {
    const user = {
      ...JSON.parse(localStorage.getItem('user')),
      screening_type: getScreeningType(),
    }

    Sentry.setUser(user)
    localStorage.setItem('user', JSON.stringify(user))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useUpdateScreeningType
