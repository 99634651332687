import { useDispatch } from 'react-redux'
import { destroy } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks'
import useRegisterUrl from 'Components/App/Hooks/useRegisterUrl'
import personalizedBenefitsIconPath from 'Shared/Icons/MenuList/personalizedBenefitsIconPath'
import browseAllBenefitsIconPath from 'Shared/Icons/MenuList/browseAllBenefitsIconPath'
import preferredLanguageIconPath from 'Shared/Icons/MenuList/preferredLanguageIconPath'
import aboutIconPath from 'Shared/Icons/MenuList/aboutIconPath'
import emailIconPath from 'Shared/Icons/MenuList/emailIconPath'
import printIconPath from 'Shared/Icons/MenuList/printIconPath'
import loginIconPath from 'Shared/Icons/MenuList/loginIconPath'
import signUpPath from 'Shared/Icons/MenuList/signUpPath'
import {
  reportIconPath,
  localIconPath,
  Cases,
} from 'Components/CaseManagement/Reporting/Sidebar/sideIcons'

export const getClientSummaryRoute = (clientId) => ({
  iconPath: browseAllBenefitsIconPath,
  text: 'Client Summary',
  path: `/case-management/clients/${clientId}`,
})

const useMenuItemsData = () => {
  const dispatch = useDispatch()
  const registerUrl = useDynamicRegistrationUrl()
  const accountData = [
    {
      iconPath: loginIconPath,
      viewBox: '0 0 32 32',
      greenColor: true,
      text: 'Log in',
      path: '/login',
      onClick: () => dispatch(destroy(PRE_SCREENER_FORM_NAME)),
    },
    {
      iconPath: signUpPath,
      viewBox: '0 0 32 32',
      greenColor: true,
      text: 'Sign up',
      path: registerUrl,
      primary: 'true',
    },
  ]

  const logoutData = [
    { iconPath: preferredLanguageIconPath, text: 'Log out', path: '/logout' },
  ]

  const fullMenuData = [
    {
      iconPath: personalizedBenefitsIconPath,
      viewBox: '0 0 32 32',
      text: 'Find My Eligibility',
      path: useRegisterUrl(registerUrl),
    },
    {
      iconPath: browseAllBenefitsIconPath,
      viewBox: '0 0 32 32',
      text: 'Browse All Benefits & Resources',
      path: '/locations/list',
    },

    {
      iconPath: aboutIconPath,
      viewBox: '0 0 32 32',
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  const tabletTopNavMenuData = [
    {
      iconPath: personalizedBenefitsIconPath,
      text: 'Find My Eligibility',
      path: useRegisterUrl(registerUrl),
    },
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Browse Resources',
      path: '/locations/list',
    },
    {
      iconPath: aboutIconPath,
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  const nextStepsTopNavMenuData = [
    { iconPath: emailIconPath, text: 'Email' },
    { iconPath: printIconPath, text: 'Print' },
  ]

  const caseManagementTopNavMenuData = [
    {
      iconPath: Cases,
      text: 'Client Records',
      path: '/case-management/clients',
    },
    {
      iconPath: localIconPath,
      text: 'Local Resources',
      path: '/locations/list',
    },
    {
      iconPath: reportIconPath,
      text: 'Reports',
      path: '/case-management/reports/demographics/overview',
    },
  ]

  const menuItemsLoggedInAsCM = [
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Client Records',
      path: '/case-management/clients',
    },
    {
      iconPath: browseAllBenefitsIconPath,
      text: 'Browse Resources',
      path: '/locations/list',
    },
    {
      iconPath: aboutIconPath,
      text: 'About',
      externalUrl: 'https://singlestop.org/',
    },
  ]

  return {
    accountData,
    logoutData,
    fullMenuData,
    tabletTopNavMenuData,
    nextStepsTopNavMenuData,
    caseManagementTopNavMenuData,
    menuItemsLoggedInAsCM,
  }
}

export default useMenuItemsData
