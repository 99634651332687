import { configureStore } from '@reduxjs/toolkit'
import { rememberEnhancer } from 'redux-remember'
import * as Sentry from '@sentry/react'

import reducer from 'Reducers'

const store = configureStore({
  reducer,
  enhancers: [
    Sentry.createReduxEnhancer(),
    rememberEnhancer(window.sessionStorage, [
      'locations',
      'ui',
      'client',
      'geolocation',
      'filters',
      'preScreener',
      'screener',
      'nextSteps',
      'form',
    ]),
  ],
})

// Expose the Redux store globally FOR CYPRESS TESTING ONLY
if (window.Cypress) window.reduxStore = store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
