import styled, { css } from 'styled-components'

export type StyledParentSponsorLogProps = {
  isSideOpen?: boolean
  isMobile?: boolean
}

export const StyledParentSponsorLog = styled.div<StyledParentSponsorLogProps>`
  width: 7rem
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  transition: .1s;
  box-shadow: 0px 3px 20px #0000002e;
  ${({ isSideOpen }) =>
    isSideOpen &&
    css`
      left: 27rem;
    `};
  ${({ isMobile }) =>
    isMobile &&
    css`
      position: fixed;
      width: 10rem;
      left: 0;
      right: 0;
      margin: auto;
      @media only screen and (min-width: 767px) {
        bottom: 2rem;
        width: 8rem;
      }
    `}
`

export const StyledSponsorLog = styled.img`
  width: 100%;
`
