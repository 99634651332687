import React from 'react'
import {
  StyledSocialsList,
  StyledSocialItem,
} from 'Components/Shared/PageFooter/Styles/SocialsBar'
import Icon from 'Components/Shared/Icon/Icon'

const socialsItems = [
  {
    Title: 'Twitter Icon',
    Link: 'https://twitter.com/SingleStop',
    ViewBox: '0 0 26 28',
    Path: (
      <path d='M25.312 6.375a10.85 10.85 0 0 1-2.531 2.609c.016.219.016.438.016.656 0 6.672-5.078 14.359-14.359 14.359-2.859 0-5.516-.828-7.75-2.266.406.047.797.063 1.219.063 2.359 0 4.531-.797 6.266-2.156a5.056 5.056 0 0 1-4.719-3.5c.313.047.625.078.953.078.453 0 .906-.063 1.328-.172a5.048 5.048 0 0 1-4.047-4.953v-.063a5.093 5.093 0 0 0 2.281.641 5.044 5.044 0 0 1-2.25-4.203c0-.938.25-1.797.688-2.547a14.344 14.344 0 0 0 10.406 5.281 5.708 5.708 0 0 1-.125-1.156 5.045 5.045 0 0 1 5.047-5.047 5.03 5.03 0 0 1 3.687 1.594 9.943 9.943 0 0 0 3.203-1.219 5.032 5.032 0 0 1-2.219 2.781c1.016-.109 2-.391 2.906-.781z'></path>
    ),
  },
  {
    Title: 'Facebook Icon',
    Link: 'https://www.facebook.com/singlestopusa',
    ViewBox: '0 0 16 28',
    Path: (
      <path d='M14.984.187v4.125h-2.453c-1.922 0-2.281.922-2.281 2.25v2.953h4.578l-.609 4.625H10.25v11.859H5.469V14.14H1.485V9.515h3.984V6.109C5.469 2.156 7.891 0 11.422 0c1.687 0 3.141.125 3.563.187z'></path>
    ),
  },
  {
    Title: 'Youtube Icon',
    Link: 'https://www.youtube.com/user/SingleStopUSA',
    ViewBox: '0 0 461.001 461.001',
    Path: (
      <path d='M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728  c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137  C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607  c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z' />
    ),
  },
  {
    Title: 'LinkedIn Icon',
    Link: 'https://www.linkedin.com/company/single-stop-usa',
    ViewBox: '0 0 24 28',
    Path: (
      <path d='M5.453 9.766v15.484h-5.156v-15.484h5.156zM5.781 4.984c0.016 1.484-1.109 2.672-2.906 2.672v0h-0.031c-1.734 0-2.844-1.188-2.844-2.672 0-1.516 1.156-2.672 2.906-2.672 1.766 0 2.859 1.156 2.875 2.672zM24 16.375v8.875h-5.141v-8.281c0-2.078-0.75-3.5-2.609-3.5-1.422 0-2.266 0.953-2.641 1.875-0.125 0.344-0.172 0.797-0.172 1.266v8.641h-5.141c0.063-14.031 0-15.484 0-15.484h5.141v2.25h-0.031c0.672-1.062 1.891-2.609 4.672-2.609 3.391 0 5.922 2.219 5.922 6.969z'></path>
    ),
  },
]

const SocialsBar = () => {
  return (
    <StyledSocialsList>
      {socialsItems.map((value, index) => {
        return (
          <StyledSocialItem key={index}>
            <Icon
              as='a'
              href={value.Link}
              target='_blank'
              viewBox={value.ViewBox}
              scale={'18px'}
              screenReaderLabel={value.Title}>
              {value.Path}
            </Icon>
          </StyledSocialItem>
        )
      })}
    </StyledSocialsList>
  )
}

export default SocialsBar
