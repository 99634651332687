import {
  screenerSelector,
  additionalIncomeType,
  getClientName,
  getClientSchool,
  householdMemberName,
  householdMemberNamePossessive,
  doesUser,
  hasUser,
  isUser,
  clientPayFrequency,
  householdMemberPayFrequency,
  yourTheir,
} from './screener'
import { preScreenerSelector } from './preScreener'
import { loginSelector } from './login'

const emailOrPhone = (state) => {
  const fromPreScreener = preScreenerSelector(state, 'emailOrPhone')
  const fromLogin = loginSelector(state, 'emailOrPhone')
  return fromPreScreener || fromLogin
}

export {
  screenerSelector,
  additionalIncomeType,
  getClientName,
  getClientSchool,
  householdMemberName,
  householdMemberNamePossessive,
  doesUser,
  hasUser,
  isUser,
  clientPayFrequency,
  householdMemberPayFrequency,
  yourTheir,
  preScreenerSelector,
  loginSelector,
  emailOrPhone,
}
