import { ApolloClient, HttpLink, from } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { API_BASE } from 'Shared/constants'
import cache from './caseManagementCache'
import { IS_DEVELOPMENT } from 'Shared/constants'
import { getLocalAndSessionCredentials } from '../Shared/helpers'
import { resetCaseManagementFilters } from 'ApolloClient/caseManagementCache'
import loadingIndicatorLink from './loadingIndicatorLink'
import { customFetch, redirectToLoginPath } from './helpers'
const server = `${API_BASE}/graphql`

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getLocalAndSessionCredentials(),
    },
  }
})

const httpLink = new HttpLink({
  uri: server,
  fetch: customFetch,
  headers: { ...getLocalAndSessionCredentials() },
})

const batchHttpLink = new BatchHttpLink({
  uri: server,
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
})

const errorLink = onError(({ operation, networkError }) => {
  const { pathname } = window.location
  if (networkError?.statusCode === 401) {
    redirectToLoginPath(pathname, operation)
  }
})

const caseManagementClient = new ApolloClient({
  link: from([loadingIndicatorLink, authLink, errorLink, httpLink]),
  cache: cache,
  connectToDevTools: IS_DEVELOPMENT,
})

export const batchCaseManagementClient = new ApolloClient({
  link: from([loadingIndicatorLink, authLink, errorLink, batchHttpLink]),
  cache: cache,
  connectToDevTools: IS_DEVELOPMENT,
})

export const reset = () =>
  new Promise((resolve, reject) => {
    caseManagementClient.resetStore().then(() => {
      resetCaseManagementFilters()
      resolve()
    })
  })

export default caseManagementClient
