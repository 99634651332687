import React from 'react'
import SectionContent from 'Components/Review/ReviewSection/ReviewSectionContent'
import FormError from 'Components/Shared/FormError/FormError'
import {
  StyledCard,
  StyledCardHeader,
} from 'Components/Review/ReviewSection/style'
import { formTouched } from '../helpers'
import ActionButton from './ActionButton/ActionButton'

const ReviewSectionCard = ({
  title,
  titleIcon,
  isEditing,
  error,
  submitError,
  data,
  touched,
  readOnly,
  ...buttonProps
}) => (
  <StyledCard>
    <StyledCardHeader
      title={title}
      avatar={titleIcon}
      action={
        <ActionButton
          {...buttonProps}
          isEditing={isEditing}
          editDisabled={readOnly}
        />
      }
    />
    <FormError error={error ?? submitError} touched={formTouched(touched)} />
    <SectionContent data={data} isEditing={isEditing} />
  </StyledCard>
)

export default ReviewSectionCard
