import React from 'react'
import { Field } from 'redux-form'
import SelectMenuOptions from 'Components/Shared/ReduxForm/SelectMenu/SelectMenuOptions'
import SelectMenuMuiRenderField from 'Components/Shared/ReduxForm/SelectMenuMuiRenderField'
import ToolTip from './ToolTip'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'
import { Row, Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import ScreenerActions from 'Components/Screener/screenerActions'

const SelectMenuField = (props) => {
  const {
    options,
    questionDirectionText,
    questionText,
    toolTip,
    error,
    label: questionLabel,
  } = props
  return (
    <>
      {questionText && (
        <StyledHeadingMain id='mainContent'>{questionText}</StyledHeadingMain>
      )}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}
      {toolTip && <ToolTip text={toolTip} />}
      <StyledHouseholdMembersListItem margin='0'>
        <MuiThemeProvider theme={ssTheme}>
          <StyledHouseholdMembersList>
            <Row>
              <Col lg='12'>
                <StyledMuiTextFieldWrapper width='100%'>
                  <Field
                    {...props}
                    component={SelectMenuMuiRenderField}
                    type='text'
                  >
                    {SelectMenuOptions({ options, questionLabel })}
                  </Field>
                  <FormSubmissionError error={error} />
                </StyledMuiTextFieldWrapper>
              </Col>
            </Row>
          </StyledHouseholdMembersList>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
      <ScreenerActions
        {...props}
        tabletWidth={'60%'}
        tabletMargin={'0 auto 2rem'}
      />
    </>
  )
}

export default SelectMenuField
