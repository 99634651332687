import React from 'react'

const legalIconPath = (
  <g id='a'>
    <g transform='translate(-48.598 -40.399)'>
      <path
        d='M39.392.665C61.137-4.316,62.02,19.306,62.008,50.673S66.343,118.02,44.6,123-.011,97.848,0,66.481,17.647,5.647,39.392.665Z'
        transform='translate(70.354 169.018) rotate(-115)'
        fill='#ffe9b1'
        opacity='0.8'
      />
      <g transform='translate(67.316 53.501)'>
        <g transform='translate(0 54.537)'>
          <path
            d='M49.933,55.948V66.162H1.5V55.948A5.641,5.641,0,0,1,7.148,50.3H44.4A5.78,5.78,0,0,1,49.933,55.948Z'
            transform='translate(-1.5 -50.3)'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='3.3'
          />
        </g>
        <g transform='translate(9.163 0)'>
          <rect
            width='20.309'
            height='25.356'
            transform='translate(22.518 4.54) rotate(45)'
            strokeWidth='3.3'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            fill='none'
          />
          <path
            d='M24,38.563,9.936,24.382a2.684,2.684,0,0,1,0-3.845h0a2.684,2.684,0,0,1,3.845,0L27.963,34.716a2.684,2.684,0,0,1,0,3.845h0A2.829,2.829,0,0,1,24,38.563Z'
            transform='translate(-9.125 2.145)'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='3.3'
          />
          <path
            d='M42.2,20.363,28.136,6.182a2.684,2.684,0,0,1,0-3.845h0a2.684,2.684,0,0,1,3.845,0L46.163,16.4a2.684,2.684,0,0,1,0,3.845h0a2.727,2.727,0,0,1-3.967.122Z'
            transform='translate(-5.454 -1.526)'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='3.3'
          />
          <path
            d='M69.215,56.708,35.207,22.7,30.4,27.507,64.408,61.515a3.409,3.409,0,0,0,4.807,0h0A3.408,3.408,0,0,0,69.215,56.708Z'
            transform='translate(-4.834 2.745)'
            fill='none'
            stroke='#000'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='10'
            strokeWidth='3.3'
          />
        </g>
      </g>
    </g>
  </g>
)

export default legalIconPath
