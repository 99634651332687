import authClient, { reset as authReset } from 'ApolloClient/authClient'
import { LOG_OUT } from './gql'
import { useMutation } from '@apollo/client'
// import { reset as caseManagementReset } from 'ApolloClient/caseManagementClient'
import useClearReduxStore from 'Components/Shared/Hooks/useClearReduxStore'
import * as Sentry from '@sentry/browser'

const useLogOut = () => {
  const { handleClearReduxStore } = useClearReduxStore()

  const [logoutMutation] = useMutation(LOG_OUT, {
    client: authClient,
  })

  // https://stackoverflow.com/questions/48887480/reset-store-after-logout-with-apollo-client
  const handleLogOut = () =>
    new Promise((resolve) => {
      return logoutMutation()
        .then((response) => {
          Sentry.configureScope((scope) => scope.setUser(null))
          // the response payload comes from LOG_OUT gql
          clearUserData()
          authReset().then(() => resolve(response))

          // Kevin Kim: Unfortunately caseManagementReset() breaks in an odd unresolved rejected promise
          // Let's debug later and try to find some time to see if we even really need caseManagementReset

          // authReset().then(() => {
          //   caseManagementReset()
          //     .then(() => {
          //       resolve(response)
          //     })
          //     .catch((err) => {
          //       debugger
          //       console.log(err)
          //     })
          // })
        })
        .catch((err) => {
          console.error(err)
          resolve(err)
        })
    })

  const clearUserData = () => {
    sessionStorage.clear()
    localStorage.clear()
    handleClearReduxStore()
  }

  return {
    handleLogOut,
    clearUserData,
  }
}

export default useLogOut
