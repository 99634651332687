import React from 'react'
import ReviewSectionTableRow from './ReviewSectionTableRow'
import { StyledTableBody } from 'Components/Review/ReviewSection/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'

const ReviewSectionTableBody = ({ rows, labels }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  return (
    <>
      {isMobile && (
        <>
          {rows?.map((row, i) => (
            <ReviewSectionTableRow key={i} row={row} labels={labels} />
          ))}
        </>
      )}
      {!isMobile && (
        <StyledTableBody>
          {rows?.map((row, i) => (
            <ReviewSectionTableRow key={i} row={row} labels={labels} />
          ))}
        </StyledTableBody>
      )}
    </>
  )
}

export default ReviewSectionTableBody
