import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { ArrowSpan } from 'Components/Shared/Dialog/style'

import { SelectBtn, SelectDropDown } from './style'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 'unset',
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid #DDDDDD',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    height: '290px',
    overflow: 'auto',
    margin: '0 0 1rem 0',
    boxShadow: '4px 8px 19px #0000000F',
    borderRadius: '0px 0px 23px 23px',
  },
}))

const ClickAway = ({ ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <SelectBtn type='button' onClick={handleClick}>
          {props.btnName}
          <ArrowSpan />
        </SelectBtn>
        {open ? (
          <SelectDropDown className={classes.dropdown}>
            {props.content}
          </SelectDropDown>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

export default ClickAway
