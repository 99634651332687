import styled from 'styled-components/macro'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'
import { MenuItem } from '@material-ui/core'

export const StyledLabel = styled.label`
  font-size: 0.975rem;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
`

export const StyledSelect = styled(CustomDropdown)`
  color: '#29283E';
  font-size: 0.875rem;
  font-weight: 400;
  fontfamily: Noto Sans HK, sans-serif;
`

export const StyledOption = styled(MenuItem)`
  font-size: inherit;
`
