import { Container } from 'styled-bootstrap-grid'
import { Row } from 'styled-bootstrap-grid'
import React from 'react'
import ValueDisplay from './ReviewSectionValue'

/**
 * Value pair component used in Review Sections
 *
 * @param {{tableDisplays:Array, isEditing:boolean}} param0
 * @returns
 */
const ReviewSectionValues = ({ valueDisplays, isEditing }) => (
  <Container>
    <Row>
      {valueDisplays?.map((value, i) => (
        <ValueDisplay
          item
          key={i}
          isEditing={value.editable && isEditing}
          {...value}
        />
      ))}
    </Row>
  </Container>
)

export default ReviewSectionValues
