import React from 'react'

export const IncompleteIcon = (
  <>
    <circle
      className='a'
      fill='#ffb5b5'
      cx='8.545'
      cy='8.545'
      r='8.545'
      transform='translate(0.736 0.736)'
    />
    <circle
      cx='0.789'
      cy='0.789'
      r='0.789'
      transform='translate(14.777 13.775)'
    />
    <path
      d='M99.282,90a9.282,9.282,0,1,0,4.414,17.445.559.559,0,1,0-.533-.983,8.167,8.167,0,1,1,3.3-3.3.559.559,0,1,0,.983.533A9.279,9.279,0,0,0,99.282,90Z'
      transform='translate(-90 -90)'
    />
    <path
      className='b'
      style={{
        fill: 'none',
        stroke: '#000',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
      d='M10,10l5.232,5.232m0-5.232L10,15.232'
      transform='translate(-3.398 -2.93)'
    />
  </>
)

export const BypassedIcon = (
  <>
    <g transform='translate(0.449 0.449)'>
      <circle
        className='a'
        fill='#dfe6ff'
        cx='8.564'
        cy='8.564'
        r='8.564'
        transform='translate(-0.307 -0.254)'
      />
    </g>
    <circle
      cx='0.481'
      cy='0.481'
      r='0.481'
      transform='translate(14.497 14.497)'
    />
    <path
      d='M99.013,90a9.013,9.013,0,1,0,4.286,16.939.543.543,0,0,0-.518-.954,7.93,7.93,0,1,1,3.2-3.2.543.543,0,1,0,.954.518A9.011,9.011,0,0,0,99.013,90Z'
      transform='translate(-90 -90)'
    />
    <g transform='translate(1.634 1.948)'>
      <path className='b' fill='none' d='M0,0H14.633V14.633H0Z' />
      <circle
        className='c'
        fill='none'
        style={{
          stroke: '#000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        cx='5.5'
        cy='5.5'
        r='5.5'
        transform='translate(2.009 1.745)'
      />
      <path
        className='c'
        fill='none'
        style={{
          stroke: '#000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        d='M12,7v3.049l1.829,1.829'
        transform='translate(-3.991 -2.253)'
      />
    </g>
  </>
)

export const DoneIcon = (
  <g transform='translate(0 0)'>
    <g transform='translate(0.736 0.736)'>
      <circle
        className='a'
        style={{
          fill: '#e5e5e5',
        }}
        cx='14.045'
        cy='14.045'
        r='14.045'
        transform='translate(0 0)'
      />
    </g>
    <circle
      cx='0.789'
      cy='0.789'
      r='0.789'
      transform='translate(23.777 23.777)'
    />
    <path
      d='M104.782,90a14.782,14.782,0,1,0,7.03,27.782.891.891,0,0,0-.85-1.565,13.006,13.006,0,1,1,5.255-5.255.891.891,0,1,0,1.565.85,14.778,14.778,0,0,0-13-21.812Z'
      transform='translate(-90 -90)'
    />
    <g transform='translate(2.68 3.195)'>
      <path className='b' style={{ fill: 'none' }} d='M0,0H24V24H0Z' />
      <circle
        style={{
          fill: 'none',
          stroke: '#000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }}
        cx='2'
        cy='2'
        r='2'
        transform='translate(6 10)'
      />
      <rect
        className='c'
        style={{
          fill: 'none',
          stroke: '#000',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
        }}
        width='20'
        height='12'
        rx='6'
        transform='translate(2 6)'
      />
    </g>
  </g>
)

export const CompleteIcon = (
  <g transform='translate(-2.699 -2.699)'>
    <g transform='translate(2.699 2.699)'>
      <g transform='translate(0.444 0.444)'>
        <circle
          className='a'
          fill='#c1ffda'
          cx='8.471'
          cy='8.471'
          r='8.471'
          transform='translate(0 0)'
        />
      </g>
      <path
        d='M179.212,216.157l-4.454,4.453-2.842-2.842a.537.537,0,0,0-.76.759l3.222,3.222a.537.537,0,0,0,.76,0l4.833-4.833a.537.537,0,1,0-.759-.759Z'
        transform='translate(-166.649 -209.234)'
      />
      <circle
        cx='0.476'
        cy='0.476'
        r='0.476'
        transform='translate(14.34 14.34)'
      />
      <path
        d='M98.915,90a8.915,8.915,0,1,0,4.24,16.755.537.537,0,0,0-.512-.944,7.844,7.844,0,1,1,3.169-3.169.537.537,0,1,0,.944.512A8.913,8.913,0,0,0,98.915,90Z'
        transform='translate(-90 -90)'
      />
    </g>
  </g>
)

export const ConfirmationRateIcon = (
  <>
    <circle fill='#dfe8ff' cx='30.352' cy='30.352' r='30.352' />
    <g transform='translate(4.572 4.32)'>
      <path fill='none' d='M0,0H51.866V51.866H0Z' />
      <circle
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        cx='6.483'
        cy='6.483'
        r='6.483'
        transform='translate(25.933 25.933)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M13,17.5v9.725l4.322-3.242,4.322,3.242V17.5'
        transform='translate(15.094 20.319)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M18.127,35.255H7.322A4.322,4.322,0,0,1,3,30.933V9.322A4.335,4.335,0,0,1,7.322,5H37.577A4.322,4.322,0,0,1,41.9,9.322V30.933a4.322,4.322,0,0,1-2.161,3.739'
        transform='translate(3.483 5.805)'
      />
      <line
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        x2='25.933'
        transform='translate(12.966 19.45)'
      />
      <line
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        x2='6.483'
        transform='translate(12.966 25.933)'
      />
      <line
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        x2='4.322'
        transform='translate(12.966 32.416)'
      />
    </g>
  </>
)

export const MultiConfirmationRateIcon = (
  <>
    <g transform='translate(0 0)'>
      <circle
        fill='#e4d2ff'
        cx='30.297'
        cy='30.297'
        r='30.297'
        transform='translate(0 0)'
      />
    </g>
    <g transform='translate(4.508 6.314)'>
      <path fill='none' d='M0,0H41.455V41.455H0Z' />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M14,3V9.909a1.727,1.727,0,0,0,1.727,1.727h6.909'
        transform='translate(10.182 2.182)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M5,11.637V6.455A3.455,3.455,0,0,1,8.455,3H20.546l8.637,8.637v19a3.455,3.455,0,0,1-3.455,3.455H17.091'
        transform='translate(3.637 2.182)'
      />
      <circle
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        cx='5.182'
        cy='5.182'
        r='5.182'
        transform='translate(5.182 19)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M5.591,17,3,25.637l5.182-2.591,5.182,2.591L10.773,17'
        transform='translate(2.182 12.364)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M5,24.273v3.455a3.455,3.455,0,0,0,3.455,3.455H20.546A3.455,3.455,0,0,0,24,27.728V10.455A3.455,3.455,0,0,0,20.546,7H17.091'
        transform='translate(17.299 11.999)'
      />
    </g>
  </>
)

export const TotalDrawdownIcon = (
  <g transform='translate(-0.396)'>
    <circle
      fill='#c1ffda'
      cx='30.352'
      cy='30.352'
      r='30.352'
      transform='translate(0.396)'
    />
    <g transform='translate(2.395 2)'>
      <path fill='none' d='M0,0H56.307V56.307H0Z' />
      <circle
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        cx='21'
        cy='21'
        r='21'
        transform='translate(7 7.307)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M22.607,10.348A4.692,4.692,0,0,0,18.384,8H13.692a4.692,4.692,0,0,0,0,9.384h4.692a4.692,4.692,0,1,1,0,9.384H13.692a4.692,4.692,0,0,1-4.223-2.346'
        transform='translate(12.115 10.767)'
      />
      <path
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5px'
        fill='none'
        d='M12,6v4.692m0,18.769v4.692'
        transform='translate(16.153 8.077)'
      />
    </g>
  </g>
)

export const PotentialBenefitIcon = (
  <g transform='translate(-0.396)'>
    <circle
      fill='#ffe9b1'
      cx='30.352'
      cy='30.352'
      r='30.352'
      transform='translate(0.396)'
    />
    <g transform='translate(5 2)'>
      <path fill='none' d='M0,0H56.307V56.307H0Z' />
      <path id='Path_17750' d='M0,0H53.341V56.388H0Z' fill='none' />
      <rect
        width='31'
        height='22'
        rx='4'
        transform='translate(15.538 21.683)'
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5'
      />
      <circle
        cx='4.5'
        cy='4.5'
        r='4.5'
        transform='translate(26.538 28.683)'
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5'
      />
      <path
        d='M33.8,13.8V9.4A4.4,4.4,0,0,0,29.4,5H7.4A4.4,4.4,0,0,0,3,9.4V22.6A4.4,4.4,0,0,0,7.4,27h4.4'
        transform='translate(3.6 7.281)'
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2.5'
      />
    </g>
  </g>
)

export const FilterIcon = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z' />
  </>
)

export const CloseIcon = (
  <>
    <path d='M0 0h24v24H0V0z' fill='none' />
    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z' />
  </>
)

export const ClosedCaseIcon = (
  <>
    <circle
      id='Ellipse_1'
      data-name='Ellipse 1'
      cx='8.471'
      cy='8.471'
      r='8.471'
      transform='translate(0 0)'
      fill='#FFD7D7'
    />
    <circle
      id='Ellipse_2'
      data-name='Ellipse 2'
      cx='0.476'
      cy='0.476'
      r='0.476'
      transform='translate(14.34 14.34)'
    />
    <path
      id='Path_4'
      data-name='Path 4'
      d='M98.915,90a8.915,8.915,0,1,0,4.24,16.755.537.537,0,0,0-.512-.944,7.844,7.844,0,1,1,3.169-3.169.537.537,0,1,0,.944.512A8.913,8.913,0,0,0,98.915,90Z'
      transform='translate(-90 -90)'
    />
  </>
)
