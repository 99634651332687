import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import backIconPath from 'Shared/Icons/backIconPath'
import { white } from 'Shared/Theme/index'

const ContinueIcon = () => {
  return (
    <Icon
      position={'absolute'}
      right={'1.375rem'}
      fill={white}
      margin={'0'}
      scale={'1rem'}
      rotate='true'
      viewBox={'0 0 24 24'}
      cursorOnHover={'pointer'}>
      {backIconPath}
    </Icon>
  )
}

export default ContinueIcon
