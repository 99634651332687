import React from 'react'
import { StyledPeople, StyledPeopleParent } from './style'

const makeLabel = ({ findOutMore, eligible, people }) => {
  let label
  if (eligible) {
    label = people.length ? 'Likely Eligible For' : 'Likely Eligible'
  } else {
    label = 'Unlikely Eligible'
  }
  return label
}

const EligibilityStatus = ({ findOutMore, eligible, people }) => {
  return (
    <StyledPeopleParent>
      <span>{makeLabel({ findOutMore, eligible, people })}</span>
      {people && (
        <StyledPeople>
          {people.map((item) => item?.split(',')[0]).join(', ')}
        </StyledPeople>
      )}
    </StyledPeopleParent>
  )
}

export default EligibilityStatus
