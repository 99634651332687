const toNumber = (value) => Number(value.replace(/,/g, ''))

const formatters = {
  toCurrencyString: (value) =>
    value
      ? String(value)
          .replace(/,/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '',
  toBoolean: (value) => (value ? value === 'true' : ''),
  toNumberString: (value) => (value ? value.replace(/,/g, '') : ''),
  toNumber,
  ageToNumber: (value) => (value ? toNumber(value) : ''),
  lowercase: (value) => (value ? value.toLowerCase() : ''),
}
export default formatters
