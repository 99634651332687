import styled from 'styled-components/macro'
import { media } from 'styled-bootstrap-grid'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

export const StyledParentFilter = styled.div`
  ${media.smaller`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9;
  `}
  ${media.tablet`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9;
  `}
`

export const StyledHeaderFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 2rem;
  ${media.smaller`
  padding: 2rem;
  `}
  ${media.tablet`
  padding: 2rem 4rem;
  `}
`

export const StyledFilterSpan = styled.span`
  width: 22px;
`
export const StyledFilterClearButton = withStyles({
  root: {
    color: '#29283E',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'inherit',
    fontSize: '0.9375rem',
    textDecoration: 'underline',
    padding: '0',
    minWidth: 'auto',
    position: 'relative',
    top: '0.9rem',
  },
})(Button)

export const StyledFilterMenu = styled.div`
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  padding: 0 2rem;
  height: calc(100vh - 195px);
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.smaller`
  padding: 2rem 2rem;
  `}
  ${media.tablet`
  padding: 0rem 4rem;
  `}
  ${media.desktop`
  padding: 0 2rem;
  `}
`
export const StyledFilterTitle = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 0.8em;
`
export const StyledFilterRow = styled.div`
  margin-bottom: 1.8rem;
`

export const StyledFilterFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
`

export const StyledFilterBtn = styled.button`
  width: 100%;
  min-height: 4rem;
  box-shadow: 0px 11px 7px #0000000f;
  border: 1px solid #6363631c;
  border-radius: 4px;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  ${media.desktop`
    min-height: 3.375rem;
    margin-bottom: 1rem;
  `}
`

StyledParentFilter.displayName = 'StyledParentFilter'
StyledHeaderFilter.displayName = 'StyledHeaderFilter'
StyledFilterSpan.displayName = 'StyledFilterSpan'
StyledFilterMenu.displayName = 'StyledFilterMenu'
StyledFilterTitle.displayName = 'StyledFilterTitle'
StyledFilterRow.displayName = 'StyledFilterRow'
StyledFilterFlex.displayName = 'StyledFilterFlex'
StyledFilterBtn.displayName = 'StyledFilterBtn'
