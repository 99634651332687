import React, { useMemo } from 'react'

import { createIdFromLabel } from 'Shared/helpers'

import { StyledIcon, screenReaderContext } from './style'

const Icon = (props) => {
  const { children, screenReaderLabel, viewBox, width, fill, opacity } = props

  const defaultAriaLabelledBy = useMemo(
    () => `titleID_${createIdFromLabel(screenReaderLabel)}`,
    [screenReaderLabel]
  )

  const ariaLabelledBy = props['aria-labelledby'] || defaultAriaLabelledBy

  return (
    <StyledIcon {...props}>
      <svg
        viewBox={viewBox || '0 0 120 120'}
        style={{
          display: 'flex',
          width: `${width ? width : ''}`,
          fill: `${fill ? fill : ''}`,
          height: '100%',
          enableBackground: 'new 0 0 24 24',
          opacity: `${opacity ? opacity : 1}`,
        }}
        role='img'
        aria-labelledby={ariaLabelledBy}
      >
        <title id={ariaLabelledBy} style={screenReaderContext}>
          {screenReaderLabel}
        </title>
        {children}
      </svg>
    </StyledIcon>
  )
}

export default Icon
