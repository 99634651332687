import React from 'react'
import {
  StyledErrorSpan,
  StyledFormHelperText,
} from 'Components/Shared/Error/style'

const FormError = ({ touched, error, id }) => {
  if (touched && error) {
    return (
      <StyledFormHelperText
        aria-live='polite'
        aria-label={`Error: ${error}`}
        id={id}
      >
        {error ? (
          <StyledErrorSpan role='alert'>{error}</StyledErrorSpan>
        ) : (
          <StyledErrorSpan role='alert' aria-hidden='true'>
            {error}
          </StyledErrorSpan>
        )}
      </StyledFormHelperText>
    )
  } else {
    return null
  }
}

export default FormError
