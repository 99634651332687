import React from 'react'
import {
  StyledFormHelperText,
  StyledErrorIcon,
} from 'Components/Shared/ReduxForm/style'
import { StyledErrorSpan } from 'Components/Shared/Error/style'

const FormSubmissionError = ({ error }) => {
  if (error) {
    return (
      <StyledFormHelperText aria-live='polite' aria-label={`Error: ${error}`}>
        <StyledErrorIcon />
        {error ? (
          <StyledErrorSpan role='alert'>{error}</StyledErrorSpan>
        ) : (
          <StyledErrorSpan role='alert' aria-hidden='true'>
            {error}
          </StyledErrorSpan>
        )}
      </StyledFormHelperText>
    )
  } else {
    return null
  }
}

export default FormSubmissionError
