import React from 'react'
import { StyledMenuItem } from 'Components/Shared/ReduxForm/SelectMenu/style'

const SelectMenuOptions = ({ options, questionLabel }) =>
  options.map((option) => {
    const { label: optionLabel, value } = option
    return (
      <StyledMenuItem
        id={value}
        value={value}
        key={value}
        className={optionLabel === '5 or more' ? 'resetCapitalize' : null}
      >
        {optionLabel}
      </StyledMenuItem>
    )
  })

export default SelectMenuOptions
