import React from 'react'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import { StyledParagraph } from './style'
import useAuthenticationPath from 'Components/PreScreener/Hooks/useAuthenticationPath'
import { useSelector } from 'react-redux'
import { emailOrPhone as selectEmailOrPhone } from 'Selectors'
import { parseEmailOrPhone } from 'Components/PreScreener/helpers'

const ConfirmSkipVerificationModal = ({
  handleCloseCase,
  mutationArgs,
  resetContext,
  open,
}) => {
  const { handleSkipAuthenticateAndContinue } = useAuthenticationPath()

  const emailOrPhone = selectEmailOrPhone(useSelector((state) => state))

  return (
    <ModalTemplate
      open={open}
      iconKey={''}
      heading={'Are you sure you want to skip verification?'}
      textAlign={'center'}
    >
      <StyledParagraph textAlign='center' marginBottom='.5em'>
        Please note that by selecting “Yes,” the client will become unverified.
        The client will not be able to access their profile without the
        assistance of a case manager.
      </StyledParagraph>
      <ConfirmOrCancel
        handleOnCancel={resetContext}
        handleOnConfirm={async () => {
          await handleSkipAuthenticateAndContinue(
            parseEmailOrPhone({ emailOrPhone })
          )
          resetContext()
        }}
        confirmLabel='Yes, Skip Verification'
        cancelLabel='Cancel'
      />
    </ModalTemplate>
  )
}

export default ConfirmSkipVerificationModal
