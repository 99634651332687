import React, { useState, useEffect } from 'react'
import { Field } from 'redux-form'
import Checkbox from '@material-ui/core/Checkbox'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import questionIconPath from 'Shared/Icons/CategoryIcons/questionIconPath'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import {
  StyledMuiTextFieldWrapper,
  StyledCheckboxLabelField,
} from 'Components/Shared/ReduxForm/style'
import CreateIcon from 'Components/Shared/Icon/CreateIcon'
import { Col } from 'styled-bootstrap-grid'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import 'Components/Uielements/styles/globalStyle.css'
import {
  MOBILE_MEDIA_QUERY,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  desktopAndTabletMediaQuery,
} from 'Shared/constants'
import MediaQuery from 'react-responsive'
import { useMediaQuery } from 'react-responsive'
import 'Components/Uielements/styles/globalStyle.css'
import {
  StyledParagraph,
  useStyles,
  StyledDialogContent,
  StyledRadioButtonUncheckedIcon,
  StyledCheckCircleIcon,
  StyledCardButton,
  StyledCardActions,
  StyledTypography,
  StyledSubHeading,
  StyledDialog,
  StyledDialogTitle,
  StyledCardActionArea,
  StyledCardContent,
} from 'Components/Screener/style'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { styles } from 'Components/Shared/Dialog/style'

const renderCheckbox = ({ input, label, ariaLabel }) => {
  return (
    <StyledCheckboxLabelField
      control={
        <Checkbox
          icon={<StyledRadioButtonUncheckedIcon />}
          checkedIcon={<StyledCheckCircleIcon />}
          label={label}
          checked={input.value ? true : false}
          onChange={input.onChange}
          inputProps={{ 'aria-label': ariaLabel }}
        />
      }
    />
  )
}

const CategoryIcon = ({ iconPath }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <CreateIcon
      radius={'0'}
      scale={isMobile ? '4.5rem' : '7rem'}
      padding={'0'}
      margin={isMobile ? '0 0 0 0.5rem' : 'auto'}
      fill={'auto'}
      iconPath={iconPath}
    />
  )
}

const CategoryIconForDialog = ({ iconPath }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <CreateIcon
      radius={'0'}
      scale={isMobile ? '4.5rem' : '7rem'}
      padding={'0'}
      margin={isMobile ? '0 0 0 0.5rem' : '0'}
      fill={'auto'}
      iconPath={iconPath}
    />
  )
}

const DualText = ({ line1, line2 }) => {
  return (
    <StyledMuiTextFieldWrapper mbottom='0' textalign='center'>
      <StyledTypography gutterBottom variant='h5' component='h2' tabIndex='0'>
        {line1}
      </StyledTypography>
      {line2 && (
        <Typography
          variant='body2'
          color='textSecondary'
          component='p'
          tabIndex='0'
        >
          {line2}
        </Typography>
      )}
    </StyledMuiTextFieldWrapper>
  )
}

const CardActionsButton = ({ label, onClick }) => {
  return (
    <StyledMuiTextFieldWrapper mbottom='0' justCont='right' display='flex'>
      <StyledCardActions id={label}>
        <StyledCardButton size='small' onClick={onClick} tabIndex='0'>
          {label}
        </StyledCardButton>
      </StyledCardActions>
    </StyledMuiTextFieldWrapper>
  )
}

const CategoryField = ({ id, component, name, label }) => (
  <StyledMuiTextFieldWrapper mbottom='0' textalign='left' categories={true}>
    <Field
      name={`category[key-${id}]`}
      component={renderCheckbox}
      label={name}
      ariaLabel={label}
    />
  </StyledMuiTextFieldWrapper>
)

const PreScreenerCategory = ({ icon }) => {
  const isTabletAndMobile = useMediaQuery({
    query: MOBILE_AND_TABLET_MEDIA_QUERY,
  })

  const [open, setOpen] = useState(false)

  const handleClose = (label) => {
    setOpen(false)
    setTimeout(() => {
      document.getElementById(label)?.focus()
    })
  }
  const { id, name, explanation, detailedExplanation } = icon
  const iconPath = categoryIconPathsMap[id]

  const handleHelpClick = (e) => {
    e.preventDefault()
    setOpen(!open)
    setTimeout(() => {
      document.getElementById('closeModalBtn')?.focus()
    })
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, uniqueId, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
            tabIndex='0'
            id='closeModalBtn'
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <StyledDialogTitle variant='h1' id={uniqueId} tabIndex='0'>
          {children}
        </StyledDialogTitle>
      </MuiDialogTitle>
    )
  })

  const classes = useStyles()

  // Accessibility audit fix: Added Aria Attr in Right Div of Modal and Remove Role Presentation
  useEffect(() => {
    setTimeout(() => {
      const ModalContainer = document.querySelector('div[role="dialog"]')
      if (ModalContainer) {
        ModalContainer.setAttribute('aria-modal', 'true')
      }
      const ModalRolePresentation = document.querySelector(
        'div[role="presentation"]'
      )
      const ModalRoleNone = document.querySelector(
        'div[role="none presentation"]'
      )
      if (ModalRolePresentation) {
        ModalRolePresentation.removeAttribute('role')
      }
      if (ModalRoleNone) {
        ModalRoleNone.removeAttribute('role')
      }
    })
  })

  return (
    <MuiThemeProvider theme={ssTheme}>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <StyledDialog
          fullScreen={isTabletAndMobile}
          className='dialogInMobile'
          onClose={handleClose}
          aria-labelledby={name}
          open={open}
        >
          <DialogTitle uniqueId={name} onClose={handleClose}>
            <CategoryIcon iconPath={iconPath} />
            <DualText line1={name} line2={`What is ${name} help?`} />
          </DialogTitle>
          <StyledParagraph dialogdesktop tabIndex='0'>
            {explanation}
          </StyledParagraph>
          {detailedExplanation?.length > 0 && (
            <StyledDialogContent>
              <DualText
                line1={`We help with ${name} in the following ways.`}
                line2={detailedExplanation}
              />
            </StyledDialogContent>
          )}
        </StyledDialog>

        <Col col='12'>
          <MuiThemeProvider theme={ssTheme}>
            <Card
              className={classes.root + ' customHeight'}
              role='group'
              aria-label='assistance'
            >
              <StyledCardActionArea>
                <CategoryField id={id} label={name} />
                <CategoryIcon iconPath={iconPath} />
                <StyledCardContent>
                  <StyledSubHeading
                    textalign='center'
                    fontSize='1.0625rem'
                    marginBottom='0'
                    fontWeight='500'
                    padding='0.5rem'
                  >
                    {name}
                  </StyledSubHeading>
                </StyledCardContent>
              </StyledCardActionArea>
              <CardActionsButton
                label={
                  <CreateIcon
                    viewBox={'0 0 24 24'}
                    radius={'0'}
                    scale={'2rem'}
                    padding={'0'}
                    margin={'0 0 0 auto'}
                    fill={'#1e1e1e91'}
                    iconPath={questionIconPath}
                  />
                }
                onClick={handleHelpClick}
              />
            </Card>
          </MuiThemeProvider>
        </Col>
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <Dialog onClose={handleClose} aria-labelledby={name} open={open}>
          <DialogTitle uniqueId={name} onClose={handleClose}>
            <CategoryIconForDialog iconPath={iconPath} />
            <DualText line1={name} />
          </DialogTitle>
          <StyledParagraph dialogdesktop tabIndex='0'>
            {explanation}
          </StyledParagraph>
          {detailedExplanation?.length > 0 && (
            <StyledDialogContent>
              <DualText
                line1={`We help with ${name} in the following ways.`}
                line2={detailedExplanation}
              />
            </StyledDialogContent>
          )}
        </Dialog>

        <Col lg='3' md='4'>
          <MuiThemeProvider theme={ssTheme}>
            <Card
              className={`${classes.root} customHeight`}
              role='group'
              aria-label='assistance'
            >
              <CategoryField id={id} label={name} />
              <CategoryIcon iconPath={iconPath} />
              <StyledCardContent>
                <StyledSubHeading
                  textalign='center'
                  fontSize='1.0625rem'
                  marginBottom='0'
                  fontWeight='500'
                  padding='0.5rem 0'
                >
                  {name}
                </StyledSubHeading>
              </StyledCardContent>
              <CardActionsButton
                label={`What is ${name} help?`}
                onClick={handleHelpClick}
              />
            </Card>
          </MuiThemeProvider>
        </Col>
      </MediaQuery>
    </MuiThemeProvider>
  )
}
export default PreScreenerCategory
