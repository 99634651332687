import React, { useState } from 'react'

import LocationInfo from '../LocationInfo/LocationInfo'

import {
  StyledBusinessHoursData,
  StyledDays,
  StyledBusinessHoursButton,
} from './style'

import { timeIconPath } from 'Shared/Icons/LocationInfo/paths'

import { ArrowSpan } from 'Components/Shared/Dialog/style'
import theme from 'Shared/Theme/ssTheme'

const BusinessHours = (props) => {
  const { businessHours } = props

  const todayEnabled = false
  const [showHours, toggleHours] = useState(true)
  const Today = new Date()
    .toLocaleString('default', { weekday: 'long' })
    .toLowerCase()
  const Day = (props) => {
    const { day, openingTime, closingTime } = props
    return (
      <tr style={{ color: Today === day ? theme.palette.primary.dark : '' }}>
        <StyledBusinessHoursData paddingProp={'0.4rem 0'}>
          {day}
        </StyledBusinessHoursData>
        <StyledBusinessHoursData>
          {openingTime === null && closingTime === null
            ? 'Closed'
            : openingTime + '-' + closingTime}
        </StyledBusinessHoursData>
      </tr>
    )
  }

  const renderToday = () => {
    return (
      <StyledBusinessHoursButton
        onClick={() => toggleHours(!showHours)}
        onKeyDown={() => toggleHours(!showHours)}
        role='button'
      >
        {!todayEnabled && (
          <>
            <div>Business Hours</div>
            {showHours ? (
              <ArrowSpan
                right={'-40px'}
                afterTop={'24%'}
                afterLeft={'0'}
                toggleRotate
                screenReaderLabel={'Open or Close'}
              />
            ) : (
              <ArrowSpan
                right={'-40px'}
                afterLeft={'0'}
                screenReaderLabel={'Open or Close'}
              />
            )}
          </>
        )}
      </StyledBusinessHoursButton>
    )
  }

  const days = (businessHours) => (
    <StyledDays>
      <tbody>
        {businessHours.map((day) => (
          <Day {...day} key={day.id} />
        ))}
      </tbody>
    </StyledDays>
  )

  const content = (
    <div>
      {renderToday()}
      {showHours ? days(businessHours) : null}
    </div>
  )

  if (businessHours?.length > 0) {
    return (
      <LocationInfo
        content={content}
        screenReaderLabel={'Business Hours'}
        icon={timeIconPath}
      />
    )
  } else {
    return null
  }
}

export default BusinessHours
