import React from 'react'
import Modal from '@material-ui/core/Modal'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import {
  StyledHeading,
  StyledModalConfirmation,
  StyledDetail,
} from 'Components/CaseManagement/Modals/ConfirmationModal/style'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'

const ConfirmationModal = ({
  handleOnCancel,
  handleOnConfirm,
  error,
  heading,
  detail,
  confirmLabel,
  confirmOnly,
  isReadOnly,
  submitting,
}) => {
  return (
    <Modal open>
      <StyledModalConfirmation>
        <StyledHeading marginBottom={'1rem'} fontWeight={500}>
          {heading}
        </StyledHeading>

        {detail && <StyledDetail>{detail}</StyledDetail>}

        <ConfirmOrCancel
          submitting={submitting}
          handleOnConfirm={handleOnConfirm}
          handleOnCancel={handleOnCancel}
          confirmLabel={confirmLabel}
          confirmOnly={confirmOnly}
          isReadOnly={isReadOnly}
        />

        {error && (
          <FormSubmissionError error='Something went wrong. Please Try Again' />
        )}
      </StyledModalConfirmation>
    </Modal>
  )
}

export default ConfirmationModal
