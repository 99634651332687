import styled, { css } from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import {
  isReportPage,
  isCaseManagementClints,
  isLocalResources,
} from 'Shared/helpers'

export const StyledInput = styled.input`
  padding: 0.6em 1.5em;
  line-height: 1.8;
  border: 1px solid #ececec;
  border-right: 0;
  &:focus {
    outline: unset;
    border-color: ${theme.palette.primary.dark};
  }
  @media only screen and (max-width: 480px) {
    flex-grow: 0;
    border-radius: 50px;
    width: 100%;
    border-right: 1px solid #ececec;
    height: 5rem;
    box-shadow: 0px 11px 7px #0000000f;
    font-size: 1.2rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 481px) {
    flex-grow: 0;
    border-radius: 50px;
    width: 100%;
    border-right: 1px solid #ececec;
    height: 5rem;
    box-shadow: 0px 11px 7px #0000000f;
    font-size: 1.2rem;
    font-weight: 500;
  }
  @media only screen and (min-width: 1025px) {
    flex-grow: 1;
    border-radius: 7px 0 0 7px;
    width: 100%;
    height: auto;
    box-shadow: none;
    font-size: 0.875rem;
    font-weight: inherit;
    padding: 0.75rem;
  } ;
`

export const StyledInputCaseManagement = styled.input`
  font-size: 0.75rem;
  padding: 0rem;
  line-height: 1;
  background-color: unset;
  width: 100%;
  &:focus {
    outline: unset;
  }
  border: none;
`

export const StyledSearchBox = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.4rem;
  display: flex;
  flex: 1 0;
  justify-content: space-between;
  min-height: 4.8rem;
`
type searchButtonProps = {
  isCaseManagement?: boolean
  clear?: boolean
}
export const SearchButton = styled.button<searchButtonProps>`
  background-color: ${(props) =>
    props.isCaseManagement ? 'unset' : theme.palette.primary.dark};
  text-shadow: 0 0 5px ${theme.palette.color.black};
  width: ${(props) => (props.isCaseManagement ? '1.75rem' : '4rem')};
  height: ${(props) => (props.isCaseManagement ? '100%' : '')};
  position: ${(props) => (props.isCaseManagement ? 'absolute' : '')};
  right: ${(props) => (props.isCaseManagement ? '0' : '')};
  top: ${(props) => (props.isCaseManagement ? '0' : '')};
  border-radius: 0.7rem;
  @media only screen and (max-width: 480px) {
    background-color: ${isReportPage() ||
    isCaseManagementClints() ||
    isLocalResources()
      ? 'unset'
      : '#fff'};
    position: absolute;
    z-index: 1;
    transform: translate(0, 50%);
    right: ${isReportPage() ? '2%' : '16%'};
    top: ${isReportPage() ? '-30%' : ''};
    padding-right: ${isReportPage() ? '0' : '0.5rem'};
    width: ${isReportPage() ? '' : '0 0 0 0.1875rem'};
    border: unset;
    &:hover {
      background-color: unset;
    }
    &:before {
      content: '';
      height: 75%;
      width: 0.0625rem;
      background-color: ${isReportPage() ? 'unset' : 'grey'};
      display: flex;
      position: absolute;
      right: 0;
      top: 14%;
    }
  }
  @media only screen and (min-width: 481px) {
    background-color: unset;
    position: absolute;
    z-index: 1;
    top: ${(props) => (props.isCaseManagement ? '30%' : '28%')};
    right: ${(props) => (props.isCaseManagement ? '0%' : '10%')};
    padding-right: 0.5rem;
    border: unset;
    &:hover {
      background-color: unset;
    }
    &:before {
      content: '';
      height: 75%;
      width: 1px;
      background-color: grey;
      display: ${(props) => (props.isCaseManagement ? 'none' : 'flex')};
      position: absolute;
      right: 0;
      top: 14%;
    }
  }
  @media only screen and (min-width: 1025px) {
    background-color: ${(props) =>
      props.isCaseManagement ? 'unset' : theme.palette.primary.dark};
    border-radius: 0.7rem;
    width: ${(props) => (props.isCaseManagement ? '1.75rem' : '5rem')};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    transform: translate(-0.5rem, 0rem);
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 0;
    right: 0;
    padding: 0;
    &:hover {
      background-color: ${(props) =>
        props.isCaseManagement ? 'unset' : theme.palette.primary.darken5};
    }
    &:before {
      content: '';
      height: 75%;
      width: 1px;
      background-color: grey;
      display: none;
      position: absolute;
      right: 0;
      top: 14%;
    }
  }
  ${({ clear }) =>
    clear &&
    css`
      &:hover {
        background: ${(props: searchButtonProps) =>
          props.isCaseManagement ? 'unset' : theme.palette.primary.darken5};
        cursor: pointer;
      }
    `};
`

export const StyledLabel = styled.label`
  position: absolute;
  left: 14px;
  top: -7px;
  background: #fff;
  font-size: 0.75rem;
  color: ${theme.palette.primary.dark};
  @media only screen and (max-width: 1024px) {
    left: 20px;
    top: -4px;
  }
`

StyledInput.displayName = 'StyledInput'
StyledSearchBox.displayName = 'StyledSearchBox'
