import React, { useLayoutEffect, useRef } from 'react'

import Card from 'Components/Shared/Card/Card'
import CategoryFilterItem from 'Components/LocalResources/Filtering/CategoryFilterItem/CategoryFilterItem'
import ClickAway from 'Components/Shared/MultiSelect/MultipleSelect'
import { multiSelectName } from 'Shared/helpers'
import { useAppSelector } from 'Store/hooks'

interface QuickFilters {
  [key: string]: {
    name: string
    selected: boolean
  }
}

type CategoryFilterProps = {
  callback: Function
  minHeightMob?: string
  minHeightTab?: string
}

const CategoryFilter = (props: CategoryFilterProps) => {
  const { callback } = props
  const quickFilters: QuickFilters = useAppSelector(
    (state) => state.filters.quickFilters
  )

  const filterItems = Object.keys(quickFilters).map((id) => {
    const filter = quickFilters[id]
    const { name } = filter

    return <CategoryFilterItem key={name} category={filter} id={id} />
  })

  const alphabetizedFilterItems = [...filterItems].sort((a, b) => {
    const keyA = a.key!
    const keyB = b.key!

    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })

  const isInitialRender = useRef(true)

  useLayoutEffect(() => {
    if (isInitialRender) {
      isInitialRender.current = false
      return
    }
    callback()
  }, [callback, isInitialRender, quickFilters])

  return (
    <Card
      minHeightMob={props.minHeightMob}
      minHeightTab={props.minHeightTab}
      elevation={1}
      boxShadow={'none'}
    >
      <ClickAway
        btnName={multiSelectName(quickFilters, 'Categories')}
        content={alphabetizedFilterItems}
      />
    </Card>
  )
}

export default CategoryFilter
