import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'

export const StyledDesktopFormControl = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    '& .select-input': {
      fontFamily: 'Noto Sans HK,sans-serif',
      fontSize: '0.9rem',
      fontWeight: '500',
      color: '#393946',
    },
    '& .select-input > div': {
      padding: '.5rem 0',
      paddingRight: '1.5rem',
      '&:focus': {
        background: 'unset',
        outline: '2px solid #000',
        borderRadius: '5px',
      },
    },
    '& .select-input svg': {
      right: '0',
    },
    '& .select-input fieldset': {
      border: 'unset',
    },
  },
})(FormControl)

export const StyledTabletAndMobileFormControl = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5rem',
    position: 'relative',
    '& .select-input-outside-menu': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: '0',
      left: '0',
      opacity: '0',
    },
    '& .select-input > div': {
      padding: '.5rem 0',
      paddingRight: '1.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      '&:focus': {
        background: 'unset',
        outline: '2px solid #000',
        borderRadius: '5px',
      },
    },
    '& .select-input svg': {
      right: '-0.5rem',
      top: 'calc(50% - 12px)',
      color: '#333',
      width: '2rem',
      height: '2rem',
    },
    '& .select-input fieldset': {
      border: 'unset',
    },
  },
})(FormControl)

export const StyledMenuItem = withStyles({
  root: {
    padding: '0.75rem 3rem !important',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Noto Sans HK,sans-serif !important',
    fontSize: '0.9rem',
    fontWeight: '500',
  },
})(MenuItem)
