import { isMobile } from 'react-device-detect'
import { getEnvironmentVariable } from 'Shared/helpers'
import {
  GET_SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART,
  GET_DEMOGRAPHIC_QUICK_DETAILS,
  GET_HOUSEHOLD_SCREENING_CHART_DATA,
  GET_SCREENING_DETAILS_CHART_DATA,
  GET_DEMOGRAPHIC_VERTICAL_BAR_CHART,
  GET_OUTCOME_SERVICE_CASE_CHART,
  GET_PROGRAM_ANALYTICS_QUICK_DETAILS,
  GET_SERVICE_CASES_BY_TYPE_CHART,
  GET_CONFIRMED_CASES_TIMESERIES_CHART,
  OVERALL_CONFIRMATION_RATE,
  OVERALL_MULTIPLE_CONFIRMATION_RATE,
  TOTAL_DRAWDOWN,
  ACTIVITY_BY_OUTCOMES_CHART,
  ACTIVITIES_BY_TYPE,
  KPI_QUICK_DETAILS,
  DRAWDOWN,
  TOTAL_POTENTIAL_BENEFIT,
} from 'Components/CaseManagement/Reporting/Hooks/gql'

/**
 * [GOOGLE_API_URL Base Google API URL]
 * @type {String}
 */
const GOOGLE_API_URL = 'https://maps.googleapis.com'

export const noop = () => null

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

export const API_BASE = process.env.REACT_APP_API_URL

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY

export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID

export const ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME

export const SMALL_MOBILE_MEDIA_QUERY = 'only screen and (max-width: 350px)'

export const MEDIUM_MOBILE_MEDIA_QUERY = 'only screen and (max-width: 370px)'

export const MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY =
  'only screen and (max-width : 767px)'

export const MOBILE_MEDIA_QUERY = 'only screen and (max-width : 480px)'

export const CUSTOM_MOBILE_TABLET_MEDIA_QUERY =
  'only screen and (max-width : 800px)'

export const CUSTOM_DESKTOP_MEDIA_QUERY = 'only screen and (min-width : 801px)'

export const TABLET_MEDIA_QUERY =
  'only screen and (min-width : 481px) and (max-width : 1024px)'

export const MOBILE_AND_TABLET_MEDIA_QUERY =
  'only screen and (max-width : 480px), only screen and (min-width : 481px) and (max-width : 1024px)'

export const DESKTOP_MEDIA_QUERY = 'only screen and (min-width: 1025px)'

export const desktopAndTabletMediaQuery =
  'only screen and (min-width : 481px) and (max-width : 1024px), only screen and (min-width: 1025px)'

export const LANDSCAPE_MEDIA_QUERY = '(orientation: landscape)'

export const PORTRAIT_MEDIA_QUERY = '(orientation: portrait)'

export const SCREENER_FORM_NAME = 'singleStopScreener'

export const PRE_SCREENER_FORM_NAME = 'singleStopPreScreener'

export const LOG_IN_FORM_NAME = 'singleStopLogin'

export const CASE_MANAGEMENT_RESET_PASSWORD_FORM_NAME =
  'caseManagementResetPassword'

export const CASE_MANAGEMENT_LOGIN_FORM_NAME = 'caseManagementLogin'

export const DEFAULT_ORGANIZATION = 'single-stop'

export const DEFAULT_LOCATION = 'hq'

/**
 * [GOOGLE_API_GEOCODE_URL Google Geocode API URL]
 * @type {String}
 */
export const GOOGLE_API_GEOCODE_URL = `${GOOGLE_API_URL}/maps/api/geocode/json`

export const GOOGLE_API_GEOCODE_URL_WITH_KEY = `${GOOGLE_API_URL}/maps/api/geocode/json?key=${GOOGLE_API_KEY}&language=en`

export const GTAG = process.env.REACT_APP_GTAG

/**
 * [ERROR_GENERIC_MESSAGE generic error message]
 * @type {String}
 */
export const ERROR_GENERIC_MESSAGE = 'An error has occurred, please try again'

/**
 * [ERROR_GENERIC_GEOLOCATION_MESSAGE generic geolocation error message]
 * @type {String}
 */
export const ERROR_GENERIC_GEOLOCATION_MESSAGE =
  'We were unable to geolocate you. Please try again.'

/**
 * [ERROR_INVALID_ZIP_CODE show when zip code does not exist]
 * @type {String}
 */
export const ERROR_INVALID_ZIP_CODE = 'Invalid Zip Code'

/**
 * [ERROR_MISSING_ZIP_CODE displayed when no zip is entered]
 * @type {String}
 */
export const ERROR_MISSING_ZIP_CODE = 'Enter zip code or share location'

/**
 * [ERROR_STATE_NOT_PERMITTED displayed when screening isn't available in a state]
 * @type {String}
 */
export const ERROR_STATE_NOT_PERMITTED = 'Sorry, screening is not available in '

/**
 * [ERROR_INVALID_ADDRESS_COMPONENT value returned if address component can't be found]
 * @type {String}
 */
export const ERROR_INVALID_ADDRESS_COMPONENT =
  'no appropriate address component found'

export const EMAIL_ALREADY_TAKEN_ERROR = `An account already exists for this Email or Phone Number. Click Log In to
login to your account`

export const USER_NOT_FOUND_ERROR = `An account does not exist for this Email or Phone number. Please register.`

export const ERROR_VALIDATION_FAIL = 'Data does not match our records'

export const ERROR_LOCKED_ACCOUNT =
  'This account is now locked. Please contact a case manager for assistance'

export const ERROR_MISSING_MAIL_PHONE =
  'Email address or phone number is required'

export const ERROR_REQUIRED_FIELD = 'This is a required field'

/**
 * [PERMITTED_STATE_NAMES States we screen for]
 * @type {Array}
 */
export const PERMITTED_STATE_NAMES = [
  'Arizona',
  'Colorado',
  'Connecticut',
  'Florida',
  'Indiana',
  'Louisiana',
  'Maine',
  'Massachusetts',
  'Mississippi',
  'New Hampshire',
  'New Jersey',
  'New York',
  'North Carolina',
  'Oregon',
  'Pennsylvania',
  'Texas',
  'Virginia',
]

export const CASE_MANAGER_USER_NOT_FOUND_ERROR = `An account does not exist for this Email`

/**
 * [LANDSCAPE_NOT_SUPPORTED_WARNING shown when a phone is landscape]
 * @type {String}
 */
export const LANDSCAPE_NOT_SUPPORTED_WARNING = 'Landscape mode not supported'

/**
 * [HIDE_LOGO_EVENTS events that trigger hideLogo in App]
 * @type {Array}
 */
export const HIDE_LOGO_EVENTS = [isMobile ? 'touchstart' : 'click', 'resize']

/**
 * [DEBOUNCE_TIME Debounce time]
 * @type {Number}
 */
export const DEBOUNCE_TIME = 200

/**
 * [ORIENTATION_CHANGE_DELAY Timeout duration before executing orientation
 * change event handler]
 * @type {Number}
 */
export const ORIENTATION_CHANGE_DELAY = 500

export const SCREENER_IDLE_TIMEOUT = getEnvironmentVariable(
  'SCREENER_IDLE_TIMEOUT'
)
  ? Number(getEnvironmentVariable('SCREENER_IDLE_TIMEOUT'))
  : 1000 * 60 * 12.5 //750000

export const CASE_MANAGEMENT_IDLE_TIMEOUT = getEnvironmentVariable(
  'CASE_MANAGEMENT_IDLE_TIMEOUT'
)
  ? Number(getEnvironmentVariable('CASE_MANAGEMENT_IDLE_TIMEOUT'))
  : 1000 * 60 * 27.5 //1650000

export const IDLE_TIME_MODAL_COUNTDOWN = getEnvironmentVariable(
  'IDLE_TIME_MODAL_COUNTDOWN'
)
  ? Number(getEnvironmentVariable('IDLE_TIME_MODAL_COUNTDOWN'))
  : 1000 * 60 * 2 //120000

/**
 * Step the user is returned to when they change answers
 * @type {Number}
 */
export const FIRST_STEP = 101

export const REPORTING_GRID_PAGE_SIZE = 25

export const REPORTING_DEMOGRAPHICS_ROUTES = {
  OVERVIEW: 'demographics/overview',
  HOUSEHOLD: 'demographics/household',
  INDIVIDUAL: 'demographics/individual',
}

export const REPORTING_ANALYTICS_ROUTES = {
  OVERVIEW: 'analytics/overview',
  CASES: 'analytics/cases',
  ACTIVITIES: 'analytics/activities',
  NOTES: 'analytics/notes',
}

export const REPORTING_KPI_ROUTES = {
  OVERVIEW: 'kpi/overview',
  CASES: 'kpi/cases',
  ACTIVITIES: 'kpi/activities',
  DRAWDOWN: 'kpi/drawdown',
}

export const LOADING_INDICATOR_DEBOUNCE = 500

export const HOUSEHOLD_QUESTION_STEP = 109

export const NON_BLOCKING_OPERATION_NAMES = [
  'IntrospectionQuery',
  'createCaseReport',
  'createHouseholdReport',
  'createIndividualReport',
  'getReportStatusFromMe',
]

export const DASHBOARD_CHART_COLORS_BY_RANK = {
  0: '#8E8E8E',
  1: '#F443BF',
  2: '#9229D3',
  3: '#009B6D',
  4: '#13A0B5',
  5: '#EA6C00',
  6: '#4B75FF',
  7: '#6456FA',
  8: '#669F2A',
  9: '#A365FF',
  10: '#FF5151',
  11: '#618F66',
  12: '#B9367E',
  13: '#7D689E',
  14: '#C4704F',
}
// Add a rank for each field to keep its color consistent for  all reports
export const DASHBOARD_CHART_LABELS_RANK = {
  //gender
  female: 0,
  male: 1,
  transgender_woman: 2,
  transgender_man: 3,
  non_binary: 4,
  not_listed: 5,

  //Race
  white: 0,
  black_or_african_american: 1,
  american_indian_or_alaskan_native: 2,
  asian: 3,
  native_hawaiian_or_other_pacific_islander: 6,
  from_multiple_races: 7,
  other: 8,

  //Ethnicity
  hispanic: 0,
  non_hispanic: 1,

  //Education
  fifth_grade_or_below: 0,
  sixth_to_eighth_grade: 1,
  ninth_to_twelfth_grade: 2,
  high_school_graduate: 3,
  ged_or_equivalent: 6,
  technical_or_occupational_certificate: 7,
  some_college_no_degree: 8,
  associates_degree: 9,
  bachelors_degree: 10,
  masters_degree: 11,
  professional_doctorate_degree: 12,
  academic_doctorate_degree: 13,

  //Common
  not_available: 4,
  prefer_not_to_answer: 5,
}

/**
 * This is used by the useReportingDashboardQuery() hook
 * The top-level property names of this object are passed to the hook as the `reportEnum` named parameter
 * the hook then uses this enum to lookup the following:correct GraphQL tag and
 *     * The correct path to destructure the variables from when querying the local cache for query variables (see GET_DASHBOARD_QUERY_VARIABLES in `src/Hooks/Reporting/gql.js`
 *     * The correct GraphQL tag to pass to useQuery()
 * @type {{DEMOGRAPHIC_QUICK_DETAILS: {tag: *, variablesPath: string}, SERVICE_CASES_CHART: {tag: *, variablesPath: string}, SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART: {tag: *, variablesPath: string}, HOUSEHOLD_SCREENING_CHART_DATA: {tag: *, variablesPath: string}, DEMOGRAPHIC_VERTICAL_BAR_CHART: {tag: *, variablesPath: string}, SCREENING_DETAILS_CHART_DATA: {tag: *, variablesPath: string}, OUTCOME_SERVICE_CASE_CHART: {tag: *, variablesPath: string}, CONFIRMED_CASES_TIMESERIES_CHART: {tag: *, variablesPath: string}, PROGRAM_ANALYTICS_QUICK_DETAILS: {tag: *, variablesPath: string}, SERVICE_CASES_BY_TYPE_CHART: {tag: *, variablesPath: string}}}
 */
export const DASHBOARD_QUERIES_MAP = {
  // DEMOGRAPHICS DASHBOARD CHART #1
  HOUSEHOLD_SCREENING_CHART_DATA: {
    tag: GET_HOUSEHOLD_SCREENING_CHART_DATA,
    variablesPath: 'dashboardQueryVariables.householdScreeningChartData',
  },
  // DEMOGRAPHICS DASHBOARD #2
  SCREENING_DETAILS_CHART_DATA: {
    tag: GET_SCREENING_DETAILS_CHART_DATA,
    variablesPath: 'dashboardQueryVariables.screeningDetails',
  },
  // DEMOGRAPHICS DASHBOARD CHART #3
  SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART: {
    tag: GET_SINGLE_INDIVIDUAL_DEMOGRAPHICS_CHART,
    variablesPath: 'dashboardQueryVariables.singleIndividualDemographicsChart',
  },
  // DEMOGRAPHICS DASHBOARD CHART #4
  DEMOGRAPHIC_VERTICAL_BAR_CHART: {
    tag: GET_DEMOGRAPHIC_VERTICAL_BAR_CHART,
    variablesPath: 'dashboardQueryVariables.demographicVerticalBarChart',
  },
  // DEMOGRAPHICS DASHBOARD #5
  DEMOGRAPHIC_QUICK_DETAILS: {
    tag: GET_DEMOGRAPHIC_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.demographicQuickDetails',
  },
  // PROGRAM ANALYTICS CHART #2
  OUTCOME_SERVICE_CASE_CHART: {
    tag: GET_OUTCOME_SERVICE_CASE_CHART,
    variablesPath: 'dashboardQueryVariables.outcomeServiceCaseChart',
  },
  // ANALYTICS DASHBOARD CHART #3
  SERVICE_CASES_BY_TYPE_CHART: {
    tag: GET_SERVICE_CASES_BY_TYPE_CHART,
    variablesPath: 'dashboardQueryVariables.serviceCasesByTypeChart',
  },
  // PROGRAM ANALYTICS CHART #4
  CONFIRMED_CASES_TIMESERIES_CHART: {
    tag: GET_CONFIRMED_CASES_TIMESERIES_CHART,
    variablesPath: 'dashboardQueryVariables.confirmedCasesTimeseriesChart',
  },
  // PROGRAM ANALYTICS CHART #5
  PROGRAM_ANALYTICS_QUICK_DETAILS: {
    tag: GET_PROGRAM_ANALYTICS_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.programAnalyticsQuickDetails',
  },
  // KPI CHART #1
  OVERALL_CONFIRMATION_RATE: {
    tag: OVERALL_CONFIRMATION_RATE,
    variablesPath: 'dashboardQueryVariables.overallConfirmationRate',
  },
  // KPI CHART #2
  OVERALL_MULTIPLE_CONFIRMATION_RATE: {
    tag: OVERALL_MULTIPLE_CONFIRMATION_RATE,
    variablesPath: 'dashboardQueryVariables.overallMultipleConfirmationRate',
  },
  // KPI CHART #3
  TOTAL_DRAWDOWN: {
    tag: TOTAL_DRAWDOWN,
    variablesPath: 'dashboardQueryVariables.totalDrawdown',
  },
  // KPI CHART #4
  ACTIVITY_BY_OUTCOMES_CHART: {
    tag: ACTIVITY_BY_OUTCOMES_CHART,
    variablesPath: 'dashboardQueryVariables.activityByOutcomeChart',
  },
  // KPI CHART #5
  ACTIVITIES_BY_TYPE: {
    tag: ACTIVITIES_BY_TYPE,
    variablesPath: 'dashboardQueryVariables.activitiesByType',
  },
  // KPI CHART #6
  KPI_QUICK_DETAILS: {
    tag: KPI_QUICK_DETAILS,
    variablesPath: 'dashboardQueryVariables.kpiQuickDetails',
  },
  // KPI CHART #7
  DRAWDOWN: {
    tag: DRAWDOWN,
    variablesPath: 'dashboardQueryVariables.drawdown',
  },
  POTENTIAL_BENEFIT: {
    tag: TOTAL_POTENTIAL_BENEFIT,
    variablesPath: 'dashboardQueryVariables.drawdown',
  },
}
export const SERVICE_CASE_CLOSURE_REASONS = [
  {
    value: 'UNABLE_TO_FOLLOW_UP',
    label: 'Unable to contact',
  },
  {
    value: 'DID_NOT_SEE_PROVIDER',
    label: 'Client did not see provider',
  },
  {
    value: 'OUTCOME_ADDED',
    label: 'Outcome added',
  },
  {
    value: 'SERVICE_NOT_OFFERED',
    label: 'Service is not offered at the site',
  },
  {
    value: 'ALREADY_RECEIVING_SERVICE',
    label: 'Already receiving the service',
  },
  {
    value: 'NOT_INTERESTED',
    label: 'Client not interested in applying',
  },
  {
    value: 'CLIENT_NOT_ELIGIBLE',
    label: 'Client not eligible',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
]
export const KENDO_UI_LICENSE = process.env.REACT_APP_KENDO_UI_LICENSE

export const MUI_SELECT_OPEN_EVENT = 'MUI_SELECT_OPEN'

export const MAX_CHARACTERS_LONG = 82
export const MAX_CHARACTERS_SHORT = 42
