import React from 'react'
import { connect, useSelector } from 'react-redux'

import { StyledScreenerBackButton } from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import backIconPath from 'Shared/Icons/backIconPath'
import { singleStopBlack } from 'Shared/Theme/index'
import { FIRST_STEP } from 'Shared/constants'
import { useNavigate } from 'react-router'
import { stateToProps, dispatchToProps } from './shared/reduxButtonProps'

const ScreenerBackButton = (props) => {
  const {
    currentStep: stepNumber,
    handleUpdateReturnStep,
    steps,
    progressStatus,
    updateStep,
    screenerCompleted,
    handleChangeAnswers,
    hasCustomQuestions,
  } = props
  const { editing } = useSelector((state) => state.client)
  const navigate = useNavigate()

  if (!steps[stepNumber]) return null

  const displayCondition =
    stepNumber === FIRST_STEP && !screenerCompleted && !hasCustomQuestions
  return (
    <StyledScreenerBackButton
      background={props.background}
      maxwidth={props.maxWidth}
      smallermargin={props.smallerMargin}
      data-testid='screenerBackButton'
      displaynone={displayCondition ? 1 : 0}
      width={props.width || '50%'}
      padding={props.padding}
      margin={
        props.hasExtraButton
          ? props.margin || '2rem'
          : props.margin || '0 0 0 1rem'
      }
      type={'button'}
      aria-label={'Go Back To Previous Question'}
      onClick={() => {
        if (hasCustomQuestions && stepNumber === FIRST_STEP) {
          navigate('/questions')
        } else if (!screenerCompleted || editing) {
          handleUpdateReturnStep(stepNumber)
          updateStep(stepNumber, progressStatus, stepNumber)
        } else {
          handleChangeAnswers()
        }
      }}
    >
      <Icon
        position={'absolute'}
        left={'1.375rem'}
        fill={singleStopBlack}
        margin={'0'}
        scale={'1rem'}
        viewBox={'0 0 24 24'}
        cursorOnHover={'pointer'}
      >
        {backIconPath}
      </Icon>
      Back
    </StyledScreenerBackButton>
  )
}

export default connect(stateToProps, dispatchToProps)(ScreenerBackButton)
