import styled, { css } from 'styled-components/macro'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { singleStopDarkerGray } from 'Shared/Theme'
import { Link } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'
import theme from 'Shared/Theme/ssTheme'

const topAndBotBordersOnly = `
  border-width: 0.1rem 0;
  border-style: solid;
`

export const StyledTermsAndButtonToContinueWrapper = styled.div`
  margin-top: auto;
  margin: ${(props) => props.margin};
  width: ${(props) => (props.width ? props.width : '60%')};
  ${media.smaller`
   width: 100%;
  `}
  ${({ reviewResponse }) =>
    reviewResponse &&
    css`
      width: 50%;
      margin: 8rem auto 2rem;
    `}
`

export const StyledButtonContainer = styled.div`
  padding: 3rem 2rem;
`

export const StyledCategoryContainer = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  margin-right: 0.7rem;
`

export const StyledCategoryHeadingWrapper = styled.div`
  margin: 2rem;
  margin-bottom: 0;
`

export const StyledCategoryLabel = styled.div`
  font-size: 1.5rem;
  text-transform: capitalize;
`

export const StyledCategoryExplanation = styled.div`
  font-size: 1.4rem;
  line-height: 2.6rem;
  color: ${(props) => props.theme.text.default};
`

export const StyledCategoryLongExplanation = styled.div`
  border-color: ${(props) => props.theme.background.buttonHover};
  ${topAndBotBordersOnly}
  padding: 0.5rem 1.5rem;
  padding-bottom: 2rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.default};
`

export const StyledCategoryLongExplanationWrapper = styled.div`
  padding: 1.5rem;
`

export const StyledCategoryLongExplanationParagraph = styled.p`
  font-size: 1.4rem;
  line-height: 2.8rem;
  margin-bottom: 0;
`

export const StyledCategoryLongExplanationHeading = styled.h3`
  margin-top: 0;
`

export const StyledCategoryButton = styled.button`
  border-color: ${(props) => props.theme.background.buttonHover};
  ${topAndBotBordersOnly}
  width: 100%;
  color: ${(props) => props.theme.text.default};
  text-align: left;
  padding: 1.2rem 0;
  padding-left: 3rem;
  font-size: 1.3rem;
  background: none;
  &:hover {
    cursor: pointer;
  }
`

export const StyledLocationButton = withStyles({
  root: {
    background: 'none',
    border: '1px solid #70707087',
    borderRadius: 3,
    color: `${singleStopDarkerGray}`,
    height: '3.8rem',
    padding: '0 3rem',
    width: '100%',
    boxShadow: 'none',
    marginBottom: '1rem',
    margin: 'auto',
    '&:hover': {
      background: `${singleStopDarkerGray}`,
      color: 'white',
      boxShadow: 'none',
      filter: 'brightness(97.5%)',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  label: {
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: '.1rem',
  },
})(Button)

export const StyledLink = styled(Link)`
  display: flex;
  font-size: 1.2rem;
  color: ${(props) => props.theme.text.caseManagerLink};
  margin: ${(props) => (props.margin ? props.margin : '1.4rem 0 0 1.4rem')};
  justify-content: ${(props) => props.justcont};
  width: ${(props) => props.width};
`
export const ProgressSideParent = styled.div`
  width: 94%;
  height: 100%;
  padding: 2rem;
  background-color: ${theme.palette.secondary.default};
  overflow: auto;
  position: absolute;
`

export const ProgressSideBox = styled.div`
  width: 9.125rem;
  height: 8.5625rem;
  margin: 0 auto 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${theme.palette.color.white};
  height: 8.75rem;
  box-shadow: 0px 3px 6px #1d283117;
  border-radius: 7px;
  flex-direction: column;
`
export const StyledCategoriesWrapper = styled.div``

export const StyledFieldset = styled.fieldset`
  padding: 0;
  border: unset;
  legend {
    text-align: left;
    color: ${theme.palette.text.darken};
    font-size: 2.5rem;
    line-height: 3.3rem;
    margin: 1rem 0 !important;
    font-weight: 700;
    &:focus {
      outline: 0;
    }
    @media only screen and (min-width: 1025px) {
      width: 100%;
    }
  }
`

export const ResetFieldset = styled.fieldset`
  padding: 0;
  margin: 0;
  border: unset;
`

export const StyledHeadingLegend = styled.h2`
  margin: 0;
  text-align: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`
