import styled from 'styled-components/macro'

// CSS styling based off https://wordhtml.com/ DOCX to HTML conversion
export const StyledLegalContainer = styled.div`
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  & > p {
    line-height: 1.7;
    font-size: 1.0625rem;
  }

  & > ul > li {
    margin-bottom: 1.5rem;
    line-height: 1.7;
  }
`

export const StyledTermsAndConditionsContainer = styled.div`
  & > p > span > span {
    color: rgb(30, 54, 90);
    font-size: 1.8rem;
  }

  & > p > strong > span > span {
    color: rgb(89, 129, 184);
  }

  & > div > p {
    margin-left: 1.5rem;
  }
`
