import styled from 'styled-components/macro'
import FloorRight from 'Components/LandingPage/Assets/FloorRight.svg'
import { media } from 'styled-bootstrap-grid'
import * as u from 'Shared/Theme/utilities.styles'
import Dots from 'Components/LandingPage/Assets/dots.svg'

export const StyledParentHelp = styled.section`
  ${u.wd100}
  height: 70%;
  ${u.dFlex}
  background-image: url(${FloorRight});
  background-repeat: no-repeat;
  padding: 0 0 12rem;
  ${u.pAbsolute}
  z-index: 1;
  ${u.justifyContentCenter}
  ${u.alignItemsCenter}
  background-size: contain;
  ${media.smaller`
  bottom: -12rem;
  left: 0;
  background-position-x: calc(100% - -20.0625rem);
  `}
  ${media.tablet`
  bottom: 15%;
  left: 0;
  background-position-x: calc(100% - -33.0625rem);
  `}
  ${media.desktop`
  top: 0;
  left: 0;
  background-position-x: calc(100% - -30.0625rem);
  `}
`

export const StyledDotsShape = styled.div`
  ${u.alignShapeImage}
  background-image: url(${Dots});
  background-repeat: no-repeat;
  background-position: 2% 90%;
  top: -3rem;
  right: 0;
  ${media.smaller`
  background-image: unset;
  `}
`

export const StyledParentImg = styled.div`
  ${u.pAbsolute};
  top: 40%;
  left: 0;
  ${media.smaller`
  top: 35%;
  left: -1rem;
  width: 30%;
  `}
`

export const StyledElmImg = styled.img`
  width: 70%;
  ${u.pRelative}
  left: 10%;
`
StyledParentHelp.displayName = 'StyledParentHelp'
StyledDotsShape.displayName = 'StyledDotsShape'
StyledParentImg.displayName = 'StyledParentImg'
StyledElmImg.displayName = 'StyledElmImg'
