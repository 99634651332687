import React from 'react'

import Icon from 'Components/Shared/Icon/Icon'

import { StyledLocationInfo, StyledInfo } from './style'

const LocationInfo = (props) => {
  const { content, screenReaderLabel, icon } = props
  return (
    <StyledLocationInfo alignitems={props.alignitems} showMore>
      <Icon
        style={{ alignSelf: 'baseline' }}
        scale={'1.4rem'}
        screenReaderLabel={screenReaderLabel}
        fill={'rgba(0,0,0,.2)'}
        viewBox={'0 0 24 24'}
        margin={'0 0.8rem 0 0'}
      >
        {icon}
      </Icon>
      <StyledInfo>{content}</StyledInfo>
    </StyledLocationInfo>
  )
}

export default LocationInfo
