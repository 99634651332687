import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Link, useNavigate } from 'react-router-dom'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import {
  StyledButtonFilled,
  StyledStyledButtonFilledParent,
} from 'Shared/Buttons/style'
import ScreeningCompleteSvg from 'Pages/ScreenerCompleted/ScreenerCompleted.svg'
import { StyledTermsAndButtonToContinueWrapper } from 'Components/PreScreener/style'
import {
  StyledHeadingMain,
  StyledParagraph,
  StyledScreeningCompleteSvg,
} from 'Components/Screener/style'
import { Container } from 'styled-bootstrap-grid'
import moment from 'moment'
import ModalError from 'Components/Shared/Modals/ModalError'
import ScreenerCompletedPrimaryButton from 'Pages/ScreenerCompleted/ScreenerCompletedPrimaryButton'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'

const ScreenerCompleted = ({ client }) => {
  const navigate = useNavigate()
  const { screenerCompleted, editing, id } = client
  const [isReturningUser, setIsReturningUser] = useState(false)
  const [error, setError] = useState('')
  const isFeatureToggleOn = useIsFeatureToggleOn()

  useEffect(() => {
    if (!screenerCompleted || editing) {
      navigate('/screening')
    }
  }, [navigate, screenerCompleted, editing])

  useEffect(() => {
    if (screenerCompleted) {
      const numberOfDaysSinceScreeningCompleted = moment().diff(
        moment(screenerCompleted),
        'days'
      )
      if (isFeatureToggleOn('update_eligibility_button')) {
        if (numberOfDaysSinceScreeningCompleted) {
          setIsReturningUser(true)
        }
      }
    }
  }, [])

  return (
    <HeaderWithContainerAndCard>
      <Container>
        <StyledHeadingMain
          id='mainContent'
          tabIndex='-1'
          textalign='center'
          marginBottom='2rem'
        >
          Congratulations
        </StyledHeadingMain>

        <StyledScreeningCompleteSvg
          alt='stick figure waving'
          src={ScreeningCompleteSvg}
        />
        <StyledHeadingMain
          fontSize='1.5625rem'
          textalign='center'
          marginTop='.5rem'
          marginBottom='.5rem'
        >
          your screening is now completed!
        </StyledHeadingMain>

        <StyledParagraph
          textalign='center'
          fontSize='1rem'
          marginBottom='2.25rem'
        >
          If you'd like to, you can review your responses before moving forward.
        </StyledParagraph>

        <StyledTermsAndButtonToContinueWrapper width='50%' margin='auto'>
          <ScreenerCompletedPrimaryButton
            clientId={id}
            isReturningUser={isReturningUser}
            setError={setError}
          />
          <StyledStyledButtonFilledParent>
            <StyledButtonFilled
              disableFocusRipple={true}
              variant='outlined'
              component={Link}
              to={'/review'}
            >
              review your responses
            </StyledButtonFilled>
          </StyledStyledButtonFilledParent>
        </StyledTermsAndButtonToContinueWrapper>
        {error && <ModalError type={'error'} message={error} />}
      </Container>
    </HeaderWithContainerAndCard>
  )
}

const mapStateToProps = (state) => {
  const { client } = state

  return {
    client,
  }
}

export default compose(connect(mapStateToProps))(ScreenerCompleted)
