import React, { useRef, useEffect } from 'react'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import { useLocation } from 'react-router-dom'
import { StyledContainer } from './style'
import { StyledMain } from 'Pages/NextSteps/style'

const HeaderWithContainer = ({
  section,
  children,
  containerDisplay,
  overflow,
}) => {
  const location = useLocation()
  const scrollRef = useRef()

  const scrollToTop = () => {
    scrollRef.current.scrollTop = 0
  }

  useEffect(() => {
    scrollToTop()
  }, [section])

  return (
    <>
      <Header />
      <Menu pathname={location.pathname} fullHeight={true} />
      <StyledMain>
        <StyledContainer
          ref={scrollRef}
          display={containerDisplay}
          overflow={overflow}
        >
          {children}
        </StyledContainer>
      </StyledMain>
    </>
  )
}

export default HeaderWithContainer
