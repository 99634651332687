import React from 'react'

const immigrationIconPath = (
  <g id='a'>
    <g transform='translate(-40.88 -39.739)'>
      <path
        d='M.975,10.671c7.691-19,65.119-11.751,84.408,14.8s2.941,81.725-15.086,80.568S49.73,66.757,41.55,55.079-6.715,29.672.975,10.671Z'
        transform='matrix(-0.799, 0.602, -0.602, -0.799, 167.196, 111.352)'
        fill='rgba(145,255,189,0.7)'
        opacity='0.8'
      />
      <g transform='translate(74.67 56.117)'>
        <path
          d='M61.165,14.131,47.619,8.817A3.491,3.491,0,0,0,44.18,5.691H39.6L27.924,1.211a3.423,3.423,0,0,0-4.481,1.98l-.938,2.5H8.543A3.581,3.581,0,0,0,5,9.234V59.46A3.513,3.513,0,0,0,8.543,63H28.863l11.562,4.481a3.423,3.423,0,0,0,4.481-1.98l1.25-3.23a3.508,3.508,0,0,0,1.355-2.813v-.835l15.53-40.014a3.411,3.411,0,0,0-1.875-4.481ZM25.111,3.919A1.615,1.615,0,0,1,27.3,2.982L34.59,5.795H24.382ZM6.772,59.46V9.234A1.646,1.646,0,0,1,8.439,7.566H44.076a1.646,1.646,0,0,1,1.667,1.667V59.46a1.644,1.644,0,0,1-1.667,1.667H8.439A1.713,1.713,0,0,1,6.772,59.46Zm36.471,5.314a1.649,1.649,0,0,1-2.185.938L33.764,62.9H43.972ZM61.478,17.987h0L47.619,53.624V10.8l12.921,5a1.737,1.737,0,0,1,.937,2.188Z'
          transform='translate(-5 -0.965)'
          stroke='#000'
          strokeWidth='0.5'
        />
        <path
          d='M26.03,42.461h0a15.63,15.63,0,1,0,0-31.261h0a15.63,15.63,0,1,0,0,31.261Zm-11.045-7.4h4.273a16.509,16.509,0,0,0,2.4,4.9,14.35,14.35,0,0,1-6.67-4.9Zm24.8-9.171H34.05a29.51,29.51,0,0,0-.728-5.523h4.9a12.946,12.946,0,0,1,1.563,5.523ZM25.2,35.063v5.419c-1.563-.521-3.126-2.5-4.064-5.419Zm-4.585-1.772a28.377,28.377,0,0,1-.728-5.523H25.2v5.523Zm6.357,7.192V34.962h4.064c-.939,3.018-2.4,5-4.063,5.519Zm0-7.192V27.77h5.314a29.511,29.511,0,0,1-.728,5.523Zm7.086-5.522H39.79a12.542,12.542,0,0,1-1.563,5.523h-4.9a19.118,19.118,0,0,0,.73-5.523Zm-1.772-1.875H26.969V20.37h4.585a32.029,32.029,0,0,1,.728,5.523Zm-5.313-7.3V13.284c1.667.521,3.022,2.5,4.064,5.314ZM25.2,13.284V18.6H21.133c1.042-2.813,2.5-4.793,4.064-5.314Zm0,7.192V26H19.883a28.377,28.377,0,0,1,.728-5.523H25.2Zm-7.087,5.416H12.376a14.1,14.1,0,0,1,1.563-5.523h4.9a19.61,19.61,0,0,0-.725,5.523Zm0,1.875a29.8,29.8,0,0,0,.625,5.523h-4.9a16.114,16.114,0,0,1-1.563-5.521h5.836Zm12.4,12.192a16.509,16.509,0,0,0,2.4-4.9H37.18a14.35,14.35,0,0,1-6.669,4.9ZM37.075,18.6H32.908a17.166,17.166,0,0,0-2.4-4.793A14.384,14.384,0,0,1,37.075,18.6Zm-15.422-4.69a15.836,15.836,0,0,0-2.4,4.793H15.089a12.622,12.622,0,0,1,6.564-4.792Z'
          transform='translate(-4.773 -0.534)'
          stroke='#000'
          strokeWidth='0.5'
        />
        <path
          d='M39.275,52.838a.934.934,0,0,0-.938-.938h-24.8a.938.938,0,1,0,0,1.875h24.8A1.1,1.1,0,0,0,39.275,52.838Z'
          transform='translate(-4.68 1.176)'
          stroke='#000'
          strokeWidth='0.5'
        />
        <path
          d='M13.538,47.3a.934.934,0,0,0-.938.938.851.851,0,0,0,.938.834H32.4a.938.938,0,0,0,0-1.875H13.538Z'
          transform='translate(-4.68 0.979)'
          stroke='#000'
          strokeWidth='0.5'
        />
      </g>
    </g>
  </g>
)

export default immigrationIconPath
