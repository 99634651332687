import React, {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import { API_BASE } from 'Shared/constants'

export type FeatureToggle = {
  [key: string]: 'on' | 'off'
}

export const FeatureToggleContext = createContext<FeatureToggle>({})

const FeatureToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toggles, setToggles] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const reduced = await fetch(`${API_BASE}/flipper/api/features`)
        .then((res) => res.json())
        .then((json: { features: FeatureToggle[] }) =>
          json.features.reduce(
            (acc: FeatureToggle, curr: FeatureToggle) => ({
              ...acc,
              [curr.key]: curr.state,
            }),
            {} as FeatureToggle
          )
        )
        .catch((e) => {
          console.error(e)
          return {}
        })

      setToggles(reduced)
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (Object.keys(toggles).length) {
      localStorage.setItem('featureToggles', JSON.stringify(toggles))
    }
  }, [toggles])

  return (
    <FeatureToggleContext.Provider value={toggles}>
      {children}
    </FeatureToggleContext.Provider>
  )
}
export default FeatureToggleProvider
