import React, { useMemo, useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Icon from 'Components/Shared/Icon/Icon'
import { infoIconPath } from 'Shared/Icons/LocationInfo/paths'
import { createIdFromLabel } from 'Shared/helpers'

import {
  StyledCardBody,
  StyledCardDescr,
  StyledCardInfoContainer,
  StyledCardTitle,
  StyledIconButton,
  useStyles,
} from './style'

type CardInfoProps = {
  title: string
  descr?: string | JSX.Element
  quickDetails?: boolean
  rateDisplay?: boolean
}

export default function CardInfo({
  title,
  descr = '',
  quickDetails,
  rateDisplay,
}: CardInfoProps) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const moreInfoAriaLabelledBy = useMemo(
    () => `${createIdFromLabel(title)}_card_more_info`,
    [title]
  )

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledCardInfoContainer className={classes.root}>
        <StyledIconButton
          type='button'
          onClick={handleClick}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls={`${moreInfoAriaLabelledBy}_icon`}
        >
          <Icon
            fill={'#ddd'}
            viewBox={'0 0 24 24'}
            cursorOnHover={'pointer'}
            scale={'1.25rem'}
            screenReaderLabel={'More Information'}
            aria-labelledby={moreInfoAriaLabelledBy}
          >
            {infoIconPath}
          </Icon>
        </StyledIconButton>
        {open ? (
          <StyledCardBody
            className={classes.dropdown}
            quickDetails={quickDetails}
            rateDisplay={rateDisplay}
            id={`${moreInfoAriaLabelledBy}_icon`}
          >
            <StyledCardTitle>{title}</StyledCardTitle>
            <StyledCardDescr>{descr}</StyledCardDescr>
          </StyledCardBody>
        ) : null}
      </StyledCardInfoContainer>
    </ClickAwayListener>
  )
}
