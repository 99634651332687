import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import useOtp from 'Components/Auth/Hooks/useOtp'
import useAuthenticationPath from 'Components/PreScreener/Hooks/useAuthenticationPath'
import useStep from 'Components/PreScreener/Hooks/useStep'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import PreScreenerName from 'Components/PreScreener/PreScreenerName'
import PreScreenerBirthday from 'Components/PreScreener/PreScreenerBirthday'
import PreScreenerCategories from 'Components/PreScreener/PreScreenerCategories'
import PreScreenerSectionIntroduction from 'Components/PreScreener/PreScreenerSectionIntroduction'
import PreScreenerEmailOrPhone from 'Components/PreScreener/PreScreenerEmailOrPhone'
import PreScreenerLocation from 'Components/PreScreener/PreScreenerLocation'
import ClientAuth from 'Components/Auth/ClientAuth'
import { useIsValidOrganizationAndLocationSlugs } from 'Components/App/Hooks/index'
import useCustomScreenerQuestions from 'Components/Screener/Hooks/useCustomScreenerQuestions'
import { updateHasCustomQuestions } from 'Actions/screener'
import { updateShowLocationRequest } from 'Reducers/uiSlice'
import useHandlePatchClient from 'Components/Shared/Hooks/useHandlePatchClient'
import { ME } from 'Components/Auth/Hooks/gql'
import { PreScreenerContext } from './PreScreenerProvider'
import SkipVerificationModal from 'Components/PreScreener/SkipVerificationModal/ConfirmSkipVerificationModal'
import mockCategories from 'Components/PreScreener/preScreenerMockCategoriesPayload'

const PreScreener = (props) => {
  const { isValidUrl, isLoading } = useIsValidOrganizationAndLocationSlugs()

  const [content, setContent] = useState(null)

  const {
    preScreenerState: { modal },
    resetContext,
  } = useContext(PreScreenerContext)

  const {
    handleAuthenticateAndContinue,
    handleCreateClient,
    handleSkipAuthenticateAndContinue,
    nextStep,
  } = useAuthenticationPath()

  const { showLocationRequest } = useSelector((state) => state.ui)

  const dispatch = useDispatch()

  const { data } = useQuery(ME)

  const isACaseManager = data && data.me.isACaseManager

  const { hasCustomFields, loading: customFieldsResponseLoading } =
    useCustomScreenerQuestions()

  useEffect(() => {
    if (!customFieldsResponseLoading) {
      dispatch(updateHasCustomQuestions(hasCustomFields))
    }
  }, [customFieldsResponseLoading, hasCustomFields, dispatch])

  const { step } = useStep()

  const { handleRequestOtp } = useOtp()

  const handlePatchClient = useHandlePatchClient(nextStep)

  useEffect(() => {
    switch (step) {
      case 1:
        setContent(<PreScreenerName onSubmit={nextStep} />)
        break
      case 2:
        setContent(<PreScreenerBirthday onSubmit={nextStep} />)
        break
      case 3:
        setContent(<PreScreenerSectionIntroduction onSubmit={nextStep} />)
        break
      case 4:
        setContent(<PreScreenerEmailOrPhone onSubmit={handleCreateClient} />)
        break
      case 5:
        setContent(
          <ClientAuth
            handleRequestOtp={handleRequestOtp}
            onSubmit={(formData) => {
              return handleAuthenticateAndContinue({ formData, isACaseManager })
            }}
            handleSkipAuth={handleSkipAuthenticateAndContinue}
          />
        )
        break
      case 6:
        setContent(
          <PreScreenerCategories
            categories={mockCategories}
            onSubmit={handlePatchClient}
          />
        )
        break
      case 7:
        setContent(
          <PreScreenerLocation
            onSubmit={(...params) => {
              if (showLocationRequest) {
                dispatch(updateShowLocationRequest())
              }
              handlePatchClient(...params)
            }}
          />
        )
        break
      default:
        setContent(null)
    }
  }, [step])

  if (isLoading) return null

  if (!isValidUrl) return <PageNotFound />

  return (
    <HeaderWithContainerAndCard padding={'0'}>
      {content}
      <SkipVerificationModal
        open={modal === 'SKIP_VERIFY'}
        resetContext={resetContext}
      />
    </HeaderWithContainerAndCard>
  )
}

export default PreScreener
