import React from 'react'
import MediaQuery from 'react-responsive'

import MobileHeader from 'Components/Shared/MobileHeader/MobileHeader'
import TabletAndDesktopHeader from 'Components/Shared/TabletAndDesktopHeader/TabletAndDesktopHeader'
import {
  CUSTOM_DESKTOP_MEDIA_QUERY,
  CUSTOM_MOBILE_TABLET_MEDIA_QUERY,
} from 'Shared/constants'

type HeaderProps = {
  pathname?: string
  zIndex?: number | string
}

const Header = (props: HeaderProps) => {
  return (
    <>
      <MediaQuery query={CUSTOM_MOBILE_TABLET_MEDIA_QUERY}>
        <MobileHeader />
      </MediaQuery>

      <MediaQuery query={CUSTOM_DESKTOP_MEDIA_QUERY}>
        <TabletAndDesktopHeader zIndex={props.zIndex} />
      </MediaQuery>
    </>
  )
}

export default Header
