import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import { StyledQuestionContainer } from './style'
import {
  INPUT_TYPE_DATE,
  INPUT_TYPE_STRING,
  INPUT_TYPE_LIST,
  INPUT_TYPE_BOOLEAN,
} from './Inputs/constants'
import DateInput from './Inputs/DateInput/DateInput'
import ListInput from './Inputs/ListInput/ListInput'
import BooleanInput from './Inputs/BooleanInput/BooleanInput'
import StringInput from './Inputs/StringInput/StringInput'
import { StyledSubHeading } from 'Components/Screener/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import { validate } from 'Shared/helpers'

/**
 * Field used to render each custom question. Automatically handles the input
 * type.
 *
 * @param {{ name: string, dataType: string, label: string, options: object }} props
 * @param {string} name The field name
 * @param {string} dataType The field data type, which controls the input type
 * @param {string} label The question
 * @param {object} options The list options when rendering input data type LIST
 * @returns {React.ReactFragment} A field with one of the custom input types
 *                                inside
 */
const CustomQuestionField = ({
  name,
  dataType,
  label,
  options,
  required,
  validator,
  maxLength,
  inputTypePhone,
}) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const validateWithValidator = required
    ? validate
    : validator
    ? validator
    : undefined
  return (
    <StyledQuestionContainer>
      <StyledSubHeading
        fontSize={isMobile ? '1rem' : '.75rem'}
        fontWeight='400'
        marginBottom='0.625rem'
      >
        {label}
      </StyledSubHeading>
      <Field
        name={name}
        options={options}
        data-testid={name}
        validate={validateWithValidator}
      >
        {(props) => {
          switch (dataType) {
            case INPUT_TYPE_STRING:
              return (
                <StringInput
                  inputTypePhone={inputTypePhone}
                  maxLength={maxLength}
                  {...props}
                />
              )
            case INPUT_TYPE_DATE:
              return <DateInput {...props} />
            case INPUT_TYPE_LIST:
              return (
                <ListInput
                  {...props}
                  label={label}
                  dataTestId={name}
                  name={name}
                />
              )
            case INPUT_TYPE_BOOLEAN:
              return <BooleanInput {...props} label={label} dataTestId={name} />
            default:
              throw new Error(`Unsupported Data Type: ${dataType}`)
          }
        }}
      </Field>
    </StyledQuestionContainer>
  )
}

export default CustomQuestionField
