import gql from 'graphql-tag'
export const AGREE_TO_TERMS = gql`
  mutation agreeToTerms($clientId: ID!, $agreedToTerms: Boolean!) {
    agreeToTerms(clientId: $clientId, agreedToTerms: $agreedToTerms) {
      client {
        id
      }
    }
  }
`
