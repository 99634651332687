import React from 'react'
import {
  StyledDiv,
  StyledDynamicCtaButton,
  StyledHeadingMain,
  StyledParagraph,
  StyledScreeningPairSvg,
} from 'Components/Screener/style'

import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'

import ScreenerBackButton from 'Components/Screener/ScreenerBackButton'
import { StyledTermsAndButtonToContinueWrapper } from 'Components/PreScreener/style'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import ScreeningPairSvg from 'Components/PreScreener/screeningPair.svg'
import GreatSvg from 'Components/PreScreener/GreatSvg.svg'
import IncomeIcon from 'Components/PreScreener/Income.svg'
import Savings from 'Components/PreScreener/Savings.svg'
import Cost from 'Components/PreScreener/Cost.svg'
import Finally from 'Components/PreScreener/Finally.svg'
const Icons = {
  ScreeningPairSvg: ScreeningPairSvg,
  GreatSvg: GreatSvg,
  IncomeIcon: IncomeIcon,
  Savings: Savings,
  Cost: Cost,
  Finally: Finally,
}
const SectionIntroduction = (props) => {
  const {
    navigation,
    questionText,
    secondP,
    questionDirectionText,
    handleNavigationButtonClick,
    stepNumber,
    icon,
  } = props

  const {
    nextButton: { buttonText: nextButtonText },
  } = navigation

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='10'>
          <StyledDiv className='cypress-prescreen-intro-form'>
            {questionText && (
              <StyledParagraph
                introduction
                marginBottom='0.6875rem'
                textalign='center'
              >
                {questionText}
              </StyledParagraph>
            )}
            {questionDirectionText && (
              <StyledHeadingMain textalign='center'>
                {questionDirectionText}
              </StyledHeadingMain>
            )}
            <StyledScreeningPairSvg
              margin='auto auto 4rem'
              alt='single stop case workers'
              src={Icons[icon]}
            />
            {secondP && (
              <StyledParagraph
                width='80%'
                textalign='center'
                fontSize='1.125rem'
                fontWeight='300'
                marginBottom='1.375rem'
              >
                {secondP}
              </StyledParagraph>
            )}
            <StyledTermsAndButtonToContinueWrapper width='80%' margin='auto'>
              <StyledDynamicCtaButton
                data-testid='ctaButton'
                width='100%'
                name={'next'}
                type={'button'}
                variant={'contained'}
                $hasExtraButton={false}
                onClick={() => {
                  handleNavigationButtonClick(stepNumber, 'nextButton')
                }}
              >
                {nextButtonText || 'continue'}
                <ContinueIcon />
              </StyledDynamicCtaButton>
              <ScreenerBackButton
                width='100%'
                maxWidth={'100%'}
                margin='1rem 0 0 0'
              />
            </StyledTermsAndButtonToContinueWrapper>
          </StyledDiv>
        </Col>
      </Row>
    </Container>
  )
}

export default SectionIntroduction
