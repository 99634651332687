import React, { CSSProperties } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { StyledCheckbox } from 'Components/CaseManagement/Modals/style'

const Styles: CSSProperties = {
  marginLeft: '0',
  marginRight: '0',
  position: 'relative',
  right: '6px',
}

type CheckboxProps = {
  checked: boolean
}

const Checkbox = ({ checked }: CheckboxProps) => (
  <MuiThemeProvider theme={ssTheme}>
    <FormGroup row>
      <FormControlLabel
        label={null}
        style={Styles}
        control={<StyledCheckbox checked={checked} name='checkedG' />}
      />
    </FormGroup>
  </MuiThemeProvider>
)

export default Checkbox
