import React, { memo, useLayoutEffect, useRef } from 'react'
import {
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import { updateLanguageSelected } from 'Actions/filters'
import Card from 'Components/Shared/Card/Card'
import ClickAway from 'Components/Shared/MultiSelect/MultipleSelect'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { multiSelectName } from 'Shared/helpers'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: '0',
  },
  hidden: {
    display: 'none',
  },
}))

interface LanguageOptions {
  [key: string]: {
    id: string
    name: string
    nativeName: string
    selected: boolean
  }
}

type LanguageFilterProps = {
  callback: Function
}

const LanguageFilter = (props: LanguageFilterProps) => {
  const { callback } = props
  const dispatch = useAppDispatch()

  const languageObject: LanguageOptions = useAppSelector(
    (state) => state.filters.languageAccommodation
  )

  const isInitialRender = useRef(true)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateLanguageSelected(event.target.value))
  }

  useLayoutEffect(() => {
    if (isInitialRender) {
      isInitialRender.current = false
      return
    }
    callback()
  }, [callback, isInitialRender, languageObject])

  const languages = Object.values(languageObject)
    .map((language) => {
      return {
        ...language,
      }
    })
    .sort((x, y) => {
      if (x.nativeName.trim() < y.nativeName.trim()) {
        return -1
      } else if (x.nativeName.trim() > y.nativeName.trim()) {
        return 1
      } else {
        return 0
      }
    })

  const classes = useStyles()

  return (
    <MuiThemeProvider theme={ssTheme}>
      <Card
        elevation={1}
        iconPath={''}
        text={'Language Accommodation'}
        boxShadow={'none'}
      >
        <ClickAway
          btnName={multiSelectName(languageObject, 'Language Accommodation')}
          content={
            <FormControl component='fieldset' className={classes.formControl}>
              <FormGroup>
                {languages.map((language) => {
                  const { id } = language
                  const checked = languageObject[id].selected

                  return (
                    <FormControlLabel
                      style={{ padding: '.8rem 0', fontWeight: '500' }}
                      key={id}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          value={id}
                          style={{ color: '#43a047', padding: '0 16px' }}
                        />
                      }
                      label={language.nativeName}
                    />
                  )
                })}
              </FormGroup>
            </FormControl>
          }
        />
      </Card>
    </MuiThemeProvider>
  )
}

export default memo(LanguageFilter)
