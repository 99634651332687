import React from 'react'
import { StyledSectionsContainer } from './style'
import ReviewSection from './ReviewSection'
import { useReviewSectionNavigation } from '../hooks'
import { Grid } from '@material-ui/core'

const ReviewSections = ({ sections, sectionIndex, onScroll, legacy }) => {
  const [container, grid] = useReviewSectionNavigation(sectionIndex)

  return sections ? (
    <div style={{ backgroundColor: '#f1f1f1' }}>
      <StyledSectionsContainer
        data-testid='sections'
        ref={container}
        onScroll={onScroll}>
        <Grid ref={grid}>
          {sections.map((section, i) => (
            <ReviewSection key={i} index={i} legacy={legacy} {...section} />
          ))}
        </Grid>
      </StyledSectionsContainer>
    </div>
  ) : (
    <></>
  )
}

export default ReviewSections
