import styled from 'styled-components/macro'

type StyledLogoContainerProps = {
  width?: string
}

export const StyledLogoContainer = styled.div<StyledLogoContainerProps>`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  @media only screen and (max-width: 1025px) {
    width: ${(props) => props.width};
  }
`
export const StyledVerticalRule = styled.div`
  width: 0.0625rem;
  height: 2rem;
  background: #00000014;
`
