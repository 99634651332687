import { loadingStarted, loadingStopped } from 'Reducers/uiSlice'
import axios from 'axios'
import { getSessionAndLocalCredentials, dispatchEvent } from 'Shared/helpers'

/**
 * Reusable function that uses a dynamic import to get our redux store. If
 * you try to use an import statement at the top of the file, there will be
 * a runtime error involving uninitialized constants/variables/etc.
 */
const getStore = async () =>
  await import('Store').then(({ default: store }) => store)

/**
 * Gets the store and dispatches one of the loading actions. Called without an
 * argument this function will dispatch the loading started action. With an
 * argument of false, this function dispatches the loading stopped action.
 */
const loading = async (isLoading = true) =>
  isLoading
    ? (await getStore()).dispatch(loadingStarted())
    : (await getStore()).dispatch(loadingStopped())

export const axiosWithAuthHeaders = axios.create()

export const requestInterceptor = (config) => {
  loading()

  const credentials = getSessionAndLocalCredentials()
  credentials &&
    (config.headers.common = { ...getSessionAndLocalCredentials() })

  // react-idle-timer listens to this event
  // (we measure idle time by lack of API requests, not lack of DOM interactions)
  dispatchEvent({ eventName: 'apiRequest', bubbles: true })
  return config
}

export const responseErrorInterceptor = (error) => {
  loading(false)

  if (error.response?.status === 401) {
    window.location.replace(`${window.location.origin}/login`)
  } else {
    return Promise.reject(error)
  }
}

export const responseInterceptor = (response) => {
  loading(false)

  return response
}

axiosWithAuthHeaders.interceptors.request.use(requestInterceptor)
axiosWithAuthHeaders.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
)

/**
 * Shared axios instance for use without authentication headers. Use this
 * instance for API calls that don't require credentials.
 */
export const axiosWithoutAuthHeaders = axios.create()

/**
 * Request Interceptor - No Auth
 *
 * @param {*} config
 */
export const axiosWithoutAuthHeadersRequestInterceptor = (config) => {
  loading()
  return config
}

/**
 * Error Response Interceptor - No Auth
 *
 * @param {*} config
 */
export const axiosWithoutAuthHeadersErrorInterceptors = (error) => {
  loading(false)
  return error
}

/**
 * Response Interceptor - No Auth
 *
 * @param {*} config
 */
export const axiosWithoutAuthHeadersResponseInterceptor = (response) => {
  loading(false)
  return response
}

axiosWithoutAuthHeaders.interceptors.request.use(
  axiosWithoutAuthHeadersRequestInterceptor
)
axiosWithoutAuthHeaders.interceptors.response.use(
  axiosWithoutAuthHeadersResponseInterceptor,
  axiosWithoutAuthHeadersErrorInterceptors
)
