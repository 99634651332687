import React from 'react'
import Header from 'Components/Shared/Header/Header'
import { getClientId } from 'Shared/helpers'
import useCustomScreenerQuestions from 'Components/Screener/Hooks/useCustomScreenerQuestions'
import CustomQuestionsForm from 'Components/CustomQuestions/CustomQuestionsForm/CustomQuestionsForm'
import { StyledMain } from 'Pages/NextSteps/style'

const CustomQuestions = () => {
  const clientId = getClientId()

  const { customFields, responses } = useCustomScreenerQuestions({ clientId })

  // In order to submit empty string values for untouched (optional) fields we have to set empty string initial values
  // However, we may have a previously submitted value
  // Therefore, we reduce the (previously submitted) responses adding in empty string values for any unanswered questions
  const initialValues = customFields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: responses[curr.name] ? responses[curr.name] : '',
    }),
    {}
  )

  return (
    <>
      <Header />
      <StyledMain>
        <CustomQuestionsForm
          customFields={customFields}
          initialValues={initialValues}
        />
      </StyledMain>
    </>
  )
}

export default CustomQuestions
