import React from 'react'

const browseAllBenefitsIconPath = (
  <>
    <path className='a' fill='none' d='M0,0H32V32H0Z' />
    <path d='M16,2A14,14,0,1,0,30,16,14.005,14.005,0,0,0,16,2Zm0,25.2A11.2,11.2,0,1,1,27.2,16,11.215,11.215,0,0,1,16,27.2ZM8.3,23.7l10.514-4.886L23.7,8.3,13.186,13.186ZM16,14.46A1.54,1.54,0,1,1,14.46,16,1.536,1.536,0,0,1,16,14.46Z' />
  </>
)

export default browseAllBenefitsIconPath
