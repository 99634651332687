import React, { useEffect, useRef } from 'react'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import MainFooter from 'Components/Shared/PageFooter/Footer'
import { Container } from 'styled-bootstrap-grid'
import { StyledTermsAndConditionsContainer } from './style'
import {
  StyledSubHeading,
  StyledParagraph,
  StyledHeadingMain,
} from 'Components/Screener/style'
import { StyledLegalContainer } from 'Pages/LegalSections/style'

// CSS styling based off https://wordhtml.com/ DOCX to HTML conversion
const TermsAndConditions = () => {
  useEffect(() => {
    scrollToTop()
  }, [])
  const scrollRef = useRef()
  const scrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <HeaderWithContainer overflow={'hidden'}>
      <Container ref={scrollRef}>
        <StyledTermsAndConditionsContainer>
          <StyledHeadingMain
            textalign='center'
            marginBottom='0'
            padding='2rem 0'
            border={'border-bottom: 1px solid #ddd'}>
            <span data-contrast='auto'>Single Stop&nbsp;</span>
            <span data-contrast='auto'>Terms and Conditions</span>

            <StyledParagraph privacyTerms textalign='center' marginBottom='0'>
              <em>
                <span data-contrast='auto'>
                  Please read these terms and conditions carefully before using
                  Our Service.
                </span>
                <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'></span>
              </em>
            </StyledParagraph>
          </StyledHeadingMain>
          <StyledLegalContainer width='80%' margin='auto'>
            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                marginTop='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  Interpretation and Definitions
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <em>
                <StyledSubHeading
                  marginBottom='1rem'
                  fontWeight='700'
                  fontSize='1.1875rem'
                  data-contrast='none'>
                  <span data-ccp-parastyle='heading 2'>Interpretation</span>
                  <span data-ccp-props='{"201341983":0,"335559738":200,"335559739":0,"335559740":276}'>
                    &nbsp;
                  </span>
                </StyledSubHeading>
              </em>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <em>
                <StyledSubHeading
                  marginBottom='1rem'
                  fontWeight='700'
                  fontSize='1.1875rem'
                  data-contrast='none'>
                  <span data-ccp-parastyle='heading 2'>Definitions</span>
                  <span data-ccp-props='{"201341983":0,"335559738":200,"335559739":0,"335559740":276}'>
                    &nbsp;
                  </span>
                </StyledSubHeading>
              </em>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    For the purposes of these Terms and Conditions:
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span className='indentation'>
                    <strong>
                      <span data-contrast='auto'>Application: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means the software program provided by the
                      Company&nbsp;
                    </span>
                    <span data-contrast='auto'>accessed</span>
                    <span data-contrast='auto'>
                      &nbsp;by You on any electronic device, named Single Stop
                    </span>
                    <span data-contrast='auto'>.</span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Affiliate: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means an entity that controls, is controlled by or
                      is under common control with a party, where "control"
                      means ownership of 50% or more of the shares, equity
                      interest or other securities entitled to vote for election
                      of directors or other managing authority.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Account: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means a unique account created for You to access our
                      Service or parts of our Service.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Country: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;refers to:&nbsp; United States
                    </span>
                    <span data-contrast='auto'>&nbsp;of America</span>
                    <span data-contrast='auto'>.</span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Company: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;(referred to as either "the Company", "We", "Us" or
                      "Our" in this Agreement) refers to Single Stop USA Inc,
                      123 William St, New York NY 10038.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Device: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means any device that can access the Service such as
                      a computer, a cellphone or a digital tablet.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Feedback: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means feedback, innovations or suggestions sent by
                      You regarding the attributes, performance or features of
                      our Service.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Service: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;refers to the Application.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>Terms and Conditions: </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;(also referred as "Terms") mean these Terms and
                      Conditions that form the entire agreement between You and
                      the Company regarding the use of the Service.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>
                        Third-party Social Media Service:
                      </span>
                    </strong>
                    <span data-contrast='auto'>
                      &nbsp;means any services or content (including data,
                      information, products or services) provided by a
                      third-party that may be displayed, included or made
                      available by the Service.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                    <br />
                    <br />
                    <strong>
                      <span data-contrast='auto'>You: </span>
                    </strong>
                    <span data-contrast='auto'>&nbsp;</span>
                    <span data-contrast='auto'>means</span>
                    <span data-contrast='auto'>
                      &nbsp;the individual accessing or using the Service, or
                      the company, or other legal entity on behalf of which such
                      individual is accessing or using the Service, as
                      applicable.
                    </span>
                    <span data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":200,"335559740":276}'>
                      &nbsp;
                    </span>
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Acknowledgment</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    These are the Terms and Conditions governing the use of this
                    Service and the agreement that operates between You and the
                    Company. These Terms and Conditions set out the rights and
                    obligations of all users regarding the use of the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Your access to and use of the Service is conditioned on Your
                    acceptance of and compliance with these Terms and
                    Conditions. These Terms and Conditions apply to all
                    visitors, users and others who access or use the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    By accessing or using the Service You agree to be bound by
                    these Terms and Conditions. If You disagree with any part of
                    these Terms and&nbsp;
                  </span>
                  <span data-contrast='auto'>Conditions</span>
                  <span data-contrast='auto'>
                    &nbsp;then You may not access the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    You represent that you are over the age of 18. The Company
                    does not permit those under 18 to use the Service
                  </span>
                  <span data-contrast='auto'>
                    &nbsp;without the consent of a parent or legal guardian.
                  </span>
                  <span data-contrast='auto'>.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Your access to and use of the Service is also conditioned on
                    Your acceptance of and compliance with the Privacy Policy of
                    the Company. Our Privacy Policy describes Our policies and
                    procedures on the collection, use and disclosure of Your
                    personal information when You use the Application or the
                    Website and tells You about Your privacy rights and how the
                    law protects You. Please read Our Privacy Policy carefully
                    before using Our Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>User Accounts</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    When You create an account with Us,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>
                    &nbsp;must provide Us information that is accurate,
                    complete, and current at all times. Failure to do so
                    constitutes a breach of the Terms, which may result in
                    immediate termination of Your account on Our Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    You are responsible for safeguarding the password that You
                    use to access the Service and for any activities or actions
                    under Your password, whether Your password is with Our
                    Service or a Third-Party Social Media Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    You agree not to disclose Your password to any third party.
                    You must notify Us immediately upon becoming aware of any
                    breach of security or unauthorized use of Your account.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than You without appropriate authorization, or
                    a name that is otherwise offensive, vulgar or obscene.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Intellectual Property</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    The Service and its original content (excluding Content
                    provided by You or other users), features and functionality
                    are and will remain the exclusive property of&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>&nbsp;and its licensors.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    The Service is protected by copyright, trademark,
                  </span>
                  <span data-contrast='auto'>
                    &nbsp;maintained as a trade secret
                  </span>
                  <span data-contrast='auto'>
                    &nbsp;and other laws of both the Country and foreign
                    countries.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Our trademarks and trade dress may not be used in connection
                    with any product or service without the prior written
                    consent of the Company.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Your Feedback to Us</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    You assign all rights, title and interest in any Feedback
                    You provide&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    . If for any reason such assignment is ineffective,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>&nbsp;agree to grant&nbsp;</span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;a non-exclusive, perpetual, irrevocable, royalty free,
                    worldwide right and license to use, reproduce, disclose,
                    sub-license, distribute, modify and exploit such Feedback
                    without restriction.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Links to Other Websites</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    Our Service may contain links to third-party web sites or
                    services that are not owned or controlled by&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;has no control over, and assumes no responsibility
                    for, the content, privacy policies, or practices of any
                    third
                  </span>
                  <span data-contrast='auto'>-</span>
                  <span data-contrast='auto'>
                    party web sites or services. You further acknowledge and
                    agree that&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;shall not be responsible or liable, directly or
                    indirectly, for any damage or loss caused or alleged to be
                    caused by or in connection with the use of or reliance on
                    any such content, goods or services available on or through
                    any such web sites or services.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    We strongly advise You to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that You visit.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Termination</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    We may terminate or suspend Your Account immediately,
                    without prior notice or liability, for any reason
                    whatsoever, including without limitation if You breach these
                    Terms and Conditions.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>Upon termination,&nbsp;</span>
                  <span data-contrast='auto'>Your</span>
                  <span data-contrast='auto'>
                    &nbsp;right to use the Service will cease immediately. If
                    You wish to terminate Your Account,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>
                    &nbsp;may simply discontinue using the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Limitation of Liability</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    Notwithstanding any damages that You might incur, the entire
                    liability of&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;and any of its suppliers under any provision of this
                    Terms and Your exclusive remedy for all of the foregoing
                    shall be limited to the amount actually paid by You through
                    the Service or 100 USD if You haven't purchased anything
                    through the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    To the maximum extent permitted by applicable law, in no
                    event shall&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;or its suppliers be liable for any special,
                    incidental, indirect, or consequential damages whatsoever
                    (including, but not limited to, damages for loss of profits,
                    loss of data or other information, for business
                    interruption, for personal injury, loss of privacy arising
                    out of or in any way related to the use of or inability to
                    use the Service, third-party software and/or third-party
                    hardware used with the Service, or otherwise in connection
                    with any provision of this Terms), even if&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;or any supplier has been advised of the possibility of
                    such damages and even if the remedy fails of its essential
                    purpose.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Some states do not allow the exclusion of implied warranties
                    or limitation of liability for incidental or consequential
                    damages, which means that some of the above limitations may
                    not apply. In these states, each party's liability will be
                    limited to the greatest extent permitted by law.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  "AS IS" and "AS AVAILABLE" Disclaimer
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    The Service is provided to You "AS IS" and "AS AVAILABLE"
                    and with all faults and defects without warranty of any
                    kind. To the maximum extent permitted under applicable
                    law,&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    , on its own behalf and on behalf of its Affiliates and its
                    and their respective licensors and service providers,
                    expressly disclaims all warranties, whether express,
                    implied, statutory or otherwise, with respect to the
                    Service, including all implied warranties of
                    merchantability, fitness for a particular purpose, title and
                    non-infringement, and warranties that may arise out of
                    course of dealing, course of performance, usage or trade
                    practice. Without limitation to the foregoing,&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;provides no warranty or undertaking, and makes no
                    representation of any kind that the Service will meet Your
                    requirements, achieve any intended results, be compatible or
                    work with any other software, applications, systems or
                    services, operate without interruption, meet any performance
                    or reliability standards or be error free or that any errors
                    or defects can or will be corrected.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Without limiting the foregoing, neither the Company nor any
                    of&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    's provider makes any representation or warranty of any
                    kind, express or implied: (
                  </span>
                  <span data-contrast='auto'>i</span>
                  <span data-contrast='auto'>
                    ) as to the operation or availability of the Service, or the
                    information, content, and materials or products included
                    thereon; (ii) that the Service will be uninterrupted or
                    error-free; (iii) as to the accuracy, reliability, or
                    currency of any information or content provided through the
                    Service; or (iv) that the Service, its servers, the content,
                    or e-mails sent from or on behalf of&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>
                    &nbsp;are free of viruses, scripts, trojan horses, worms,
                    malware, timebombs or other harmful components.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                  <br />
                  <br />
                  <span data-contrast='auto'>
                    Some jurisdictions do not allow the exclusion of certain
                    types of warranties or limitations on applicable statutory
                    rights of a consumer, so some or&nbsp;
                  </span>
                  <span data-contrast='auto'>all of</span>
                  <span data-contrast='auto'>
                    &nbsp;the above exclusions and limitations may not apply to
                    You. But in such a case the exclusions and limitations set
                    forth in this section shall be applied to the greatest
                    extent enforceable under applicable law.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Governing Law</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>The</span>
                  <span data-contrast='auto'>
                    &nbsp;Agreement is subject to New York law and where
                    applicable
                  </span>
                  <span data-contrast='auto'>
                    &nbsp;laws of the Country, excluding its conflicts of law
                    rules, shall govern this Terms and Your use of the Service.
                    Your use of the Application may also be subject to other
                    local, state, national, or international laws.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Disputes Resolution</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If You have any concern or dispute about the Service,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>
                    &nbsp;agree to first try to resolve the dispute informally
                    by contacting&nbsp;
                  </span>
                  <span data-contrast='auto'>Single Stop</span>
                  <span data-contrast='auto'>.</span>
                  <span data-contrast='auto'>&nbsp; If Y</span>
                  <span data-contrast='auto'>o</span>
                  <span data-contrast='auto'>
                    u are unable to resolve the dispute informally You agree
                    that any dispute will be subject to arbitration and no
                    action
                  </span>
                  <span data-contrast='auto'>&nbsp;or proceeding</span>
                  <span data-contrast='auto'>&nbsp;may be brough</span>
                  <span data-contrast='auto'>t before any court,&nbsp;</span>
                  <span data-contrast='auto'>such arbitration to be held</span>
                  <span data-contrast='auto'>
                    &nbsp;subject to the rules of the Arbitration Association of
                    America.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  For European Union (EU) Users
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If You are a European Union consumer, you will benefit from
                    any mandatory provisions of the law of the country in which
                    you reside as well as the General Data Protection Regulation
                    (GDPR).
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  United States Legal Compliance
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    You represent and warrant that (
                  </span>
                  <span data-contrast='auto'>i</span>
                  <span data-contrast='auto'>
                    ) You are not located in a country that is subject to the
                    United States government embargo, or that has been
                    designated by the United States government as a "terrorist
                    supporting" country, and (ii) You are not listed on any
                    United States government list of prohibited or restricted
                    parties
                  </span>
                  <span data-contrast='auto'>&nbsp;or persons</span>
                  <span data-contrast='auto'>.</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Severability and Waiver</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <em>
                <StyledSubHeading
                  marginBottom='1rem'
                  fontWeight='700'
                  fontSize='1rem'
                  data-contrast='none'>
                  <span data-ccp-parastyle='heading 2'>Severability</span>
                  <span data-ccp-props='{"201341983":0,"335559738":200,"335559739":0,"335559740":276}'>
                    &nbsp;
                  </span>
                </StyledSubHeading>
              </em>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If any provision of these Terms is held to be unenforceable
                    or invalid, such provision will be changed and interpreted
                    to accomplish the objectives of such provision to the
                    greatest extent possible under applicable law and the
                    remaining provisions will continue in full force and effect.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <em>
                <StyledSubHeading
                  marginBottom='1rem'
                  fontWeight='700'
                  fontSize='1rem'
                  data-contrast='none'>
                  <span data-ccp-parastyle='heading 2'>Waiver</span>
                  <span data-ccp-props='{"201341983":0,"335559738":200,"335559739":0,"335559740":276}'>
                    &nbsp;
                  </span>
                </StyledSubHeading>
              </em>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    Except as provided herein, the failure to exercise a right
                    or to require performance of an obligation under this Terms
                    shall not effect a party's ability to exercise such right or
                    require such performance at any time thereafter nor shall be
                    the waiver of a breach constitute a waiver of any subsequent
                    breach.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  Translation Interpretation
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    These Terms and Conditions may have been translated if We
                    have made them available to You on our Service. You agree
                    that the original English text shall prevail in the case of
                    a dispute.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>
                  Changes to These Terms and Conditions
                </span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    We reserve the right, at Our sole discretion, to modify or
                    replace these Terms at any time. If a revision is&nbsp;
                  </span>
                  <span data-contrast='auto'>material</span>
                  <span data-contrast='auto'>
                    &nbsp;We will make reasonable efforts to provide at least 30
                    days' notice prior to any new terms taking effect. What
                    constitutes a material change will be determined at Our sole
                    discretion.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    By continuing to access or use Our Service after those
                    revisions become effective,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>
                    &nbsp;agree to be bound by the revised terms. If You do not
                    agree to the new terms, in whole or in part, please stop
                    using the website and the Service.
                  </span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
            </StyledLegalContainer>

            <StyledLegalContainer>
              <StyledSubHeading
                marginBottom='1.25rem'
                fontWeight='700'
                fontSize='1.5rem'
                data-contrast='none'>
                <span data-ccp-parastyle='Title'>Contact Us</span>
                <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":300,"335559740":240}'>
                  &nbsp;
                </span>
              </StyledSubHeading>
              <StyledParagraph privacyTerms>
                <em>
                  <span data-contrast='auto'>
                    If you have any questions about these Terms and
                    Conditions,&nbsp;
                  </span>
                  <span data-contrast='auto'>You</span>
                  <span data-contrast='auto'>&nbsp;can contact us:</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </em>
              </StyledParagraph>
              <ul>
                <li
                  data-leveltext=''
                  data-font='Symbol'
                  data-listid='1'
                  data-aria-posinset='1'
                  data-aria-level='1'>
                  <span data-contrast='auto'>
                    <span data-ccp-parastyle='List Bullet'>
                      By email: sssupport@singlestop.org
                    </span>
                  </span>
                  <span data-ccp-props='{"134233279":true,"201341983":0,"335559739":200,"335559740":276,"469777462":[360],"469777927":[0],"469777928":[8]}'>
                    &nbsp;
                  </span>
                </li>
              </ul>
            </StyledLegalContainer>

            <StyledParagraph privacyTerms>
              <em>
                <strong>
                  <span data-contrast='auto'>04/</span>
                  <span data-contrast='auto'>18/</span>
                  <span data-contrast='auto'>2021</span>
                  <span data-ccp-props='{"201341983":0,"335559739":200,"335559740":276}'>
                    &nbsp;
                  </span>
                </strong>
              </em>
            </StyledParagraph>
          </StyledLegalContainer>
        </StyledTermsAndConditionsContainer>
      </Container>
      <MainFooter />
    </HeaderWithContainer>
  )
}

export default TermsAndConditions
