import React, { lazy, useEffect } from 'react'
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import MediaQuery from 'react-responsive'

import About from 'Pages/About/About'
import Account from 'Pages/Account/Account'
import CheckEmail from 'Pages/CheckEmail/CheckEmail'
import Locations from 'Pages/Locations/Locations'
import LocationDetail from 'Pages/LocationDetail/LocationDetail'
import LocationRequest from 'Pages/LocationRequest/LocationRequest'
import NextSteps from 'Pages/NextSteps/NextSteps'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'
import PreScreener from 'Pages/PreScreener/PreScreener'
import PrivacyPolicy from 'Pages/LegalSections/PrivacyPolicy'
import Screener from 'Pages/Screener/Screener'
import CustomQuestions from 'Pages/CustomQuestions/CustomQuestions'
import ScreenerCompleted from 'Pages/ScreenerCompleted/ScreenerCompleted'
import TermsAndConditions from 'Pages/LegalSections/TermsAndConditions'
import Login from 'Pages/Login/Login'
import Logout from 'Pages/Logout/Logout'
import ClientAuthPage from 'Pages/ClientAuth/ClientAuth'
import ProtectedRoute from 'Components/Shared/ProtectedRoute/ProtectedRoute'
import Review from 'Pages/Review/Review'
import Maintenance from 'Pages/Maintenance/Maintenance'
import { getClientId } from 'Shared/helpers'
import { PartnerLocationModalContext } from 'Components/PartnerLocationModal/PartnerLocationModal'
import {
  SCREENER_IDLE_TIMEOUT,
  MOBILE_AND_TABLET_MEDIA_QUERY,
  DESKTOP_MEDIA_QUERY,
} from 'Shared/constants'
import LandingPage from 'Pages/LandingPage/LandingPage'
import PreScreenerProvider from 'Pages/PreScreener/PreScreenerProvider'
import { titleMap } from 'Pages/helpers'
import ZipCodeForm from 'Pages/ZipCodeVerification/ZipCodeVerification'
import Fallback from 'Components/Shared/Fallback/Fallback'
import { useAppSelector } from 'Store/hooks'

const CaseManagement = lazy(() => import('Pages/CaseManagement/CaseManagement'))

const LocationsID = () => {
  const { showLocationRequest } = useAppSelector((state) => state.ui)
  const locationID = useParams().id || ''

  if (showLocationRequest) {
    return <LocationRequest />
  } else {
    return (
      <>
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <LocationDetail id={locationID} />
        </MediaQuery>

        <MediaQuery query={DESKTOP_MEDIA_QUERY}>
          <Locations showDesktopDetailView />
        </MediaQuery>
      </>
    )
  }
}

const PageRouter = () => {
  const curLoc = useLocation()

  useEffect(() => {
    const curTitle = titleMap.find((item) =>
      curLoc.pathname.includes(item.path)
    )
    curTitle && curTitle.title
      ? (document.title = curTitle.title)
      : (document.title =
          'Single Stop™ - Your Single Stop in finding benefits & resources.')
  }, [curLoc.pathname])

  return (
    <Routes>
      <Route path={'/zip-code-validation'} element={<ZipCodeForm />} />
      <Route
        path='/locations'
        element={<Navigate to='/locations/list' replace />}
      />
      <Route path={'/locations/list'} element={<Locations showList={true} />} />
      <Route path={'/locations/map'} element={<Locations />} />
      <Route path={'/locations/:id'} element={<LocationsID />} />
      <Route
        path={'/registration'}
        element={
          <PreScreenerProvider>
            <PreScreener />
          </PreScreenerProvider>
        }
      />
      <Route
        path={'/:organization/:location/registration'}
        element={
          <PreScreenerProvider>
            <PreScreener />
          </PreScreenerProvider>
        }
      />
      <Route
        path={'/questions'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <CustomQuestions />
          </ProtectedRoute>
        }
      />
      <Route
        path={'/screening'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <Screener />
          </ProtectedRoute>
        }
      />

      <Route path={'/completed-screener'} element={<ScreenerCompleted />} />

      <Route
        path={'/next-steps'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <NextSteps section={'benefits'} />
          </ProtectedRoute>
        }
      />

      <Route
        path={'/next-steps'}
        element={
          <ProtectedRoute
            conditional={getClientId}
            redirectUrl={'/login'}
            timeout={SCREENER_IDLE_TIMEOUT}
          >
            <NextSteps section={'benefits'} />
          </ProtectedRoute>
        }
      >
        <Route
          path={'preferred-partners'}
          element={
            <ProtectedRoute
              conditional={getClientId}
              redirectUrl={'/login'}
              timeout={SCREENER_IDLE_TIMEOUT}
            >
              <NextSteps section={'preferred-partners'} />
            </ProtectedRoute>
          }
        />
        <Route
          path={'documentation'}
          element={
            <ProtectedRoute
              conditional={getClientId}
              redirectUrl={'/login'}
              timeout={SCREENER_IDLE_TIMEOUT}
            >
              <NextSteps section={'documentation'} />
            </ProtectedRoute>
          }
        />
        <Route
          path={'next-steps/apply-online/:id'}
          element={
            <ProtectedRoute
              conditional={getClientId}
              redirectUrl={'/login'}
              timeout={SCREENER_IDLE_TIMEOUT}
            >
              <NextSteps section={'apply-online'} />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={'/case-management/*'}
        element={
          <Fallback>
            <CaseManagement />
          </Fallback>
        }
      />
      <Route path={'/about'} element={<About />} />
      <Route path={'/account'} element={<Account />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/authenticate'} element={<ClientAuthPage />} />
      <Route path={'/checkemail'} element={<CheckEmail />} />
      <Route path={'/logout'} element={<Logout />} />
      {['/review', '/review/:action', '/review/:action/:section'].map(
        (path, index) => (
          <Route
            key={index}
            path={path}
            element={
              <ProtectedRoute
                conditional={getClientId}
                redirectUrl={'/login'}
                timeout={SCREENER_IDLE_TIMEOUT}
              >
                <Review />
              </ProtectedRoute>
            }
          />
        )
      )}

      <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
      <Route path={'/terms-and-conditions'} element={<TermsAndConditions />} />
      {['/', '/:organization/:location', '/:organization'].map(
        (path, index) => (
          <Route
            key={index}
            path={path}
            element={<LandingPage modalContext={PartnerLocationModalContext} />}
          />
        )
      )}
      <Route path={'/maintenance/maintenance'} element={<Maintenance />} />
      <Route element={<PageNotFound />} />
    </Routes>
  )
}

export default PageRouter
