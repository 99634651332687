import { useLocation } from 'react-router-dom'

const useActiveLink = () => {
  const location = useLocation()
  const isActiveLink = ({ path }) => location.pathname.includes(path)
  return {
    isActiveLink,
  }
}

export default useActiveLink
