import React from 'react'

const workIconPath = (
  <g id='a'>
    <g transform='translate(-6.365 -11.267)'>
      <path
        d='M40.377,125.952c22.29,5.107,23.194-19.106,23.182-51.258S68,5.663,45.714.557-.011,26.338,0,58.49,18.089,120.845,40.377,125.952Z'
        transform='matrix(0.485, -0.875, 0.875, 0.485, -9, 79.901)'
        fill='rgba(242,187,255,0.6)'
        opacity='0.8'
      />
      <path
        d='M74.2,24.936H62.718A16.353,16.353,0,0,0,46.383,12.48a2.108,2.108,0,0,0-.739.152,2.108,2.108,0,0,0-.739-.152A16.353,16.353,0,0,0,28.57,24.936H17.088A6.6,6.6,0,0,0,10.5,31.524V72.668a6.6,6.6,0,0,0,6.588,6.588h57.1a6.6,6.6,0,0,0,6.588-6.588V31.519A6.6,6.6,0,0,0,74.2,24.936ZM44.906,16.75a2.108,2.108,0,0,0,.739-.16,2.108,2.108,0,0,0,.739.152,11.949,11.949,0,0,1,11.851,8.186H33.055a11.949,11.949,0,0,1,11.851-8.186ZM17.088,29.206H74.2a2.322,2.322,0,0,1,2.314,2.314V50.31C72.886,51.2,60.636,53.94,51.73,54.3a6.46,6.46,0,0,0-12.188,0c-8.889-.439-21.139-3.168-24.769-3.995V31.519a2.322,2.322,0,0,1,2.316-2.314ZM43.473,56.191a2.18,2.18,0,0,1,4.342,0,.668.668,0,0,0,0,.525,2.18,2.18,0,0,1-4.342,0,.668.668,0,0,0,0-.525ZM74.2,74.981H17.1a2.322,2.322,0,0,1-2.316-2.312v-18c4.662,1.086,16.015,3.559,24.752,3.879a6.46,6.46,0,0,0,12.243,0c8.693-.329,20.094-2.793,24.752-3.879v18A2.322,2.322,0,0,1,74.2,74.981Z'
        transform='translate(29.912 13.723)'
      />
    </g>
  </g>
)

export default workIconPath
