import React, { useRef } from 'react'
import {
  StyledParentElem,
  StyledSubTitleElem,
  StyledTitleElem,
  StyledMessageElem,
  StyledActionsElem,
} from '../Styles/FeaturesText'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import { Link } from 'react-router-dom'
import { TimelineMax } from 'gsap'
import { useInView } from 'react-hook-inview'

export const FeaturesText = (props) => {
  let ElemRef = useRef([])
  let TextTl = null
  let entered = false
  const TweenText = () => {
    TextTl = new TimelineMax({
      defaults: {
        duration: 0.6,
      },
    })
    TextTl.set(ElemRef.current, { y: -20 }).staggerTo(
      ElemRef.current,
      0.6,
      { y: 0, opacity: 1 },
      0.3
    )
  }
  const [textRef] = useInView({
    threshold: 0.5,
    onEnter: () => {
      if (!entered) {
        TweenText()
        entered = true
      }
    },
  })

  const {
    title,
    subTitle,
    heroSubTitle,
    message,
    action1,
    action2,
    target1,
    target2,
    target1External = false,
    target2External = false,
  } = props
  return (
    <>
      <StyledParentElem
        sectionTop={props.sectionTop}
        ref={textRef}
        dirLTR={props.dirLTR}
      >
        {subTitle && (
          <StyledSubTitleElem
            ref={(element) => {
              ElemRef.current[0] = element
            }}
          >
            {subTitle}
          </StyledSubTitleElem>
        )}
        {title && (
          <StyledTitleElem
            width={props.widthTitle}
            lineHeight={props.lineHeightTitle}
            ref={(element) => {
              ElemRef.current[1] = element
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {heroSubTitle && (
          <StyledSubTitleElem
            ref={(element) => {
              ElemRef.current[2] = element
            }}
          >
            {heroSubTitle}
          </StyledSubTitleElem>
        )}
        {message && (
          <StyledMessageElem
            width={props.widthMessage}
            marginSmaller={props.marginSmallerMessage}
            margin={props.marginMessage}
            dirLTR
            ref={(element) => {
              ElemRef.current[3] = element
            }}
          >
            {message}
          </StyledMessageElem>
        )}

        <StyledActionsElem
          position={props.position}
          moveUp={props.moveUp}
          moveBtn={props.moveBtn}
          topBtn={props.topBtn}
          topBtnTab={props.topBtnTab}
          marginSmaller={props.footerMarginSmaller}
          ref={(element) => {
            ElemRef.current[4] = element
          }}
        >
          {action1 && (
            <Button
              primary='true'
              md='true'
              padding='0.9em 1.4em 1em'
              margin='0 1rem 0 0'
              featuretext='true'
              as={Link}
              to={target1 ? target1 : '/'}
              target={target1External ? '_blank' : '_self'}
            >
              {action1}
            </Button>
          )}
          {action2 && (
            <Button
              customBackgroundSecondaryButton={
                props.customBackgroundSecondaryButton
              }
              marginSM='1rem'
              secondary='true'
              padding='0.9em 1.4em 1em'
              featuretext='true'
              md
              as='a'
              href={target2 ? target2 : '/'}
              target={target2External ? '_blank' : '_self'}
            >
              {action2}
            </Button>
          )}
        </StyledActionsElem>
      </StyledParentElem>
    </>
  )
}
