import { createTheme } from '@material-ui/core/styles'
import { singleStopGreen } from 'Shared/Theme'
const theme = {}
theme.palette = {
  primary: {
    default: '#11AB7C', // '#076448'  this color is preferred to use for accessibility purpose
    dark: '#0d8762',
    lighten5: '#13c28d',
    lighten10: '#16d99e',
    lighten30: '#f8fffd',
    darken5: '#0e8761',
    darken10: '#0c7d5a',
    darken20: '#017a56',
  },
  secondary: {
    default: '#F1F1F1',
    lighten5: '#fefefe',
    lighten10: '#fafbfd',
    lighten20: '#F3F7FA',
    darken5: '#e4e4e4',
    darken10: '#d7d7d7',
    lightGrey: '#f8f8f8',
    darkGrey: '#F3F3F3',
  },
  color: {
    white: '#fff',
    black: '#000',
    lightGreen: '#E0FECC',
    lightGrey: '#F9FBFC',
    lightSemon: '#ffeec1',
    orange: '#FB8E63',
    purple: '#A069F2',
    yellow: '#FFC32C',
    blue: '#72bdff',
    grey: '#979797',
    red: '#FF6363',
    checkboxGrey: '#6363633B',
  },
  text: {
    default: '#393946',
    darken: '#29283E',
    Lighten: 'rgba(41, 40, 62, .7)',
  },
  warning: '#ffbf00', // 0: Warning
  success: '#00b16a', // 0: Success
  error: '#f64744', // 0: Error
}

theme.flatIcon = {
  width: '24px',
  height: '24px',
  fill: 'rgba(0,0,0,.54)',
}

theme.fonts = {
  primary: 'Noto Sans HK, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
}

theme.viewPortFont = {
  mobile: '12px',
  tablet: '13px',
  web: '16px',
  wide: '18px',
}

theme.layout = {
  headerHeight: '5.3125rem',
  asideWidth: '420px',
  filterHeight: '105px',
  asideShadow: ' 0px 3px 20px #0000002E',
}

export const ssTheme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: [`${theme.fonts.primary}`, '"sans-serif"'].join(','),
  },
  palette: {
    primary: {
      main: theme.palette.primary.default,
    },
    secondary: {
      main: theme.palette.secondary.default,
    },
  },
  overrides: {
    MuiSlider: {
      root: {
        color: '#d2d2d2',
        height: 11,
      },
      thumb: {
        height: 34,
        width: 34,
        backgroundColor: '#fff',
        border: '0',
        marginTop: -13,
        boxShadow: '0 0px 7px rgba(0,0,0,0.1)',
        marginLeft: -17,
        '&:focus, &:hover, &$active': {
          boxShadow: '0 0px 7px rgba(0,0,0,0.1)',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 18px)',
      },
      track: {
        height: 11,
        borderRadius: 8,
        backgroundColor: '#c1c5c8',
      },
      rail: {
        height: 11,
        borderRadius: 8,
        backgroundColor: '#EFEFEF ',
      },
    },
    MuiRadio: {
      root: {
        '&&$checked': {
          color: singleStopGreen,
        },
      },
    },
  },
})

export default theme
