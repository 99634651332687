import React, { memo, useState, useLayoutEffect, useRef } from 'react'
import {
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

import RadioButton from 'Components/Shared/RadioButton/RadioButton'
import { updateDistanceUI } from 'Actions/filters'
import {
  StyledFilterRow,
  StyledFilterFlex,
} from 'Components/LocalResources/Filtering/FilterMenu/style'
import { ssTheme } from 'Shared/Theme/ssTheme'

import { StyledList, StyledListItem, StyledSpan } from './styles'

type DistanceFilterProps = {
  callback: Function
  distanceUnit: string
  distanceValue: number
  handleUpdateDistanceAround: Function
  handleUpdateDistanceUI: Function
}

export const DistanceFilter = (props: DistanceFilterProps) => {
  const {
    callback,
    distanceUnit,
    distanceValue,
    handleUpdateDistanceAround,
    handleUpdateDistanceUI,
  } = props

  const [min] = useState(0.25)
  const [max] = useState(50)
  const [unit, setUnit] = useState(distanceUnit)
  const [value, setValue] = useState(distanceValue)

  const isInitialRender = useRef(true)

  useLayoutEffect(() => {
    if (isInitialRender) {
      isInitialRender.current = false
      return
    }
    callback()
  }, [callback, isInitialRender, unit, value])

  const styles = makeStyles((theme) => ({
    root: {
      marginTop: '1rem',
      color: 'rgb(92,102,111)',
      padding: '0 0.5',
    },
  }))

  const classes = styles()

  return (
    <StyledFilterRow>
      <StyledFilterFlex>
        Distance Away
        <StyledList>
          <StyledListItem>
            <RadioButton
              name={'distanceUnit'}
              id={'miles'}
              labelText={'in miles'}
              value={'mi'}
              checked={unit === 'mi'}
              handleChange={(unit) => {
                setUnit(unit)
                handleUpdateDistanceUI({ value, unit })
              }}
            />
          </StyledListItem>
          <StyledListItem>
            <RadioButton
              name={'distanceUnit'}
              id={'kilometers'}
              labelText={'in kilometers'}
              value={'km'}
              checked={unit === 'km'}
              handleChange={(unit) => {
                setUnit(unit)
                handleUpdateDistanceUI({ value, unit })
              }}
            />
          </StyledListItem>
        </StyledList>
      </StyledFilterFlex>
      <MuiThemeProvider theme={ssTheme}>
        <StyledFilterFlex>
          <Slider
            style={{ width: 80 + '%' }}
            className={classes.root}
            getAriaValueText={(value) => `${value}${unit}`}
            value={value}
            onChange={(_evt, value) => {
              if (typeof value !== 'number') return
              setValue(value)
              updateDistanceUI({ value, unit })
            }}
            onChangeCommitted={(_evt, value) => {
              handleUpdateDistanceAround({ value, unit })
            }}
            valueLabelFormat={(val) => `${val}`}
            min={min}
            max={max}
            step={0.25}
          />
          <StyledSpan>{`${value} ${unit}`}</StyledSpan>
        </StyledFilterFlex>
      </MuiThemeProvider>
    </StyledFilterRow>
  )
}

export default memo(DistanceFilter)
