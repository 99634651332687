import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import { SCREENER_FORM_NAME } from '../../Shared/constants'
import RenderQuestion from './RenderQuestion'
import { onSubmit } from 'Actions/screener'
import {
  additionalIncomeType,
  clientPayFrequency,
  doesUser,
  getClientName,
  householdMemberName,
  householdMemberNamePossessive,
  householdMemberPayFrequency,
  hasUser,
  isUser,
  yourTheir,
} from 'Selectors'

import { StyledList, StyledListItem } from './style'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { StyledForm, StyledHeadingMain } from 'Components/Screener/style'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { finishEditing } from 'Actions/client'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { StyledParagraph } from 'Components/Screener/style'
import { StyledTermsAndButtonToContinueWrapper } from 'Components/PreScreener/style'
import { StyledDynamicCtaButton } from 'Components/Screener/style'

const Step = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const state = useSelector(selectFromState)
  const {
    screenerCategoryId,
    editing,
    editingCategoryId,
    categoryIndex,
    currentStep,
    descriptionText,
    descriptionTextList,
    handleSubmit,
    isCenter,
    firstP,
    mainText,
    question,
    questionDirectionText,
    questionText,
    stepNumber,
    textReplacements,
    textReplacementSelectors,
  } = { ...props, ...state }
  const focusRef = useRef(null)
  const scrollRef = useRef()

  useEffect(() => {
    setFocusToMainHeading()
    scrollToTopOfStep()
  }, [currentStep, categoryIndex])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const scrollToTopOfStep = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }

  if (currentStep !== stepNumber) return null

  const replaceText = (text, textReplacements) => {
    if (!textReplacements || !textReplacements.length) return text

    const mapObj = textReplacements.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.token]: textReplacementSelectors[curr.replacementValue],
      }),
      {}
    )
    const regExp = new RegExp(Object.keys(mapObj).join('|'), 'gi')
    const replaced = text
      ? text.replace(regExp, (matched) => mapObj[matched])
      : ''
    return replaced
      ? replaced.replace(/^\w/, (firstCharacter) =>
          firstCharacter.toUpperCase()
        )
      : ''
  }

  const replacedQuestionDirectionText = questionDirectionText
    ? replaceText(questionDirectionText, textReplacements)
    : ''
  const replacedQuestionText = questionText
    ? replaceText(questionText, textReplacements)
    : ''

  const questionProps = {
    ...state,
    ...props,
    question: {
      ...props.question,
      label: replaceText(question.label, textReplacements),
    },
    handleSubmit,
    replacedQuestionDirectionText,
    replacedQuestionText,
  }

  // Todo: Custom Callback
  const onReviewClick = (event) => {
    dispatch(finishEditing())
    navigate('/review')
  }
  const editedSectionComplete =
    editing &&
    editingCategoryId !== screenerCategoryId &&
    question.component === 'SectionIntroduction'

  const DescriptionParagraphs = ({ descriptionText }) =>
    descriptionText
      ? descriptionText.map((text, i) => (
          <StyledParagraph key={i} fontWeight='500' marginBottom='1.375rem'>
            {replaceText(text, textReplacements)}
          </StyledParagraph>
        ))
      : null

  return editedSectionComplete ? (
    <Container>
      <StyledTermsAndButtonToContinueWrapper reviewResponse={true}>
        <StyledDynamicCtaButton
          data-testid='reviewButton'
          onClick={onReviewClick}
        >
          review your responses
        </StyledDynamicCtaButton>
      </StyledTermsAndButtonToContinueWrapper>
    </Container>
  ) : (
    <Container data-testid='stepContainer' ref={scrollRef}>
      <Row alignItems='center' justifyContent='center'>
        <Col md='12'>
          <StyledForm
            margin='2rem 0'
            data-testid='stepForm'
            onSubmit={handleSubmit}
          >
            {firstP && (
              <StyledParagraph
                textalign='center'
                fontSize='1.25rem'
                fontWeight='300'
                marginBottom='1.1875rem'
              >
                {firstP}
              </StyledParagraph>
            )}
            {mainText && (
              <StyledHeadingMain
                textalign={isCenter ? 'center' : 'left'}
                id='mainContent'
                tabIndex='-1'
                ref={focusRef}
              >
                {replaceText(mainText, textReplacements)}
              </StyledHeadingMain>
            )}
            <DescriptionParagraphs descriptionText={descriptionText} />
            <StyledHouseholdMembersListItem margin='.6rem 0 0'>
              <MuiThemeProvider theme={ssTheme}>
                <StyledHouseholdMembersList>
                  {descriptionTextList && (
                    <StyledList margin='0 0 2rem'>
                      {descriptionTextList.map((item, index) => (
                        <StyledListItem key={index}>{item}</StyledListItem>
                      ))}
                    </StyledList>
                  )}
                  <RenderQuestion {...questionProps} />
                </StyledHouseholdMembersList>
              </MuiThemeProvider>
            </StyledHouseholdMembersListItem>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

const selectFromState = (state) => {
  const { currentStep, categoryIndex, steps, returnStep } = state.screener
  const { editing, editingStepNumber, editingCategoryId } = state.client
  return {
    backButtonClicked: returnStep,
    screenerCategoryId: steps[currentStep].category?.id,
    currentStep,
    categoryIndex,
    editing,
    editingStepNumber,
    editingCategoryId,
    textReplacementSelectors: {
      clientAdditionalIncomeType: additionalIncomeType(state, 'client'),
      clientName: getClientName(state),
      clientPrimaryJobPayFrequency: clientPayFrequency(state, 'primary'),
      clientSecondJobPayFrequency: clientPayFrequency(state, 'second'),
      clientThirdJobPayFrequency: clientPayFrequency(state, 'third'),
      clientFourthJobPayFrequency: clientPayFrequency(state, 'fourth'),
      doesUser: doesUser(state),
      householdMemberAdditionalIncomeType: additionalIncomeType(
        state,
        'householdMember'
      ),
      householdMemberName: householdMemberName(state),
      householdMemberNamePossessive: householdMemberNamePossessive(state),
      hasUser: hasUser(state),
      isUser: isUser(state),
      primaryJobPayFrequency: householdMemberPayFrequency(state, 'primary'),
      secondJobPayFrequency: householdMemberPayFrequency(state, 'second'),
      thirdJobPayFrequency: householdMemberPayFrequency(state, 'third'),
      fourthJobPayFrequency: householdMemberPayFrequency(state, 'fourth'),
      yourTheir: yourTheir(state),
    },
  }
}

export default reduxForm({
  form: SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onSubmit: (values, dispatch, props) => dispatch(onSubmit(props)),
})(Step)
