import styled from 'styled-components/macro'

export const StyledLoadingAnimationWrapper = styled.div`
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
StyledLoadingAnimationWrapper.displayName = 'StyledLoadingAnimationWrapper'
