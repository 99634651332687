import React from 'react'
import LocationInfo from '../LocationInfo/LocationInfo'
import { timeIconPath } from 'Shared/Icons/LocationInfo/paths'
import _get from 'lodash.get'

const TodaysClosingTime = ({ businessHours, alignitems }) => {
  const today = new Date().toLocaleString('us', { weekday: 'long' })
  const closingTime = _get(
    businessHours.filter((item) => item.day === today.toLowerCase()).pop(),
    'closingTime'
  )

  if (closingTime) {
    return (
      <LocationInfo
        alignitems={alignitems}
        content={`Open until ${closingTime} Today`}
        screenReaderLabel={'Open or Close'}
        icon={timeIconPath}
      />
    )
  } else {
    return null
  }
}

export default TodaysClosingTime
