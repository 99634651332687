import styled, { css } from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'

const StyledFilterForMobAndTab = css`
  background-color: unset;
  position: relative;
  z-index: 9;
`

const StyledActionForMobAndTab = css`
  color: ${(props) => (props.color ? props.color : theme.palette.color.white)};
  flex: auto;
  &:hover {
    background-color: ${theme.palette.color.black};
  }
`

export const StyledAction = styled.button`
  padding: ${(props) => (props.padding ? props.padding : 'inherit')};
  align-items: center;
  color: ${theme.palette.color.black};
  display: flex;
  font-weight: 600;
  opacity: ${(props) => props.opacity};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2rem')};
  flex: ${(props) => (props.flex ? props.flex : '0 1 0')};
  min-width: 4rem;
  border: inherit;
  text-transform: capitalize;
  background-color: ${(props) =>
    props.background ? props.background : theme.palette.color.lightGrey};
  border-radius: 7px;
  justify-content: ${(props) => (props.justCont ? props.justCont : 'center')};
  transition: background-color 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.secondary.default};
  }
  ${media.smaller`
  ${StyledActionForMobAndTab}
  `};
  ${media.tablet`
  ${StyledActionForMobAndTab}
  `};
  ${media.desktop`
    color: ${theme.palette.color.black};
    flex: ${(props) => (props.flex ? props.flex : '0 1 0')};
    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.secondary.default};
    };
  `}
  ${({ activeFilter }) =>
    activeFilter &&
    css`
      background: #000;
      &:hover {
        background: #000;
      }
    `};

  ${({ filterinmobile }) =>
    filterinmobile &&
    css`
      ${media.smaller`
        ${StyledFilterForMobAndTab}
        top: ${(props) => (props.topMob ? props.topMob : '30%')};
        right: ${(props) => (props.rightMob ? props.rightMob : '7%')};
        left: ${(props) => props.leftMob}
        &:hover {
          cursor: pointer;
          background-color: unset;
        };
      `};
      ${media.tablet`
      ${StyledFilterForMobAndTab}
        top: ${(props) => (props.topTab ? props.topTab : '24%')};
        right: ${(props) => (props.rightTab ? props.rightTab : '3.5%')};
        left: ${(props) => props.leftTab}
        &:hover {
          cursor: pointer;
          background-color: unset;
        };
      `}
    `};
`

StyledAction.displayName = 'StyledAction'
