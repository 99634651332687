import styled from 'styled-components/macro'
import theme from 'Shared/Theme/ssTheme'

export const StyledDesktopSecondaryActionButton = styled.button`
  position: absolute;
  top: 30px;
  left: ${(props) =>
    props.leftPosition ? props.leftPosition : theme.layout.asideWidth};
  width: 24px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 0 0.5em 0.5em 0;
  text-align: center;
  background-color: #fff;
  z-index: 10;
  box-shadow: 5px 0 9px 0px rgba(0, 0, 0, 0.1);
  &:after {
    position: absolute;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #858585;
    border-width: 5px;
    margin-top: -5px;
    transform: ${(props) =>
      props.transformButton ? 'rotate(0)' : 'rotate(180deg)'};
    left: ${(props) => (props.transformButton ? '3px' : '8px')};
  }
  :hover {
    cursor: pointer;
  }
  &:focus {
    background: #f1f1f1;
  }
`

StyledDesktopSecondaryActionButton.displayName =
  'StyledDesktopSecondaryActionButton'
