import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetCompleteScreener } from 'Actions/client'

const useHandleRescreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleRescreen = () => {
    dispatch(resetCompleteScreener())
    navigate('/screening')
  }

  return handleRescreen
}

export default useHandleRescreen
