import { FORM_ERROR } from 'final-form'
import { useNavigate } from 'react-router-dom'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'

/**
 * Provides an onSubmit callback to final-form that then calls a mutation
 * or PATCH (client or screening...) as needed
 *
 * @returns {function}
 */
const useHandleOnSubmit = (submitCallback) => {
  const navigate = useNavigate()

  return (values) =>
    new Promise((resolve) =>
      submitCallback(values)
        .then(() => {
          resolve()
          navigate('/review')
        })
        .catch(() =>
          resolve({
            [FORM_ERROR]: ERROR_GENERIC_MESSAGE,
          })
        )
    )
}

export default useHandleOnSubmit
