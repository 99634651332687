import React, { useState } from 'react'
import Surface from '../Surface/Surface'
import {
  StyledLocationService,
  StyledBullet,
  StyledLocationServices,
  StyledButtonReferral,
  ContainerButton,
} from './style'
import {
  StyledCategory,
  StyledShowMore,
  StyledH1,
  StyledH3,
  StyledBorderBottom,
} from 'Components/LocalResources/LocationCard/style'
import { StyledHeader } from '../Header/style'
import ActionBar from '../ActionBar/ActionBar'
import {
  directionsIconPath,
  phoneIconPath,
  publicIconPath,
  shareIconPath,
  infoIconPath,
} from 'Shared/Icons/LocationInfo/paths'
import ActionButton from '../ActionButton/ActionButton'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import SendIcon from '@material-ui/icons/Send'
import StartReferralModal from './StartReferral/StartReferral'
import { useSelector } from 'react-redux'
import useCreateReferral from './StartReferral/hooks/Hooks'
import ConfirmationModal from 'Components/CaseManagement/Modals/ConfirmationModal/ConfirmationModal'
import { isJestTest } from 'Shared/helpers'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'

const StyledDialog = withStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '23px',
      padding: '0 5rem',
    },
  },
})(Dialog)

const Header = (props) => {
  const [open, setOpen] = React.useState(false)
  const client = useSelector((state) => state.client)
  const { handleCreateReferral, handleGetClientLocation } = useCreateReferral()

  const isFeatureToggleOn = useIsFeatureToggleOn()
  const enableReferrals = isFeatureToggleOn('referrals')

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const { badges, location, preferredPartner } = props
  const { name, numbers, url, coordinates, description } = location
  const phoneNumber = numbers.primary ? numbers.primary.number : null

  let [more, setMore] = useState(false)

  const [openReferral, setOpenReferral] = useState(false)
  const [referralInfo, setReferralInfo] = useState(null)
  const [confirmReferral, setConfirmReferral] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const submitReferral = (values) => {
    setReferralInfo({ ...values })
    setOpenReferral(false)
    setConfirmReferral(true)
    setError(false)
  }
  const handlerToggleMore = () => {
    setMore(!more)
  }

  const handleOpenReferral = () => {
    setOpenReferral(true)
  }

  const handleCloseReferral = () => {
    setOpenReferral(false)
  }

  const createReferral = async () => {
    setError(false)
    setSubmitting(true)
    try {
      const { data: locationInfo } = await handleGetClientLocation(client.id)
      const { data } = await handleCreateReferral(
        locationInfo.calculateScreeningResults.screening.serviceCases[0]
          .clientLocation.id,
        [
          {
            locationId: referralInfo.locationId,
            recipientType: 'CLIENT',
            referralCategories: referralInfo.services || [],
          },
        ]
      )
      if (data) {
        setConfirmReferral(false)
      }
    } catch (error) {
      !isJestTest() && console.error(error)
      setError(true)
    }
    setSubmitting(false)
  }
  const { lat, lng } = coordinates

  const actionType = 'HEADER'

  const actions = [
    {
      icon: phoneIconPath,
      label: 'Call',
      callback: () =>
        phoneNumber ? window.open(`tel:+${phoneNumber}`, '_blank') : null,
      key: 'call',
      disabled: !phoneNumber,
    },
    {
      icon: directionsIconPath,
      label: 'Directions',
      callback: () =>
        lat && lng
          ? window.open(
              `http://www.google.com/maps/place/${lat},${lng}`,
              '_blank'
            )
          : null,
      key: 'directions',
      disabled: !lat && lng,
    },
    {
      icon: infoIconPath,
      label: 'Info',
      callback: () => handleClickOpen(),
      key: 'info',
    },
    {
      icon: publicIconPath,
      label: 'Website',
      callback: () => (url ? window.open(url, '_blank') : null),
      key: 'website',
      disabled: !url,
    },
    { icon: shareIconPath, label: 'Share', key: 'share' },
  ].map((action) =>
    action.key !== 'share' ? <ActionButton {...action} /> : null
  )

  return (
    <div>
      <Surface elevation={5} boxShadow={'none'} width={'100%'}>
        <StyledHeader
          padding={'.8rem 1.6rem'}
          actionType={actionType}
          backgroundColor={'#ffffff'}
        >
          <StyledBorderBottom>
            <StyledH1
              actionType={actionType}
              wrapProp={false}
              color='#3B452F'
              className='cypress-location-name'
            >
              {name}
            </StyledH1>
            {preferredPartner}
          </StyledBorderBottom>
          <StyledBorderBottom>
            <ActionBar
              actionType='ACTIONS'
              children={actions}
              backgroundColor={'unset'}
            />
          </StyledBorderBottom>
          <StyledBorderBottom>
            <StyledH3>Services Types</StyledH3>
            <StyledCategory
              paddBottom={badges.length > 4 ? '3rem' : '1rem'}
              actionType='HEADER'
              maxHeight={more ? '100%' : '15rem'}
            >
              {badges}
              {badges.length > 4 && (
                <StyledShowMore onClick={() => handlerToggleMore()}>
                  Show {more ? 'Less' : 'More'} services
                </StyledShowMore>
              )}
            </StyledCategory>
          </StyledBorderBottom>
          {client.authenticated && enableReferrals && (
            <ContainerButton style={{ padding: '1rem' }}>
              <StyledButtonReferral
                color='primary'
                onClick={handleOpenReferral}
                data-testid='start-referral'
              >
                <SendIcon style={{ fontSize: '1.125rem' }} />{' '}
                {`Start referral for this location`}
              </StyledButtonReferral>
            </ContainerButton>
          )}
          {location?.categories?.length > 0 && (
            <StyledBorderBottom>
              <StyledH3>This resource helps with</StyledH3>
              <StyledLocationServices>
                {[...new Set(location.categories)].map((category, i) => (
                  <StyledLocationService key={i}>
                    <StyledBullet>&#8226;</StyledBullet>
                    {category}
                  </StyledLocationService>
                ))}
              </StyledLocationServices>
            </StyledBorderBottom>
          )}
        </StyledHeader>
      </Surface>
      <StyledDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
          style={{
            fontSize: '1.6875rem',
            color: '#29283E',
            fontFamily: 'inherit',
            letterSpacing: 'normal',
            textAlign: 'center',
          }}
        >
          More about this resource
          <p
            style={{
              fontSize: '0.8125rem',
              fontWeight: '500',
              color: '#29283E',
              fontFamily: 'inherit',
              width: '100%',
              textAlign: 'center',
              opacity: '0.5',
              margin: '5px 0',
            }}
          >
            {name}
          </p>
        </DialogTitle>
        <MuiDialogContent>
          <Typography
            style={{
              fontSize: '0.8125rem',
              fontWeight: '400',
              color: '#393946',
              fontFamily: 'inherit',
              margin: '0 auto 2rem',
              width: '92%',
            }}
          >
            {description}
          </Typography>
        </MuiDialogContent>
      </StyledDialog>
      <StartReferralModal
        open={openReferral}
        handleClose={handleCloseReferral}
        submitReferral={submitReferral}
        serviceBadges={badges}
        location={location}
      />
      {confirmReferral && (
        <ConfirmationModal
          handleOnCancel={() => {
            //TO-DO: reset values
            setConfirmReferral(false)
          }}
          heading={'Are you sure you want to start a referral?'}
          detail={`When you start a referral, an entry will be made in your 
            client record with information about the location and services. 
            Your case manager may follow up with you about your experience 
            with this location.`}
          handleOnConfirm={createReferral}
          confirmLabel={
            submitting ? 'Starting referral' : 'Yes, Start referral'
          }
          error={error}
          submitting={submitting}
        />
      )}
    </div>
  )
}

export default Header
