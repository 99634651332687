import React from 'react'
import { StyledSubHeading } from 'Components/Screener/style'
import {
  StyledBox,
  StyledCustomBox,
} from 'Components/Review/ReviewSection/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { useMediaQuery } from 'react-responsive'
import CustomQuestionField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { INPUT_TYPE_STRING } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'

/**
 * Component to display a name value pair in the Review section of the
 * screener. dataType defaults to INPUT_TYPE_STRING
 *
 * displayValue is an optional property to use to display the value in human
 * readable format.
 *
 * Example: show 'true' as 'Yes': assign 'Yes' to displayValue and the component
 * will render 'Yes' instead of 'true.
 *
 * @param {{name:string, displayValue:string, isEditing:boolean, id:string, options:Array, dataType:string}} params
 */
const ValueDisplay = ({
  name,
  value,
  verified,
  displayValue,
  isEditing,
  required = false,
  id,
  options,
  hideIfEmpty = false,
  validator,
  maxLength,
  inputTypePhone,
  dataType = INPUT_TYPE_STRING,
}) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  const StyledBoxWraper = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Alternate Email',
  ].some((label) => label === name)
    ? StyledCustomBox
    : StyledBox
  const isPhoneOrEmail = [
    'Login - Phone Number',
    'Alternate Phone Number',
    'Login - Email',
    'Alternate Email',
  ].some((label) => label === name)

  return (
    <>
      {isEditing ? (
        <StyledBoxWraper>
          <CustomQuestionField
            name={id}
            label={name}
            dataType={dataType}
            options={options}
            required={required}
            validator={validator}
            maxLength={maxLength}
            inputTypePhone={inputTypePhone}
          />
        </StyledBoxWraper>
      ) : (
        <StyledBoxWraper>
          {value !== 'Not Available' && (
            <>
              <StyledSubHeading
                reviewInfoCard={true}
                fontSize={isMobile ? '1rem' : '.75rem'}
                fontWeight='400'
                marginBottom='0.625rem'
              >
                {name}
              </StyledSubHeading>
              <StyledSubHeading
                reviewInfoCard={true}
                fontSize='1rem'
                fontWeight='600'
                marginBottom='0'
              >
                {displayValue ?? value}

                {isPhoneOrEmail && value !== 'Not Available' && (
                  <UserVerificationStatusIcon verified={verified} />
                )}
              </StyledSubHeading>
            </>
          )}
        </StyledBoxWraper>
      )}
    </>
  )
}

export default ValueDisplay
