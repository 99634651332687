import { mapResponses } from 'Components/Screener/Hooks/helpers'
import { removeScreenerFields } from '.'

export const basicInformationSubmitCallback =
  (clientId, handlePatchClient, saveCustomFields) => (values) =>
    new Promise((resolve, reject) => {
      // Patch Client: Save First Name, Last Name, and Nickname
      handlePatchClient(values)
        .then(() => {
          // Mutation: Save Custom Questions
          removeScreenerFields(values)
          saveCustomFields({
            variables: {
              clientId,
              responses: mapResponses(values),
            },
          })
            .then(() => resolve())
            .catch(() => reject())
        })
        .catch(() => reject())
    })

export const alternateContactInformationSubmitCallback =
  (clientId, saveAlternateContactInformation) => async (values) => {
    return Promise.all(
      Object.keys(values).map((key) => {
        return Promise.resolve(
          saveAlternateContactInformation({
            variables: {
              clientId,
              alternateContact: values[key] === '' ? null : values[key],
              alternateContactType: key.toLocaleUpperCase(),
            },
          })
        )
      })
    )
  }
