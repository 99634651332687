import { fetchAlgolia } from 'Actions/algolia'
import { updateCoordinates, updateZipCode } from './geolocation'

export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
export const LOAD_INITIAL_LOCATIONS = 'LOAD_INITIAL_LOCATIONS'
export const UPDATE_LOCATION_DETAIL = 'UPDATE_LOCATION_DETAIL'

export const fetchLocationDetails = (id) => (dispatch, getState) => {
  const { searchResults: locations } = getState().locations

  if (locations[id]) {
    dispatch(updateLocationDetail(id, locations[id]))
  } else {
    dispatch(
      fetchAlgolia('', {
        facetFilters: [[`id:${id}`]],
      })
    ).then((results) => {
      const searchResults = results.reduce((acc, location) => {
        acc[location.id] = location
        return acc
      }, {})
      const [result] = results

      dispatch(updateZipCode(result.address.zipCode))
      dispatch(updateCoordinates(result.coordinates))
      dispatch(loadInitialLocations(searchResults))
      dispatch(updateLocationDetail(id, result))
    })
  }
}

export const loadInitialLocations = (initialLocations) => ({
  type: LOAD_INITIAL_LOCATIONS,
  initialLocations,
})

export const updateLocationDetail = (id, locationDetail) => ({
  type: UPDATE_LOCATION_DETAIL,
  locationDetail,
  id,
})

export const getLocationsFailure = (error) => ({
  type: GET_LOCATIONS_FAILURE,
  error,
})
