import React from 'react'
import { StyledSubHeading } from 'Components/Screener/style'

/**
 * Review Page Header
 */
const ReviewHeading = () => (
  <StyledSubHeading
    fontSize='1.25rem'
    fontWeight='700'
    marginBottom='0'
    paddingBottom='0.75rem'
    paddingTop='0.75rem'
    borderBottom='2px solid #F1F1F1'
    borderBottomMobile='unset'
    paddingBottomMobile='0'
    paddingTopMobile='1.25rem'>
    Review Your Responses
  </StyledSubHeading>
)

export default ReviewHeading
