/**
 * CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR
 * Checkbox type that selects one or more household members, and sets those
 * household member(s) to the filtered household members array
 * @type {String}
 */
export const CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR = 'householdMemberSelector'

/**
 * [CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR description]
 * Same as CHECKBOX_TYPE_CATEGORY_SELECTOR, renders CheckboxesFormSection.
 * Only iterates over Filtered Household Members.
 * @see CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR
 * @type {String}
 */
export const CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR =
  'householdMemberCategorySelector'

/**
 * [CHECKBOX_TYPE_CATEGORY description]
 * Same as CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR, renders
 * CheckboxesFormSection.
 * Only iterates over Filtered Household Members
 * @see CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR
 * @type {String}
 */
export const CHECKBOX_TYPE_CATEGORY_SELECTOR = 'categorySelector'

/**
 * CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER
 * Checkbox Type for a question
 * that asks one household member a single question that can have multiple
 * answers.
 *
 * @type {String}
 */
export const CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER =
  'householdMemberMultiAnswer'

/**
 * CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT
 * For when you don't want the client to show up in the household members
 * checkbox list selector
 *
 * @type {String}
 */
export const CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT =
  'householdMembersExcludingClient'
