import React from 'react'
import { Coverimg, ParentCover } from './CoverImage.style'
import SecondaryActionBar from 'Components/Shared/SecondaryActionBar/SecondaryActionBar'

const CoverImage = (props) => {
  return (
    <ParentCover>
      <Coverimg {...props} />
      <SecondaryActionBar
        boxShadow='unset'
        opacity='1'
        bottom='0'
        left='0'
        width='auto'
        minHeight='4rem'
        minHeightMob='4rem'
        minHeightTab='5rem'
        background='none'
        backgroundBtn='none'
        actionPadding='0 1rem'
        callback={props.callback}
      />
    </ParentCover>
  )
}

export default CoverImage
